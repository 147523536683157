import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {eyebrows,eyelashextension,facial,haircolours,hotwax,massage,threading,waxing,doubledrawnindianhairextension} from "../../assets/img";

function Massage () {

return (
    <>
        <section className="rbpsec">
            <div className="container">                
                <div className="row justify-content-center">                    
                    <div className="col-md-5">
                        <div className="rbptable">
                            <h3>Massage</h3>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>Reflexology treatment</td>
                                        <td>£60.00</td>
                                        <td className="text-end w-cus1">60mins</td>
                                    </tr>
                                    <tr>
                                        <td>Head,shoulder,back & neck</td>
                                        <td>£25.00</td>
                                        <td className="text-end w-cus1">30mins</td>
                                    </tr>
                                    <tr>
                                        <td>Swedish</td>
                                        <td>£25.00</td>
                                        <td className="text-end w-cus1">30mins</td>
                                    </tr>
                                    <tr>
                                        <td>Aromatherapy full body</td>
                                        <td>£50.00</td>
                                        <td className="text-end w-cus1">60mins</td>
                                    </tr>
                                    <tr>
                                        <td>Deep tissue massage</td>
                                        <td>£30.00</td>
                                        <td className="text-end w-cus1">30mins</td>
                                    </tr>
                                    <tr>
                                        <td>Hot stone massage</td>
                                        <td>£30.00</td>
                                        <td className="text-end w-cus1">25mins</td>
                                    </tr>
                                    <tr>
                                        <td>Stress relief<span className="rbprctext">with Kerala oil</span></td>
                                        <td>£30.00</td>
                                        <td className="text-end w-cus1">30mins</td>
                                    </tr>
                                    <tr>
                                        <td>Ear Candle</td>
                                        <td>£25.00</td>
                                        <td className="text-end w-cus1"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="rbpimg">
                            <img src={massage} alt="massage" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
)
}
export default Massage;