import React from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import SectionOneThemeFour from "./section-one";
import SectionTwoBannerThemeFour from "./section-two";
import SectionThreeThemeFour from "./section-three";
import SectionFourThemeFour from "./section-four";
import SectionFiveThemeFour from "./section-five";
import SectionSixThemeFour from "./section-six";
import SectionSevenThemeFour from "./section-seven";
import SectionEightThemeFour from "./section-eight";
import SectionNineThemeFour from "./section-nine";
import SectionTenThemeFour from "./section-ten";
import SectionElevenThemeFour from "./section-eleven";
import SectionTwelveThemeFour from "./section-twelve";
import SectionThirteenThemeFour from "./section-thirteen";
import SectionFourteenThemeFour from "./section-fourteen";
import SectionOffer from "./section-offer";
import GoogleReviews from "../googleReview";
import { checkShopListFlow } from "../../utility";
import HomeShopList from "../homeShopList";
import DailyDeals from "../dailyDeals";

function ThemeFour() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  const checkIsSectionEnabled = (index: any) => {
    if (settingsData?.template == 4) {
      return jsonData?.theme_4?.home?.sections[index]?.is_section_enable;
    }
  };

  return (
    <>
      {checkIsSectionEnabled(0) && <SectionOneThemeFour />}
      {checkIsSectionEnabled(1) && <SectionTwoBannerThemeFour />}
      {checkShopListFlow() && <HomeShopList/>}
      {checkShopListFlow() && <DailyDeals/>}
      {/* <SectionOffer /> */}
      <GoogleReviews />
      {checkIsSectionEnabled(2) && <SectionThreeThemeFour />}
      {checkIsSectionEnabled(3) && <SectionFourThemeFour />}
      {checkIsSectionEnabled(4) && <SectionFiveThemeFour />}
      {checkIsSectionEnabled(5) && <SectionSixThemeFour />}
      {checkIsSectionEnabled(6) && <SectionSevenThemeFour />}
      {checkIsSectionEnabled(7) && <SectionEightThemeFour />}
      {checkIsSectionEnabled(8) && <SectionNineThemeFour />}
      {checkIsSectionEnabled(9) && <SectionTenThemeFour />}
      {checkIsSectionEnabled(10) && <SectionElevenThemeFour />}
      {checkIsSectionEnabled(11) && <SectionTwelveThemeFour />}
      {checkIsSectionEnabled(12) && <SectionThirteenThemeFour />}
      {checkIsSectionEnabled(13) && <SectionFourteenThemeFour />}
    </>
  );
}

export default ThemeFour;
