import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TimePicker from "react-time-picker";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

import ReCAPTCHA from "react-google-recaptcha";
import {
  addressPattern,
  alphaNumericPattern,
  alphabet,
  email,
  phoneNumber,
} from "../core/regex";
import { specialRequestForm } from "../../redux/Actions";
interface TimeSlot {
  name: string;
  value: string;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .matches(alphabet, "Name should be only alphabet"),
  email: yup
    .string()
    .required("Email is required")
    .matches(email, "Enter a valid email"),
  mobile: yup
    .string()
    .required("Mobile is required")
    .matches(phoneNumber, "Enter a valid mobile number"),
    time: yup
    .string()
    .required("Time is required"),    
    service_type: yup
    .string()
    .matches(
      alphaNumericPattern,
      "Type of services should not have any special characters"
    ),
});

function SpecialRequest() {
  const [isRecaptchaCompleted, setIsRecaptchaCompleted] = useState(true);
  const [error, setError] = useState("");
  const initialValues: any = {
    name: "",
    email: "",
    mobile: "",
    time: "",
    service_type: "",
  };
  const {
    control: specialRequestControl,
    handleSubmit: tableBookHandleSubmit,
    reset: specialRequestFormReset,
    setValue: specialRequestSetValue,
    formState: { errors, isValid },
  } = useForm<any>({
    resolver: yupResolver<any>(schema),
    defaultValues: initialValues,
    mode: "onChange",
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      let user_details: any = localStorage.getItem("userDetails");
      user_details = JSON.parse(user_details).data.user_info;
      specialRequestSetValue("name", user_details.name);
      specialRequestSetValue("email", user_details.email);
      specialRequestSetValue("mobile", user_details.mobile_number);
    }
  }, []);

  const onSubmitBookingForm = async (payload: any) => {
    setError("");
    const response: any = await specialRequestForm(payload);
    if (response.status) {
      specialRequestFormReset();
      setError(response.message);
      setTimeout(() => {
        setError("");
      }, 5000);
    } else {
      setError(response.message);
    }
  };

  const handleRecaptchaChange = (value: any) => {
    if (value != null) {
      setIsRecaptchaCompleted(false);
    } else {
      setIsRecaptchaCompleted(true);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card card-bt-form">
              <h3>Special Request</h3>
              <form>
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label>Name</label>
                      <Controller
                        name="name"
                        control={specialRequestControl}
                        render={({ field }) => (
                          <input
                            type="text"
                            className={`form-control ${
                              errors.name ? "invalid" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />
                      {errors?.name?.message && (
                        <small className="text-danger">
                          {errors?.name?.message as React.ReactNode}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label>Email ID</label>
                      <Controller
                        name="email"
                        control={specialRequestControl}
                        render={({ field }) => (
                          <input
                            type="text"
                            className={`form-control ${
                              errors.email ? "invalid" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />
                      {errors?.email?.message && (
                        <small className="text-danger">
                          {errors?.email?.message as React.ReactNode}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label>Phone</label>
                      <Controller
                        name="mobile"
                        control={specialRequestControl}
                        render={({ field }) => (
                          <input
                            type="text"
                            className={`form-control ${
                              errors.mobile ? "invalid" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />
                      {errors?.mobile?.message && (
                        <small className="text-danger">
                          {errors?.mobile?.message as React.ReactNode}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label>Best time to call back</label>
                      {/* <Controller
                        name="time"
                        control={specialRequestControl}
                        render={({ field }) => (
                          <input
                            type="text"
                            className={`form-control ${
                              errors.time ? "invalid" : ""
                            }`}
                            {...field}
                          />
                        )}
                      /> */}
                      <Controller
                        name="time"
                        control={specialRequestControl}
                        render={({ field }) => (
                        <TimePicker
                            {...field}
                            onChange={(value) => field.onChange(value)}
                            value={field.value || ''}
                            className={`form-control ${errors.time ? "invalid" : ""}`}
                        />
                        )}
                    />
                      {errors?.time?.message && (
                        <small className="text-danger">
                          {errors?.time?.message as React.ReactNode}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label>Type of services</label>
                      <Controller
                        name="service_type"
                        control={specialRequestControl}
                        render={({ field }) => (
                          <textarea
                            className={`form-control ${
                              errors.service_type ? "invalid" : ""
                            }`}
                            {...field}
                          ></textarea>
                        )}
                      />
                      {errors?.service_type?.message && (
                        <small className="text-danger">
                          {errors?.service_type?.message as React.ReactNode}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row align-items-end">
                  <div className="col-md">
                    <ReCAPTCHA
                      sitekey="6Ld0akMiAAAAAJn3hCRDkEJll1gxFTxsv-9NjeVV"
                      onChange={handleRecaptchaChange}
                    />
                  </div>
                  <div className="col-md">
                    <div className="mb-3 d-flex flex-column align-items-end">
                      {error != "" && (
                        <span className="text-danger">{error}</span>
                      )}
                      <button
                        onClick={tableBookHandleSubmit(onSubmitBookingForm)}
                        type="submit"
                        disabled={isRecaptchaCompleted}
                        className="btn btn-primary"
                      >
                        Request
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SpecialRequest;
