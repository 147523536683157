export const shop_data_laundry = {
  theme_1: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/img/section/section1.png",
        },
        {},
        // {
        //   _id: 2,
        //   is_section_enable: true,
        //   icon: "/assets/img/section/section2.png",
        // },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/img/section/section3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/img/section/section4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "/assets/img/section/section5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/img/section/section6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/img/section/section7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "/assets/img/section/section8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "/assets/img/section/section9.png",
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "/assets/img/section/section10.png",
        },
      ],
      header: {
        primary_color: "ff0059",
        secondary_color: "ffcb00",
        nav: {
          nav_bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            cart_bg_color: "ffffff",
            cart_icon_bg_color: "f07f1b",
            cart_value_color: "000000",
          },
          contact: {
            contact_is_enable: true,
            number: "+(044) 071012345",
            contact_color: "2e3639",
          },
        },
        btn_group: {
          btn1: {
            text: "Login / Register",
            btn_bg_color: "ffffff",
            btn_color: "1b9db3",
            border_color: "1b9db3",
            btn1_is_enable: true,
          },
        },
      },
      section_1: {
        banner: "/assets/img/home_page_single_search.jpg",
        sub_heading_top: "24/7 Dry Cleaning & Laundry Service",
        title: "One Solution for all your Washing Needs",
        paragraph:
          "While you are doing something important, we will put things in order in the apartment, private house or office, clean the upholstered furniture and wash the windows.",
        btn_group: {
          btn1: {
            text: "Book now",
            placeholder: "Enter Postalcode",
            bg_color: "1b9db3",
            color: "ffffff",
          },
        },
      },
      section_2: {
        title: "Select Your Time & Location For The Service",
        btn_group: {
          btn1: {
            text: "order now",
            placeholder: "Enter Postcode",
            bg_color: "1b9db3",
            color: "ffffff",
          },
        },
      },
      section_3: {
        is_section_enable: true,
        banner: "/assets/img/trustimg.png",
        title: "Trust the Expert",
        paragraph:
          "Champion Cleaners aim has always been to provide 5 star premium dry cleaning and laundry services to expat and local populations of Dubai and the United Arab Emirates",
        sub_heading: "Unique solutions for all your laundry needs",
        check_list: [
          {
            _id: 1,
            title: "Clean for professional garment care",
          },
          {
            _id: 1,
            title: "Wash & Fold for items that don't need pressing",
          },
          {
            _id: 1,
            title: "Press Only for clean clothes that need pressing",
          },
          {
            _id: 1,
            title: "ShoeCare for cleaned and restored shoes",
          },
        ],
        btn_group: {
          btn1: {
            text: "Read More",
            bg_color: "#ffcb00",
            color: "ffffff",
            is_enable: true,
          },
        },
      },
      section_4: {
        title: "Your Health & Safety Is Our Priority",
        cards: [
          {
            heading: "Contactless deliveries",
            icon: "/assets/img/icons/priorityicon1.svg",
            image: "/assets/img/home/priorityimg1.jpg",
            paragraph:
              "For our customers safety, we enabled the feature to allow customers to conduct contactless pick up & deliveries.",
          },
          {
            heading: "Monitoring the situation",
            icon: "/assets/img/icons/priorityicon2.svg",
            image: "/assets/img/home/priorityimg2.jpg",
            paragraph:
              "We’re keeping up to date with all developments from government to ensure the safety of our customers and staff.",
          },
          {
            heading: "Instant updates",
            icon: "/assets/img/icons/priorityicon3.svg",
            image: "/assets/img/home/priorityimg3.jpg",
            paragraph:
              "We’ll keep you posted throughout the process with texts/emails when driver is on their way and order has been cleaned.",
          },
        ],
      },
      section_5: {
        is_section_enable: true,
        banner: "/assets/img/phone.png",
        title: "Just A Click Away",
        paragraph:
          "Our apps got a better and convenient way of getting your laundry and dry cleaning with our 5-star services. Download now and register to get more exclusive offers",
        check_list: [
          {
            _id: 1,
            icons: "/assets/img/icons/priorityicon1.svg",
            title: "Users",
            count: "25000",
          },
          {
            _id: 2,
            icons: "/assets/img/icons/priorityicon2.svg",
            title: "Downloads",
            count: "40000",
          },
          {
            _id: 3,
            icons: "/assets/img/icons/priorityicon3.svg",
            title: "Ratings",
            count: "4.5",
          },
        ],
        mobile_link_section: {
          is_enable: true,
          android_link:
            "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link:
            "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
        },
      },
      section_6: {
        heading: "Save with Our Subscriptions",
        card_one_title: "Laundry Bag",
        card_one_paragraph:
          "One bag = one price, fill it up and get it washed! Includes FREE next day delivery & all perks from Laundry Go. Ideal for couples & families. Plans start from £25/month",
        card_one_image: "/assets/img/home/wing1.png",
        card_two_title: "Ongoing discount on All Services",
        card_two_paragraph:
          "10% OFF all services (apart from laundry) for just £3.99/month. This includes Eco Dry Cleaning, shirt service & bed linen service. Ideal for single busy professionals & couples.",
        card_two_image: "/assets/img/home/wing2.png",
        card_1: {
          card_one_title: "Laundry Bag",
          card_one_paragraph:
            "One bag = one price, fill it up and get it washed! Includes FREE next day delivery & all perks from Laundry Go. Ideal for couples & families. Plans start from £25/month",
          card_one_image: "/assets/img/home/wing1.png",
        },
        card_2: {
          card_two_title: "Ongoing discount on All Services",
          card_two_paragraph:
            "10% OFF all services (apart from laundry) for just £3.99/month. This includes Eco Dry Cleaning, shirt service & bed linen service. Ideal for single busy professionals & couples.",
          card_two_image: "/assets/img/home/wing2.png",
        },
      },
      section_8: {
        title: "What Our Client Say",
        cards: [
          {
            profile: "/assets/img/home/testimonialuser1.jpg",
            name: "Judy Wells",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "/assets/img/home/testimonialimg1.jpg",
          },
          {
            profile: "/assets/img/home/testimonialuser2.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "/assets/img/home/testimonialimg2.jpg",
          },
          {
            profile: "/assets/img/home/testimonialuser3.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "/assets/img/home/testimonialimg3.jpg",
          },
          {
            profile: "/assets/img/home/testimonialuser1.jpg",
            name: "Judy Wells",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "/assets/img/home/testimonialimg1.jpg",
          },
          {
            profile: "/assets/img/home/testimonialuser2.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "/assets/img/home/testimonialimg2.jpg",
          },
          {
            profile: "/assets/img/home/testimonialuser3.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "/assets/img/home/testimonialimg3.jpg",
          },
        ],
      },
      section_10: {
        title: "Latest News & Events",
        button: {
          text: "View More",
          bg_color: "#ffcb00",
          color: "ffffff",
          is_enable: true,
        },
      },
      section_7: {
        is_section_enable: true,
        title: "On Demand Laundry, Dry Cleaning and Ironing Service",
      },
      section_9: {
        is_section_enable: true,
        title: "Frequently Asked Questions",
        paragraph:
          "online laundry & dry cleaning companies who simply just own the technology and farm the cleaning to other providers with little to no process system checks.",
        banner: [
          {
            banner: "/assets/img/faqimg.jpg",
          },
        ],
        card_heading: "CALL US FOR A FREE QUOTE ON:",
        card_number: "+(044) 071012345",
        card_buttontext: "Enquire Now",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "Do you have a re-cleaning Policy?",
              isEnabled: true,
              collection_paragraph:
                "Although we have quality control where we check each garment before we pack, if we are unable to remove stains we usually inform customers before. If something slips through and you are not completely satisfied with the quality of our dry cleaning, we will re-clean your item free of charge, as long as it’s within 24 hours.",
            },
            {
              _id: 2,
              collection_title: "Do you have policy grantees?",
              isEnabled: true,
              collection_paragraph: "Yes",
            },
          ],
        },
      },
      footer: {
        bg_color: "ffffff",
        section_heading_color: "333333",
        section_paragraph_color: "c2c2c2",
        mbl_number : "+442086388690",
        email_val: "sales@bestatservices.com",
        address_val: "81 Randall Drive, Hornchurch - RM126TA",
        footer_section: {
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
      },
    },
  },
  theme_2: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/section-two/section1.png",
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/section-two/section2.png",
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/section-two/section3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/section-two/section4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "/assets/section-two/section5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/section-two/section6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/section-two/section7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "/assets/section-two/section8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "/assets/section-two/section9.png",
        },
      ],
      header: {
        primary_color: "ff0059",
        secondary_color: "ffcb00",
        nav: {
          nav_bg_color: "ffffff",
          header_bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
        },
        btn_group: {
          btn1: {
            text: "Continue to order",
            btn_bg_color: "ff0059",
            btn_color: "ffffff",
          },
          btn2: {
            btn2_text: "Order Now",
            btn2_bg_color: "ffcb00",
            btn2_color: "2e3639",
          },
        },
      },
      section_1: {
        title_1: "Solution for your",
        title_2: "Laundry & Dry Cleaning Service",
        title_3: "Highest quality and lowest price guaranteed.",
        card_section: [
          "/assets/img/theme_2/banner-slider-01.jpg",
          "/assets/img/theme_2/banner-slider-02.jpg",
          "/assets/img/theme_2/banner-slider-03.jpg",
          "/assets/img/theme_2/banner-slider-04.jpg",
          "/assets/img/theme_2/banner-slider-05.jpg",
        ],
      },
      section_2: {
        is_section_enable: true,
        banner: "/assets/img/trustimg2.png",
        sub_heading_top: "Our Introduction",
        title: "Welcome",
        mainparagraph:
          "There are many variations of passag of lorem ipsum available but the majority have suffered alteration in some form by injected humour randomised words which don't look even slightly believable.There are many variations of passag of lorem ipsum available but the majority have suffered alteration in some form by injected humour randomised words which don't look even slightly believable.",
        card_section_one: [
          {
            _id: 1,
            text: "Dry Cleaning",
            count: "12K",
          },
          {
            _id: 2,
            text: "Satisfied Clients",
            count: "20K",
          },
          {
            _id: 3,
            text: "Happy Customers",
            count: "8K",
          },
        ],
        card_section_title: "Top rated laundry",
        rating: "5",
        card_sub_text: "(30k Reviews)",
        card_section_two: [
          {
            _id: 1,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "Claim Support",
            card_title: "24/7 Supports",
            card_paragraph:
              "We love to help you to get your clothes fresh and clean so we are here for you 24/7 for any question, concern or suggestion.",
          },
          {
            _id: 2,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "100% Reliable",
            card_title: "Offers & cares",
            card_paragraph:
              "Every item sent to dry cleaning or Laundromat, hands of experts who take care of your garments in detailed way.",
          },
          {
            _id: 3,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "Consultation",
            card_title: "Expert teams",
            card_paragraph:
              "With our subscription, you can set up your services and never worry about laundry or for dry cleaning again.",
          },
        ],
      },
      section_3: {
        cards: [
          {
            bg_img: "/assets/img/home/wing1.png",
            card_title: "Any Special Cleaning Instructions for us?",
            card_paragraph:
              "Please Handle carefully, Plink sweater has a delicate embroidery and needs additional care.",
              services: ["Laundry", "Dry Cleaning", "Ironing"],
            card_main_title: "We pick up your valuable Clothes",
            card_main_paragraph:
              "Our delivery services work no matter where you are. Do you need your clothes dropped off at your place of work? We can do that for you. We’ll drop it off at your house, apartment, or even at your hotel.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Free Collection & Delivery",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/home/wing2.png",
            card_title: "Order Scheduled",
            card_paragraph:
              "Download our app or visit our website to start your laundry order.",
              services: [
                "Pay Online in Seconds",
                "Eco-Friendly",
                "Save Time & Money"  
            ],
            card_main_title: "Pack up your valuable Clothes",
            card_main_paragraph:
              "Pack the item in a disposable bag, use one bag per service, our driver will transfer them to a reusable laundry heap bag that you can save for your next order, pack one bag per service, no need to count or weight the items, efficient water use",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Free Collection & Delivery",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/home/wing3.png",
            card_title: "Order Scheduled",
            card_paragraph:
              "Download our app or visit our website to start your laundry order.",
              services: ["Competitive Price", "5 Star Rated"],
            card_main_title: "Wait for driver to pick up Clothes",
            card_main_paragraph:
              "Get notified when your driver is nearby. They will pick up your bag and take it to a local cleaning facility. Regular order updates, live driver tracking, zero-emission delivery vehicles, trusted local cleaners, and convenient laundry solutions those help save the environment.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Free Collection & Delivery",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/home/wing4.png",
            card_title: "Order Picked",
            card_paragraph:
              "Download our app or visit our website to start your laundry order.",
              services: ["24h turnaround time", "Easy to reschedule"],
            card_main_title: "We take care of your Clothes",
            card_main_paragraph:
              "Items will be meticulously cleaned by a local partner facility. Our driver will bring them whenever you want. You are in complete control of your deliveries and can reschedule at any time if you are not home.24 hour processing time, real-time order status and easy rescheduling",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Free Collection & Delivery",
                "Best Quality",  
            ],
          },
        ],
      },
      section_4: {},
      section_5: {
        title: "Compare our Services",
        cards: [
          {
            card_title: "",
            icon: "/assets/img/icons/serviceicon2.svg",
            card_options: [
              "What is included",
              "Typically included items",
              "Service time",
              "Delivery",
              "Items returned",
              "Eco friendly",
            ],
          },
          {
            card_title: "Laundry Services",
            icon: "/assets/img/icons/serviceicon2.svg",
            card_options: [
              "Wash + Tumble-Dry",
              "T-shirts, trousers, bedsheets.",
              "Service time 24h*",
              "Delivery FREE",
              "Items returned in a bag",
              "Eco friendly",
            ],
          },
          {
            card_title: "Dry Cleaning Services",
            icon: "/assets/img/icons/serviceicon3.svg",
            card_options: [
              "Dry Cleaning + Ironing",
              "Suits, shirts, dresses and coats.",
              "Service time 24h*",
              "Delivery FREE",
              "Items returned in a bag",
              "Eco friendly",
            ],
          },
          {
            card_title: "Ironing Services",
            icon: "/assets/img/icons/serviceicon4.svg",
            card_options: [
              "Ironing",
              "Shirts, t-shirts and bedsheets.",
              "Service time 24h*",
              "Delivery FREE",
              "Items returned in a bag",
              "Eco friendly",
            ],
          },
        ],
      },
      section_6: {
        collection: [
          {
            collection_title: "Do you have a re-cleaning Policy?",
            collection_paragraph:
              "Although we have quality control where we check each garment before we pack, if we are unable to remove stains we usually inform customers before. If something slips through and you are not completely satisfied with the quality of our dry cleaning, we will re-clean your item free of charge, as long as it’s within 24 hours.",
          },
          {
            collection_title: "Do you have a loss policy?",
            collection_paragraph:
              "We take full responsibility for the clothes from the moment we issue a ticket or pick up from you. Even though we exercise the utmost care, there will be instances where items may get misplaced or lost. Should you have reason to believe that we have lost or misplaced your item. Missing items must be reported to us within 24 hours with descriptions so that we can trace your garment. In the unlikely event of losing any of your items, bestatlaundry liability with respect to any lost item shall not exceed ten (10) times our charge for cleaning that garment regardless of brand or condition.",
          },
          {
            collection_title: "Do you have a safe keeping policy?",
            collection_paragraph:
              "We take responsibility to keep your clothes safe for a maximum of 60 days. Should you be unable to pick your clothes within 60 days, due to holiday, illness, etc. please let us know ASAP and we shall arrange to keep your garments safely for an agreed time period. Otherwise, due to storage, we will have to give your clothes to charity.",
          },
          {
            collection_title: "Do you have a privacy statement?",
            collection_paragraph:
              "We respect our customer’s privacy and do not sell any identifiable information regarding our customers to any third party. Any information you give us is held with the utmost care and security, and will not be used in ways to which you have not consented.",
          },
          {
            collection_title: "Do you have a garment damage policy?",
            collection_paragraph:
              "Whilst every care is taken handling your clothes, if you believe that we have damaged your garments in any way please contact as within 24 hours. We will do our best to resolve the situation. We will investigate thoroughly and if we feel that we were negligent we shell pay fair compensation based upon the current value of the garment. This is usually around 6-10 times the cost of the dry cleaning. Our damage policy is in line with the Office of Fair Trades recommendations.",
          },
          {
            collection_title: "How should my garments be cleaned?",
            collection_paragraph:
              "As we are experts at dealing with all garments that come our way. When they arrive at our premises, your garments are examined carefully and then cleaned under the manufacturer’s instructions. Our cleaning is as gentle and delivers better results than traditional dry cleaning. If there’s any doubt or we can’t find a care label, we will clean at what we call “owner’s risk” (as we can sadly not accept any liability for garments where there are no instructions). If we feel there is really too much of a risk to your garment and we cannot get in touch with you, we will return the item to you uncleaned.",
          },
          {
            collection_title: "How long does it take to clean my items?",
            collection_paragraph:
              "We aim to collect your clothes, give them our expert care and attention and deliver them back to you within working 48 hours. There might be some exceptions: In some instances, your preferred delivery slot may not be available. This is mainly because we have already reached full capacity for that time slot, or because we do not visit your area as often as we would like at this moment. We apologise for any inconvenience caused and assure you that we are working very hard to increase our time slot capacity. Some items such as bed linen, curtains, leather, or tailoring may take longer to process as they will be entrusted to various experts. The delivery timing will be provided to you as soon as the respective items have been inspected at our premises. On inspection, we might consider that it will take longer to remove particularly difficult stains or other issues with fabrics. We offer an express service with same-day turnaround* in all our stores however due to logistical constraints thi",
          },
          {
            collection_title:
              "Why can I not select my preferred delivery slot?",
            collection_paragraph:
              "In some instances, your preferred delivery slot may not be available. This could be because we have already reached full capacity for that time slot. We apologize for any inconvenience caused and assure you that we are working very hard to increase our time slot capacity. Should you wish to drop us a line and check to see if we accommodate your chosen slot or to see if there is any cancellation please email info@bestatlaundry.uk",
          },
          {
            collection_title: "How do I order online?",
            collection_paragraph:
              "Simply click Book Now in the top right-hand corner. On the next screen, you will be able to choose a pickup time that we will collect the clothing items from your home/concierge and a dropoff time where we will drop the dry cleaned clothes back to your home/concierge. The next screen is where you can choose your items either select the items directly or choose a category and the system will refine the items to help you choose. Simply choose the quantity and select add to put the item in your basket. When ready click with view basket or the basket in the top right-hand corner of the page you can then check out adding your address and any special requirements. Lastly, you can pay using any credit or debit card using our encrypted service with stripe. We will then process your order and arrive at your requested time slot ready to pick up your items.",
          },
          {
            collection_title: "can I book a regular pickup & delivery?",
            collection_paragraph:
              "Yes! If you want to book a regular pickup and delivery slot each week, just email us to info@bestatlaundry.uk This saves having to deal with laundry and to re-book on a regular basis, and you can cancel at any time if you don’t need a delivery that week. Although it is currently not possible to book a recurring time slot online, you can call our customer care team and we will gladly set this up.",
          },
          {
            collection_title: "How do I know when my driver will arrive?",
            collection_paragraph:
              "We currently offer 1-hour time slots, which allows for traffic and our logistical requirements, if our driver is later than the chosen time slot we will contact you.",
          },
          {
            collection_title: "Why do you not cover my area?",
            collection_paragraph:
              "We are continually growing and expanding and while we might not currently cover your area. Send a message with your details to info@bestatlaundry.uk and as soon as we start to serve your area, we will contact you. You can also mail us to info@bestatlaundry.uk as they will often be able to arrange a special courier or Uber service for you (delivery fees will apply).",
          },
          {
            collection_title: "How will I be charged for the order",
            collection_paragraph:
              "We use a highly secure platform to register your credit or debit card and collect payment. After picking up your clothes, our experts will examine them and determine the total amount of your invoice based on the extent of the work that needs to be carried out on each item (much as a till assistant would do in-store). Until then, you can get an estimate of your order according to our price list here. We are unable to be more exact at this stage because some items require more time and specific treatment, and we can’t know the detail of this before we examine your garments. Once this is done, we will send you an email detailing your invoice, item by item, and charge your card on that basis. If you disagree with anything that we have detailed on your invoice, please feel free email us to info@bestatlaundry.uk and we will be happy to look into this for you. Kindly note that we cannot accept cash payment for deliveries.",
          },
          {
            collection_title: "What does owner's risk mean?",
            collection_paragraph:
              "Some fragile or highly sensitive garments inherently have a risk in cleaning, this could be due to fabric, age, colour, pre-existing damage etc. thus meaning there is a risk that they could be damaged in the cleaning process, in this instance we will refrain from cleaning the garment in question. We will make an attempt to contact you and advise you of the potential risks associated with proceeding with the treatment. Note that if you authorise us to proceed, it will imply that you assume full responsibility for any damage to the garments. In certain circumstances, we may still return the garment and advise not to clean it as damage is inevitable.",
          },
          {
            collection_title:
              "can we dry clean wedding dresses and other bespoke items?",
            collection_paragraph:
              "We are the experts at cleaning these pieces. Please note that the sooner you bring your dress in after your wedding, the better the results we can achieve, as leaving little time for stains to set helps us to get that better finish. We can also clean other bespoke items – just ask one of your store assistants for a quote, email us info@bestatlaundry.uk if you would like more information or to ask for a quote. Kindly note that we assess all those very specific items before cleaning to ensure that we have spotted and highlighted all the potential risks to you before proceeding with the clean.",
          },
        ],
      },
      section_7: {
        title_1: "Latest Testimonial",
        title_2: "What Our Client Say",
        cards: [
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser2.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser3.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser2.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser3.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
        ],
      },
      section_8: {
        title_1: "ARTICLES",
        title_2: "Latest News",
      },
      section_9: {
        title: "We're available on mobile Just download it!",
        paragraph:
          "Download the our app and request our laundry service. Your clothes washed, folded and delivered to your doorstep. So go ahead and say yes to more time with the family, more happy hours, and more slapping the snooze button every morning — we've got laundry day covered.",
        android_link:
          "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
        ios_link:
          "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
        banner: "/assets/img/iPhone13.png",
      },
      footer: {
        custome_banner_design: {
          is_enable: true,
        },
        bg_color: "151e2e",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          about_section: {
            is_enable: true,
            text: "Ready to take laundry off your to-do list? Bestatlaundry services is a local laundry pickup and delivery service that you can order directly from your smart phone.",
          },
          service_section: {
            is_enable: true,
          },
          quicklink_section: {
            is_enable: true,
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "+442086388690",
              value: "tel:+442086388690",
            },
            email: {
              label: "Email Us",
              value: "sales@bestatlaundry.com",
            },
            address: {
              text: "81 Randall Drive, Hornchurch - RM126TA",
            },
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
      },
    },
  },
  theme_3: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/section/section1.jpg",
        },
        // {
        //   _id: 2,
        //   is_section_enable: true,
        //   icon: "/assets/section/section2.jpg",
        // },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/section/section3.jpg",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/section/section4.jpg",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "/assets/section/section5.jpg",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/section/section6.jpg",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/section/section7.jpg",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "/assets/section/section8.jpg",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "/assets/section/section9.jpg",
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "/assets/section/section10.jpg",
        },
        {
          _id: 11,
          is_section_enable: true,
          icon: "/assets/section/section11.jpg",
        },
        {
          _id: 12,
          is_section_enable: true,
          icon: "/assets/section/section12.jpg",
        },
        {
          _id: 13,
          is_section_enable: true,
          icon: "/assets/section/section13.jpg",
        },
        // {
        //   _id: 14,
        //   is_section_enable: true,
        //   icon: "/assets/section/section14.jpg"
        // },
        {
          _id: 15,
          is_section_enable: true,
          icon: "/assets/section/section15.jpg",
        },
      ],
      header: {
        is_section_enable: true,
        primary_color: "1B9DB3",
        secondary_color: "F07F1B",
        nav: {
          bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "#6b7385",
            },
            cart_value_color: "000000",
          },
        },
        btn_group: {
          btn1: {
            text: "Login / Register",
            bg_color: "ffffff",
            color: "#1b9db3",
            border_color: "#1b9db3",
            btn1_is_enable: true,
          },
          btn2: {
            text: "Quick Order",
            color: "ffffff",
            bg_color: "000",
            border_color: "#192335",
            btn2_is_enable: true,
          },
        },
      },
      section_1: {
        is_section_enable: true,
        banner_type: "image",
        banner: "/assets/home_page_single_search.jpg",
        banner_two: "/assets/home/heroimg.png",
        sub_heading_top: "24/7 Dry Cleaning & Laundry Service",
        title: "One Solution for all your Washing Needs",
        paragraph:
          "Bestatlaundry is synonymous with quality, reliability, and trust. We provide fast dry cleaning and laundry services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers.",
        btn_group: {
          btn1: {
            text: "Book now",
            bg_color: "#1b9db3",
            color: "ffffff",
            placeholder: "Enter Postalcode",
          },
        },
        mobile_link_section: {
          is_enable: true,
          mob_title: "Just A Click Away",
          android_link: "#",
          ios_link: "#",
        },
      },
      section_2: {
        is_section_enable: true,
        sub_heading_top: "Book A Service",
        title: "Select Your Time & Location For The Service",
        btn_group: {
          btn1: {
            text: "order now",
            bg_color: "#1b9db3",
            color: "ffffff",
          },
        },
      },
      section_3: {
        is_section_enable: true,
        sub_heading_top: "Dry Cleaning & Laundry",
        title: "Trust The Expert On Laundry Services",
        paragraph:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/icons/priorityicon1.png",
              collection_title: "Top Quality",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 2,
              icon: "/assets/icons/priorityicon2.png",
              collection_title: "Best Service",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 3,
              icon: "/assets/icons/priorityicon3.png",
              collection_title: "Value",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 4,
              icon: "/assets/icons/priorityicon4.png",
              collection_title: "Professionalism",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 5,
              icon: "/assets/icons/priorityicon5.png",
              collection_title: "Convenience",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 6,
              icon: "/assets/icons/priorityicon6.png",
              collection_title: "Cleanliness",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
          ],
          btn_group: {
            btn1: {
              text: "More About Us",
              bg_color: "192335",
              color: "ffffff",
            },
          },
        },
      },
      section_4: {
        is_section_enable: true,
        title:
          "Bestatlaundry is Ranked #1 by customers in the UK in categories of dry cleaning",
      },
      section_5: {
        is_section_enable: true,
        banner: [
          {
            banner: "/assets/home/howwe1.jpg"
          }
        ],
        title: "We collect, clean, & deliver your laundry & dry cleaning.",
        paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took",
        check_list: [
          {
            _id: 1,
            list: "Free same day collection",
          },
          {
            _id: 2,
            list: "24h turnaround time",
          },
          {
            _id: 3,
            list: "Dedicated 24/7 support",
          },
          {
            _id: 4,
            list: "Standard wash for just £14",
          },
          {
            _id: 5,
            list: "Wash & Fold for items that don't need pressing",
          },
          {
            _id: 6,
            list: "Free collection and delivery",
          },
        ],
        btn_group: {
          btn1: {
            text: "Schedule a Collection Now",
            bg_color: "#192335",
            color: "ffffff",
            is_enable: true
          },
          btn2: {
            text: "Request a Pickup",
            bg_color: "#1b9db3",
            color: "ffffff",
            is_enable: true
          }
        }
      },
      section_6: {
        is_section_enable: true,
        sub_heading_top: "Know More On",
        title: "How We Works",
        paragraph:
          "We collect, clean and deliver your clothes within 24-48 hrs.",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/home/howwe1.jpg",
              card_title: "Online Order",
              card_paragraph:
                "Use j our friendly and easy access website or app to place your laundry order. choose a suitable pickup location and time.",
                chips: [                  
                  {id:'1', chips:"ChipOne"},
                  {id:'2', chips:"ChipTwo"}
                ]
            },
            {
              _id: 2,
              icon: "/assets/home/howwe2.jpg",
              card_title: "Transport",
              card_paragraph:
                "Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities.",
                chips: [                  
                  {id:'1', chips:"ChipOne"},
                  {id:'2', chips:"ChipTwo"}
                ]
            },
            {
              _id: 3,
              icon: "/assets/home/howwe3.jpg",
              card_title: "Dry Cleaning",
              card_paragraph:
                "Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities.",
                chips: [                  
                  {id:'1', chips:"ChipOne"},
                  {id:'2', chips:"ChipTwo"}
                ]
            },
            {
              _id: 4,
              icon: "/assets/home/howwe4.jpg",
              card_title: "Door Delivery",
              card_paragraph:
                "Dry and clean clothes were carefully bagged and delivered to your doorstep.",
                chips: [                  
                  {id:'1', chips:"ChipOne"},
                  {id:'2', chips:"ChipTwo"}
                ]
            },
          ],
          btn_group: {
            paragraph: "Lorem Ipsum is simply dummy text of the",
            btn1: {
              text: "Book Online Now",
              bg_color: "#192335",
              color: "#ffffff",
            },
          },
        },
      },
      section_7: {
        is_section_enable: true,
        title: "On Demand Laundry, Dry Cleaning And Ironing Categories",
        paragraph:
          "We provide commercial laundry, shoe repair, clothing alteration, tailoring and wedding dress fitting services in London, Essex, Kent, Westminster, Chelsea, Dartford, Croydon, Enfield, Basildon, South On Sea, Bromley, Mayfair, Romford and other areas in London.",
          card_section: {
            collection: [
              {
                _id: 1,
                icon: "/assets/img/laundry.png",
                card_title: "Eyebrows",
                chips: [ 
                  {id:'1', chips:"Tape Extension"},
                  {id:'2', chips:"clip in extension"},
                ],
              },
              {
                _id: 2,
                icon: "/assets/img/alterations.png",
                card_title: "Hair Extension",
                chips: [ 
                  {id:'1', chips:"Microblading"},
                  {id:'2', chips:"Ombre shading"},
                ],
              },
              {
                _id: 3,
                icon: "/assets/img/dry cleaning.png",
                card_title: "LathaNanth",
                chips: [ 
                  {id:'1', chips:"Bridal makeup"},
                  {id:'2', chips:"saree ceremony makeup"},
                ],
              },
            ],
          },
        btn_group: {
          btn_paragraph: "Lorem Ipsum is simply dummy text of the",
          btn1: {
            text: " Request a Pickup",
            bg_color: "192335",
            color: "fff",
          },
        },
      },
      section_8: {
        is_section_enable: true,
        banner: "howwe1",
        title: "About Us",
        section_image: "/assets/home/trustimg2.jpg",
        paragraph:
          "Bestatlaundry is synonymous with quality, reliability, and trust. We provide fast dry cleaning and laundry services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers.",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "James please use React import aliase",
              collection_title: "Free Pick up and Delivery",
              collection_image: "/assets/icons/priorityicon7.png",
              isEnabled: true,
              collection_paragraph:
                "We offer a convenient way to have laundry picked up and delivered for free.",
            },
            {
              _id: 2,
              icon: "James please use React import aliase",
              collection_title: "High Quality of Services",
              collection_image: "/assets/icons/priorityicon8.png",
              isEnabled: true,
              collection_paragraph:
                "Our laundry team perfectly cleans, iron, fold, pack, and deliver to you on time.",
            },
            {
              _id: 3,
              icon: "James please use React import aliase",
              collection_title: "Affordable Cost",
              collection_image: "/assets/icons/priorityicon9.png",
              isEnabled: true,
              collection_paragraph:
                "We offer affordable prices when compared to the current market.",
            },
          ],
        },
        btn_group: {
          btn1_text: "Schedule a Collection Now",
          btn1_bg_color: "192335",
          btn1_color: "ffffff",
          btn2_text: "Request a Pickup",
          btn2_bg_color: "1b9db3",
          btn2_color: "ffffff",
        },
      },
      section_9: {
        is_section_enable: true,
        section_bg_color: "#1b9db3",
        sub_heading_top: "Our Promising Services",
        title: "On Demand Laundry, Dry Cleaning And Ironing Service",
        paragraph:
          "We collect, clean and deliver your clothes within 24-48 hrs.",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/home/priorityimg1.jpg",
              collection_title: "Laundry",
              collection_paragraph:
                "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.",
            },
            {
              _id: 2,
              icon: "/assets/home/priorityimg2.jpg",
              collection_title: "Dry Cleaning",
              collection_paragraph:
                "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.",
            },
            {
              _id: 3,
              icon: "/assets/home/priorityimg3.jpg",
              collection_title: "Alteration & Repair",
              collection_paragraph:
                "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.",
            },
            {
              _id: 14,
              icon: "/assets/home/priorityimg1.jpg",
              collection_title: "Laundry",
              collection_paragraph:
                "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.",
            },
          ],
          count_section: [
            {
              _id: 1,
              icon: "/assets/icons/priorityicon1.png",
              text: "Laundry Clothes",
              count: "1639+",
            },
            {
              _id: 2,
              icon: "/assets/icons/priorityicon2.png",
              text: "Steam Ironing",
              count: "24359+",
            },
            {
              _id: 3,
              icon: "/assets/icons/priorityicon3.png",
              text: "Dry Cleaning",
              count: "4793+",
            },
            {
              _id: 4,
              icon: "/assets/icons/priorityicon4.png",
              text: "Cleaning programs",
              count: "25+",
            },
          ],
        },
      },
      section_10: {
        is_section_enable: true,
        banner: "/assets/home/trustimg3.png",
        sub_heading_top: "Your Health & Safety Is Our Priority",
        title: "Our Values",
        mainparagraph:
          "online laundry & dry cleaning companies who simply just own the technology and farm the cleaning to other providers with little to no process system checks.",
        list_Section: [
          {
            _id: 1,
            text: "Contactless deliveries",
            paragraph:
              "For our customers safety, we enabled the feature to allow customers to conduct contactless pick up & deliveries.",
          },
          {
            _id: 2,
            text: "Instant updates",
            paragraph:
              "We’ll keep you posted throughout the process with texts/emails when driver is on their way and order has been cleaned.",
          },
          {
            _id: 3,
            text: "Monitoring the situation",
            paragraph:
              "We’re keeping up to date with all developments from government to ensure the safety of our customers and staff.",
          },
        ],
        card_section_title: "We use Eco-friendly Laundry Detergent Sheets.",
        card_section: [
          {
            _id: 1,
            card_bg_color: "192335",
            card_text: "Vegan",
            card_paragraph:
              "Our vegan-friendly laundry detergent is free from any animal product.",
          },
          {
            _id: 2,
            card_bg_color: "f07f1b",
            card_text: "Cruelty Free",
            card_paragraph:
              "We never test on animals – so our Laundry Leaves will always be cruelty-free.",
          },
          {
            _id: 3,
            card_bg_color: "1bb333",
            card_text: "Eco-Friendly",
            card_paragraph:
              "Free from any harmful chemicals or pollutants and are devoid of harmful toxins.",
          },
        ],
      },
      section_11: {
        is_section_enable: true,
        banner: [
          {
            banner: "/assets/home/howwe1.jpg",
          },
        ],
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "Free Pick up and Delivery",
              isEnabled: true,
              collection_paragraph:
                "We offer a convenient way to have laundry picked up and delivered for free.",
            },
            {
              _id: 2,
              collection_title: "High Quality of Services",
              isEnabled: true,
              collection_paragraph:
                "Our laundry team perfectly cleans, iron, fold, pack, and deliver to you on time.",
            },
            {
              _id: 3,
              collection_title: "Affordable Cost",
              isEnabled: true,
              collection_paragraph:
                "We offer affordable prices when compared to the current market.",
            },
          ],
        },
        sub_heading_top: "Ask Your Question",
        title: "Frequently Asked Questions",
        paragraph:
          "online laundry & dry cleaning companies who simply just own the technology and farm the cleaning to other providers with little to no process system checks.",
      },
      section_12: {
        is_section_enable: true,
        banner: "howwe1",
        sub_heading_top: "What Our Client Says",
        title: "A Trusted Laundry And Dry Cleaning Company",
        paragraph: "Read what our customers say about us",
        review_bottom_paragraph:
          "More than 5,000+ reviews s on TrustPilot, Google, Google Play & App Store",
        review_card: [
          {
            _id: 1,
            reviewer_pic: "/assets/img/services/testimonialuser1.jpg",
            reviewer_name: "Ariyan Rusov",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 2,
            reviewer_pic: "/assets/img/services/testimonialuser2.jpg",
            reviewer_name: "Darren Valdez",
            rating: "4",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 3,
            reviewer_pic: "/assets/img/services/testimonialuser3.jpg",
            reviewer_name: "Elinor Dunn",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
        ],
        btn_group: {
          btn1: {
            text: "Book Online Now",
            bg_color: "#192335",
            color: "#ffffff",
            is_enable: true,
          },
        },
      },
      section_13: {
        is_section_enable: true,
        banner: "howwe1",
        title: "Just A Click Away",
        paragraph: "Download Our Best Active Mobile App",
        check_list: [
          {
            _id: 1,
            icons: "/assets/icons/priorityicon14.png",
            title: "Cashless payments",
          },
          {
            _id: 2,
            icons: "/assets/icons/priorityicon15.png",
            title: "Delivery in less then 24 hours",
          },
          {
            _id: 3,
            icons: "/assets/icons/priorityicon16.png",
            title: "Standard wash for just £14",
          },
          {
            _id: 4,
            icons: "/assets/icons/priorityicon17.png",
            title: "In-app live chat support",
          },
        ],
        mobile_link_section: {
          is_enable: true,
          android_link:
            "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link:
            "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
        },
      },
      section_14: {
        is_section_enable: true,
        section_bg_color: "#1b9db3",
        title:
          "Subscribe to our newsletter for updates, exclusive promotions and our latest content!",
        btn_group: {
          btn1: {
            text: "Subscribe",
            bg_color: "#192335",
            color: "ffffff",
          },
        },
      },
      section_15: {
        is_section_enable: true,
        sub_heading_top: "Our Latest",
        title: "News & Events",
        paragraph:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
        btn_group: {
          btn1: {
            text: "More News & Events",
            bg_color: "#192335",
            color: "ffffff",
          },
        },
      },
      footer: {
        custome_banner_design: {
          is_enable: true,
        },
        bg_color: "151e2e",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          about_section: {
            is_enable: true,
            text: "Ready to take laundry off your to-do list? Bestatlaundry services is a local laundry pickup and delivery service that you can order directly from your smart phone.",
          },
          service_section: {
            is_enable: true,
          },
          quicklink_section: {
            is_enable: true,
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "+442086388690",
              value: "tel:+442086388690",
            },
            email: {
              label: "Email Us",
              value: "sales@bestatlaundry.com",
            },
            address: {
              text: "81 Randall Drive, Hornchurch - RM126TA",
            },
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
      },
    },
  },
  theme_4: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_1.png",
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_2.png",
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_9.png",
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_10.png",
        },
        {
          _id: 11,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_11.png",
        },
        {
          _id: 12,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_12.png",
        },
      ],
      header: {
        is_section_enable: true,
        primary_color: "046A38",
        secondary_color: "01190D",
        contact: {
          email_id: "sales@bestatlaundry.com",
          contact_number: "+442086388690",
        },
        nav: {
          bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "#6b7385",
            },
            cart_value_color: "000000",
          },
        },
        btn_group: {
          btn1_text: "Login / Register",
          btn1_bg_color: "046A38",
          btn1_color: "ffffff",
          btn1_border_color: "046A38",
          btn1_is_enable: true,
          btn2_text: "Quick Order",
          btn2_color: "ffffff",
          btn2_bg_color: "000",
          btn2_border_color: "#192335",
          btn2_is_enable: true,
        },
      },
      section_1: {
        is_section_enable: true,
        banner_type: "image",
        banner: "/assets/home_page_single_search.jpg",
        banner_two: "/assets/img/home/heroimg.png",
        sub_heading_top: "24/7 Dry Cleaning & Laundry Service",
        title: "One Solution for all your Washing Needs",
        paragraph: "Select your time & location for the service",
        btn_group: {
          btn1: {
            text: "Order now",
            bg_color: "#046A38",
            color: "ffffff",
            placeholder: "Enter Postcode",
          },
        },
      },
      section_2: {
        is_section_enable: true,
        title: "Trust the Experts",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        first_card_image: "/assets/img/home/trustimg1.jpg",
        image_title: "Why 1000+ customers trust Us?",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/icons/priorityicon1.png",
              collection_title: "Top Quality",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 2,
              icon: "/assets/icons/priorityicon4.png",
              collection_title: "Professionalism",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 3,
              icon: "/assets/icons/priorityicon5.png",
              collection_title: "Convenience",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 4,
              icon: "/assets/icons/priorityicon6.png",
              collection_title: "Cleanliness",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
          ],
        },
      },
      section_3: {
        is_section_enable: true,
        title:
          "5 star Rated by customers in the UK in categories of dry cleaning",
        banner: "/assets/img/home/review-bg.jpg",
      },
      section_4: {
        is_section_enable: true,
        banner: "/assets/home/trustimg2.jpg",
        title: "About We",
        paragraph_one:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock,",
        paragraph_two:
          "A Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
        option_one: "Free same day collection",
        option_two: "Dedicated 24/7 support",
        option_three: "Standard wash for just £14",
        btn_group: {
          btn1: {
            text: "Know More",
            bg_color: "#046A38",
            color: "ffffff",
            is_enable: true,
          },
        },
      },
      section_5: {
        is_section_enable: true,
        title: "How We Works",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/home/howwe1.jpg",
              card_title: "Online Order",
              card_paragraph:
                "Use our friendly and easy access website or app to place your laundry order. choose a suitable pickup location and time.",
            },
            {
              _id: 2,
              icon: "/assets/home/howwe2.jpg",
              card_title: "Transport",
              card_paragraph:
                "Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities.",
            },
            {
              _id: 3,
              icon: "/assets/home/howwe3.jpg",
              card_title: "Dry Cleaning",
              card_paragraph:
                "Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities.",
            },
            {
              _id: 4,
              icon: "/assets/home/howwe4.jpg",
              card_title: "Door Delivery",
              card_paragraph:
                "Dry and clean clothes were carefully bagged and delivered to your doorstep.",
            },
          ],
        },
      },
      section_6: {
        is_section_enable: true,
        title: "service for Business & Personal",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        first_card_image: "/assets/home/trustimg3.png",
        sub_title: "For Business",
        sub_title_two: "For Personal",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_list: "Restaurants",
            },
            {
              _id: 2,
              collection_list: "Spa",
            },
            {
              _id: 3,
              collection_list: "Hotels",
            },
            {
              _id: 4,
              collection_list: "Gym",
            },
            {
              _id: 5,
              collection_list: "Factory",
            },
            {
              _id: 6,
              collection_list: "Malls",
            },
            {
              _id: 7,
              collection_list: "Public sectors",
            },
            {
              _id: 8,
              collection_list: "Hospitals",
            },
          ],
        },
        card_section_two: {
          collection: [
            {
              _id: 1,
              collection_list: "Home Stay",
            },
            {
              _id: 2,
              collection_list: "Local Living",
            },
            {
              _id: 3,
              collection_list: "Hostel",
            },
            {
              _id: 4,
              collection_list: "Cottage",
            },
            {
              _id: 5,
              collection_list: "PG Stay",
            },
          ],
        },
      },
      section_7: {
        is_section_enable: true,
        title: "Our Product Category",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
      },
      section_8: {
        is_section_enable: true,
        part_one: {
          title: "How we Prioritize",
          sub_title: "Step we follow for Health & Safety Precaution",
          section_image: "/assets/home/trustimg2.jpg",
          paragraph:
            "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old",
          card_section: {
            collection: [
              {
                _id: 1,
                collection_title: "Contactless deliveries",
                collection_paragraph:
                  "For our customers safety, we enabled the feature to allow customers to conduct contactless pick up & deliveries.",
              },
              {
                _id: 2,
                collection_title: "Instant updates",
                collection_paragraph:
                  "We’ll keep you posted throughout the process with texts/emails when driver is on their way and order has been cleaned.",
              },
              {
                _id: 3,
                collection_title: "Monitoring the situation",
                collection_paragraph:
                  "We’re keeping up to date with all developments from government to ensure the safety of our customers and staff.",
              },
            ],
          },
        },
        part_two: {
          bg_color: "046A38",
          text_color: "ffffff",
          title: "We use Eco-friendly Laundry Detergent Sheets.",
          paragraph:
            "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin",
          card_section: {
            collection: [
              {
                _id: 1,
                collection_title: "Vegan",
                collection_paragraph:
                  "Our vegan-friendly laundry detergent is free from any animal product.",
              },
              {
                _id: 2,
                collection_title: "Cruelty Free",
                collection_paragraph:
                  "We never test on animals – so our Laundry Leaves will always be cruelty-free.",
              },
              {
                _id: 3,
                collection_title: "Eco-Friendly",
                collection_paragraph:
                  "Free from any harmful chemicals or pollutants and are devoid of harmful toxins.",
              },
            ],
          },
        },
      },
      section_9: {
        is_section_enable: true,
        title: "Frequently Asked Question",
        description:
          " Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        card_image: "/assets/home/trustimg4.jpg",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "Do you have a re-cleaning Policy?",
              isEnabled: true,
              collection_paragraph: "Although we have quality control where we check each garment before we pack, if we are unable to remove stains we usually inform customers before. If something slips through and you are not completely satisfied with the quality of our dry cleaning, we will re-clean your item free of charge, as long as itâ€™s within 24 hours."
            },
            {
              _id: 2,
              collection_title: "Do you have policy grantees?",
              isEnabled: true,
              collection_paragraph: "Yes"
            }
          ]
        }
      },
      section_10: {
        is_section_enable: true,
        banner: "howwe1",
        title: "Client Testimonials",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        review_card: [
          {
            _id: 1,
            reviewer_pic: "/assets/img/services/testimonialuser1.jpg",
            reviewer_name: "Ariyan Rusov",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 2,
            reviewer_pic: "/assets/img/services/testimonialuser2.jpg",
            reviewer_name: "Darren Valdez",
            rating: "4",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 3,
            reviewer_pic: "/assets/img/services/testimonialuser3.jpg",
            reviewer_name: "Elinor Dunn",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
        ],
      },
      section_11: {
        is_section_enable: true,
        title: "Just a Click Away",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        check_list: [
          {
            _id: 1,
            icons: "/assets/icons/priorityicon14.png",
            title: "Cashless payments",
          },
          {
            _id: 2,
            icons: "/assets/icons/priorityicon15.png",
            title: "Delivery in less then 24 hours",
          },
          {
            _id: 3,
            icons: "/assets/icons/priorityicon16.png",
            title: "Standard wash for just £14",
          },
          {
            _id: 4,
            icons: "/assets/icons/priorityicon17.png",
            title: "In-app live chat support",
          },
        ],
        banner: "/assets/home/just_clickaway.png",
      },
      section_12: {
        is_section_enable: true,
        title: "Blogs & Events",
        description:
          " Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
      },
      footer: {
        bg_color: "046A38",
        section_paragraph_color: "c2c2c2",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        social_media: {
          facebook: {
            is_enable: true,
            facebook_link: "facebook.com",
          },
          instagram: {
            is_enable: true,
            instagram_link: "instagram.com",
          },
          whatsapp: {
            is_enable: true,
            whatsapp_link: "whatsapp.com",
          },
          linkedin: {
            is_enable: true,
            linkedin_link: "linkedin.com",
          },
          youtube: {
            is_enable: true,
            youtube_link: "youtube.com",
          },
          twitter: {
            is_enable: true,
            twitter_link: "twitter.com",
          },
        },
      },
    },
  },
  theme_5: {
    home: {
      header: {
        is_section_enable: "on",
        primary_color: "2fa0ea",
        secondary_color: "efc10b",
        nav: {
          bg_color: "ffffff",
          menu_font_color: "000000",
          menu_font_color_active: "efc10b",
          menu_font_color_hover: "efc10b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "000000",
              bg_color: "ffffff",
              menu_font_color_hover: "efc10b",
              menu_font_color_active: "efc10b",
              btn1_bg_color: "efc10b",
              btn2_bg_color: "efc10b",
              btn1_border_color: "efc10b",
              btn2_border_color: "efc10b",
              btn3_bg_color: "efc10b",
              btn3_border_color: "efc10b",
              menu_font_color: "000000",
              btn1_color: "000000",
              btn2_color: "000000",
              btn3_color: "000000",
              cart_value_color: "ffffff"
            },
            cart_value_color: "ffffff",
            menu_font_color_hover: "efc10b",
            menu_font_color_active: "efc10b",
            btn1_bg_color: "efc10b",
            btn2_bg_color: "efc10b",
            btn1_border_color: "efc10b",
            btn2_border_color: "efc10b",
            btn3_bg_color: "efc10b",
            btn3_border_color: "efc10b",
            menu_font_color: "000000",
            btn1_color: "000000",
            btn2_color: "000000",
            btn3_color: "000000",
            color: "000000"
          },
          btn1_bg_color: "efc10b",
          btn2_bg_color: "efc10b",
          btn1_border_color: "efc10b",
          btn2_border_color: "efc10b",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          btn1_color: "000000",
          btn2_color: "000000",
          btn3_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        btn_group: {
          btn1_text: "Log in",
          btn1_bg_color: "efc10b",
          btn1_color: "000000",
          btn1_border_color: "efc10b",
          btn1_is_enable: true,
          btn2_text: "Sign up",
          btn2_bg_color: "efc10b",
          btn2_color: "000000",
          btn2_border_color: "efc10b",
          btn2_is_enable: true,
          btn3_text: "Quick Order",
          btn3_color: "000000",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          btn3_is_enable: true,
          bg_color: "ffffff",
          menu_font_color_hover: "efc10b",
          menu_font_color_active: "efc10b",
          menu_font_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        bg_color: "ffffff",
        contact: {
          bg_color: "ffffff",
          menu_font_color_hover: "efc10b",
          menu_font_color_active: "efc10b",
          btn1_bg_color: "efc10b",
          btn2_bg_color: "efc10b",
          btn1_border_color: "efc10b",
          btn2_border_color: "efc10b",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          menu_font_color: "000000",
          btn1_color: "000000",
          btn2_color: "000000",
          btn3_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        menu_font_color_hover: "efc10b",
        menu_font_color_active: "efc10b",
        btn1_bg_color: "efc10b",
        btn2_bg_color: "efc10b",
        btn1_border_color: "efc10b",
        btn2_border_color: "efc10b",
        btn3_bg_color: "efc10b",
        btn3_border_color: "efc10b",
        menu_font_color: "000000",
        btn1_color: "000000",
        btn2_color: "000000",
        btn3_color: "000000",
        cart_value_color: "ffffff",
        color: "000000"
      },
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/section-five/section_1.png"
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/section-five/section_2.png"
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/section-five/section_3.png"
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/section-five/section_4.png"
        },
        {
          _id: 5,
          is_section_enable: false,
          icon: "/assets/section-five/section_5.png"
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/section-five/section_6.png"
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/section-five/section_7.png"
        },
        {
          _id: 8,
          is_section_enable: false,
          icon: "/assets/section-five/section_8.png"
        }
      ],
      section_1: {
        is_section_enable: true,
        banner_type: "image",
        banner: "/assets/home_page_single_search.jpg",
        banner_two: "https://api.bestatservices.com/storage/compress_images/65c64bab94fb1.jpeg",
        title: "Welcome to BestatServices",
        paragraph: "Unveil an array of innovative white label software solutions crafted to cater to a diverse range of services, spanning across laundry, cleaning, restaurants, salons, nail care, and barber shops.",
        btn_group: {
          btn1: {
            text: "Search",
            bg_color: "efc10b",
            color: "ffffff",
            placeholder: "Enter Postcode",
            paragraph: "Unveil an array of innovative white label software solutions crafted to cater to a diverse range of services, spanning across laundry, cleaning, restaurants, salons, nail care, and barber shops.",
            title: "Welcome to BestatServices"
          }
        },
        bg_color: "efc10b"
      },
      section_4: {
        is_section_enable: true,
        banner: "/assets/img/offer/offer-vector-bg.png",
        offer_tag: "/assets/img/offer/offer-tag.png",
        picture: "/assets/img/offer/offer-wash-img.png",
        paragraph_one: "* Offer applied only to Registered Users (On Selected Shops)"
      },
      section_5: {
        is_section_enable: true,
        banner: "/assets/img/wash-fold-bg.jpg",
        banner_two: "/assets/img/home/heroimg.png",
        title: "WASH & FOLD",
        wash_title: [
          {
            _id: 1,
            wash_list: "Washed under 30 degrees"
          },
          {
            _id: 2,
            wash_list: "Dried at medium heat"
          },
          {
            _id: 3,
            wash_list: "Customerâ€™s responsibility to make sure all items are washable"
          },
          {
            _id: 4,
            wash_list: "Towels and beddings are excluded and will be charged separately."
          }
        ]
      },
      section_6: {
        is_section_enable: true,
        title: "ABOUT BESTATSERVICES",
        sub_heading: "We believe in innovation, fueling success at every step",
        section_image: "https://api.bestatservices.com/storage/compress_images/65c64ad629988.png",
        background_image_color: "2fa0ea",
        paragraph: "It is one of zest and competence, which drives us to create extraordinary solutions adapted to your specific requirements. We want to be your valued partner on the road to success by committing to excellence.",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "Cost Effective",
              collection_image: "/assets/img/about-section/about-icon-01.png",
              isEnabled: true,
              collection_paragraph: "BestatServices provides quality service with reasonable prices to all."
            },
            {
              _id: 2,
              collection_title: "Ease to Use",
              collection_image: "/assets/img/about-section/about-icon-02.png",
              isEnabled: true,
              collection_paragraph: "User friendly website, Book orders with 1 step."
            },
            {
              _id: 3,
              collection_title: "Faster Service",
              collection_image: "/assets/img/about-section/about-icon-03.png",
              isEnabled: true,
              collection_paragraph: "Our team will complete the Service within 48 hours."
            },
            {
              _id: 4,
              collection_title: "Quality of Service",
              collection_image: "/assets/img/about-section/about-icon-04.png",
              isEnabled: true,
              collection_paragraph: "Our promise is to provide an exceptional service to you."
            }
          ],
          _id: 4,
          collection_title: "Quality of Service",
          collection_image: "/assets/img/about-section/about-icon-04.png",
          isEnabled: true,
          collection_paragraph: "Our promise is to provide a exceptional service to you."
        },
        collection_paragraph: "Our promise is to provide an exceptional service to you.",
        collection_title: "Faster Service"
      },
      section_7: {
        is_section_enable: true,
        title: "Ease to Made Services",
        paragraph1: "BestatServices is a trusted white-label SaaS Software provider for businesses and agencies in this digital age.",
        banner: "https://api.bestatservices.com/storage/compress_images/65c64e69e0a75.png",
        paragraph2: "Our product is compatible with desktops, laptops, Macs, iOS, and Android devices",
        paragraph3: "Keep clients updated on their request status via email and SMS.",
        paragraph4: "Generate invoices for customers and business accounts by sending them printable PDFs or via email.",
        card_section: [
          {
            _id: 1,
            text: "Search for your postal code"
          },
          {
            _id: 2,
            text: "Select a convenient shop"
          },
          {
            _id: 3,
            text: "Add required services in your cart"
          },
          {
            _id: 4,
            text: "Choose a convenient date and time slot"
          },
          {
            text: "Place Order"
          }
        ],
        text: "Place Order"
      },
      section_8: {
        is_section_enable: true,
        banner: "/assets/home/iPhone13.png",
        bg_color: "#FBF2FF",
        placeholder: "Eg:-  +4407816545718",
        banner_two: "/assets/img/home/heroimg.png",
        title: "Download the app",
        paragraph: "With our award-winning app, your laundry and dry cleaning needs are just a tap away",
        mobile_link_section: {
          is_enable: true,
          android_link: "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link: "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8"
        }
      },
      footer: {
        custome_banner_design: {
          is_enable: true
        },
        bg_color: "2fa0ea",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        site_title_en: "Â© Best at Services 2024. All rights reserved. ",
        footer_section: {
          needhelp_section: {
            is_enable: true,
            text: "NEED HELP?"
          },
          policy_section: {
            is_enable: true,
            text: "OUR POLICY"
          },
          quickconnect_section: {
            is_enable: true,
            text: "QUICK CONNECT"
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "Phone",
              value: "+447438823475"
            },
            email: {
              label: "Email",
              value: "sales@bestatservices.com"
            }
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com"
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com"
            },
            whatsapp: {
              is_enable: false,
              whatsapp_link: "whatsapp.com"
            },
            linkedin: {
              is_enable: false,
              linkedin_link: "linkedin.com"
            },
            youtube: {
              is_enable: false,
              youtube_link: "youtube.com"
            },
            twitter: {
              is_enable: false,
              twitter_link: "twitter.com"
            }
          },
          mobile_link_section: {
            is_enable: true,
            android_link: "",
            ios_link: "",
            bg_color: "2fa0ea",
            "email.value": "sales@bestatservices.com",
            "mbl_no.value": "+447438823475",
            site_link: "Â© Best at Services 2024. All rights reserved. "
          }
        },
        ios_link: "",
        android_link: "",
        "email.value": "sales@bestatservices.com",
        "mbl_no.value": "+447438823475",
        site_link: "Â© Best at Services 2024. All rights reserved. "
      }
    }
  },
};
export const shop_data_cleaning = {
  theme_1: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "assets/img/section/section1.png",
        },
        {},
        // {
        //   _id: 2,
        //   is_section_enable: true,
        //   icon: "assets/img/section/section2.png",
        // },
        {
          _id: 3,
          is_section_enable: true,
          icon: "assets/img/section/section3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "assets/img/section/section4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "assets/img/section/section5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "assets/img/section/section6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "assets/img/section/section7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "assets/img/section/section8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "assets/img/section/section9.png",
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "assets/img/section/section10.png",
        },
      ],
      header: {
        primary_color: "ff0059",
        secondary_color: "ffcb00",
        nav: {
          nav_bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            cart_bg_color: "ffffff",
            cart_icon_bg_color: "f07f1b",
            cart_value_color: "000000",
          },
          contact: {
            contact_is_enable: true,
            number: "+(044) 071012345",
            contact_color: "2e3639",
          },
        },
        btn_group: {
          btn1: {
            text: "Login / Register",
            btn_bg_color: "ffffff",
            btn_color: "1b9db3",
            border_color: "1b9db3",
            btn1_is_enable: true,
          },
        },
      },
      section_1: {
        banner: "assets/img/home_page_single_search.jpg",
        sub_heading_top: "24/7 Dry Cleaning & Laundry Service",
        title: "One Solution for all your Washing Needs",
        paragraph:
          "While you are doing something important, we will put things in order in the apartment, private house or office, clean the upholstered furniture and wash the windows.",
        btn_group: {
          btn1: {
            text: "Book now",
            placeholder: "Enter Postalcode",
            bg_color: "1b9db3",
            color: "ffffff",
          },
        },
      },
      section_2: {
        title: "Select Your Time & Location For The Service",
        btn_group: {
          btn1: {
            text: "order now",
            placeholder: "Enter Postcode",
            bg_color: "1b9db3",
            color: "ffffff",
          },
        },
      },
      section_3: {
        is_section_enable: true,
        banner: "assets/img/trustimg.png",
        title: "Trust the Expert",
        paragraph:
          "Champion Cleaners aim has always been to provide 5 star premium dry cleaning and laundry services to expat and local populations of Dubai and the United Arab Emirates",
        sub_heading: "Unique solutions for all your laundry needs",
        check_list: [
          {
            _id: 1,
            title: "Clean for professional garment care",
          },
          {
            _id: 1,
            title: "Wash & Fold for items that don't need pressing",
          },
          {
            _id: 1,
            title: "Press Only for clean clothes that need pressing",
          },
          {
            _id: 1,
            title: "ShoeCare for cleaned and restored shoes",
          },
        ],
        btn_group: {
          btn1: {
            text: "Read More",
            bg_color: "#ffcb00",
            color: "ffffff",
            is_enable: true,
          },
        },
      },
      section_4: {
        title: "Your Health & Safety Is Our Priority",
        cards: [
          {
            heading: "Contactless deliveries",
            icon: "assets/img/icons/priorityicon1.svg",
            image: "assets/img/home/priorityimg1.jpg",
            paragraph:
              "For our customers safety, we enabled the feature to allow customers to conduct contactless pick up & deliveries.",
          },
          {
            heading: "Monitoring the situation",
            icon: "assets/img/icons/priorityicon2.svg",
            image: "assets/img/home/priorityimg2.jpg",
            paragraph:
              "We’re keeping up to date with all developments from government to ensure the safety of our customers and staff.",
          },
          {
            heading: "Instant updates",
            icon: "assets/img/icons/priorityicon3.svg",
            image: "assets/img/home/priorityimg3.jpg",
            paragraph:
              "We’ll keep you posted throughout the process with texts/emails when driver is on their way and order has been cleaned.",
          },
        ],
      },
      section_5: {
        is_section_enable: true,
        banner: "assets/img/phone.png",
        title: "Just A Click Away",
        paragraph:
          "Our apps got a better and convenient way of getting your laundry and dry cleaning with our 5-star services. Download now and register to get more exclusive offers",
        check_list: [
          {
            _id: 1,
            icons: "assets/img/icons/priorityicon1.svg",
            title: "Users",
            count: "25000",
          },
          {
            _id: 2,
            icons: "assets/img/icons/priorityicon2.svg",
            title: "Downloads",
            count: "40000",
          },
          {
            _id: 3,
            icons: "assets/img/icons/priorityicon3.svg",
            title: "Ratings",
            count: "4.5",
          },
        ],
        mobile_link_section: {
          is_enable: true,
          android_link:
            "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link:
            "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
        },
      },
      section_6: {
        heading: "Save with Our Subscriptions",
        card_1: {
          card_one_title: "Laundry Bag",
          card_one_paragraph:
            "One bag = one price, fill it up and get it washed! Includes FREE next day delivery & all perks from Laundry Go. Ideal for couples & families. Plans start from £25/month",
          card_one_image: "assets/img/home/wing1.png",
        },
        card_2: {
          card_two_title: "Ongoing discount on All Services",
          card_two_paragraph:
            "10% OFF all services (apart from laundry) for just £3.99/month. This includes Eco Dry Cleaning, shirt service & bed linen service. Ideal for single busy professionals & couples.",
          card_two_image: "assets/img/home/wing2.png",
        },
      },
      section_8: {
        title: "What Our Client Say",
        cards: [
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg1.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser2.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg2.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser3.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg3.jpg",
          },
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg1.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser2.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg2.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser3.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg3.jpg",
          },
        ],
      },
      section_10: {
        title: "Latest News & Events",
        button: {
          text: "View More",
          bg_color: "#ffcb00",
          color: "ffffff",
          is_enable: true,
        },
      },
      section_7: {
        is_section_enable: true,
        title: "On Demand Laundry, Dry Cleaning and Ironing Service",
      },
      section_9: {
        is_section_enable: true,
        title: "Frequently Asked Questions",
        paragraph:
          "online laundry & dry cleaning companies who simply just own the technology and farm the cleaning to other providers with little to no process system checks.",
        banner: [
          {
            banner: "assets/img/faqimg.jpg",
          },
        ],
        card_heading: "CALL US FOR A FREE QUOTE ON:",
        card_number: "+(044) 071012345",
        card_buttontext: "Enquire Now",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "Do you have a re-cleaning Policy?",
              isEnabled: true,
              collection_paragraph:
                "Although we have quality control where we check each garment before we pack, if we are unable to remove stains we usually inform customers before. If something slips through and you are not completely satisfied with the quality of our dry cleaning, we will re-clean your item free of charge, as long as it’s within 24 hours.",
            },
            {
              _id: 2,
              collection_title: "Do you have policy grantees?",
              isEnabled: true,
              collection_paragraph: "Yes",
            },
          ],
        },
      },
      footer: {
        bg_color: "ffffff",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
      },
    },
  },
  theme_2: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "assets/section-two/section1.png",
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "assets/section-two/section2.png",
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "assets/section-two/section3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "assets/section-two/section4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "assets/section-two/section5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "assets/section-two/section6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "assets/section-two/section7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "assets/section-two/section8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "assets/section-two/section9.png",
        },
      ],
      header: {
        primary_color: "ff0059",
        secondary_color: "ffcb00",
        nav: {
          nav_bg_color: "ffffff",
          header_bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
        },
        btn_group: {
          btn1: {
            text: "Continue to order",
            btn_bg_color: "ff0059",
            btn_color: "ffffff",
          },
          btn2: {
            btn2_text: "Order Now",
            btn2_bg_color: "ffcb00",
            btn2_color: "2e3639",
          },
        },
      },
      section_1: {
        title_1: "Solution for your",
        title_2: "Laundry & Dry Cleaning Service",
        title_3: "Highest quality and lowest price guaranteed.",
        card_section: [
          "assets/img/theme_2/banner-slider-01.jpg",
          "assets/img/theme_2/banner-slider-02.jpg",
          "assets/img/theme_2/banner-slider-03.jpg",
          "assets/img/theme_2/banner-slider-04.jpg",
          "assets/img/theme_2/banner-slider-05.jpg",
        ],
      },
      section_2: {
        is_section_enable: true,
        banner: "assets/img/trustimg2.png",
        sub_heading_top: "Our Introduction",
        title: "Welcome",
        mainparagraph:
          "There are many variations of passag of lorem ipsum available but the majority have suffered alteration in some form by injected humour randomised words which don't look even slightly believable.There are many variations of passag of lorem ipsum available but the majority have suffered alteration in some form by injected humour randomised words which don't look even slightly believable.",
        card_section_one: [
          {
            _id: 1,
            text: "Dry Cleaning",
            count: "12K",
          },
          {
            _id: 2,
            text: "Satisfied Clients",
            count: "20K",
          },
          {
            _id: 3,
            text: "Happy Customers",
            count: "8K",
          },
        ],
        card_section_title: "Top rated laundry",
        rating: "5",
        card_sub_text: "(30k Reviews)",
        card_section_two: [
          {
            _id: 1,
            card_icon: "assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "Claim Support",
            card_title: "24/7 Supports",
            card_paragraph:
              "We love to help you to get your clothes fresh and clean so we are here for you 24/7 for any question, concern or suggestion.",
          },
          {
            _id: 2,
            card_icon: "assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "100% Reliable",
            card_title: "Offers & cares",
            card_paragraph:
              "Every item sent to dry cleaning or Laundromat, hands of experts who take care of your garments in detailed way.",
          },
          {
            _id: 3,
            card_icon: "assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "Consultation",
            card_title: "Expert teams",
            card_paragraph:
              "With our subscription, you can set up your services and never worry about laundry or for dry cleaning again.",
          },
        ],
      },
      section_3: {
        cards: [
          {
            bg_img: "/assets/img/home/wing1.png",
            card_title: "Any Special Cleaning Instructions for us?",
            card_paragraph:
              "Please Handle carefully, Plink sweater has a delicate embroidery and needs additional care.",
              services: ["Laundry", "Dry Cleaning", "Ironing"],
            card_main_title: "We pick up your valuable Clothes",
            card_main_paragraph:
              "Our delivery services work no matter where you are. Do you need your clothes dropped off at your place of work? We can do that for you. We’ll drop it off at your house, apartment, or even at your hotel.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Free Collection & Delivery",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/home/wing2.png",
            card_title: "Order Scheduled",
            card_paragraph:
              "Download our app or visit our website to start your laundry order.",
              services: [
                "Pay Online in Seconds",
                "Eco-Friendly",
                "Save Time & Money",  
            ],
            card_main_title: "Pack up your valuable Clothes",
            card_main_paragraph:
              "Pack the item in a disposable bag, use one bag per service, our driver will transfer them to a reusable laundry heap bag that you can save for your next order, pack one bag per service, no need to count or weight the items, efficient water use",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Free Collection & Delivery",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/home/wing3.png",
            card_title: "Order Scheduled",
            card_paragraph:
              "Download our app or visit our website to start your laundry order.",
              services: ["Competitive Price", "5 Star Rated"],
            card_main_title: "Wait for driver to pick up Clothes",
            card_main_paragraph:
              "Get notified when your driver is nearby. They will pick up your bag and take it to a local cleaning facility. Regular order updates, live driver tracking, zero-emission delivery vehicles, trusted local cleaners, and convenient laundry solutions those help save the environment.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Free Collection & Delivery",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/home/wing4.png",
            card_title: "Order Picked",
            card_paragraph:
              "Download our app or visit our website to start your laundry order.",
              services: ["24h turnaround time", "Easy to reschedule"],
            card_main_title: "We take care of your Clothes",
            card_main_paragraph:
              "Items will be meticulously cleaned by a local partner facility. Our driver will bring them whenever you want. You are in complete control of your deliveries and can reschedule at any time if you are not home.24 hour processing time, real-time order status and easy rescheduling",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Free Collection & Delivery",
                "Best Quality",  
            ],
          },
        ],
      },
      section_4: {},
      section_5: {
        title: "Compare our Services",
        cards: [
          {
            card_title: "",
            icon: "/assets/img/icons/serviceicon2.svg",
            card_options: [
              { id: "1", list: "What is included" },
              { id: "2", list: "Typically included items" },
              { id: "3", list: "Service time" },
              { id: "4", list: "Delivery" },
              { id: "5", list: "Items returned" },
              { id: "6", list: "Eco friendly" },
            ],
          },
          {
            card_title: "Laundry Services",
            icon: "/assets/img/icons/serviceicon2.svg",
            card_options: [
              "What is included",
              "Typically included items",
              "Service time",
              "Delivery",
              "Items returned",
              "Eco friendly",
            ],
          },
          {
            card_title: "Dry Cleaning Services",
            icon: "/assets/img/icons/serviceicon3.svg",
            card_options: [
              "Wash + Tumble-Dry",
              "T-shirts, trousers, bedsheets.",
              "Service time 24h*",
              "Delivery FREE",
              "Items returned in a bag",
              "Eco friendly",
            ],
          },
          {
            card_title: "Ironing Services",
            icon: "/assets/img/icons/serviceicon4.svg",
            card_options: [
              "Ironing",
              "Shirts, t-shirts and bedsheets.",
              "Service time 24h*",
              "Delivery FREE",
              "Items returned in a bag",
              "Eco friendly",
            ],
          },
        ],
      },
      section_6: {
        collection: [
          {
            collection_title: "Do you have a re-cleaning Policy?",
            collection_paragraph:
              "Although we have quality control where we check each garment before we pack, if we are unable to remove stains we usually inform customers before. If something slips through and you are not completely satisfied with the quality of our dry cleaning, we will re-clean your item free of charge, as long as it’s within 24 hours.",
          },
          {
            collection_title: "Do you have a loss policy?",
            collection_paragraph:
              "We take full responsibility for the clothes from the moment we issue a ticket or pick up from you. Even though we exercise the utmost care, there will be instances where items may get misplaced or lost. Should you have reason to believe that we have lost or misplaced your item. Missing items must be reported to us within 24 hours with descriptions so that we can trace your garment. In the unlikely event of losing any of your items, bestatlaundry liability with respect to any lost item shall not exceed ten (10) times our charge for cleaning that garment regardless of brand or condition.",
          },
          {
            collection_title: "Do you have a safe keeping policy?",
            collection_paragraph:
              "We take responsibility to keep your clothes safe for a maximum of 60 days. Should you be unable to pick your clothes within 60 days, due to holiday, illness, etc. please let us know ASAP and we shall arrange to keep your garments safely for an agreed time period. Otherwise, due to storage, we will have to give your clothes to charity.",
          },
          {
            collection_title: "Do you have a privacy statement?",
            collection_paragraph:
              "We respect our customer’s privacy and do not sell any identifiable information regarding our customers to any third party. Any information you give us is held with the utmost care and security, and will not be used in ways to which you have not consented.",
          },
          {
            collection_title: "Do you have a garment damage policy?",
            collection_paragraph:
              "Whilst every care is taken handling your clothes, if you believe that we have damaged your garments in any way please contact as within 24 hours. We will do our best to resolve the situation. We will investigate thoroughly and if we feel that we were negligent we shell pay fair compensation based upon the current value of the garment. This is usually around 6-10 times the cost of the dry cleaning. Our damage policy is in line with the Office of Fair Trades recommendations.",
          },
          {
            collection_title: "How should my garments be cleaned?",
            collection_paragraph:
              "As we are experts at dealing with all garments that come our way. When they arrive at our premises, your garments are examined carefully and then cleaned under the manufacturer’s instructions. Our cleaning is as gentle and delivers better results than traditional dry cleaning. If there’s any doubt or we can’t find a care label, we will clean at what we call “owner’s risk” (as we can sadly not accept any liability for garments where there are no instructions). If we feel there is really too much of a risk to your garment and we cannot get in touch with you, we will return the item to you uncleaned.",
          },
          {
            collection_title: "How long does it take to clean my items?",
            collection_paragraph:
              "We aim to collect your clothes, give them our expert care and attention and deliver them back to you within working 48 hours. There might be some exceptions: In some instances, your preferred delivery slot may not be available. This is mainly because we have already reached full capacity for that time slot, or because we do not visit your area as often as we would like at this moment. We apologise for any inconvenience caused and assure you that we are working very hard to increase our time slot capacity. Some items such as bed linen, curtains, leather, or tailoring may take longer to process as they will be entrusted to various experts. The delivery timing will be provided to you as soon as the respective items have been inspected at our premises. On inspection, we might consider that it will take longer to remove particularly difficult stains or other issues with fabrics. We offer an express service with same-day turnaround* in all our stores however due to logistical constraints thi",
          },
          {
            collection_title:
              "Why can I not select my preferred delivery slot?",
            collection_paragraph:
              "In some instances, your preferred delivery slot may not be available. This could be because we have already reached full capacity for that time slot. We apologize for any inconvenience caused and assure you that we are working very hard to increase our time slot capacity. Should you wish to drop us a line and check to see if we accommodate your chosen slot or to see if there is any cancellation please email info@bestatlaundry.uk",
          },
          {
            collection_title: "How do I order online?",
            collection_paragraph:
              "Simply click Book Now in the top right-hand corner. On the next screen, you will be able to choose a pickup time that we will collect the clothing items from your home/concierge and a dropoff time where we will drop the dry cleaned clothes back to your home/concierge. The next screen is where you can choose your items either select the items directly or choose a category and the system will refine the items to help you choose. Simply choose the quantity and select add to put the item in your basket. When ready click with view basket or the basket in the top right-hand corner of the page you can then check out adding your address and any special requirements. Lastly, you can pay using any credit or debit card using our encrypted service with stripe. We will then process your order and arrive at your requested time slot ready to pick up your items.",
          },
          {
            collection_title: "can I book a regular pickup & delivery?",
            collection_paragraph:
              "Yes! If you want to book a regular pickup and delivery slot each week, just email us to info@bestatlaundry.uk This saves having to deal with laundry and to re-book on a regular basis, and you can cancel at any time if you don’t need a delivery that week. Although it is currently not possible to book a recurring time slot online, you can call our customer care team and we will gladly set this up.",
          },
          {
            collection_title: "How do I know when my driver will arrive?",
            collection_paragraph:
              "We currently offer 1-hour time slots, which allows for traffic and our logistical requirements, if our driver is later than the chosen time slot we will contact you.",
          },
          {
            collection_title: "Why do you not cover my area?",
            collection_paragraph:
              "We are continually growing and expanding and while we might not currently cover your area. Send a message with your details to info@bestatlaundry.uk and as soon as we start to serve your area, we will contact you. You can also mail us to info@bestatlaundry.uk as they will often be able to arrange a special courier or Uber service for you (delivery fees will apply).",
          },
          {
            collection_title: "How will I be charged for the order",
            collection_paragraph:
              "We use a highly secure platform to register your credit or debit card and collect payment. After picking up your clothes, our experts will examine them and determine the total amount of your invoice based on the extent of the work that needs to be carried out on each item (much as a till assistant would do in-store). Until then, you can get an estimate of your order according to our price list here. We are unable to be more exact at this stage because some items require more time and specific treatment, and we can’t know the detail of this before we examine your garments. Once this is done, we will send you an email detailing your invoice, item by item, and charge your card on that basis. If you disagree with anything that we have detailed on your invoice, please feel free email us to info@bestatlaundry.uk and we will be happy to look into this for you. Kindly note that we cannot accept cash payment for deliveries.",
          },
          {
            collection_title: "What does owner's risk mean?",
            collection_paragraph:
              "Some fragile or highly sensitive garments inherently have a risk in cleaning, this could be due to fabric, age, colour, pre-existing damage etc. thus meaning there is a risk that they could be damaged in the cleaning process, in this instance we will refrain from cleaning the garment in question. We will make an attempt to contact you and advise you of the potential risks associated with proceeding with the treatment. Note that if you authorise us to proceed, it will imply that you assume full responsibility for any damage to the garments. In certain circumstances, we may still return the garment and advise not to clean it as damage is inevitable.",
          },
          {
            collection_title:
              "can we dry clean wedding dresses and other bespoke items?",
            collection_paragraph:
              "We are the experts at cleaning these pieces. Please note that the sooner you bring your dress in after your wedding, the better the results we can achieve, as leaving little time for stains to set helps us to get that better finish. We can also clean other bespoke items – just ask one of your store assistants for a quote, email us info@bestatlaundry.uk if you would like more information or to ask for a quote. Kindly note that we assess all those very specific items before cleaning to ensure that we have spotted and highlighted all the potential risks to you before proceeding with the clean.",
          },
        ],
      },
      section_7: {
        title_1: "Latest Testimonial",
        title_2: "What Our Client Say",
        cards: [
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser2.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser3.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser2.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser3.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
        ],
      },
      section_8: {
        title_1: "ARTICLES",
        title_2: "Latest News",
      },
      section_9: {
        title: "We're available on mobile Just download it!",
        paragraph:
          "Download the our app and request our laundry service. Your clothes washed, folded and delivered to your doorstep. So go ahead and say yes to more time with the family, more happy hours, and more slapping the snooze button every morning — we've got laundry day covered.",
        android_link:
          "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
        ios_link:
          "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
      },
      footer: {
        custome_banner_design: {
          is_enable: true,
        },
        bg_color: "151e2e",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          about_section: {
            is_enable: true,
            text: "Ready to take laundry off your to-do list? Bestatlaundry services is a local laundry pickup and delivery service that you can order directly from your smart phone.",
          },
          service_section: {
            is_enable: true,
          },
          quicklink_section: {
            is_enable: true,
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "+442086388690",
              value: "tel:+442086388690",
            },
            email: {
              label: "Email Us",
              value: "sales@bestatlaundry.com",
            },
            address: {
              text: "81 Randall Drive, Hornchurch - RM126TA",
            },
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
      },
    },
  },
  theme_3: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "assets/section/section1.jpg",
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "assets/section/section2.jpg",
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "assets/section/section3.jpg",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "assets/section/section4.jpg",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "assets/section/section5.jpg",
        },
        {
          _id: 6,
          is_section_enable: false,
          icon: "assets/section/section6.jpg",
        },
        {
          _id: 7,
          is_section_enable: false,
          icon: "assets/section/section7.jpg",
        },
        {
          _id: 8,
          is_section_enable: false,
          icon: "assets/section/section8.jpg",
        },
        {
          _id: 9,
          is_section_enable: false,
          icon: "assets/section/section9.jpg",
        },
        {
          _id: 10,
          is_section_enable: false,
          icon: "assets/section/section10.jpg",
        },
        {
          _id: 11,
          is_section_enable: true,
          icon: "assets/section/section11.jpg",
        },
        {
          _id: 12,
          is_section_enable: false,
          icon: "assets/section/section12.jpg",
        },
        {
          _id: 13,
          is_section_enable: false,
          icon: "assets/section/section13.jpg",
        },
        {
          _id: 14,
          is_section_enable: true,
          icon: "assets/section/section14.jpg",
        },
        {
          _id: 15,
          is_section_enable: false,
          icon: "assets/section/section15.jpg",
        },
      ],
      header: {
        is_section_enable: "on",
        primary_color: "1B9DB3",
        secondary_color: "F07F1B",
        nav: {
          bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "#6b7385",
            },
            cart_value_color: "000000",
          },
        },
        btn_group: {
          btn1: {
            text: "Login / Register",
            bg_color: "ffffff",
            color: "#1b9db3",
            border_color: "#1b9db3",
            btn1_is_enable: true,
          },
          btn2: {
            text: "Quick Order",
            color: "ffffff",
            bg_color: "000",
            border_color: "#192335",
            btn2_is_enable: true,
          },
        },
      },
      section_1: {
        is_section_enable: true,
        banner: "/assets/img/services/cleaning/home_page_single_search.jpg",
        sub_heading_top: "24/7 Cleaning Service",
        title: "One Solution for all your Cleaning Needs",
        paragraph:
          "Bestatcleaning is synonymous with quality, reliability, and trust. We provide fast cleaning services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers.",
        btn_group: {
          btn1: {
            text: "Request a Service",
            bg_color: "1b9db3",
            color: "ffffff",
          },
          btn2: {
            text: "Book now",
            bg_color: "F07F1B",
            color: "ffffff",
          },
        },
        mobile_link_section: {
          is_enable: true,
          mob_title: "Just A Click Away",
          android_link: "#",
          ios_link: "#",
          sub_heading_top: "24/7 Cleaning Service",
          title: "One Solution for all your Cleaning Needs",
          paragraph:
            "Bestatcleaning is synonymous with quality, reliability, and trust. We provide fast cleaning services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers.",
          "btn1.text": "Request a Service",
        },
        "btn1.text": "Request a Service",
      },
      section_2: {
        is_section_enable: true,
        sub_heading_top: "Book A Service",
        title: "Select Your Time & Location For The Service",
        btn_group: {
          btn1: {
            text: "order now",
            bg_color: "#1b9db3",
            color: "ffffff",
          },
        },
      },
      section_3: {
        is_section_enable: true,
        sub_heading_top: "Cleaning Service",
        title: "Trust The Expert On Cleaning Services",
        paragraph:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
        card_section: {
          btn_group: {
            btn1: {
              text: "More About Best@cleaning",
              bg_color: "192335",
              color: "ffffff",
            },
          },
          collection: [
            {
              _id: 1,
              icon: "/assets/img/services/priorityicon1.png",
              collection_title: "Top Quality",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 2,
              icon: "/assets/img/services/priorityicon2.png",
              collection_title: "Best Service",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 3,
              icon: "/assets/img/services/priorityicon3.png",
              collection_title: "Value",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 4,
              icon: "/assets/img/services/priorityicon4.png",
              collection_title: "Professionalism",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 5,
              icon: "/assets/img/services/priorityicon5.png",
              collection_title: "Convenience",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 6,
              icon: "/assets/img/services/priorityicon6.png",
              collection_title: "Cleanliness",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
          ],
        },
        "btn1.text": "More About Best@cleaning",
      },
      section_4: {
        is_section_enable: true,
        title:
          "Bestatcleaning is Ranked #1 by customers in the UK in categories of cleaning",
        "section_4.title":
          "Bestatcleaning is Ranked #1 by customers in the UK in categories of cleaning",
      },
      section_5: {
        is_section_enable: true,
        banner: [
          {
            banner: "/assets/img/services/cleaning/img3.png"
          }
        ],
        title: "We visit, clean, & deliver a perfect service on cleaning.",
        paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took",
        check_list: [
          {
            _id: 1,
            list: "Same Day Cleaning",
          },
          {
            _id: 2,
            list: "24h turnaround time",
          },
          {
            _id: 3,
            list: "Dedicated 24/7 support",
          },
          {
            _id: 4,
            list: "Standard clean for just Â£15",
          },
        ],
        btn_group: {
          btn1: {
            text: "Schedule a Service Now",
            bg_color: "#192335",
            color: "ffffff",
            is_enable: true
          },
          btn2: {
            text: "Request a Service",
            bg_color: "#1b9db3",
            color: "ffffff",
            is_enable: true
          }
        },
      },
      section_6: {
        is_section_enable: true,
        sub_heading_top: "Know More On",
        title: "How We Works",
        paragraph: "We visit & clean your place within 24-48 hrs.",
        card_section: {
          btn_group: {
            paragraph: "Lorem Ipsum is simply dummy text of the",
            btn1: {
              text: "Book Online Now",
              bg_color: "#192335",
              color: "#ffffff",
            },
          },
          collection: [
            {
              _id: 1,
              icon: "/assets/img/services/cleaning/img2.png",
              card_title: "Online Order",
              card_paragraph:
                "Use our friendly and easy access website or app to place your cleaning order. choose a suitable service location and time.",
            },
            {
              _id: 2,
              icon: "assets/home/howwe2.jpg",
              card_title: "Transport",
              card_paragraph:
                "Our registered worker will visit your doorstep and clean your place safely with our most trusted cleaning facilities.",
            },
            {
              _id: 3,
              icon: "assets/home/howwe3.jpg",
              card_title: "Cleaning",
              card_paragraph:
                "Our registered worker will visit your doorstep and clean your place safely with our most trusted cleaning facilities.",
            },
            {
              _id: 4,
              icon: "assets/home/howwe4.jpg",
              card_title: "Perfect Delivery",
              card_paragraph:
                "All your needs will be cleaned and were perfectly delivered to you.",
            },
          ],
        },
        card_paragraph:
          "All your needs will be cleaned and were perfectly delivered to you.",
        card_title: "Perfect Delivery",
      },
      section_7: {
        is_section_enable: true,
        title: "On Demand Cleaning Categories",
        paragraph:
          "We provide carpet cleaning, tenancy cleaning, office cleaning, oven cleaning services in London, Essex, Kent, Westminster, Chelsea, Dartford, Croydon, Enfield, Basildon, South On Sea, Bromley, Mayfair, Romford and other areas in London.",
        btn_group: {
          btn_paragraph: "Lorem Ipsum is simply dummy text of the",
          btn1: {
            text: " Request a Service",
            bg_color: "192335",
            color: "fff",
            title: "On Demand Cleaning Categories",
            paragraph:
              "We provide carpet cleaning, tenancy cleaning, office cleaning, oven cleaning services in London, Essex, Kent, Westminster, Chelsea, Dartford, Croydon, Enfield, Basildon, South On Sea, Bromley, Mayfair, Romford and other areas in London.",
          },
          title: "On Demand Cleaning Categories",
          paragraph:
            "We provide carpet cleaning, tenancy cleaning, office cleaning, oven cleaning services in London, Essex, Kent, Westminster, Chelsea, Dartford, Croydon, Enfield, Basildon, South On Sea, Bromley, Mayfair, Romford and other areas in London.",
          text: " Request a Service",
        },
        text: " Request a Service",
      },
      section_8: {
        is_section_enable: true,
        banner: "howwe1",
        title: "About Best@cleaning",
        section_image: "assets/home/trustimg2.jpg",
        paragraph:
          "Bestacleaning is synonymous with quality, reliability, and trust. We provide fast cleaning services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers.",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "James please use React import aliase",
              collection_title: "Quick Visit",
              collection_image: "/assets/img/services/priorityicon15.png",
              isEnabled: true,
              collection_paragraph:
                "We offer a convenient and quick way to have a visit on your place.",
            },
            {
              _id: 2,
              icon: "James please use React import aliase",
              collection_title: "High Quality of Services",
              collection_image: "/assets/img/services/priorityicon8.png",
              isEnabled: true,
              collection_paragraph:
                "Our cleaning team perfectly cleans, and deliver to you on time.",
            },
            {
              _id: 3,
              icon: "James please use React import aliase",
              collection_title: "Affordable Cost",
              collection_image: "/assets/img/services/priorityicon9.png",
              isEnabled: true,
              collection_paragraph:
                "We offer affordable prices when compared to the current market.",
            },
          ],
          _id: 2,
          icon: "James please use React import aliase",
          collection_title: "High Quality of Services",
          collection_image: "/assets/img/services/priorityicon8.png",
          isEnabled: true,
          collection_paragraph:
            "Our cleaningteam perfectly cleans, and deliver to you on time.",
        },
        btn_group: {
          btn1_text: "Schedule a Service Now",
          btn1_bg_color: "192335",
          btn1_color: "ffffff",
          btn2_text: "Request a Service",
          btn2_bg_color: "1b9db3",
          btn2_color: "ffffff",
          title: "About Best@cleaning",
          paragraph:
            "Bestacleaning is synonymous with quality, reliability, and trust. We provide fast cleaning services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers.",
          collection_title: "Quick Visit",
          collection_paragraph:
            "Our cleaning team perfectly cleans, and deliver to you on time.",
        },
        btn1_text: "Schedule a Service Now",
        btn2_text: "Request a Service",
        collection_title: "Quick Visit",
        collection_paragraph:
          "Our cleaning team perfectly cleans, and deliver to you on time.",
      },
      section_9: {
        collection: [
          {
            _id: 1,
            icon: "assets/home/priorityimg1.jpg",
            collection_title: "Cleaning",
            collection_paragraph:
              "A tender cleaning procedure is done which may include fluff drying process depending on the places. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.",
          },
          {
            _id: 2,
            icon: "assets/home/priorityimg2.jpg",
            collection_title: "Cleaning",
            collection_paragraph:
              "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.",
          },
          {
            _id: 3,
            icon: "assets/home/priorityimg3.jpg",
            collection_title: "Cleaning",
            collection_paragraph:
              "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.",
          },
          {
            _id: 14,
            icon: "assets/home/priorityimg1.jpg",
            collection_title: "Cleaning",
            collection_paragraph:
              "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.",
          },
        ],
        count_section: [
          {
            _id: 1,
            icon: "/assets/img/services/priorityicon1.png",
            text: "Houses",
            count: "1639+",
          },
          {
            _id: 2,
            icon: "/assets/img/services/priorityicon2.png",
            text: "Rooms",
            count: "24359+",
          },
          {
            _id: 3,
            icon: "/assets/img/services/priorityicon3.png",
            text: "Dry Cleaning",
            count: "4793+",
          },
          {
            _id: 4,
            icon: "/assets/img/services/priorityicon4.png",
            text: "Cleaning programs",
            count: "25+",
          },
        ],
        text: "Cleaning",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "assets/home/priorityimg1.jpg",
              collection_title: "Cleaning",
              collection_paragraph:
                "A tender cleaning procedure is done which may include fluff drying process depending on the places. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.",
            },
            {
              _id: 2,
              icon: "assets/home/priorityimg2.jpg",
              collection_title: "Cleaning",
              collection_paragraph:
                "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.",
            },
            {
              _id: 3,
              icon: "assets/home/priorityimg3.jpg",
              collection_title: "Cleaning",
              collection_paragraph:
                "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.",
            },
            {
              _id: 14,
              icon: "assets/home/priorityimg1.jpg",
              collection_title: "Cleaning",
              collection_paragraph:
                "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.",
            },
          ],
          count_section: [
            {
              _id: 1,
              icon: "/assets/img/services/priorityicon1.png",
              text: "Houses",
              count: "1639+",
            },
            {
              _id: 2,
              icon: "/assets/img/services/priorityicon2.png",
              text: "Rooms",
              count: "24359+",
            },
            {
              _id: 3,
              icon: "/assets/img/services/priorityicon3.png",
              text: "Cleaning",
              count: "4793+",
            },
            {
              _id: 4,
              icon: "/assets/img/services/priorityicon4.png",
              text: "Cleaning programs",
              count: "25+",
            },
          ],
        },
        sub_heading_top: "Our Promising Services",
        title: "On Demand Cleaning Services",
        paragraph: "We clean and deliver your needs within 24-48 hrs",
      },
      section_10: {
        is_section_enable: true,
        banner: "assets/home/trustimg3.png",
        sub_heading_top: "Your Health & Safety Is Our Priority",
        title: "Our Values",
        mainparagraph:
          "online cleaning companies who simply just own the technology and farm the cleaning to other providers with little to no process system checks.",
        list_Section: [
          {
            _id: 1,
            text: "Contactless deliveries",
            paragraph:
              "For our customers safety, we enabled the feature to allow customers to conduct contactless deliveries.",
          },
          {
            _id: 2,
            text: "Instant updates",
            paragraph:
              "Weâ€™ll keep you posted throughout the process with texts/emails when driver is on their way and order has been cleaned.",
          },
          {
            _id: 3,
            text: "Monitoring the situation",
            paragraph:
              "Weâ€™re keeping up to date with all developments from government to ensure the safety of our customers and staff.",
          },
        ],
        card_section_title: "We use Eco-friendly Cleaning Sheets.",
        card_section: [
          {
            _id: 1,
            card_bg_color: "192335",
            card_text: "Vegan",
            card_paragraph:
              "Our vegan-friendly cleaning detergent is free from any animal product.",
          },
          {
            _id: 2,
            card_bg_color: "f07f1b",
            card_text: "Cruelty Free",
            card_paragraph:
              "We never test on animals â€“ so our cleaning leaves will always be cruelty-free.",
          },
          {
            _id: 3,
            card_bg_color: "1bb333",
            card_text: "Eco-Friendly",
            card_paragraph:
              "Free from any harmful chemicals or pollutants and are devoid of harmful toxins.",
          },
        ],
        paragraph:
          "For our customers safety, we enabled the feature to allow customers to conduct contactless deliveries.",
        card_paragraph:
          "We never test on animals â€“ so our cleaning leaves will always be cruelty-free.",
      },
      section_11: {
        is_section_enable: true,
        banner: [
          {
            banner: "/assets/img/services/cleaning/img5.png",
          },
        ],
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "How Quick visit works?",
              isEnabled: true,
              collection_paragraph:
                "We offer a convenient way to have quick visit and delivery.",
            },
            {
              _id: 2,
              collection_title: "What is High Quality of Services?",
              isEnabled: true,
              collection_paragraph:
                "Our cleaning team perfectly cleans and deliver to you on time.",
            },
            {
              _id: 3,
              collection_title: "What about Cost?",
              isEnabled: true,
              collection_paragraph:
                "We offer affordable prices when compared to the current market.",
            },
          ],
        },
        sub_heading_top: "Ask Your Question",
        title: "Frequently Asked Questions",
        paragraph:
          "online cleaning companies who simply just own the technology and farm the cleaning to other providers with little to no process system checks.",
        "section_11.paragraph":
          "online cleaning companies who simply just own the technology and farm the cleaning to other providers with little to no process system checks.",
      },
      section_12: {
        is_section_enable: true,
        banner: "howwe1",
        sub_heading_top: "What Our Client Says",
        title: "A Trusted Cleaning Company",
        paragraph: "Read what our customers say about Best@cleaning",
        review_bottom_paragraph:
          "More than 5,000+ reviews s on TrustPilot, Google, Google Play & App Store",
        review_card: [
          {
            _id: 1,
            reviewer_pic: "/assets/img/services/testimonialuser1.jpg",
            reviewer_name: "Ariyan Rusov",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 2,
            reviewer_pic: "/assets/img/services/testimonialuser2.jpg",
            reviewer_name: "Darren Valdez",
            rating: "4",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 3,
            reviewer_pic: "/assets/img/services/testimonialuser3.jpg",
            reviewer_name: "Elinor Dunn",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
        ],
        btn_group: {
          btn1: {
            text: "Book Online Now",
            bg_color: "#192335",
            color: "#ffffff",
            is_enable: true,
          },
        },
        "section_12.title": "A Trusted Cleaning Company",
        "section_12.paragraph":
          "Read what our customers say about Best@cleaning",
        review:
          "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
      },
      section_13: {
        is_section_enable: true,
        banner: "howwe1",
        title: "Just A Click Away",
        paragraph: "Download Our Best Active Mobile App",
        check_list: [
          {
            _id: 1,
            icons: "/assets/img/services/priorityicon14.png",
            title: "Cashless payments",
          },
          {
            _id: 2,
            icons: "/assets/img/services/priorityicon15.png",
            title: "Quick Delivery",
          },
          {
            _id: 3,
            icons: "/assets/img/services/priorityicon16.png",
            title: "Standard clean for just Â£15",
          },
          {
            _id: 4,
            icons: "/assets/img/services/priorityicon17.png",
            title: "In-app live chat support",
          },
        ],
        mobile_link_section: {
          is_enable: true,
          android_link:
            "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link:
            "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
          "check_list.title": "Standard clean for just Â£15",
        },
        "check_list.title": "Standard clean for just Â£15",
      },
      section_14: {
        is_section_enable: true,
        section_bg_color: "#1b9db3",
        title:
          "Subscribe to our newsletter for updates, exclusive promotions and our latest content!",
        btn_group: {
          btn1: {
            text: "Subscribe",
            bg_color: "#192335",
            color: "ffffff",
          },
        },
      },
      section_15: {
        is_section_enable: true,
        sub_heading_top: "Our Latest",
        title: "News & Events",
        paragraph:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
        btn_group: {
          btn1: {
            text: "More News & Events",
            bg_color: "#192335",
            color: "ffffff",
          },
        },
      },
      footer: {
        custome_banner_design: {
          is_enable: true,
        },
        bg_color: "151e2e",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          about_section: {
            is_enable: true,
            text: "Bestatcleaning services is a local cleaning service that you can order directly from your smart phone.",
          },
          service_section: {
            is_enable: true,
          },
          quicklink_section: {
            is_enable: true,
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "+442086388690",
              value: "tel:+442086388690",
            },
            email: {
              label: "Email Us",
              value: "sales@bestatcleaning.com",
            },
            address: {
              text: "81 Randall Drive, Hornchurch - RM126TA",
            },
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
        "email.value": "sales@bestatcleaning.com",
        about_section:
          "Bestatcleaning services is a local cleaning service that you can order directly from your smart phone.",
      },
    },
  },
  theme_4: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "assets/sections-four/Section_1.png",
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "assets/sections-four/Section_2.png",
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "assets/sections-four/Section_3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "assets/sections-four/Section_4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "assets/sections-four/Section_5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "assets/sections-four/Section_6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "assets/sections-four/Section_7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "assets/sections-four/Section_8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "assets/sections-four/Section_9.png",
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "assets/sections-four/Section_10.png",
        },
        {
          _id: 11,
          is_section_enable: true,
          icon: "assets/sections-four/Section_11.png",
        },
        {
          _id: 12,
          is_section_enable: true,
          icon: "assets/sections-four/Section_12.png",
        },
      ],
      header: {
        is_section_enable: true,
        primary_color: "046A38",
        secondary_color: "01190D",
        contact: {
          email_id: "sales@bestatlaundry.com",
          contact_number: "+442086388690",
        },
        nav: {
          bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "#6b7385",
            },
            cart_value_color: "000000",
          },
        },
        btn_group: {
          btn1_text: "Login / Register",
          btn1_bg_color: "046A38",
          btn1_color: "ffffff",
          btn1_border_color: "046A38",
          btn1_is_enable: true,
          btn2_text: "Quick Order",
          btn2_color: "ffffff",
          btn2_bg_color: "000",
          btn2_border_color: "#192335",
          btn2_is_enable: true,
        },
      },
      section_1: {
        is_section_enable: true,
        banner_type: "image",
        banner: "assets/home_page_single_search.jpg",
        banner_two: "assets/img/home/heroimg.png",
        sub_heading_top: "24/7 Dry Cleaning & Laundry Service",
        title: "One Solution for all your Washing Needs",
        paragraph: "Select your time & location for the service",
        btn_group: {
          btn1: {
            text: "Order now",
            bg_color: "#046A38",
            color: "ffffff",
            placeholder: "Enter Postcode",
          },
        },
      },
      section_2: {
        is_section_enable: true,
        title: "Trust the Experts",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        first_card_image: "assets/img/home/trustimg1.jpg",
        image_title: "Why 1000+ customers trust Us?",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/img/services/priorityicon1.png",
              collection_title: "Top Quality",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 2,
              icon: "/assets/img/services/priorityicon4.png",
              collection_title: "Professionalism",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 3,
              icon: "/assets/img/services/priorityicon5.png",
              collection_title: "Convenience",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 4,
              icon: "/assets/img/services/priorityicon6.png",
              collection_title: "Cleanliness",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
          ],
        },
      },
      section_3: {
        is_section_enable: true,
        title:
          "5 star Rated by customers in the UK in categories of dry cleaning",
        banner: "assets/img/home/review-bg.jpg",
      },
      section_4: {
        is_section_enable: true,
        banner: "assets/home/trustimg2.jpg",
        title: "About We",
        paragraph_one:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock,",
        paragraph_two:
          "A Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
        option_one: "Free same day collection",
        option_two: "Dedicated 24/7 support",
        option_three: "Standard wash for just £14",
        btn_group: {
          btn1: {
            text: "Know More",
            bg_color: "#046A38",
            color: "ffffff",
            is_enable: true,
          },
        },
      },
      section_5: {
        is_section_enable: true,
        title: "How We Works",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "assets/home/howwe1.jpg",
              card_title: "Online Order",
              card_paragraph:
                "Use our friendly and easy access website or app to place your laundry order. choose a suitable pickup location and time.",
            },
            {
              _id: 2,
              icon: "assets/home/howwe2.jpg",
              card_title: "Transport",
              card_paragraph:
                "Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities.",
            },
            {
              _id: 3,
              icon: "assets/home/howwe3.jpg",
              card_title: "Dry Cleaning",
              card_paragraph:
                "Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities.",
            },
            {
              _id: 4,
              icon: "assets/home/howwe4.jpg",
              card_title: "Door Delivery",
              card_paragraph:
                "Dry and clean clothes were carefully bagged and delivered to your doorstep.",
            },
          ],
        },
      },
      section_6: {
        is_section_enable: true,
        title: "service for Business & Personal",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        first_card_image: "assets/home/trustimg3.png",
        sub_title: "For Business",
        sub_title_two: "For Personal",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_list: "Restaurants",
            },
            {
              _id: 2,
              collection_list: "Spa",
            },
            {
              _id: 3,
              collection_list: "Hotels",
            },
            {
              _id: 4,
              collection_list: "Gym",
            },
            {
              _id: 5,
              collection_list: "Factory",
            },
            {
              _id: 6,
              collection_list: "Malls",
            },
            {
              _id: 7,
              collection_list: "Public sectors",
            },
            {
              _id: 8,
              collection_list: "Hospitals",
            },
          ],
        },
        card_section_two: {
          collection: [
            {
              _id: 1,
              collection_list: "Home Stay",
            },
            {
              _id: 2,
              collection_list: "Local Living",
            },
            {
              _id: 3,
              collection_list: "Hostel",
            },
            {
              _id: 4,
              collection_list: "Cottage",
            },
            {
              _id: 5,
              collection_list: "PG Stay",
            },
          ],
        },
      },
      section_7: {
        is_section_enable: true,
        title: "Our Product Category",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
      },
      section_8: {
        is_section_enable: true,
        part_one: {
          title: "How we Prioritize",
          sub_title: "Step we follow for Health & Safety Precaution",
          section_image: "assets/home/trustimg2.jpg",
          paragraph:
            "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old",
          card_section: {
            collection: [
              {
                _id: 1,
                collection_title: "Contactless deliveries",
                collection_paragraph:
                  "For our customers safety, we enabled the feature to allow customers to conduct contactless pick up & deliveries.",
              },
              {
                _id: 2,
                collection_title: "Instant updates",
                collection_paragraph:
                  "We’ll keep you posted throughout the process with texts/emails when driver is on their way and order has been cleaned.",
              },
              {
                _id: 3,
                collection_title: "Monitoring the situation",
                collection_paragraph:
                  "We’re keeping up to date with all developments from government to ensure the safety of our customers and staff.",
              },
            ],
          },
        },
        part_two: {
          bg_color: "046A38",
          text_color: "ffffff",
          title: "We use Eco-friendly Laundry Detergent Sheets.",
          paragraph:
            "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin",
          card_section: {
            collection: [
              {
                _id: 1,
                collection_title: "Vegan",
                collection_paragraph:
                  "Our vegan-friendly laundry detergent is free from any animal product.",
              },
              {
                _id: 2,
                collection_title: "Cruelty Free",
                collection_paragraph:
                  "We never test on animals – so our Laundry Leaves will always be cruelty-free.",
              },
              {
                _id: 3,
                collection_title: "Eco-Friendly",
                collection_paragraph:
                  "Free from any harmful chemicals or pollutants and are devoid of harmful toxins.",
              },
            ],
          },
        },
      },
      section_9: {
        is_section_enable: true,
        title: "Frequently Asked Question",
        description:
          " Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        card_image: "assets/home/trustimg4.jpg",
      },
      section_10: {
        is_section_enable: true,
        banner: "howwe1",
        title: "Client Testimonials",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        review_card: [
          {
            _id: 1,
            reviewer_pic: "/assets/img/services/testimonialuser1.jpg",
            reviewer_name: "Ariyan Rusov",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 2,
            reviewer_pic: "/assets/img/services/testimonialuser2.jpg",
            reviewer_name: "Darren Valdez",
            rating: "4",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 3,
            reviewer_pic: "/assets/img/services/testimonialuser3.jpg",
            reviewer_name: "Elinor Dunn",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
        ],
      },
      section_11: {
        is_section_enable: true,
        title: "Just a Click Away",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        check_list: [
          {
            _id: 1,
            icons: "/assets/img/services/priorityicon14.png",
            title: "Cashless payments",
          },
          {
            _id: 2,
            icons: "/assets/img/services/priorityicon15.png",
            title: "Delivery in less then 24 hours",
          },
          {
            _id: 3,
            icons: "/assets/img/services/priorityicon16.png",
            title: "Standard wash for just £14",
          },
          {
            _id: 4,
            icons: "/assets/img/services/priorityicon17.png",
            title: "In-app live chat support",
          },
        ],
        banner: "assets/home/just_clickaway.png",
      },
      section_12: {
        is_section_enable: true,
        title: "Blogs & Events",
        description:
          " Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
      },
      footer: {
        bg_color: "046A38",
        section_paragraph_color: "c2c2c2",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        social_media: {
          facebook: {
            is_enable: true,
            facebook_link: "facebook.com",
          },
          instagram: {
            is_enable: true,
            instagram_link: "instagram.com",
          },
          whatsapp: {
            is_enable: true,
            whatsapp_link: "whatsapp.com",
          },
          linkedin: {
            is_enable: true,
            linkedin_link: "linkedin.com",
          },
          youtube: {
            is_enable: true,
            youtube_link: "youtube.com",
          },
          twitter: {
            is_enable: true,
            twitter_link: "twitter.com",
          },
        },
      },
    },
  },
  theme_5: {
    home: {
      header: {
        is_section_enable: "on",
        primary_color: "2fa0ea",
        secondary_color: "efc10b",
        nav: {
          bg_color: "ffffff",
          menu_font_color: "000000",
          menu_font_color_active: "efc10b",
          menu_font_color_hover: "efc10b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "000000",
              bg_color: "ffffff",
              menu_font_color_hover: "efc10b",
              menu_font_color_active: "efc10b",
              btn1_bg_color: "efc10b",
              btn2_bg_color: "efc10b",
              btn1_border_color: "efc10b",
              btn2_border_color: "efc10b",
              btn3_bg_color: "efc10b",
              btn3_border_color: "efc10b",
              menu_font_color: "000000",
              btn1_color: "000000",
              btn2_color: "000000",
              btn3_color: "000000",
              cart_value_color: "ffffff"
            },
            cart_value_color: "ffffff",
            menu_font_color_hover: "efc10b",
            menu_font_color_active: "efc10b",
            btn1_bg_color: "efc10b",
            btn2_bg_color: "efc10b",
            btn1_border_color: "efc10b",
            btn2_border_color: "efc10b",
            btn3_bg_color: "efc10b",
            btn3_border_color: "efc10b",
            menu_font_color: "000000",
            btn1_color: "000000",
            btn2_color: "000000",
            btn3_color: "000000",
            color: "000000"
          },
          btn1_bg_color: "efc10b",
          btn2_bg_color: "efc10b",
          btn1_border_color: "efc10b",
          btn2_border_color: "efc10b",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          btn1_color: "000000",
          btn2_color: "000000",
          btn3_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        btn_group: {
          btn1_text: "Log in",
          btn1_bg_color: "efc10b",
          btn1_color: "000000",
          btn1_border_color: "efc10b",
          btn1_is_enable: true,
          btn2_text: "Sign up",
          btn2_bg_color: "efc10b",
          btn2_color: "000000",
          btn2_border_color: "efc10b",
          btn2_is_enable: true,
          btn3_text: "Quick Order",
          btn3_color: "000000",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          btn3_is_enable: true,
          bg_color: "ffffff",
          menu_font_color_hover: "efc10b",
          menu_font_color_active: "efc10b",
          menu_font_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        bg_color: "ffffff",
        contact: {
          bg_color: "ffffff",
          menu_font_color_hover: "efc10b",
          menu_font_color_active: "efc10b",
          btn1_bg_color: "efc10b",
          btn2_bg_color: "efc10b",
          btn1_border_color: "efc10b",
          btn2_border_color: "efc10b",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          menu_font_color: "000000",
          btn1_color: "000000",
          btn2_color: "000000",
          btn3_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        menu_font_color_hover: "efc10b",
        menu_font_color_active: "efc10b",
        btn1_bg_color: "efc10b",
        btn2_bg_color: "efc10b",
        btn1_border_color: "efc10b",
        btn2_border_color: "efc10b",
        btn3_bg_color: "efc10b",
        btn3_border_color: "efc10b",
        menu_font_color: "000000",
        btn1_color: "000000",
        btn2_color: "000000",
        btn3_color: "000000",
        cart_value_color: "ffffff",
        color: "000000"
      },
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/section-five/section_1.png"
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/section-five/section_2.png"
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/section-five/section_3.png"
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/section-five/section_4.png"
        },
        {
          _id: 5,
          is_section_enable: false,
          icon: "/assets/section-five/section_5.png"
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/section-five/section_6.png"
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/section-five/section_7.png"
        },
        {
          _id: 8,
          is_section_enable: false,
          icon: "/assets/section-five/section_8.png"
        }
      ],
      section_1: {
        is_section_enable: true,
        banner_type: "image",
        banner: "/assets/home_page_single_search.jpg",
        banner_two: "https://api.bestatservices.com/storage/compress_images/65c64bab94fb1.jpeg",
        title: "Welcome to BestatServices",
        paragraph: "Unveil an array of innovative white label software solutions crafted to cater to a diverse range of services, spanning across laundry, cleaning, restaurants, salons, nail care, and barber shops.",
        btn_group: {
          btn1: {
            text: "Search",
            bg_color: "efc10b",
            color: "ffffff",
            placeholder: "Enter Postcode",
            paragraph: "Unveil an array of innovative white label software solutions crafted to cater to a diverse range of services, spanning across laundry, cleaning, restaurants, salons, nail care, and barber shops.",
            title: "Welcome to BestatServices"
          }
        },
        bg_color: "efc10b"
      },
      section_4: {
        is_section_enable: true,
        banner: "/assets/img/offer/offer-vector-bg.png",
        offer_tag: "/assets/img/offer/offer-tag.png",
        picture: "/assets/img/offer/offer-wash-img.png",
        paragraph_one: "* Offer applied only to Registered Users (On Selected Shops)"
      },
      section_5: {
        is_section_enable: true,
        banner: "/assets/img/wash-fold-bg.jpg",
        banner_two: "/assets/img/home/heroimg.png",
        title: "WASH & FOLD",
        wash_title: [
          {
            _id: 1,
            wash_list: "Washed under 30 degrees"
          },
          {
            _id: 2,
            wash_list: "Dried at medium heat"
          },
          {
            _id: 3,
            wash_list: "Customerâ€™s responsibility to make sure all items are washable"
          },
          {
            _id: 4,
            wash_list: "Towels and beddings are excluded and will be charged separately."
          }
        ]
      },
      section_6: {
        is_section_enable: true,
        title: "ABOUT BESTATSERVICES",
        sub_heading: "We believe in innovation, fueling success at every step",
        section_image: "https://api.bestatservices.com/storage/compress_images/65c64ad629988.png",
        background_image_color: "2fa0ea",
        paragraph: "It is one of zest and competence, which drives us to create extraordinary solutions adapted to your specific requirements. We want to be your valued partner on the road to success by committing to excellence.",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "Cost Effective",
              collection_image: "/assets/img/about-section/about-icon-01.png",
              isEnabled: true,
              collection_paragraph: "BestatServices provides quality service with reasonable prices to all."
            },
            {
              _id: 2,
              collection_title: "Ease to Use",
              collection_image: "/assets/img/about-section/about-icon-02.png",
              isEnabled: true,
              collection_paragraph: "User friendly website, Book orders with 1 step."
            },
            {
              _id: 3,
              collection_title: "Faster Service",
              collection_image: "/assets/img/about-section/about-icon-03.png",
              isEnabled: true,
              collection_paragraph: "Our team will complete the Service within 48 hours."
            },
            {
              _id: 4,
              collection_title: "Quality of Service",
              collection_image: "/assets/img/about-section/about-icon-04.png",
              isEnabled: true,
              collection_paragraph: "Our promise is to provide an exceptional service to you."
            }
          ],
          _id: 4,
          collection_title: "Quality of Service",
          collection_image: "/assets/img/about-section/about-icon-04.png",
          isEnabled: true,
          collection_paragraph: "Our promise is to provide a exceptional service to you."
        },
        collection_paragraph: "Our promise is to provide an exceptional service to you.",
        collection_title: "Faster Service"
      },
      section_7: {
        is_section_enable: true,
        title: "Ease to Made Services",
        paragraph1: "BestatServices is a trusted white-label SaaS Software provider for businesses and agencies in this digital age.",
        banner: "https://api.bestatservices.com/storage/compress_images/65c64e69e0a75.png",
        paragraph2: "Our product is compatible with desktops, laptops, Macs, iOS, and Android devices",
        paragraph3: "Keep clients updated on their request status via email and SMS.",
        paragraph4: "Generate invoices for customers and business accounts by sending them printable PDFs or via email.",
        card_section: [
          {
            _id: 1,
            text: "Search for your postal code"
          },
          {
            _id: 2,
            text: "Select a convenient shop"
          },
          {
            _id: 3,
            text: "Add required services in your cart"
          },
          {
            _id: 4,
            text: "Choose a convenient date and time slot"
          },
          {
            text: "Place Order"
          }
        ],
        text: "Place Order"
      },
      section_8: {
        is_section_enable: true,
        banner: "/assets/home/iPhone13.png",
        bg_color: "#FBF2FF",
        placeholder: "Eg:-  +4407816545718",
        banner_two: "/assets/img/home/heroimg.png",
        title: "Download the app",
        paragraph: "With our award-winning app, your laundry and dry cleaning needs are just a tap away",
        mobile_link_section: {
          is_enable: true,
          android_link: "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link: "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8"
        }
      },
      footer: {
        custome_banner_design: {
          is_enable: true
        },
        bg_color: "2fa0ea",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        site_title_en: "Â© Best at Services 2024. All rights reserved. ",
        footer_section: {
          needhelp_section: {
            is_enable: true,
            text: "NEED HELP?"
          },
          policy_section: {
            is_enable: true,
            text: "OUR POLICY"
          },
          quickconnect_section: {
            is_enable: true,
            text: "QUICK CONNECT"
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "Phone",
              value: "+447438823475"
            },
            email: {
              label: "Email",
              value: "sales@bestatservices.com"
            }
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com"
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com"
            },
            whatsapp: {
              is_enable: false,
              whatsapp_link: "whatsapp.com"
            },
            linkedin: {
              is_enable: false,
              linkedin_link: "linkedin.com"
            },
            youtube: {
              is_enable: false,
              youtube_link: "youtube.com"
            },
            twitter: {
              is_enable: false,
              twitter_link: "twitter.com"
            }
          },
          mobile_link_section: {
            is_enable: true,
            android_link: "",
            ios_link: "",
            bg_color: "2fa0ea",
            "email.value": "sales@bestatservices.com",
            "mbl_no.value": "+447438823475",
            site_link: "Â© Best at Services 2024. All rights reserved. "
          }
        },
        ios_link: "",
        android_link: "",
        "email.value": "sales@bestatservices.com",
        "mbl_no.value": "+447438823475",
        site_link: "Â© Best at Services 2024. All rights reserved. "
      }
    }
  },
};
export const shop_data_salon = {
  theme_1: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "assets/img/section/section1.png",
        },
        {},
        // {
        //   _id: 2,
        //   is_section_enable: true,
        //   icon: "assets/img/section/section2.png",
        // },
        {
          _id: 3,
          is_section_enable: true,
          icon: "assets/img/section/section3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "assets/img/section/section4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "assets/img/section/section5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "assets/img/section/section6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "assets/img/section/section7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "assets/img/section/section8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "assets/img/section/section9.png",
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "assets/img/section/section10.png",
        },
      ],
      header: {
        primary_color: "ff0059",
        secondary_color: "ffcb00",
        nav: {
          nav_bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            cart_bg_color: "ffffff",
            cart_icon_bg_color: "f07f1b",
            cart_value_color: "000000",
          },
          contact: {
            contact_is_enable: true,
            number: "+(044) 071012345",
            contact_color: "2e3639",
          },
        },
        btn_group: {
          btn1: {
            text: "Login / Register",
            btn_bg_color: "ffffff",
            btn_color: "1b9db3",
            border_color: "1b9db3",
            btn1_is_enable: true,
          },
        },
      },
      section_1: {
        banner: "assets/img/theme1/girl-hari-dresser.png",
        sub_heading_top: "24/7 Salon Service",
        title: "One Solution For All Your Salon Needs",
        paragraph:
          "The Ultimate Salon Experience Awaits—Indulge in Unmatched Luxury Today!",
        btn_group: {
          btn1: {
            text: "Book now",
            placeholder: "Enter Postalcode",
            bg_color: "1b9db3",
            color: "ffffff",
          },
        },
      },
      section_2: {
        title: "Select Your Time & Location For The Service",
        btn_group: {
          btn1: {
            text: "order now",
            placeholder: "Enter Postcode",
            bg_color: "1b9db3",
            color: "ffffff",
          },
        },
      },
      section_3: {
        is_section_enable: true,
        banner: "assets/theme1/trustimg.png",
        title: "Trust the Expert",
        paragraph:
          "Let your inner beauty take center stage as we transform, illuminate, and inspire at our welcoming salon ",
        sub_heading: "Unique solutions for all your Salon needs",
        check_list: [
          {
            _id: 1,
            title: "Smart Offers",
          },
          {
            _id: 1,
            title: "Consultations",
          },
          {
            _id: 1,
            title: "Scheduling App",
          },
          {
            _id: 1,
            title: "Appointment Reminders & more",
          },
        ],
        btn_group: {
          btn1: {
            text: "Read More",
            bg_color: "#ffcb00",
            color: "ffffff",
            is_enable: true,
          },
        },
      },
      section_4: {
        title: "Experience Beauty and Pampering Like Never Before",
        cards: [
          {
            heading: "Exceptional Service",
            icon: "assets/img/icons/priorityicon1.svg",
            image: "assets/img/home/priorityimg1.jpg",
            paragraph:
              "Experience a level of service that goes beyond expectations, leaving you refreshed and rejuvenated after every visit.",
          },
          {
            heading: "Professionalism",
            icon: "assets/img/icons/priorityicon2.svg",
            image: "assets/img/home/priorityimg2.jpg",
            paragraph:
              "Your salon visit reflects our commitment to professionalism—delivering top-notch service with a touch of class and expertise.",
          },
          {
            heading: "Value",
            icon: "assets/img/icons/priorityicon3.svg",
            image: "assets/img/home/priorityimg3.jpg",
            paragraph:
              "Experience a perfect blend of quality service, affordability, and a sense of well-being @ our Salon.",
          },
        ],
      },
      section_5: {
        is_section_enable: true,
        banner: "assets/img/phone.png",        
        title: "Just A Click Away",
        paragraph:
          "Our apps got a better and convenient way of getting our 5-star services. Download now and register to get more exclusive offers",
        check_list: [
          {
            _id: 1,
            icons: "assets/img/icons/priorityicon1.svg",
            title: "Users",
            count: "25000",
          },
          {
            _id: 2,
            icons: "assets/img/icons/priorityicon2.svg",
            title: "Downloads",
            count: "40000",
          },
          {
            _id: 3,
            icons: "assets/img/icons/priorityicon3.svg",
            title: "Ratings",
            count: "4.5",
          },
        ],
        mobile_link_section: {
          is_enable: true,
          android_link:
            "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link:
            "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
        },
      },
      section_6: {
        heading:
          "Your Path to Radiant Beauty Begins Here, at Our Salon Sanctuary",
        card_1: {
          card_one_title: "Hair Services",
          card_one_paragraph:
            "Step into a world where your hair's potential is unlocked through expert styling and transformative treatments. From timeless cuts that accentuate your features to cutting-edge styles that make a statement, our skilled stylists craft hair artistry tailored to your personality. Experience the beauty of each strand, where every visit is a brushstroke of elegance and innovation.",
          card_one_image: "assets/img/home/wing1.png",
        },
        card_2: {
          card_two_title: "Skincare Services",
          card_two_paragraph:
            "Revel in a sanctuary of self-care as our dedicated skincare professionals nurture your skin to its ultimate glow. Our skincare services embrace your unique complexion, providing tailored treatments that rejuvenate, hydrate, and bring out your inner radiance. Immerse yourself in a journey of wellness and transformation, where every treatment is a harmonious blend of science, art, and personal attention",
          card_two_image: "assets/img/home/wing2.png",
        },
      },
      section_8: {
        title: "What Our Client Say",
        cards: [
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg1.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser2.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg2.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser3.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg3.jpg",
          },
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg1.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser2.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg2.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser3.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg3.jpg",
          },
        ],
      },
      section_10: {
        title: "Latest News & Events",
        button: {
          text: "View More",
          bg_color: "#ffcb00",
          color: "ffffff",
          is_enable: true,
        },
      },
      section_7: {
        is_section_enable: true,
        title: "Salon on Wheels: Your Beauty Destination, Anywhere You Are",
      },
      section_9: {
        is_section_enable: true,
        title: "Frequently Asked Questions",
        paragraph:
          "Delight in luxury with our salon's top-tier treatments, meticulously curated to enhance your unique beauty.",
        banner: [
          {
            banner: "assets/img/theme1/faqimg.jpg",
          },
        ],
        card_heading: "CALL US FOR A FREE QUOTE ON:",
        card_number: "+(044) 071012345",
        card_buttontext: "Enquire Now",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title:
                "What are the types of hair services offered at Best@Salon?",
              isEnabled: true,
              collection_paragraph:
                "Best@Salon offers a range of hair services including cuts, styling, coloring, extensions, and more.",
            },
            {
              _id: 2,
              collection_title:
                "Is Best@Salon suitable for individuals with sensitive skin or specific allergies?",
              isEnabled: true,
              collection_paragraph:
                "Absolutely, our salon accommodates sensitive skin and specific allergies, ensuring a safe and tailored experience.",
            },
            {
              _id: 3,
              collection_title: "How can I book an appointment at Best@Salon?",
              isEnabled: true,
              collection_paragraph:
                "Booking an appointment is quick and easy - either call us directly or book online through our website for your convenience.",
            },
          ],
        },
      },
      footer: {
        bg_color: "ffffff",
        section_heading_color: "333333",
        section_paragraph_color: "c2c2c2",
        mbl_number : "+442086388690",
        email_val: "sales@bestatservices.com",
        address_val: "81 Randall Drive, Hornchurch - RM126TA",
        footer_section: {
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
      },
    },
  },
  theme_2: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/section-two/section1.png",
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/section-two/section2.png",
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/section-two/section3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/section-two/section4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "/assets/section-two/section5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/section-two/section6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/section-two/section7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "/assets/section-two/section8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "/assets/section-two/section9.png",
        },
      ],
      header: {
        primary_color: "ff0059",
        secondary_color: "ffcb00",
        nav: {
          nav_bg_color: "ffffff",
          header_bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
        },
        btn_group: {
          btn1: {
            text: "Continue to order",
            btn_bg_color: "ff0059",
            btn_color: "ffffff",
          },
          btn2: {
            btn2_text: "Order Now",
            btn2_bg_color: "ffcb00",
            btn2_color: "2e3639",
          },
        },
      },
      section_1: {
        title_1: "Solution for your",
        title_2: "Salon Service",
        title_3:
          "Unveil Your Best Self: Your Choices, Perfected by Our Expertise.",
        card_section: [
          "/assets/img/theme_2/banner-slider-01.jpg",
          "/assets/img/theme_2/banner-slider-02.jpg",
          "/assets/img/theme_2/banner-slider-03.jpg",
          "/assets/img/theme_2/banner-slider-04.jpg",
          "/assets/img/theme_2/banner-slider-05.jpg",
        ],
      },
      section_2: {
        is_section_enable: true,
        banner: "/assets/img/trustimg2.png",
        sub_heading_top: "Our Introduction",
        title: "Welcome",
        mainparagraph:
          "Step into a world of luxury and allure. Our salon is your haven for unparalleled beauty and rejuvenating pampering that redefines elegance.",
        card_section_one: [
          {
            _id: 1,
            text: "Dry Cleaning",
            count: "12K",
          },
          {
            _id: 2,
            text: "Satisfied Clients",
            count: "20K",
          },
          {
            _id: 3,
            text: "Happy Customers",
            count: "8K",
          },
        ],
        card_section_title: "Top rated laundry",
        rating: "5",
        card_sub_text: "(30k Reviews)",
        card_section_two: [
          {
            _id: 1,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "Claim Support",
            card_title: "Instant Updates",
            card_paragraph:
              "we keep you updated, always a step ahead for your convenience.",
          },
          {
            _id: 2,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "100% Reliable",
            card_title: "Customers Safety",
            card_paragraph:
              "From sanitized spaces to mindful practices, we're dedicated to your peace of mind and well-being.",
          },
          {
            _id: 3,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "Consultation",
            card_title: "Hygiene Standards",
            card_paragraph:
              "We provide Up-to-date Information, Instant Awareness and Convenience.",
          },
        ],
      },
      section_3: {
        cards: [
          {
            bg_img: "/assets/img/home/wing1.png",
            card_title: "Understanding Your Unique Style",
            card_paragraph:
              "We kick off by engaging in a one-on-one chat, where we eagerly listen to your preferences, expectations, and personal style. Your input guides our approach, ensuring a tailor-made experience.",
              services: ["Laundry", "Dry Cleaning", "Ironing"],
            card_main_title: "Your Personal Consultation",
            card_main_paragraph:
              "Your journey begins with a personal consultation led by one of our skilled stylists. We work closely with you to recommend services that align with your vision and individuality.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/home/wing2.png",
            card_title: "Customized Just for You",
            card_paragraph:
              "Every service is exclusively tailored to suit your hair type, facial features, and desired outcome. Your distinct characteristics shape our approach, resulting in a truly personalized salon adventure.",
              services: [
                "Pay Online in Seconds",
                "Eco-Friendly",
                "Save Time & Money",  
            ],
            card_main_title: "Booking Made Simple",
            card_main_paragraph:
              "Scheduling an appointment is a breeze! Whether you prefer online booking or a friendly phone call, we strive to make this process convenient. We even offer same-day appointments for that spontaneous pampering.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/home/wing3.png",
            card_title: "Welcoming You In",
            card_paragraph:
              "As you step into our salon, expect a warm and inviting atmosphere. Our team is ready to guide you through your visit, making you feel right at home from the very start.",
              services: ["Competitive Price", "5 Star Rated"],
            card_main_title: "Crafting Beauty, Just for You",
            card_main_paragraph:
              "Our skilled professionals meticulously carry out your chosen services, focusing on your unique needs and desires. Your satisfaction is our primary goal, and our expertise is at your service.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/home/wing4.png",
            card_title: "Unwinding in Your Personal Space",
            card_paragraph:
              "Enjoy the soothing ambiance and any extra amenities we provide as you indulge in a rejuvenating experience crafted exclusively for you.",
              services: ["24h turnaround time", "Easy to reschedule"],
            card_main_title: "Your Feedback Matters",
            card_main_paragraph:
              "We value your thoughts and opinions. After your session, we encourage your feedback, allowing us to adjust and improve to ensure your complete delight with our services.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Best Quality",  
            ],
          },
        ],
      },
      section_4: {},
      section_5: {
        title: "Compare our Services",
        cards: [
          {
            card_title: "",
            icon: "/assets/img/icons/serviceicon2.svg",
            card_options: [
              "What is included",
              "Typically included items",
              "Service time",
              "Delivery",
              "Items returned",
              "Eco friendly",
            ],
          },
          {
            card_title: "Laundry Services",
            icon: "/assets/img/icons/serviceicon2.svg",
            card_options: [
              "Wash + Tumble-Dry",
              "T-shirts, trousers, bedsheets.",
              "Service time 24h*",
              "Delivery FREE",
              "Items returned in a bag",
              "Eco friendly",
            ],
          },
          {
            card_title: "Dry Cleaning Services",
            icon: "/assets/img/icons/serviceicon3.svg",
            card_options: [
              "Dry Cleaning + Ironing",
              "Suits, shirts, dresses and coats.",
              "Service time 24h*",
              "Delivery FREE",
              "Items returned in a bag",
              "Eco friendly",
            ],
          },
          {
            card_title: "Ironing Services",
            icon: "/assets/img/icons/serviceicon4.svg",
            card_options: [
              "Ironing",
              "Shirts, t-shirts and bedsheets.",
              "Service time 24h*",
              "Delivery FREE",
              "Items returned in a bag",
              "Eco friendly",
            ],
          },
        ],
      },
      section_6: {
        collection: [
          {
            collection_title: "Do you have a re-cleaning Policy?",
            collection_paragraph:
              "Although we have quality control where we check each garment before we pack, if we are unable to remove stains we usually inform customers before. If something slips through and you are not completely satisfied with the quality of our dry cleaning, we will re-clean your item free of charge, as long as it’s within 24 hours.",
          },
          {
            collection_title: "Do you have a loss policy?",
            collection_paragraph:
              "We take full responsibility for the clothes from the moment we issue a ticket or pick up from you. Even though we exercise the utmost care, there will be instances where items may get misplaced or lost. Should you have reason to believe that we have lost or misplaced your item. Missing items must be reported to us within 24 hours with descriptions so that we can trace your garment. In the unlikely event of losing any of your items, bestatlaundry liability with respect to any lost item shall not exceed ten (10) times our charge for cleaning that garment regardless of brand or condition.",
          },
          {
            collection_title: "Do you have a safe keeping policy?",
            collection_paragraph:
              "We take responsibility to keep your clothes safe for a maximum of 60 days. Should you be unable to pick your clothes within 60 days, due to holiday, illness, etc. please let us know ASAP and we shall arrange to keep your garments safely for an agreed time period. Otherwise, due to storage, we will have to give your clothes to charity.",
          },
          {
            collection_title: "Do you have a privacy statement?",
            collection_paragraph:
              "We respect our customer’s privacy and do not sell any identifiable information regarding our customers to any third party. Any information you give us is held with the utmost care and security, and will not be used in ways to which you have not consented.",
          },
          {
            collection_title: "Do you have a garment damage policy?",
            collection_paragraph:
              "Whilst every care is taken handling your clothes, if you believe that we have damaged your garments in any way please contact as within 24 hours. We will do our best to resolve the situation. We will investigate thoroughly and if we feel that we were negligent we shell pay fair compensation based upon the current value of the garment. This is usually around 6-10 times the cost of the dry cleaning. Our damage policy is in line with the Office of Fair Trades recommendations.",
          },
          {
            collection_title: "How should my garments be cleaned?",
            collection_paragraph:
              "As we are experts at dealing with all garments that come our way. When they arrive at our premises, your garments are examined carefully and then cleaned under the manufacturer’s instructions. Our cleaning is as gentle and delivers better results than traditional dry cleaning. If there’s any doubt or we can’t find a care label, we will clean at what we call “owner’s risk” (as we can sadly not accept any liability for garments where there are no instructions). If we feel there is really too much of a risk to your garment and we cannot get in touch with you, we will return the item to you uncleaned.",
          },
          {
            collection_title: "How long does it take to clean my items?",
            collection_paragraph:
              "We aim to collect your clothes, give them our expert care and attention and deliver them back to you within working 48 hours. There might be some exceptions: In some instances, your preferred delivery slot may not be available. This is mainly because we have already reached full capacity for that time slot, or because we do not visit your area as often as we would like at this moment. We apologise for any inconvenience caused and assure you that we are working very hard to increase our time slot capacity. Some items such as bed linen, curtains, leather, or tailoring may take longer to process as they will be entrusted to various experts. The delivery timing will be provided to you as soon as the respective items have been inspected at our premises. On inspection, we might consider that it will take longer to remove particularly difficult stains or other issues with fabrics. We offer an express service with same-day turnaround* in all our stores however due to logistical constraints thi",
          },
          {
            collection_title:
              "Why can I not select my preferred delivery slot?",
            collection_paragraph:
              "In some instances, your preferred delivery slot may not be available. This could be because we have already reached full capacity for that time slot. We apologize for any inconvenience caused and assure you that we are working very hard to increase our time slot capacity. Should you wish to drop us a line and check to see if we accommodate your chosen slot or to see if there is any cancellation please email info@bestatlaundry.uk",
          },
          {
            collection_title: "How do I order online?",
            collection_paragraph:
              "Simply click Book Now in the top right-hand corner. On the next screen, you will be able to choose a pickup time that we will collect the clothing items from your home/concierge and a dropoff time where we will drop the dry cleaned clothes back to your home/concierge. The next screen is where you can choose your items either select the items directly or choose a category and the system will refine the items to help you choose. Simply choose the quantity and select add to put the item in your basket. When ready click with view basket or the basket in the top right-hand corner of the page you can then check out adding your address and any special requirements. Lastly, you can pay using any credit or debit card using our encrypted service with stripe. We will then process your order and arrive at your requested time slot ready to pick up your items.",
          },
          {
            collection_title: "can I book a regular pickup & delivery?",
            collection_paragraph:
              "Yes! If you want to book a regular pickup and delivery slot each week, just email us to info@bestatlaundry.uk This saves having to deal with laundry and to re-book on a regular basis, and you can cancel at any time if you don’t need a delivery that week. Although it is currently not possible to book a recurring time slot online, you can call our customer care team and we will gladly set this up.",
          },
          {
            collection_title: "How do I know when my driver will arrive?",
            collection_paragraph:
              "We currently offer 1-hour time slots, which allows for traffic and our logistical requirements, if our driver is later than the chosen time slot we will contact you.",
          },
          {
            collection_title: "Why do you not cover my area?",
            collection_paragraph:
              "We are continually growing and expanding and while we might not currently cover your area. Send a message with your details to info@bestatlaundry.uk and as soon as we start to serve your area, we will contact you. You can also mail us to info@bestatlaundry.uk as they will often be able to arrange a special courier or Uber service for you (delivery fees will apply).",
          },
          {
            collection_title: "How will I be charged for the order",
            collection_paragraph:
              "We use a highly secure platform to register your credit or debit card and collect payment. After picking up your clothes, our experts will examine them and determine the total amount of your invoice based on the extent of the work that needs to be carried out on each item (much as a till assistant would do in-store). Until then, you can get an estimate of your order according to our price list here. We are unable to be more exact at this stage because some items require more time and specific treatment, and we can’t know the detail of this before we examine your garments. Once this is done, we will send you an email detailing your invoice, item by item, and charge your card on that basis. If you disagree with anything that we have detailed on your invoice, please feel free email us to info@bestatlaundry.uk and we will be happy to look into this for you. Kindly note that we cannot accept cash payment for deliveries.",
          },
          {
            collection_title: "What does owner's risk mean?",
            collection_paragraph:
              "Some fragile or highly sensitive garments inherently have a risk in cleaning, this could be due to fabric, age, colour, pre-existing damage etc. thus meaning there is a risk that they could be damaged in the cleaning process, in this instance we will refrain from cleaning the garment in question. We will make an attempt to contact you and advise you of the potential risks associated with proceeding with the treatment. Note that if you authorise us to proceed, it will imply that you assume full responsibility for any damage to the garments. In certain circumstances, we may still return the garment and advise not to clean it as damage is inevitable.",
          },
          {
            collection_title:
              "can we dry clean wedding dresses and other bespoke items?",
            collection_paragraph:
              "We are the experts at cleaning these pieces. Please note that the sooner you bring your dress in after your wedding, the better the results we can achieve, as leaving little time for stains to set helps us to get that better finish. We can also clean other bespoke items – just ask one of your store assistants for a quote, email us info@bestatlaundry.uk if you would like more information or to ask for a quote. Kindly note that we assess all those very specific items before cleaning to ensure that we have spotted and highlighted all the potential risks to you before proceeding with the clean.",
          },
        ],
      },
      section_7: {
        title_1: "Latest Testimonial",
        title_2: "What Our Client Say",
        cards: [
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser2.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser3.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser2.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser3.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
        ],
      },
      section_8: {
        title_1: "ARTICLES",
        title_2: "Latest News",
      },
      section_9: {
        title: "We're available on mobile Just download it!",
        paragraph:
          "Download the our app and request our laundry service. Your clothes washed, folded and delivered to your doorstep. So go ahead and say yes to more time with the family, more happy hours, and more slapping the snooze button every morning — we've got laundry day covered.",
        android_link:
          "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
        ios_link:
          "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
      },
      footer: {
        custome_banner_design: {
          is_enable: true,
        },
        bg_color: "151e2e",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          about_section: {
            is_enable: true,
            text: "Ready to take laundry off your to-do list? Bestatlaundry services is a local laundry pickup and delivery service that you can order directly from your smart phone.",
          },
          service_section: {
            is_enable: true,
          },
          quicklink_section: {
            is_enable: true,
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "+442086388690",
              value: "tel:+442086388690",
            },
            email: {
              label: "Email Us",
              value: "sales@bestatlaundry.com",
            },
            address: {
              text: "81 Randall Drive, Hornchurch - RM126TA",
            },
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
      },
    },
  },
  theme_3: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "assets/section/section1.jpg",
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "assets/section/section2.jpg",
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "assets/section/section3.jpg",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "assets/section/section4.jpg",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "assets/section/section5.jpg",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "assets/section/section6.jpg",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "assets/section/section7.jpg",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "assets/section/section8.jpg",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "assets/section/section9.jpg",
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "assets/section/section10.jpg",
        },
        {
          _id: 11,
          is_section_enable: true,
          icon: "assets/section/section11.jpg",
        },
        {
          _id: 12,
          is_section_enable: true,
          icon: "assets/section/section12.jpg",
        },
        {
          _id: 13,
          is_section_enable: true,
          icon: "assets/section/section13.jpg",
        },
        {
          _id: 14,
          is_section_enable: true,
          icon: "assets/section/section14.jpg",
        },
        {
          _id: 15,
          is_section_enable: true,
          icon: "assets/section/section15.jpg",
        },
      ],
      header: {
        is_section_enable: true,
        primary_color: "1B9DB3",
        secondary_color: "F07F1B",
        nav: {
          bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "#6b7385",
            },
            cart_value_color: "000000",
          },
        },
        btn_group: {
          btn1: {
            text: "Login / Register",
            bg_color: "ffffff",
            color: "#1b9db3",
            border_color: "#1b9db3",
            btn1_is_enable: true,
          },
          btn2: {
            text: "Quick Order",
            color: "ffffff",
            bg_color: "000",
            border_color: "#192335",
            btn2_is_enable: true,
          },
        },
      },
      section_1: {
        is_section_enable: true,
        banner: "/assets/img/services/salon/home_page_single_search.jpg",
        sub_heading_top: "24/7 Salon Service",
        title: "One Solution for all your salon Needs",
        paragraph:
          "Bestatsalon is The Ultimate Salon Experience Awaits—Indulge in Unmatched Luxury Today!",
        btn_group: {
          btn1: {
            text: "Request a Schedule",
            bg_color: "1b9db3",
            color: "ffffff",
          },
          btn2: {
            text: "Book now",
            bg_color: "F07F1B",
            color: "ffffff",
          },
        },
        mobile_link_section: {
          is_enable: true,
          mob_title: "Just A Click Away",
          android_link: "#",
          ios_link: "#",
        },
      },
      section_2: {
        is_section_enable: true,
        sub_heading_top: "Book A Service",
        title: "Book Your Preferred Appointment Slot & Venue",
        btn_group: {
          btn1: {
            text: "order now",
            bg_color: "#1b9db3",
            color: "ffffff",
          },
        },
      },
      section_3: {
        is_section_enable: true,
        sub_heading_top: "Salon",
        title: "Experience Beauty and Pampering Like Never Before",
        paragraph:
          "Step into a world of luxury and allure. Our salon is your haven for unparalleled beauty and rejuvenating pampering that redefines elegance.",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/img/services/priorityicon1.png",
              collection_title: "Excellence at Your Fingertips",
              collection_paragraph:
                "Delight in luxury with our salon's top-tier treatments, meticulously curated to enhance your unique beauty",
            },
            {
              _id: 2,
              icon: "/assets/img/services/priorityicon2.png",
              collection_title: "Exceptional Service",
              collection_paragraph:
                "Experience a level of service that goes beyond expectations, leaving you refreshed and rejuvenated after every visit.",
            },
            {
              _id: 3,
              icon: "/assets/img/services/priorityicon3.png",
              collection_title: "Budget-Friendly Package",
              collection_paragraph:
                "Our budget-friendly packages ensure you get the care you deserve at a price you'll love.",
            },
            {
              _id: 4,
              icon: "/assets/img/services/priorityicon4.png",
              collection_title: "Professionalism",
              collection_paragraph:
                "Your salon visit reflects our commitment to professionalism—delivering top-notch service with a touch of class and expertise.",
            },
            {
              _id: 5,
              icon: "/assets/img/services/priorityicon6.png",
              collection_title: "Hygiene at the Core",
              collection_paragraph:
                "Our salon maintains the highest standards of hygiene to ensure a clean and secure environment.",
            },
            {
              _id: 6,
              icon: "/assets/img/services/priorityicon5.png",
              collection_title: "Hair Color Services",
              collection_paragraph:
                "Our hair color services offer versatile transformations. From complete color changes to highlights/lowlights for depth, techniques like balayage/ombre ensure natural transitions. Root touch-ups seamlessly blend new growth",
            },
          ],
          btn_group: {
            btn1: {
              text: "More About Best@salon",
              bg_color: "192335",
              color: "ffffff",
            },
          },
        },
      },
      section_4: {
        is_section_enable: true,
        title:
          "Unleash Your Beauty Potential: Your Journey to Salon Brilliance Begins Here!",
      },
      section_5: {
        is_section_enable: true,
        banner: [
          {
            banner: "/assets/img/services/salon/client-doing-hair-cut-barber-shop-salon.jpg"
          }
        ],
        title: "Reveal Your Inner Beauty: Transform, Shine, and Inspire at Our Salon",
        paragraph: "Let your inner beauty take center stage as we transform, illuminate, and inspire at our welcoming salon",
        check_list: [
          {
            _id: 1,
            list: "Smart Offers",
          },
          {
            _id: 2,
            list: "Consultations",
          },
          {
            _id: 3,
            list: "Scheduling App",
          },
          {
            _id: 4,
            list: "Appointment Reminders & more",
          },
          {
            _id: 5,
            list: "Standard price for just £14",
          },
        ],
        btn_group: {
          btn1: {
            text: "Schedule a Appointment Now",
            bg_color: "#192335",
            color: "ffffff",
            is_enable: true
          },
          btn2: {
            text: "Request a schedule",
            bg_color: "#1b9db3",
            color: "ffffff",
            is_enable: true
          }
        }
      },
      section_6: {
        is_section_enable: true,
        sub_heading_top: "Know More On",
        title: "How We Works",
        paragraph: "Bringing Beauty to You: Salon Services at Your Doorstep",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/img/services/salon/female-hairdresser-using-hairbrush-hair-dryer.jpg",
              card_title: "Understanding Your Unique Style",
              card_paragraph:
                "We kick off by engaging in a one-on-one chat, where we eagerly listen to your preferences, expectations, and personal style. Your input guides our approach, ensuring a tailor-made experience.",
            },
            {
              _id: 2,
              icon: "/assets/img/services/salon/pexels-cottonbro-studio-3992875.jpg",
              card_title: "Your Personal Consultation",
              card_paragraph:
                "Your journey begins with a personal consultation led by one of our skilled stylists. We work closely with you to recommend services that align with your vision and individuality.",
            },
            {
              _id: 3,
              icon: "/assets/img/services/salon/professional-makeup-artist-working-with-beautiful-young-woman-man-female-proffesion-gender-equality-concept.jpg",
              card_title: "Customized Just for You",
              card_paragraph:
                "Every service is exclusively tailored to suit your hair type, facial features, and desired outcome. Your distinct characteristics shape our approach, resulting in a truly personalized salon adventure.",
            },
            {
              _id: 4,
              icon: "/assets/img/services/salon/young-man-barbershop-trimming-hair.jpg",
              card_title: "Booking Made Simple",
              card_paragraph:
                "Scheduling an appointment is a breeze! Whether you prefer online booking or a friendly phone call, we strive to make this process convenient. We even offer same-day appointments for that spontaneous pampering.",
            },
          ],
          btn_group: {
            paragraph: "Ready for a New Look? Book Your Appointment Today!",
            btn1: {
              text: "Book Online Now",
              bg_color: "#192335",
              color: "#ffffff",
            },
          },
        },
      },
      section_7: {
        is_section_enable: true,
        title: "On Demand Salon Categories",
        paragraph:
          "Our salon services include hairstyling, manicures, pedicures, facials, and massages in various locations across the UK and its surrounding areas.",
        btn_group: {
          btn_paragraph: "Lorem Ipsum is simply dummy text of the",
          btn1: {
            text: " Request a Pickup",
            bg_color: "192335",
            color: "fff",
          },
        },
      },
      section_8: {
        is_section_enable: true,
        banner: "howwe1",
        title: "About Best@salon",
        section_image:
          "/assets/img/services/salon/pexels-thgusstavo-santana-1813272.jpg",
        paragraph:
          "Welcome to Best@Salon, where beauty meets expertise in the heart of your community. Our journey began with a vision to redefine the salon experience, blending top-tier services with a warm, inviting ambiance. At Best@Salon, we strive for unmatched beauty and wellness tailored just for you.",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "Expert Wellness Treatments",
              collection_image: "/assets/img/services/priorityicon7.png",
              isEnabled: true,
              collection_paragraph:
                "Expertly crafted wellness treatments for your ultimate relaxation.",
            },
            {
              _id: 2,
              collection_title: "Exceptional Client Care",
              collection_image: "/assets/img/services/priorityicon8.png",
              isEnabled: true,
              collection_paragraph:
                "Exceptional client care, ensuring a personalized and enjoyable experience.",
            },
            {
              _id: 3,
              collection_title: "Relaxing Ambiance",
              collection_image: "/assets/img/services/priorityicon9.png",
              isEnabled: true,
              collection_paragraph:
                "Unwind in our relaxing ambiance, where tranquility meets rejuvenation.",
            },
          ],
        },
        btn_group: {
          btn1_text: "Schedule a Appointment Now",
          btn1_bg_color: "192335",
          btn1_color: "ffffff",
          btn2_text: "Request a Pickup",
          btn2_bg_color: "1b9db3",
          btn2_color: "ffffff",
        },
      },
      section_9: {
        is_section_enable: true,
        section_bg_color: "#1b9db3",
        sub_heading_top: "Our Promising Services",
        title: "Salon on Wheels: Your Beauty Destination, Anywhere You Are",
        paragraph:
          "Your Path to Radiant Beauty Begins Here, at Our Salon Sanctuary",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/img/services/salon/man-barbershop-salon-doing-haircut-beard-trim.jpg",
              collection_title: "Hair Services",
              collection_paragraph:
                "Step into a world where your hair's potential is unlocked through expert styling and transformative treatments. From timeless cuts that accentuate your features to cutting-edge styles that make a statement, our skilled stylists craft hair artistry tailored to your personality. Experience the beauty of each strand, where every visit is a brushstroke of elegance and innovation.",
            },
            {
              _id: 2,
              icon: "/assets/img/services/salon/pexels-anete-lusina-5240817.jpg",
              collection_title: "Skincare Services",
              collection_paragraph:
                "Revel in a sanctuary of self-care as our dedicated skincare professionals nurture your skin to its ultimate glow. Our skincare services embrace your unique complexion, providing tailored treatments that rejuvenate, hydrate, and bring out your inner radiance. Immerse yourself in a journey of wellness and transformation, where every treatment is a harmonious blend of science, art, and personal attention",
            },
            {
              _id: 3,
              icon: "/assets/img/services/salon/pexels-cottonbro-studio-3992870.jpg",
              collection_title: "Precision Grooming",
              collection_paragraph:
                "Experience meticulous beard care with precise trims and sculpting techniques, enhancing facial appearance for a polished look that complements your features.",
            },
            {
              _id: 14,
              icon: "/assets/img/services/salon/professional-makeup-artist-working-with-beautiful-young-woman-man-female-proffesion-gender-equality-concept.jpg",
              collection_title: "Makeup Services",
              collection_paragraph:
                "Express yourself in every hue and shade as our makeup artists unveil your true beauty with expertly tailored applications. Whether it's a natural, everyday look or a glamorous transformation for a special event, our makeup services accentuate your features, enhancing your confidence and allure. Let us create a makeup narrative that mirrors your personality, leaving you mesmerized by the reflection in the mirror",
            },
            {
              _id: 14,
              icon: "/assets/img/services/salon/pexels-thgusstavo-santana-1813346.jpg",
              collection_title: "Waxing and Hair Removal",
              collection_paragraph:
                "Embark on a path to smooth, velvety skin as our skilled professionals deliver gentle and precise hair removal solutions. Our waxing services promise not only a hair-free experience but also a touch of luxury and care. With meticulous attention to detail, we ensure a comfortable session that leaves you feeling refreshed, confident, and ready to conquer the world, hair-free and carefree",
            },
          ],
          count_section: [
            {
              _id: 1,
              icon: "/assets/img/services/priorityicon1.png",
              text: "Expert Wellness Treatments",
              count: "1639+",
            },
            {
              _id: 2,
              icon: "/assets/img/services/priorityicon2.png",
              text: "Exceptional Client Care",
              count: "24359+",
            },
            {
              _id: 3,
              icon: "/assets/img/services/priorityicon3.png",
              text: "Relaxing Ambiance",
              count: "4793+",
            },
            {
              _id: 4,
              icon: "/assets/img/services/priorityicon4.png",
              text: "Expert Skincare Services",
              count: "25+",
            },
          ],
        },
      },
      section_10: {
        is_section_enable: true,
        banner: "/assets/img/services/salon/pexels-thgusstavo-santana-1813272.jpg",
        sub_heading_top: "Quality Meets Affordability",
        title: "Our Values",
        mainparagraph:
          "Elevate Your Beauty without Breaking the Bank. Your Beauty, Our Best Value.",
        list_Section: [
          {
            _id: 1,
            text: "Hygiene Standards",
            paragraph:
              "We provide Up-to-date Information, Instant Awareness and Convenience.",
          },
          {
            _id: 2,
            text: "Customers Safety",
            paragraph:
              "From sanitized spaces to mindful practices, we're dedicated to your peace of mind and well-being.",
          },
          {
            _id: 3,
            text: "Instant Updates",
            paragraph:
              "We keep you updated, always a step ahead for your convenience",
          },
        ],
        card_section_title: "We are an Eco Friendly Salon",
        card_section: [
          {
            _id: 1,
            card_bg_color: "192335",
            card_text: "Eco-conscious",
            card_paragraph:
              "We utilize Eco-friendly Salon Products: Our commitment to a sustainable salon experience includes the use of environmentally conscious products.",
          },
          {
            _id: 2,
            card_bg_color: "f07f1b",
            card_text: "Animal-friendly",
            card_paragraph:
              "Our dedication to vegan practices extends to our salon services. Our products are entirely free from animal products.",
          },
          {
            _id: 3,
            card_bg_color: "1bb333",
            card_text: "Cruelty-Free",
            card_paragraph:
              "Rest assured, our salon services never involve testing on animals, upholding our cruelty-free ethos.",
          },
        ],
      },
      section_11: {
        is_section_enable: true,
        banner: [
          {
            banner:
              "/assets/img/services/salon/female-hairdresser-using-hairbrush-hair-dryer.jpg",
          },
        ],
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title:
                "What are the types of hair services offered at Best@Salon?",
              isEnabled: true,
              collection_paragraph:
                "Best@Salon offers a range of hair services including cuts, styling, coloring, extensions, and more.",
            },
            {
              _id: 2,
              collection_title:
                "Is Best@Salon suitable for individuals with sensitive skin or specific allergies?",
              isEnabled: true,
              collection_paragraph:
                "Absolutely, our salon accommodates sensitive skin and specific allergies, ensuring a safe and tailored experience.",
            },
            {
              _id: 3,
              collection_title: "How can I book an appointment at Best@Salon?",
              isEnabled: true,
              collection_paragraph:
                "Booking an appointment is quick and easy - either call us directly or book online through our website for your convenience.",
            },
          ],
        },
        sub_heading_top: "Ask Your Question",
        title: "Frequently Asked Questions",
        paragraph:
          "Elevate Your Beauty without Breaking the Bank. Your Beauty, Our Best Value.",
      },
      section_12: {
        is_section_enable: true,
        banner: "howwe1",
        sub_heading_top: "What Our Client Says",
        title: "Commitment to Client Satisfaction",
        paragraph: "Read what our customers say about Best@salon",
        review_bottom_paragraph:
          "More than 5,000+ reviews s on TrustPilot, Google, Google Play & App Store",
        review_card: [
          {
            _id: 1,
            reviewer_pic: "/assets/img/services/testimonialuser1.jpg",
            reviewer_name: "Ariyan Rusov",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 2,
            reviewer_pic: "/assets/img/services/testimonialuser2.jpg",
            reviewer_name: "Darren Valdez",
            rating: "4",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 3,
            reviewer_pic: "/assets/img/services/testimonialuser3.jpg",
            reviewer_name: "Elinor Dunn",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
        ],
        btn_group: {
          btn1: {
            text: "Book Online Now",
            bg_color: "#192335",
            color: "#ffffff",
            is_enable: true,
          },
        },
      },
      section_13: {
        is_section_enable: true,
        banner: "howwe1",
        title: "Just A Click Away",
        bannerimg: "/assets/img/services/salon/mob.png",
        paragraph: "Download Our Best Active Mobile App",
        check_list: [
          {
            _id: 1,
            icons: "/assets/img/services/priorityicon14.png",
            title: "Cashless payments",
          },
          {
            _id: 2,
            icons: "/assets/img/services/priorityicon15.png",
            title: "Instant Updates",
          },
          {
            _id: 3,
            icons: "/assets/img/services/priorityicon16.png",
            title: "Hygiene Standards",
          },
          {
            _id: 4,
            icons: "/assets/img/services/priorityicon17.png",
            title: "Eco-conscious",
          },
        ],
        mobile_link_section: {
          is_enable: true,
          android_link: "https://play.google.com/store/apps/",
          ios_link: "https://itunes.apple.com/us/app/",
        },
      },
      section_14: {
        is_section_enable: true,
        section_bg_color: "#1b9db3",
        title:
          "Subscribe to our newsletter for updates, exclusive promotions and our latest content!",
        btn_group: {
          btn1: {
            text: "Subscribe",
            bg_color: "#192335",
            color: "ffffff",
          },
        },
      },
      section_15: {
        is_section_enable: true,
        sub_heading_top: "Our Latest",
        title: "News & Events",
        banner1: "/assets/img/services/salon/sec1.jpg",
        banner2: "/assets/img/services/salon/sec2.jpg",
        banner3: "/assets/img/services/salon/sec3.jpg",
        paragraph:
          "Stay in the loop with our salon's pulse! From new service launches and seasonal promotions to special events, we keep you informed about everything.",
        btn_group: {
          btn1: {
            text: "More News & Events",
            bg_color: "#192335",
            color: "ffffff",
          },
        },
      },
      footer: {
        custome_banner_design: {
          is_enable: true,
        },
        bg_color: "151e2e",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          about_section: {
            is_enable: true,
            text: "Ready to take salon off your to-do list? BestatSalon services is a local saloon service that you can book directly from your smart phone.",
          },
          service_section: {
            is_enable: true,
          },
          quicklink_section: {
            is_enable: true,
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "+442086388690",
              value: "tel:+442086388690",
            },
            email: {
              label: "Email Us",
              value: "sales@bestatlaundry.com",
            },
            address: {
              text: "81 Randall Drive, Hornchurch - RM126TA",
            },
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
      },
    },
  },
  theme_4: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_1.png",
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_2.png",
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_9.png",
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_10.png",
        },
        {
          _id: 11,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_11.png",
        },
        {
          _id: 12,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_12.png",
        },
      ],
      header: {
        is_section_enable: true,
        primary_color: "046A38",
        secondary_color: "01190D",
        contact: {
          email_id: "sales@bestatlaundry.com",
          contact_number: "+442086388690",
        },
        nav: {
          bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "#6b7385",
            },
            cart_value_color: "000000",
          },
        },
        btn_group: {
          btn1_text: "Login / Register",
          btn1_bg_color: "046A38",
          btn1_color: "ffffff",
          btn1_border_color: "046A38",
          btn1_is_enable: true,
          btn2_text: "Quick Order",
          btn2_color: "ffffff",
          btn2_bg_color: "000",
          btn2_border_color: "#192335",
          btn2_is_enable: true,
        },
      },
      section_1: {
        is_section_enable: true,
        banner_type: "image",
        banner: "/assets/home_page_single_search.jpg",
        banner_two: "/assets/img/home/heroimg.png",
        sub_heading_top: "24/7 Salon Service",
        title:
          "Discover Your Radiance: Partner with Us for the Salon Experience You Can Trust",
        paragraph: "Select your time & location for the service",
        btn_group: {
          btn1: {
            text: "Order now",
            bg_color: "#046A38",
            color: "ffffff",
            placeholder: "Enter Postcode",
          },
        },
      },
      section_2: {
        is_section_enable: true,
        title: "Trust the Experts",
        paragraph: "Experience Beauty and Pampering Like Never Before",
        first_card_image: "/assets/img/home/trustimg1.jpg",
        image_title: "Why 1000+ customers trust Us?",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/icons/priorityicon1.png",
              collection_title: "Top Quality",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 2,
              icon: "/assets/icons/priorityicon4.png",
              collection_title: "Professionalism",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 3,
              icon: "/assets/icons/priorityicon5.png",
              collection_title: "Convenience",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 4,
              icon: "/assets/icons/priorityicon6.png",
              collection_title: "Cleanliness",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
          ],
        },
      },
      section_3: {
        is_section_enable: true,
        title: "5 star Rated by customers in the UK in categories of Salon",
        banner: "/assets/img/home/review-bg.jpg",
      },
      section_4: {
        is_section_enable: true,
        banner: "/assets/home/trustimg2.jpg",
        title: "About We",
        paragraph_one:
          "Reveal Your Inner Beauty: Transform, Shine, and Inspire at Our Salon",
        paragraph_two:
          "Let your inner beauty take center stage as we transform, illuminate, and inspire at our welcoming salon ",
        option_one: "Smart Offers",
        option_two: "Consultations",
        option_three: "Scheduling App",
        btn_group: {
          btn1: {
            text: "Know More",
            bg_color: "#046A38",
            color: "ffffff",
            is_enable: true,
          },
        },
      },
      section_5: {
        is_section_enable: true,
        title: "How We Works",
        paragraph: "Bringing Beauty to You: Salon Services at Your Doorstep",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/home/howwe1.jpg",
              card_title: "Understanding Your Unique Style",
              card_paragraph:
                "We kick off by engaging in a one-on-one chat, where we eagerly listen to your preferences, expectations, and personal style. Your input guides our approach, ensuring a tailor-made experience.",
            },
            {
              _id: 2,
              icon: "/assets/home/howwe2.jpg",
              card_title: "Your Personal Consultation",
              card_paragraph:
                "Your journey begins with a personal consultation led by one of our skilled stylists. We work closely with you to recommend services that align with your vision and individuality.",
            },
            {
              _id: 3,
              icon: "/assets/home/howwe3.jpg",
              card_title: "Customized Just for You",
              card_paragraph:
                "Every service is exclusively tailored to suit your hair type, facial features, and desired outcome. Your distinct characteristics shape our approach, resulting in a truly personalized salon adventure.",
            },
            {
              _id: 4,
              icon: "/assets/home/howwe4.jpg",
              card_title: "Booking Made Simple",
              card_paragraph:
                "Scheduling an appointment is a breeze! Whether you prefer online booking or a friendly phone call, we strive to make this process convenient. We even offer same-day appointments for that spontaneous pampering.",
            },
          ],
        },
      },
      section_6: {
        is_section_enable: true,
        title: "service for Business & Personal",
        paragraph:
          "Our salon services include hairstyling, manicures, pedicures, facials, and massages in various locations across the UK and its surrounding areas",
        first_card_image: "/assets/home/trustimg3.png",
        sub_title: "For Business",
        sub_title_two: "For Personal",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_list: "Restaurants",
            },
            {
              _id: 2,
              collection_list: "Spa",
            },
            {
              _id: 3,
              collection_list: "Hotels",
            },
            {
              _id: 4,
              collection_list: "Gym",
            },
            {
              _id: 5,
              collection_list: "Factory",
            },
            {
              _id: 6,
              collection_list: "Malls",
            },
            {
              _id: 7,
              collection_list: "Public sectors",
            },
            {
              _id: 8,
              collection_list: "Hospitals",
            },
          ],
        },
        card_section_two: {
          collection: [
            {
              _id: 1,
              collection_list: "Home Stay",
            },
            {
              _id: 2,
              collection_list: "Local Living",
            },
            {
              _id: 3,
              collection_list: "Hostel",
            },
            {
              _id: 4,
              collection_list: "Cottage",
            },
            {
              _id: 5,
              collection_list: "PG Stay",
            },
          ],
        },
      },
      section_7: {
        is_section_enable: true,
        title: "Our Product Category",
        paragraph:
          "Elevate Your Beauty without Breaking the Bank. Your Beauty, Our Best Value.",
      },
      section_8: {
        is_section_enable: true,
        part_one: {
          title: "Our Values",
          sub_title: "Quality Meets Affordability",
          section_image: "/assets/home/trustimg2.jpg",
          paragraph:
            "Elevate Your Beauty without Breaking the Bank. Your Beauty, Our Best Value.",
          card_section: {
            collection: [
              {
                _id: 1,
                collection_title: "Hygiene Standards",
                collection_paragraph:
                  "We provide Up-to-date Information, Instant Awareness and Convenience.",
              },
              {
                _id: 2,
                collection_title: "Customers Safety",
                collection_paragraph:
                  "From sanitized spaces to mindful practices, we're dedicated to your peace of mind and well-being.",
              },
              {
                _id: 3,
                collection_title: "Instant Updates",
                collection_paragraph:
                  "we keep you updated, always a step ahead for your convenience",
              },
            ],
          },
        },
        part_two: {
          bg_color: "046A38",
          text_color: "ffffff",
          title: "We are an Eco Friendly Salon",
          paragraph: "",
          card_section: {
            collection: [
              {
                _id: 1,
                collection_title: "Eco-conscious",
                collection_paragraph:
                  "We utilize Eco-friendly Salon Products: Our commitment to a sustainable salon experience includes the use of environmentally conscious products.",
              },
              {
                _id: 2,
                collection_title: "Animal-friendly",
                collection_paragraph:
                  "Our dedication to vegan practices extends to our salon services. Our products are entirely free from animal products.",
              },
              {
                _id: 3,
                collection_title: "Cruelty-Free",
                collection_paragraph:
                  "Rest assured, our salon services never involve testing on animals, upholding our cruelty-free ethos.",
              },
            ],
          },
        },
      },
      section_9: {
        is_section_enable: true,
        title: "Frequently Asked Question",
        description: "",
        card_image: "/assets/home/trustimg4.jpg",
      },
      section_10: {
        is_section_enable: true,
        banner: "howwe1",
        title: "Client Testimonials",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        review_card: [
          {
            _id: 1,
            reviewer_pic: "/assets/img/services/testimonialuser1.jpg",
            reviewer_name: "Ariyan Rusov",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 2,
            reviewer_pic: "/assets/img/services/testimonialuser2.jpg",
            reviewer_name: "Darren Valdez",
            rating: "4",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 3,
            reviewer_pic: "/assets/img/services/testimonialuser3.jpg",
            reviewer_name: "Elinor Dunn",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
        ],
      },
      section_11: {
        is_section_enable: true,
        title: "Just a Click Away",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        check_list: [
          {
            _id: 1,
            icons: "/assets/icons/priorityicon14.png",
            title: "Cashless payments",
          },
          {
            _id: 2,
            icons: "/assets/icons/priorityicon15.png",
            title: "Expert Wellness Treatments",
          },
          {
            _id: 3,
            icons: "/assets/icons/priorityicon16.png",
            title: "Relaxing Ambiance",
          },
          {
            _id: 4,
            icons: "/assets/icons/priorityicon17.png",
            title: "In-app live chat support",
          },
        ],
        banner: "/assets/home/just_clickaway.png",
      },
      section_12: {
        is_section_enable: true,
        title: "Blogs & Events",
        description:
          " Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
      },
      footer: {
        bg_color: "046A38",
        section_paragraph_color: "c2c2c2",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        social_media: {
          facebook: {
            is_enable: true,
            facebook_link: "facebook.com",
          },
          instagram: {
            is_enable: true,
            instagram_link: "instagram.com",
          },
          whatsapp: {
            is_enable: true,
            whatsapp_link: "whatsapp.com",
          },
          linkedin: {
            is_enable: true,
            linkedin_link: "linkedin.com",
          },
          youtube: {
            is_enable: true,
            youtube_link: "youtube.com",
          },
          twitter: {
            is_enable: true,
            twitter_link: "twitter.com",
          },
        },
      },
    },
  },
  theme_5: {
    home: {
      header: {
        is_section_enable: "on",
        primary_color: "2fa0ea",
        secondary_color: "efc10b",
        nav: {
          bg_color: "ffffff",
          menu_font_color: "000000",
          menu_font_color_active: "efc10b",
          menu_font_color_hover: "efc10b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "000000",
              bg_color: "ffffff",
              menu_font_color_hover: "efc10b",
              menu_font_color_active: "efc10b",
              btn1_bg_color: "efc10b",
              btn2_bg_color: "efc10b",
              btn1_border_color: "efc10b",
              btn2_border_color: "efc10b",
              btn3_bg_color: "efc10b",
              btn3_border_color: "efc10b",
              menu_font_color: "000000",
              btn1_color: "000000",
              btn2_color: "000000",
              btn3_color: "000000",
              cart_value_color: "ffffff"
            },
            cart_value_color: "ffffff",
            menu_font_color_hover: "efc10b",
            menu_font_color_active: "efc10b",
            btn1_bg_color: "efc10b",
            btn2_bg_color: "efc10b",
            btn1_border_color: "efc10b",
            btn2_border_color: "efc10b",
            btn3_bg_color: "efc10b",
            btn3_border_color: "efc10b",
            menu_font_color: "000000",
            btn1_color: "000000",
            btn2_color: "000000",
            btn3_color: "000000",
            color: "000000"
          },
          btn1_bg_color: "efc10b",
          btn2_bg_color: "efc10b",
          btn1_border_color: "efc10b",
          btn2_border_color: "efc10b",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          btn1_color: "000000",
          btn2_color: "000000",
          btn3_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        btn_group: {
          btn1_text: "Log in",
          btn1_bg_color: "efc10b",
          btn1_color: "000000",
          btn1_border_color: "efc10b",
          btn1_is_enable: true,
          btn2_text: "Sign up",
          btn2_bg_color: "efc10b",
          btn2_color: "000000",
          btn2_border_color: "efc10b",
          btn2_is_enable: true,
          btn3_text: "Quick Order",
          btn3_color: "000000",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          btn3_is_enable: true,
          bg_color: "ffffff",
          menu_font_color_hover: "efc10b",
          menu_font_color_active: "efc10b",
          menu_font_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        bg_color: "ffffff",
        contact: {
          bg_color: "ffffff",
          menu_font_color_hover: "efc10b",
          menu_font_color_active: "efc10b",
          btn1_bg_color: "efc10b",
          btn2_bg_color: "efc10b",
          btn1_border_color: "efc10b",
          btn2_border_color: "efc10b",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          menu_font_color: "000000",
          btn1_color: "000000",
          btn2_color: "000000",
          btn3_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        menu_font_color_hover: "efc10b",
        menu_font_color_active: "efc10b",
        btn1_bg_color: "efc10b",
        btn2_bg_color: "efc10b",
        btn1_border_color: "efc10b",
        btn2_border_color: "efc10b",
        btn3_bg_color: "efc10b",
        btn3_border_color: "efc10b",
        menu_font_color: "000000",
        btn1_color: "000000",
        btn2_color: "000000",
        btn3_color: "000000",
        cart_value_color: "ffffff",
        color: "000000"
      },
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/section-five/section_1.png"
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/section-five/section_2.png"
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/section-five/section_3.png"
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/section-five/section_4.png"
        },
        {
          _id: 5,
          is_section_enable: false,
          icon: "/assets/section-five/section_5.png"
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/section-five/section_6.png"
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/section-five/section_7.png"
        },
        {
          _id: 8,
          is_section_enable: false,
          icon: "/assets/section-five/section_8.png"
        }
      ],
      section_1: {
        is_section_enable: true,
        banner_type: "image",
        banner: "/assets/home_page_single_search.jpg",
        banner_two: "https://api.bestatservices.com/storage/compress_images/65c64bab94fb1.jpeg",
        title: "Welcome to BestatServices",
        paragraph: "Unveil an array of innovative white label software solutions crafted to cater to a diverse range of services, spanning across laundry, cleaning, restaurants, salons, nail care, and barber shops.",
        btn_group: {
          btn1: {
            text: "Search",
            bg_color: "efc10b",
            color: "ffffff",
            placeholder: "Enter Postcode",
            paragraph: "Unveil an array of innovative white label software solutions crafted to cater to a diverse range of services, spanning across laundry, cleaning, restaurants, salons, nail care, and barber shops.",
            title: "Welcome to BestatServices"
          }
        },
        bg_color: "efc10b"
      },
      section_4: {
        is_section_enable: true,
        banner: "/assets/img/offer/offer-vector-bg.png",
        offer_tag: "/assets/img/offer/offer-tag.png",
        picture: "/assets/img/offer/offer-wash-img.png",
        paragraph_one: "* Offer applied only to Registered Users (On Selected Shops)"
      },
      section_5: {
        is_section_enable: true,
        banner: "/assets/img/wash-fold-bg.jpg",
        banner_two: "/assets/img/home/heroimg.png",
        title: "WASH & FOLD",
        wash_title: [
          {
            _id: 1,
            wash_list: "Washed under 30 degrees"
          },
          {
            _id: 2,
            wash_list: "Dried at medium heat"
          },
          {
            _id: 3,
            wash_list: "Customerâ€™s responsibility to make sure all items are washable"
          },
          {
            _id: 4,
            wash_list: "Towels and beddings are excluded and will be charged separately."
          }
        ]
      },
      section_6: {
        is_section_enable: true,
        title: "ABOUT BESTATSERVICES",
        sub_heading: "We believe in innovation, fueling success at every step",
        section_image: "https://api.bestatservices.com/storage/compress_images/65c64ad629988.png",
        background_image_color: "2fa0ea",
        paragraph: "It is one of zest and competence, which drives us to create extraordinary solutions adapted to your specific requirements. We want to be your valued partner on the road to success by committing to excellence.",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "Cost Effective",
              collection_image: "/assets/img/about-section/about-icon-01.png",
              isEnabled: true,
              collection_paragraph: "BestatServices provides quality service with reasonable prices to all."
            },
            {
              _id: 2,
              collection_title: "Ease to Use",
              collection_image: "/assets/img/about-section/about-icon-02.png",
              isEnabled: true,
              collection_paragraph: "User friendly website, Book orders with 1 step."
            },
            {
              _id: 3,
              collection_title: "Faster Service",
              collection_image: "/assets/img/about-section/about-icon-03.png",
              isEnabled: true,
              collection_paragraph: "Our team will complete the Service within 48 hours."
            },
            {
              _id: 4,
              collection_title: "Quality of Service",
              collection_image: "/assets/img/about-section/about-icon-04.png",
              isEnabled: true,
              collection_paragraph: "Our promise is to provide an exceptional service to you."
            }
          ],
          _id: 4,
          collection_title: "Quality of Service",
          collection_image: "/assets/img/about-section/about-icon-04.png",
          isEnabled: true,
          collection_paragraph: "Our promise is to provide a exceptional service to you."
        },
        collection_paragraph: "Our promise is to provide an exceptional service to you.",
        collection_title: "Faster Service"
      },
      section_7: {
        is_section_enable: true,
        title: "Ease to Made Services",
        paragraph1: "BestatServices is a trusted white-label SaaS Software provider for businesses and agencies in this digital age.",
        banner: "https://api.bestatservices.com/storage/compress_images/65c64e69e0a75.png",
        paragraph2: "Our product is compatible with desktops, laptops, Macs, iOS, and Android devices",
        paragraph3: "Keep clients updated on their request status via email and SMS.",
        paragraph4: "Generate invoices for customers and business accounts by sending them printable PDFs or via email.",
        card_section: [
          {
            _id: 1,
            text: "Search for your postal code"
          },
          {
            _id: 2,
            text: "Select a convenient shop"
          },
          {
            _id: 3,
            text: "Add required services in your cart"
          },
          {
            _id: 4,
            text: "Choose a convenient date and time slot"
          },
          {
            text: "Place Order"
          }
        ],
        text: "Place Order"
      },
      section_8: {
        is_section_enable: true,
        banner: "/assets/home/iPhone13.png",
        bg_color: "#FBF2FF",
        placeholder: "Eg:-  +4407816545718",
        banner_two: "/assets/img/home/heroimg.png",
        title: "Download the app",
        paragraph: "With our award-winning app, your laundry and dry cleaning needs are just a tap away",
        mobile_link_section: {
          is_enable: true,
          android_link: "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link: "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8"
        }
      },
      footer: {
        custome_banner_design: {
          is_enable: true
        },
        bg_color: "2fa0ea",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        site_title_en: "Â© Best at Services 2024. All rights reserved. ",
        footer_section: {
          needhelp_section: {
            is_enable: true,
            text: "NEED HELP?"
          },
          policy_section: {
            is_enable: true,
            text: "OUR POLICY"
          },
          quickconnect_section: {
            is_enable: true,
            text: "QUICK CONNECT"
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "Phone",
              value: "+447438823475"
            },
            email: {
              label: "Email",
              value: "sales@bestatservices.com"
            }
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com"
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com"
            },
            whatsapp: {
              is_enable: false,
              whatsapp_link: "whatsapp.com"
            },
            linkedin: {
              is_enable: false,
              linkedin_link: "linkedin.com"
            },
            youtube: {
              is_enable: false,
              youtube_link: "youtube.com"
            },
            twitter: {
              is_enable: false,
              twitter_link: "twitter.com"
            }
          },
          mobile_link_section: {
            is_enable: true,
            android_link: "",
            ios_link: "",
            bg_color: "2fa0ea",
            "email.value": "sales@bestatservices.com",
            "mbl_no.value": "+447438823475",
            site_link: "Â© Best at Services 2024. All rights reserved. "
          }
        },
        ios_link: "",
        android_link: "",
        "email.value": "sales@bestatservices.com",
        "mbl_no.value": "+447438823475",
        site_link: "Â© Best at Services 2024. All rights reserved. "
      }
    }
  },
};
export const shop_data_nail = {
  theme_1: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "assets/img/section/section1.png",
        },
        {},
        // {
        //   _id: 2,
        //   is_section_enable: true,
        //   icon: "assets/img/section/section2.png",
        // },
        {
          _id: 3,
          is_section_enable: true,
          icon: "assets/img/section/section3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "assets/img/section/section4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "assets/img/section/section5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "assets/img/section/section6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "assets/img/section/section7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "assets/img/section/section8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "assets/img/section/section9.png",
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "assets/img/section/section10.png",
        },
      ],
      header: {
        primary_color: "ff0059",
        secondary_color: "ffcb00",
        nav: {
          nav_bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            cart_bg_color: "ffffff",
            cart_icon_bg_color: "f07f1b",
            cart_value_color: "000000",
          },
          contact: {
            contact_is_enable: true,
            number: "+(044) 071012345",
            contact_color: "2e3639",
          },
        },
        btn_group: {
          btn1: {
            text: "Login / Register",
            btn_bg_color: "ffffff",
            btn_color: "1b9db3",
            border_color: "1b9db3",
            btn1_is_enable: true,
          },
        },
      },
      section_1: {
        banner: "assets/img/theme1/girl-hari-dresser.png",
        sub_heading_top: "24/7 Salon Service",
        title: "One Solution For All Your Salon Needs",
        paragraph:
          "The Ultimate Salon Experience Awaits—Indulge in Unmatched Luxury Today!",
        btn_group: {
          btn1: {
            text: "Book now",
            placeholder: "Enter Postalcode",
            bg_color: "1b9db3",
            color: "ffffff",
          },
        },
      },
      section_2: {
        title: "Select Your Time & Location For The Service",
        btn_group: {
          btn1: {
            text: "order now",
            placeholder: "Enter Postcode",
            bg_color: "1b9db3",
            color: "ffffff",
          },
        },
      },
      section_3: {
        is_section_enable: true,
        banner: "assets/theme1/trustimg.png",
        title: "Trust the Expert",
        paragraph:
          "Let your inner beauty take center stage as we transform, illuminate, and inspire at our welcoming salon ",
        sub_heading: "Unique solutions for all your Salon needs",
        check_list: [
          {
            _id: 1,
            title: "Smart Offers",
          },
          {
            _id: 1,
            title: "Consultations",
          },
          {
            _id: 1,
            title: "Scheduling App",
          },
          {
            _id: 1,
            title: "Appointment Reminders & more",
          },
        ],
        btn_group: {
          btn1: {
            text: "Read More",
            bg_color: "#ffcb00",
            color: "ffffff",
            is_enable: true,
          },
        },
      },
      section_4: {
        title: "Experience Beauty and Pampering Like Never Before",
        cards: [
          {
            heading: "Exceptional Service",
            icon: "assets/img/icons/priorityicon1.svg",
            image: "assets/img/home/priorityimg1.jpg",
            paragraph:
              "Experience a level of service that goes beyond expectations, leaving you refreshed and rejuvenated after every visit.",
          },
          {
            heading: "Professionalism",
            icon: "assets/img/icons/priorityicon2.svg",
            image: "assets/img/home/priorityimg2.jpg",
            paragraph:
              "Your salon visit reflects our commitment to professionalism—delivering top-notch service with a touch of class and expertise.",
          },
          {
            heading: "Value",
            icon: "assets/img/icons/priorityicon3.svg",
            image: "assets/img/home/priorityimg3.jpg",
            paragraph:
              "Experience a perfect blend of quality service, affordability, and a sense of well-being @ our Salon.",
          },
        ],
      },
      section_5: {
        is_section_enable: true,
        banner: "assets/img/phone.png",
        title: "Just A Click Away",
        paragraph:
          "Our apps got a better and convenient way of getting our 5-star services. Download now and register to get more exclusive offers",
        check_list: [
          {
            _id: 1,
            icons: "assets/img/icons/priorityicon1.svg",
            title: "Users",
            count: "25000",
          },
          {
            _id: 2,
            icons: "assets/img/icons/priorityicon2.svg",
            title: "Downloads",
            count: "40000",
          },
          {
            _id: 3,
            icons: "assets/img/icons/priorityicon3.svg",
            title: "Ratings",
            count: "4.5",
          },
        ],
        mobile_link_section: {
          is_enable: true,
          android_link:
            "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link:
            "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
        },
      },
      section_6: {
        heading:
          "Your Path to Radiant Beauty Begins Here, at Our Salon Sanctuary",
        card_1: {
          card_one_title: "Hair Services",
          card_one_paragraph:
            "Step into a world where your hair's potential is unlocked through expert styling and transformative treatments. From timeless cuts that accentuate your features to cutting-edge styles that make a statement, our skilled stylists craft hair artistry tailored to your personality. Experience the beauty of each strand, where every visit is a brushstroke of elegance and innovation.",
          card_one_image: "assets/img/home/wing1.png",
        },
        card_2: {
          card_two_title: "Skincare Services",
          card_two_paragraph:
            "Revel in a sanctuary of self-care as our dedicated skincare professionals nurture your skin to its ultimate glow. Our skincare services embrace your unique complexion, providing tailored treatments that rejuvenate, hydrate, and bring out your inner radiance. Immerse yourself in a journey of wellness and transformation, where every treatment is a harmonious blend of science, art, and personal attention",
          card_two_image: "assets/img/home/wing2.png",
        },
      },
      section_8: {
        title: "What Our Client Say",
        cards: [
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg1.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser2.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg2.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser3.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg3.jpg",
          },
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg1.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser2.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg2.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser3.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg3.jpg",
          },
        ],
      },
      section_10: {
        title: "Latest News & Events",
        button: {
          text: "View More",
          bg_color: "#ffcb00",
          color: "ffffff",
          is_enable: true,
        },
      },
      section_7: {
        is_section_enable: true,
        title: "Salon on Wheels: Your Beauty Destination, Anywhere You Are",
      },
      section_9: {
        is_section_enable: true,
        title: "Frequently Asked Questions",
        paragraph:
          "Delight in luxury with our salon's top-tier treatments, meticulously curated to enhance your unique beauty.",
        banner: [
          {
            banner: "assets/img/theme1/faqimg.jpg",
          },
        ],
        card_heading: "CALL US FOR A FREE QUOTE ON:",
        card_number: "+(044) 071012345",
        card_buttontext: "Enquire Now",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title:
                "What are the types of hair services offered at Best@Salon?",
              isEnabled: true,
              collection_paragraph:
                "Best@Salon offers a range of hair services including cuts, styling, coloring, extensions, and more.",
            },
            {
              _id: 2,
              collection_title:
                "Is Best@Salon suitable for individuals with sensitive skin or specific allergies?",
              isEnabled: true,
              collection_paragraph:
                "Absolutely, our salon accommodates sensitive skin and specific allergies, ensuring a safe and tailored experience.",
            },
            {
              _id: 3,
              collection_title: "How can I book an appointment at Best@Salon?",
              isEnabled: true,
              collection_paragraph:
                "Booking an appointment is quick and easy - either call us directly or book online through our website for your convenience.",
            },
          ],
        },
      },
      footer: {
        bg_color: "ffffff",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
      },
    },
  },
  theme_2: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/section-two/section1.png",
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/section-two/section2.png",
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/section-two/section3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/section-two/section4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "/assets/section-two/section5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/section-two/section6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/section-two/section7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "/assets/section-two/section8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "/assets/section-two/section9.png",
        },
      ],
      header: {
        primary_color: "ff0059",
        secondary_color: "ffcb00",
        nav: {
          nav_bg_color: "ffffff",
          header_bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
        },
        btn_group: {
          btn1: {
            text: "Continue to order",
            btn_bg_color: "ff0059",
            btn_color: "ffffff",
          },
          btn2: {
            btn2_text: "Order Now",
            btn2_bg_color: "ffcb00",
            btn2_color: "2e3639",
          },
        },
      },
      section_1: {
        title_1: "Solution for your",
        title_2: "Salon Service",
        title_3:
          "Unveil Your Best Self: Your Choices, Perfected by Our Expertise.",
        card_section: [
          "/assets/img/theme_2/banner-slider-01.jpg",
          "/assets/img/theme_2/banner-slider-02.jpg",
          "/assets/img/theme_2/banner-slider-03.jpg",
          "/assets/img/theme_2/banner-slider-04.jpg",
          "/assets/img/theme_2/banner-slider-05.jpg",
        ],
      },
      section_2: {
        is_section_enable: true,
        banner: "/assets/img/trustimg2.png",
        sub_heading_top: "Our Introduction",
        title: "Welcome",
        mainparagraph:
          "Step into a world of luxury and allure. Our salon is your haven for unparalleled beauty and rejuvenating pampering that redefines elegance.",
        card_section_one: [
          {
            _id: 1,
            text: "Dry Cleaning",
            count: "12K",
          },
          {
            _id: 2,
            text: "Satisfied Clients",
            count: "20K",
          },
          {
            _id: 3,
            text: "Happy Customers",
            count: "8K",
          },
        ],
        card_section_title: "Top rated laundry",
        rating: "5",
        card_sub_text: "(30k Reviews)",
        card_section_two: [
          {
            _id: 1,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "Claim Support",
            card_title: "Instant Updates",
            card_paragraph:
              "we keep you updated, always a step ahead for your convenience.",
          },
          {
            _id: 2,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "100% Reliable",
            card_title: "Customers Safety",
            card_paragraph:
              "From sanitized spaces to mindful practices, we're dedicated to your peace of mind and well-being.",
          },
          {
            _id: 3,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "Consultation",
            card_title: "Hygiene Standards",
            card_paragraph:
              "We provide Up-to-date Information, Instant Awareness and Convenience.",
          },
        ],
      },
      section_3: {
        cards: [
          {
            bg_img: "/assets/img/home/wing1.png",
            card_title: "Understanding Your Unique Style",
            card_paragraph:
              "We kick off by engaging in a one-on-one chat, where we eagerly listen to your preferences, expectations, and personal style. Your input guides our approach, ensuring a tailor-made experience.",
              services: ["Laundry", "Dry Cleaning", "Ironing"],
            card_main_title: "Your Personal Consultation",
            card_main_paragraph:
              "Your journey begins with a personal consultation led by one of our skilled stylists. We work closely with you to recommend services that align with your vision and individuality.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/home/wing2.png",
            card_title: "Customized Just for You",
            card_paragraph:
              "Every service is exclusively tailored to suit your hair type, facial features, and desired outcome. Your distinct characteristics shape our approach, resulting in a truly personalized salon adventure.",
              services: [
                "Pay Online in Seconds",
                "Eco-Friendly",
                "Save Time & Money",  
            ],
            card_main_title: "Booking Made Simple",
            card_main_paragraph:
              "Scheduling an appointment is a breeze! Whether you prefer online booking or a friendly phone call, we strive to make this process convenient. We even offer same-day appointments for that spontaneous pampering.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/home/wing3.png",
            card_title: "Welcoming You In",
            card_paragraph:
              "As you step into our salon, expect a warm and inviting atmosphere. Our team is ready to guide you through your visit, making you feel right at home from the very start.",
              services: ["Competitive Price", "5 Star Rated"],
            card_main_title: "Crafting Beauty, Just for You",
            card_main_paragraph:
              "Our skilled professionals meticulously carry out your chosen services, focusing on your unique needs and desires. Your satisfaction is our primary goal, and our expertise is at your service.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/home/wing4.png",
            card_title: "Unwinding in Your Personal Space",
            card_paragraph:
              "Enjoy the soothing ambiance and any extra amenities we provide as you indulge in a rejuvenating experience crafted exclusively for you.",
              services: ["24h turnaround time", "Easy to reschedule"],
            card_main_title: "Your Feedback Matters",
            card_main_paragraph:
              "We value your thoughts and opinions. After your session, we encourage your feedback, allowing us to adjust and improve to ensure your complete delight with our services.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Best Quality",  
            ],
          },
        ],
      },
      section_4: {},
      section_5: {
        title: "Compare our Services",
        cards: [
          {
            card_title: "",
            icon: "/assets/img/icons/serviceicon2.svg",
            card_options: [
              "What is included",
              "Typically included items",
              "Service time",
              "Delivery",
              "Items returned",
              "Eco friendly",
            ],
          },
          {
            card_title: "Laundry Services",
            icon: "/assets/img/icons/serviceicon2.svg",
            card_options: [
              "Wash + Tumble-Dry",
              "T-shirts, trousers, bedsheets.",
              "Service time 24h*",
              "Delivery FREE",
              "Items returned in a bag",
              "Eco friendly",
            ],
          },
          {
            card_title: "Dry Cleaning Services",
            icon: "/assets/img/icons/serviceicon3.svg",
            card_options: [
              "Dry Cleaning + Ironing",
              "Suits, shirts, dresses and coats.",
              "Service time 24h*",
              "Delivery FREE",
              "Items returned in a bag",
              "Eco friendly",
            ],
          },
          {
            card_title: "Ironing Services",
            icon: "/assets/img/icons/serviceicon4.svg",
            card_options: [
              "Ironing",
              "Shirts, t-shirts and bedsheets.",
              "Service time 24h*",
              "Delivery FREE",
              "Items returned in a bag",
              "Eco friendly",
            ],
          },
        ],
      },
      section_6: {
        collection: [
          {
            collection_title: "Do you have a re-cleaning Policy?",
            collection_paragraph:
              "Although we have quality control where we check each garment before we pack, if we are unable to remove stains we usually inform customers before. If something slips through and you are not completely satisfied with the quality of our dry cleaning, we will re-clean your item free of charge, as long as it’s within 24 hours.",
          },
          {
            collection_title: "Do you have a loss policy?",
            collection_paragraph:
              "We take full responsibility for the clothes from the moment we issue a ticket or pick up from you. Even though we exercise the utmost care, there will be instances where items may get misplaced or lost. Should you have reason to believe that we have lost or misplaced your item. Missing items must be reported to us within 24 hours with descriptions so that we can trace your garment. In the unlikely event of losing any of your items, bestatlaundry liability with respect to any lost item shall not exceed ten (10) times our charge for cleaning that garment regardless of brand or condition.",
          },
          {
            collection_title: "Do you have a safe keeping policy?",
            collection_paragraph:
              "We take responsibility to keep your clothes safe for a maximum of 60 days. Should you be unable to pick your clothes within 60 days, due to holiday, illness, etc. please let us know ASAP and we shall arrange to keep your garments safely for an agreed time period. Otherwise, due to storage, we will have to give your clothes to charity.",
          },
          {
            collection_title: "Do you have a privacy statement?",
            collection_paragraph:
              "We respect our customer’s privacy and do not sell any identifiable information regarding our customers to any third party. Any information you give us is held with the utmost care and security, and will not be used in ways to which you have not consented.",
          },
          {
            collection_title: "Do you have a garment damage policy?",
            collection_paragraph:
              "Whilst every care is taken handling your clothes, if you believe that we have damaged your garments in any way please contact as within 24 hours. We will do our best to resolve the situation. We will investigate thoroughly and if we feel that we were negligent we shell pay fair compensation based upon the current value of the garment. This is usually around 6-10 times the cost of the dry cleaning. Our damage policy is in line with the Office of Fair Trades recommendations.",
          },
          {
            collection_title: "How should my garments be cleaned?",
            collection_paragraph:
              "As we are experts at dealing with all garments that come our way. When they arrive at our premises, your garments are examined carefully and then cleaned under the manufacturer’s instructions. Our cleaning is as gentle and delivers better results than traditional dry cleaning. If there’s any doubt or we can’t find a care label, we will clean at what we call “owner’s risk” (as we can sadly not accept any liability for garments where there are no instructions). If we feel there is really too much of a risk to your garment and we cannot get in touch with you, we will return the item to you uncleaned.",
          },
          {
            collection_title: "How long does it take to clean my items?",
            collection_paragraph:
              "We aim to collect your clothes, give them our expert care and attention and deliver them back to you within working 48 hours. There might be some exceptions: In some instances, your preferred delivery slot may not be available. This is mainly because we have already reached full capacity for that time slot, or because we do not visit your area as often as we would like at this moment. We apologise for any inconvenience caused and assure you that we are working very hard to increase our time slot capacity. Some items such as bed linen, curtains, leather, or tailoring may take longer to process as they will be entrusted to various experts. The delivery timing will be provided to you as soon as the respective items have been inspected at our premises. On inspection, we might consider that it will take longer to remove particularly difficult stains or other issues with fabrics. We offer an express service with same-day turnaround* in all our stores however due to logistical constraints thi",
          },
          {
            collection_title:
              "Why can I not select my preferred delivery slot?",
            collection_paragraph:
              "In some instances, your preferred delivery slot may not be available. This could be because we have already reached full capacity for that time slot. We apologize for any inconvenience caused and assure you that we are working very hard to increase our time slot capacity. Should you wish to drop us a line and check to see if we accommodate your chosen slot or to see if there is any cancellation please email info@bestatlaundry.uk",
          },
          {
            collection_title: "How do I order online?",
            collection_paragraph:
              "Simply click Book Now in the top right-hand corner. On the next screen, you will be able to choose a pickup time that we will collect the clothing items from your home/concierge and a dropoff time where we will drop the dry cleaned clothes back to your home/concierge. The next screen is where you can choose your items either select the items directly or choose a category and the system will refine the items to help you choose. Simply choose the quantity and select add to put the item in your basket. When ready click with view basket or the basket in the top right-hand corner of the page you can then check out adding your address and any special requirements. Lastly, you can pay using any credit or debit card using our encrypted service with stripe. We will then process your order and arrive at your requested time slot ready to pick up your items.",
          },
          {
            collection_title: "can I book a regular pickup & delivery?",
            collection_paragraph:
              "Yes! If you want to book a regular pickup and delivery slot each week, just email us to info@bestatlaundry.uk This saves having to deal with laundry and to re-book on a regular basis, and you can cancel at any time if you don’t need a delivery that week. Although it is currently not possible to book a recurring time slot online, you can call our customer care team and we will gladly set this up.",
          },
          {
            collection_title: "How do I know when my driver will arrive?",
            collection_paragraph:
              "We currently offer 1-hour time slots, which allows for traffic and our logistical requirements, if our driver is later than the chosen time slot we will contact you.",
          },
          {
            collection_title: "Why do you not cover my area?",
            collection_paragraph:
              "We are continually growing and expanding and while we might not currently cover your area. Send a message with your details to info@bestatlaundry.uk and as soon as we start to serve your area, we will contact you. You can also mail us to info@bestatlaundry.uk as they will often be able to arrange a special courier or Uber service for you (delivery fees will apply).",
          },
          {
            collection_title: "How will I be charged for the order",
            collection_paragraph:
              "We use a highly secure platform to register your credit or debit card and collect payment. After picking up your clothes, our experts will examine them and determine the total amount of your invoice based on the extent of the work that needs to be carried out on each item (much as a till assistant would do in-store). Until then, you can get an estimate of your order according to our price list here. We are unable to be more exact at this stage because some items require more time and specific treatment, and we can’t know the detail of this before we examine your garments. Once this is done, we will send you an email detailing your invoice, item by item, and charge your card on that basis. If you disagree with anything that we have detailed on your invoice, please feel free email us to info@bestatlaundry.uk and we will be happy to look into this for you. Kindly note that we cannot accept cash payment for deliveries.",
          },
          {
            collection_title: "What does owner's risk mean?",
            collection_paragraph:
              "Some fragile or highly sensitive garments inherently have a risk in cleaning, this could be due to fabric, age, colour, pre-existing damage etc. thus meaning there is a risk that they could be damaged in the cleaning process, in this instance we will refrain from cleaning the garment in question. We will make an attempt to contact you and advise you of the potential risks associated with proceeding with the treatment. Note that if you authorise us to proceed, it will imply that you assume full responsibility for any damage to the garments. In certain circumstances, we may still return the garment and advise not to clean it as damage is inevitable.",
          },
          {
            collection_title:
              "can we dry clean wedding dresses and other bespoke items?",
            collection_paragraph:
              "We are the experts at cleaning these pieces. Please note that the sooner you bring your dress in after your wedding, the better the results we can achieve, as leaving little time for stains to set helps us to get that better finish. We can also clean other bespoke items – just ask one of your store assistants for a quote, email us info@bestatlaundry.uk if you would like more information or to ask for a quote. Kindly note that we assess all those very specific items before cleaning to ensure that we have spotted and highlighted all the potential risks to you before proceeding with the clean.",
          },
        ],
      },
      section_7: {
        title_1: "Latest Testimonial",
        title_2: "What Our Client Say",
        cards: [
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser2.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser3.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser2.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser3.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
        ],
      },
      section_8: {
        title_1: "ARTICLES",
        title_2: "Latest News",
      },
      section_9: {
        title: "We're available on mobile Just download it!",
        paragraph:
          "Download the our app and request our laundry service. Your clothes washed, folded and delivered to your doorstep. So go ahead and say yes to more time with the family, more happy hours, and more slapping the snooze button every morning — we've got laundry day covered.",
        android_link:
          "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
        ios_link:
          "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
      },
      footer: {
        custome_banner_design: {
          is_enable: true,
        },
        bg_color: "151e2e",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          about_section: {
            is_enable: true,
            text: "Ready to take laundry off your to-do list? Bestatlaundry services is a local laundry pickup and delivery service that you can order directly from your smart phone.",
          },
          service_section: {
            is_enable: true,
          },
          quicklink_section: {
            is_enable: true,
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "+442086388690",
              value: "tel:+442086388690",
            },
            email: {
              label: "Email Us",
              value: "sales@bestatlaundry.com",
            },
            address: {
              text: "81 Randall Drive, Hornchurch - RM126TA",
            },
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
      },
    },
  },
  theme_3: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "assets/section/section1.png",
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "assets/section/section2.png",
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "assets/section/section3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "assets/section/section4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "assets/section/section5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "assets/section/section6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "assets/section/section7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "assets/section/section8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "assets/section/section9.png",
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "assets/section/section10.png",
        },
        {
          _id: 11,
          is_section_enable: true,
          icon: "assets/section/section11.png",
        },
        {
          _id: 12,
          is_section_enable: true,
          icon: "assets/section/section12.png",
        },
        {
          _id: 13,
          is_section_enable: true,
          icon: "assets/section/section13.png",
        },
        {
          _id: 14,
          is_section_enable: true,
          icon: "assets/section/section14.png",
        },
      ],
      header: {
        is_section_enable: true,
        primary_color: "1B9DB3",
        secondary_color: "F07F1B",
        nav: {
          bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "#6b7385",
            },
            cart_value_color: "000000",
          },
        },
        btn_group: {
          btn1: {
            text: "Login / Register",
            bg_color: "ffffff",
            color: "#1b9db3",
            border_color: "#1b9db3",
            btn1_is_enable: true,
          },
          btn2: {
            text: "Quick Order",
            color: "ffffff",
            bg_color: "000",
            border_color: "#192335",
            btn2_is_enable: true,
          },
        },
      },
      section_1: {
        is_section_enable: true,
        banner: "/assets/img/services/nail/home_page_single_search.jpg",
        sub_heading_top: "24/7 Nailing Service",
        title: "London's Premier Nail Art Destination",
        paragraph:
          "Discover exquisite nail craftsmanship in the heart of London, where creativity and style converge for flawless, trendsetting nails.",
        btn_group: {
          btn1: {
            text: "Request a Schedule",
            bg_color: "1b9db3",
            color: "ffffff",
          },
          btn2: {
            text: "Book now",
            bg_color: "F07F1B",
            color: "ffffff",
          },
        },
        mobile_link_section: {
          is_enable: true,
          mob_title: "Just A Click Away",
          android_link: "#",
          ios_link: "#",
        },
      },
      section_2: {
        is_section_enable: true,
        sub_heading_top: "Book A Service",
        title: "Select Your Time & Location For The Service",
        btn_group: {
          btn1: {
            text: "order now",
            bg_color: "#1b9db3",
            color: "ffffff",
          },
        },
      },
      section_3: {
        is_section_enable: true,
        sub_heading_top: "Nail Artistry Unveiled",
        title: "Transforming nails into personalized masterpieces",
        paragraph:
          "Immerse yourself in the epitome of nail artistry, offering Londoners a haven for bespoke designs and impeccable manicures",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/img/services/priorityicon1.png",
              collection_title: "Design Variety",
              collection_paragraph:
                "Explore a spectrum of designs at BestatNails! From dazzling glitter fades to London's  chic geometric patterns, find your signature style with our diverse range of nail art creations.",
            },
            {
              _id: 2,
              icon: "/assets/img/services/priorityicon2.png",
              collection_title: "Customization",
              collection_paragraph:
                "Your vision, our artistry! BestatNails crafts bespoke nail designs, allowing you to personalize colors, themes, and intricate details for a truly British statement at your fingertips.",
            },
            {
              _id: 3,
              icon: "/assets/img/services/priorityicon3.png",
              collection_title: "Expertise and Skill",
              collection_paragraph:
                "With finesse and flair, our talented artists at BestatNails bring refined expertise honed in the heart of the UK. Experience craftsmanship at its finest with every stroke.",
            },
            {
              _id: 4,
              icon: "/assets/img/services/priorityicon4.png",
              collection_title: "Only the BEST for Your Nails",
              collection_paragraph:
                "Only top-tier products grace your nails at BestatNails. From premium polishes to cutting-edge tools, indulge in the best for long-lasting brilliance.",
            },
            {
              _id: 5,
              icon: "/assets/img/services/priorityicon5.png",
              collection_title: "Before and After Showcase",
              collection_paragraph:
                " See the Metamorphosis! Our before-and-after gallery illustrates the striking transformation that BestatNails' artistry brings to your fingertips.",
            },
            {
              _id: 6,
              icon: "/assets/img/services/priorityicon6.png",
              collection_title: "Special Techniques",
              collection_paragraph:
                "Discover craftsmanship at its pinnacle! BestatNails boasts unique techniques like hand-painted designs and captivating 3D elements, elevating your nail art.",
            },
          ],
          btn_group: {
            btn1: {
              text: "More About Best@nail",
              bg_color: "192335",
              color: "ffffff",
            },
          },
        },
      },
      section_4: {
        is_section_enable: true,
        title:
          "Championing Nail Excellence: Bestatnails, where customer satisfaction places us as the UK's #1 nail destination.",
      },
      section_5: {
        is_section_enable: true,
        banner: [
          {
            banner: "/assets/img/services/nail/pexels-freestocksorg-361754.jpg"
          }
        ],
        title: "Exquisite Nails, Exquisite Care",
        paragraph: "Experience our exclusive nail care rituals, meticulously curated to sculpt sophistication onto every fingertip, unveiling your unique beauty.",
        check_list: [
          {
            _id: 1,
            list: "Personalized Consultations",
          },
          {
            _id: 2,
            list: "Eco-conscious nail care",
          },
          {
            _id: 3,
            list: "Trendsetting nail art solutions",
          },
          {
            _id: 4,
            list: "Customer-Centric Experience",
          },
          {
            _id: 5,
            list: "Strict compliance with UK safety norms",
          },
          {
            _id: 6,
            list: "Transparent Pricing",
          },
        ],
        btn_group: {
          btn1: {
            text: "Book Now",
            bg_color: "#192335",
            color: "ffffff",
            is_enable: true
          },
          btn2: {
            text: "Request a schedule",
            bg_color: "#1b9db3",
            color: "ffffff",
            is_enable: true
          }
        }
      },
      section_6: {
        is_section_enable: true,
        sub_heading_top: "Know More On",
        title: "Insights into Our Approach",
        paragraph:
          "Embracing Our Distinctive Method for Exclusive Nail Care Services",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/img/services/nail/designecologist-r-Ej0NQmFlQ-unsplash.jpg",
              card_title: "Browse Our Selection",
              card_paragraph:
                "Browse through various nail art designs, styles, colors, and trends showcased on our user-friendly website to find inspiration for your desired look.",
            },
            {
              _id: 2,
              icon: "/assets/img/services/nail/foot-washing-spa.jpg",
              card_title: "Select Your Preferred Service",
              card_paragraph:
                "Whether it's intricate nail art designs, extensions for added length, or deluxe manicures and pedicures, select the service that matches your style and needs.",
            },
            {
              _id: 3,
              icon: "/assets/img/services/nail/manicurist-master.jpg",
              card_title: "Customization Options",
              card_paragraph:
                "From selecting specific designs or colors to deciding on nail shapes and lengths, tailor the service to suit your unique preferences.",
            },
            {
              _id: 4,
              icon: "/assets/img/services/nail/rune-enstad.jpg",
              card_title: "Book Your Appointment",
              card_paragraph:
                "Effortlessly book your appointment via our online scheduling system, choosing your preferred date, time, and venue. Our adaptable scheduling aims to accommodate your hectic schedule with ease.",
            },
          ],
          btn_group: {
            paragraph:
              "Ready for Stunning Nails? Schedule Your Appointment Now!",
            btn1: {
              text: "Book Online Now",
              bg_color: "#192335",
              color: "#ffffff",
            },
          },
        },
      },
      section_7: {
        is_section_enable: true,
        title: "Convenient On-Demand Nail Care Solutions Across the UK",
        paragraph:
          "Experience hassle-free nail care services brought directly to your doorstep, available throughout the UK. Enjoy the ease and convenience of on-demand treatments, ensuring your nails are pampered and perfected, wherever you are in the country.",
        btn_group: {
          btn_paragraph: "Lorem Ipsum is simply dummy text of the",
          btn1: {
            text: " Request a Schedule",
            bg_color: "192335",
            color: "fff",
          },
        },
      },
      section_8: {
        is_section_enable: true,
        banner: "howwe1",
        title: "About Best@nail",
        section_image: "/assets/img/services/nail/pexels-freestocksorg-332046.jpg",
        paragraph:
          "At BestatNails, we believe in the transformative power of exceptional nail care. Established with a passion for redefining beauty standards, we are dedicated to providing an unparalleled experience in the artistry of nails.",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "James please use React import aliase",
              collection_title: "Tailored UK Nail Trends",
              collection_image: "/assets/img/services/priorityicon7.png",
              isEnabled: true,
              collection_paragraph:
                "Crafting nail designs inspired by the latest UK fashion movements and cultural influences.",
            },
            {
              _id: 2,
              icon: "James please use React import aliase",
              collection_title: "Hygiene Standards",
              collection_image: "/assets/img/services/priorityicon8.png",
              isEnabled: true,
              collection_paragraph:
                "Adhering strictly to health regulations, ensuring impeccable hygiene for every nail care session.",
            },
            {
              _id: 3,
              icon: "James please use React import aliase",
              collection_title: "Flexible Service Packages",
              collection_image: "/assets/img/services/priorityicon9.png",
              isEnabled: true,
              collection_paragraph:
                "Tailoring service packages to suit individual schedules and preferences across the UK.",
            },
          ],
        },
        btn_group: {
          btn1_text: "Book Now",
          btn1_bg_color: "192335",
          btn1_color: "ffffff",
          btn2_text: "Request a Schedule",
          btn2_bg_color: "1b9db3",
          btn2_color: "ffffff",
        },
      },
      section_9: {
        is_section_enable: true,
        section_bg_color: "#1b9db3",
        sub_heading_top: "Book Online",
        title: "Your Perfect Nails, On Your Schedule",
        paragraph: "Your time and money are highly valued here",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/img/services/nail/pexels-cottonbro-studio-3997360.jpg",
              collection_title: "Pedicure & Manicure",
              collection_paragraph:
                "Immerse in our lavish pedicures and manicures, blending expert care with serene indulgence. Beyond nail grooming, enjoy a haven of relaxation through hydrating masks, exfoliation, and revitalizing massages, leaving your hands and feet impeccably rejuvenated.",
            },
            {
              _id: 2,
              icon: "/assets/img/services/nail/pexels-cottonbro-studio-3997377.jpg",
              collection_title: "Nail Extensions",
              collection_paragraph:
                "Transform your nails into captivating artistry with our array of extensions, from classic acrylics to flexible fiberglass overlays. Our skilled artisans craft natural-looking extensions, ensuring durability, adaptability, and a refined, elegant finish.",
            },
            {
              _id: 3,
              icon: "/assets/img/services/nail/pexels-cottonbro-studio-3997379.jpg",
              collection_title: "Gel Nails",
              collection_paragraph:
                "Embrace enduring elegance with our gel nails boasting vibrant, chip-resistant hues and a long-lasting glossy sheen. Beyond standard polish, our gel applications offer sustained allure and artistic flair, enhancing your nail aesthetics.",
            },
            {
              _id: 14,
              icon: "/assets/img/services/nail/pexels-cottonbro-studio-3997392.jpg",
              collection_title: "3D Nail Printing",
              collection_paragraph:
                "Explore the frontier of nail artistry through our innovative 3D printing, delivering intricate, unconventional designs. Precision and artistry converge, enabling personalized, avant-garde nail designs that redefine sophistication and individuality.",
            },
          ],
          count_section: [
            {
              _id: 1,
              icon: "/assets/img/services/priorityicon1.png",
              text: "Expert Wellness Treatments",
              count: "1639+",
            },
            {
              _id: 2,
              icon: "/assets/img/services/priorityicon2.png",
              text: "Exceptional Client Care",
              count: "24359+",
            },
            {
              _id: 3,
              icon: "/assets/img/services/priorityicon3.png",
              text: "Relaxing Ambiance",
              count: "4793+",
            },
            {
              _id: 4,
              icon: "/assets/img/services/priorityicon4.png",
              text: "Expert Services",
              count: "25+",
            },
          ],
        },
      },
      section_10: {
        is_section_enable: true,
        banner: "/assets/img/services/nail/pexels-cottonbro-studio-3997353.jpg",
        sub_heading_top: "Ensuring your safety remains our prime focus",
        title: "Our Values",
        mainparagraph: "",
        list_Section: [
          {
            _id: 1,
            text: "Rigorous Sanitization Protocols",
            paragraph:
              "Our precise cleaning routines maintain a pristine environment, guaranteeing your safety and comfort during your nail care experience.",
          },
          {
            _id: 2,
            text: "Certified Hygiene Standards",
            paragraph:
              "Devoted to upholding certified hygiene criteria, we create a safe and hygienic space exclusively for your nail care needs.",
          },
          {
            _id: 3,
            text: "Sterilized Tool Usage",
            paragraph:
              "Employing sterilized tools for every service, ensuring utmost hygiene and safety for your nail treatments.",
          },
        ],
        card_section_title: "We use Eco-friendly Nail Service.",
        card_section: [
          {
            _id: 1,
            card_bg_color: "192335",
            card_text: "Eco-Friendly Nail Care Products",
            card_paragraph:
              "Our commitment to eco-conscious nail care extends to our products, ensuring they are environmentally friendly and sustainably sourced.",
          },
          {
            _id: 2,
            card_bg_color: "f07f1b",
            card_text: "Transparency in Ingredients",
            card_paragraph:
              "Providing clear information about the ingredients used, fostering transparency and trust with our clients.",
          },
          {
            _id: 3,
            card_bg_color: "1bb333",
            card_text: "Non-Toxic Formulations",
            card_paragraph:
              "Utilizing formulations free from harmful chemicals, toxins, and pollutants, promoting healthier nail care practices.",
          },
        ],
      },
      section_11: {
        is_section_enable: true,
        banner: [
          {
            banner: "/assets/img/services/nail/pexels-element-digital-973405.jpg",
          },
        ],
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title:
                "How often should I get a manicure or pedicure?",
              isEnabled: true,
              collection_paragraph:
                "The frequency of nail care services depends on various factors such as nail health, lifestyle, and personal preference. Generally, a manicure or pedicure every 2-4 weeks helps maintain healthy nails.",
            },
            {
              _id: 2,
              collection_title: "Are gel nails safe for regular use?",
              isEnabled: true,
              collection_paragraph:
                "When applied and removed properly by trained technicians, gel nails are considered safe. However, continuous or improper use can weaken natural nails. Taking breaks between applications is recommended to maintain nail health.",
            },
            {
              _id: 3,
              collection_title:
                "What should I do to maintain my nail extensions?",
              isEnabled: true,
              collection_paragraph:
                "Regular maintenance is crucial for nail extensions. Avoid using your nails as tools, keep them dry, and schedule regular touch-ups (typically every 2-3 weeks) to prevent lifting or breakage.",
            },
            {
              _id: 4,
              collection_title: "How can I strengthen my natural nails?",
              isEnabled: true,
              collection_paragraph:
                "Opt for regular moisturizing, use nail strengtheners, maintain a balanced diet rich in vitamins and minerals, and minimize exposure to harsh chemicals to improve the strength of your natural nails.",
            },
            {
              _id: 5,
              collection_title:
                "What are the differences between acrylic and gel nail extensions?",
              isEnabled: true,
              collection_paragraph:
                "Acrylic extensions are robust and durable, while gel extensions offer a more natural look and flexibility. Acrylics are cured using a liquid and powder, whereas gels are cured under UV light.",
            },
          ],
        },
        sub_heading_top: "Ask Your Question",
        title: "Frequently Asked Questions",
        paragraph: "",
      },
      section_12: {
        is_section_enable: true,
        banner: "howwe1",
        sub_heading_top: "What Our Client Says",
        title: "Access Professional Nail Care On Your Terms, UK-wide",
        paragraph: "Read what our customers say about Best@Nails",
        review_bottom_paragraph:
          "More than 5,000+ reviews s on TrustPilot, Google, Google Play & App Store",
        review_card: [
          {
            _id: 1,
            reviewer_pic: "/assets/img/services/testimonialuser1.jpg",
            reviewer_name: "Ariyan Rusov",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 2,
            reviewer_pic: "/assets/img/services/testimonialuser2.jpg",
            reviewer_name: "Darren Valdez",
            rating: "4",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 3,
            reviewer_pic: "/assets/img/services/testimonialuser3.jpg",
            reviewer_name: "Elinor Dunn",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
        ],
        btn_group: {
          btn1: {
            text: "Book Online Now",
            bg_color: "#192335",
            color: "#ffffff",
            is_enable: true,
          },
        },
      },
      section_13: {
        is_section_enable: true,
        banner: "howwe1",
        title: "Just A Click Away",
        bannerimg: "/assets/img/services/nail/mob.png",
        paragraph: "Download Our Best Active Mobile App",
        check_list: [
          {
            _id: 1,
            icons: "/assets/img/services/priorityicon14.png",
            title: "Cashless payments",
          },
          {
            _id: 2,
            icons: "/assets/img/services/priorityicon15.png",
            title: "Instant Updates",
          },
          {
            _id: 3,
            icons: "/assets/img/services/priorityicon16.png",
            title: "Hygiene Standards",
          },
          {
            _id: 4,
            icons: "/assets/img/services/priorityicon17.png",
            title: "Eco-conscious",
          },
        ],
        mobile_link_section: {
          is_enable: true,
          android_link:
            "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link:
            "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
        },
      },
      section_14: {
        is_section_enable: true,
        section_bg_color: "#1b9db3",
        title:
          "Subscribe to our newsletter for updates, exclusive promotions and our latest content!",
        btn_group: {
          btn1: {
            text: "Subscribe",
            bg_color: "#192335",
            color: "ffffff",
          },
        },
      },
      section_15: {
        is_section_enable: true,
        sub_heading_top: "Our Latest",
        title: "News & Events",
        paragraph:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
        btn_group: {
          btn1: {
            text: "More News & Events",
            bg_color: "#192335",
            color: "ffffff",
          },
        },
      },
      footer: {
        custome_banner_design: {
          is_enable: true,
        },
        bg_color: "151e2e",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          about_section: {
            is_enable: true,
            text: "Ready to take nail off your to-do list? BestatNails services is a local nail service that you can book directly from your smart phone.",
          },
          service_section: {
            is_enable: true,
          },
          quicklink_section: {
            is_enable: true,
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "+442086388690",
              value: "tel:+442086388690",
            },
            email: {
              label: "Email Us",
              value: "sales@bestatlaundry.com",
            },
            address: {
              text: "81 Randall Drive, Hornchurch - RM126TA",
            },
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
      },
    },
  },
  theme_4: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_1.png",
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_2.png",
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_9.png",
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_10.png",
        },
        {
          _id: 11,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_11.png",
        },
        {
          _id: 12,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_12.png",
        },
      ],
      header: {
        is_section_enable: true,
        primary_color: "046A38",
        secondary_color: "01190D",
        contact: {
          email_id: "sales@bestatlaundry.com",
          contact_number: "+442086388690",
        },
        nav: {
          bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "#6b7385",
            },
            cart_value_color: "000000",
          },
        },
        btn_group: {
          btn1_text: "Login / Register",
          btn1_bg_color: "046A38",
          btn1_color: "ffffff",
          btn1_border_color: "046A38",
          btn1_is_enable: true,
          btn2_text: "Quick Order",
          btn2_color: "ffffff",
          btn2_bg_color: "000",
          btn2_border_color: "#192335",
          btn2_is_enable: true,
        },
      },
      section_1: {
        is_section_enable: true,
        banner_type: "image",
        banner: "/assets/home_page_single_search.jpg",
        banner_two: "/assets/img/home/heroimg.png",
        sub_heading_top: "24/7 Salon Service",
        title:
          "Discover Your Radiance: Partner with Us for the Salon Experience You Can Trust",
        paragraph: "Select your time & location for the service",
        btn_group: {
          btn1: {
            text: "Order now",
            bg_color: "#046A38",
            color: "ffffff",
            placeholder: "Enter Postcode",
          },
        },
      },
      section_2: {
        is_section_enable: true,
        title: "Trust the Experts",
        paragraph: "Experience Beauty and Pampering Like Never Before",
        first_card_image: "/assets/img/home/trustimg1.jpg",
        image_title: "Why 1000+ customers trust Us?",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/icons/priorityicon1.png",
              collection_title: "Top Quality",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 2,
              icon: "/assets/icons/priorityicon4.png",
              collection_title: "Professionalism",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 3,
              icon: "/assets/icons/priorityicon5.png",
              collection_title: "Convenience",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 4,
              icon: "/assets/icons/priorityicon6.png",
              collection_title: "Cleanliness",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
          ],
        },
      },
      section_3: {
        is_section_enable: true,
        title: "5 star Rated by customers in the UK in categories of Salon",
        banner: "/assets/img/home/review-bg.jpg",
      },
      section_4: {
        is_section_enable: true,
        banner: "/assets/home/trustimg2.jpg",
        title: "About We",
        paragraph_one:
          "Reveal Your Inner Beauty: Transform, Shine, and Inspire at Our Salon",
        paragraph_two:
          "Let your inner beauty take center stage as we transform, illuminate, and inspire at our welcoming salon ",
        option_one: "Smart Offers",
        option_two: "Consultations",
        option_three: "Scheduling App",
        btn_group: {
          btn1: {
            text: "Know More",
            bg_color: "#046A38",
            color: "ffffff",
            is_enable: true,
          },
        },
      },
      section_5: {
        is_section_enable: true,
        title: "How We Works",
        paragraph: "Bringing Beauty to You: Salon Services at Your Doorstep",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/home/howwe1.jpg",
              card_title: "Understanding Your Unique Style",
              card_paragraph:
                "We kick off by engaging in a one-on-one chat, where we eagerly listen to your preferences, expectations, and personal style. Your input guides our approach, ensuring a tailor-made experience.",
            },
            {
              _id: 2,
              icon: "/assets/home/howwe2.jpg",
              card_title: "Your Personal Consultation",
              card_paragraph:
                "Your journey begins with a personal consultation led by one of our skilled stylists. We work closely with you to recommend services that align with your vision and individuality.",
            },
            {
              _id: 3,
              icon: "/assets/home/howwe3.jpg",
              card_title: "Customized Just for You",
              card_paragraph:
                "Every service is exclusively tailored to suit your hair type, facial features, and desired outcome. Your distinct characteristics shape our approach, resulting in a truly personalized salon adventure.",
            },
            {
              _id: 4,
              icon: "/assets/home/howwe4.jpg",
              card_title: "Booking Made Simple",
              card_paragraph:
                "Scheduling an appointment is a breeze! Whether you prefer online booking or a friendly phone call, we strive to make this process convenient. We even offer same-day appointments for that spontaneous pampering.",
            },
          ],
        },
      },
      section_6: {
        is_section_enable: true,
        title: "service for Business & Personal",
        paragraph:
          "Our salon services include hairstyling, manicures, pedicures, facials, and massages in various locations across the UK and its surrounding areas",
        first_card_image: "/assets/home/trustimg3.png",
        sub_title: "For Business",
        sub_title_two: "For Personal",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_list: "Restaurants",
            },
            {
              _id: 2,
              collection_list: "Spa",
            },
            {
              _id: 3,
              collection_list: "Hotels",
            },
            {
              _id: 4,
              collection_list: "Gym",
            },
            {
              _id: 5,
              collection_list: "Factory",
            },
            {
              _id: 6,
              collection_list: "Malls",
            },
            {
              _id: 7,
              collection_list: "Public sectors",
            },
            {
              _id: 8,
              collection_list: "Hospitals",
            },
          ],
        },
        card_section_two: {
          collection: [
            {
              _id: 1,
              collection_list: "Home Stay",
            },
            {
              _id: 2,
              collection_list: "Local Living",
            },
            {
              _id: 3,
              collection_list: "Hostel",
            },
            {
              _id: 4,
              collection_list: "Cottage",
            },
            {
              _id: 5,
              collection_list: "PG Stay",
            },
          ],
        },
      },
      section_7: {
        is_section_enable: true,
        title: "Our Product Category",
        paragraph:
          "Elevate Your Beauty without Breaking the Bank. Your Beauty, Our Best Value.",
      },
      section_8: {
        is_section_enable: true,
        part_one: {
          title: "Our Values",
          sub_title: "Quality Meets Affordability",
          section_image: "/assets/home/trustimg2.jpg",
          paragraph:
            "Elevate Your Beauty without Breaking the Bank. Your Beauty, Our Best Value.",
          card_section: {
            collection: [
              {
                _id: 1,
                collection_title: "Hygiene Standards",
                collection_paragraph:
                  "We provide Up-to-date Information, Instant Awareness and Convenience.",
              },
              {
                _id: 2,
                collection_title: "Customers Safety",
                collection_paragraph:
                  "From sanitized spaces to mindful practices, we're dedicated to your peace of mind and well-being.",
              },
              {
                _id: 3,
                collection_title: "Instant Updates",
                collection_paragraph:
                  "we keep you updated, always a step ahead for your convenience",
              },
            ],
          },
        },
        part_two: {
          bg_color: "046A38",
          text_color: "ffffff",
          title: "We are an Eco Friendly Salon",
          paragraph: "",
          card_section: {
            collection: [
              {
                _id: 1,
                collection_title: "Eco-conscious",
                collection_paragraph:
                  "We utilize Eco-friendly Salon Products: Our commitment to a sustainable salon experience includes the use of environmentally conscious products.",
              },
              {
                _id: 2,
                collection_title: "Animal-friendly",
                collection_paragraph:
                  "Our dedication to vegan practices extends to our salon services. Our products are entirely free from animal products.",
              },
              {
                _id: 3,
                collection_title: "Cruelty-Free",
                collection_paragraph:
                  "Rest assured, our salon services never involve testing on animals, upholding our cruelty-free ethos.",
              },
            ],
          },
        },
      },
      section_9: {
        is_section_enable: true,
        title: "Frequently Asked Question",
        description: "",
        card_image: "/assets/home/trustimg4.jpg",
      },
      section_10: {
        is_section_enable: true,
        banner: "howwe1",
        title: "Client Testimonials",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        review_card: [
          {
            _id: 1,
            reviewer_pic: "/assets/img/services/testimonialuser1.jpg",
            reviewer_name: "Ariyan Rusov",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 2,
            reviewer_pic: "/assets/img/services/testimonialuser2.jpg",
            reviewer_name: "Darren Valdez",
            rating: "4",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 3,
            reviewer_pic: "/assets/img/services/testimonialuser3.jpg",
            reviewer_name: "Elinor Dunn",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
        ],
      },
      section_11: {
        is_section_enable: true,
        title: "Just a Click Away",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        check_list: [
          {
            _id: 1,
            icons: "/assets/icons/priorityicon14.png",
            title: "Cashless payments",
          },
          {
            _id: 2,
            icons: "/assets/icons/priorityicon15.png",
            title: "Expert Wellness Treatments",
          },
          {
            _id: 3,
            icons: "/assets/icons/priorityicon16.png",
            title: "Relaxing Ambiance",
          },
          {
            _id: 4,
            icons: "/assets/icons/priorityicon17.png",
            title: "In-app live chat support",
          },
        ],
        banner: "/assets/home/just_clickaway.png",
      },
      section_12: {
        is_section_enable: true,
        title: "Blogs & Events",
        description:
          " Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
      },
      footer: {
        bg_color: "046A38",
        section_paragraph_color: "c2c2c2",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        social_media: {
          facebook: {
            is_enable: true,
            facebook_link: "facebook.com",
          },
          instagram: {
            is_enable: true,
            instagram_link: "instagram.com",
          },
          whatsapp: {
            is_enable: true,
            whatsapp_link: "whatsapp.com",
          },
          linkedin: {
            is_enable: true,
            linkedin_link: "linkedin.com",
          },
          youtube: {
            is_enable: true,
            youtube_link: "youtube.com",
          },
          twitter: {
            is_enable: true,
            twitter_link: "twitter.com",
          },
        },
      },
    },
  },
  theme_5: {
    home: {
      header: {
        is_section_enable: "on",
        primary_color: "2fa0ea",
        secondary_color: "efc10b",
        nav: {
          bg_color: "ffffff",
          menu_font_color: "000000",
          menu_font_color_active: "efc10b",
          menu_font_color_hover: "efc10b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "000000",
              bg_color: "ffffff",
              menu_font_color_hover: "efc10b",
              menu_font_color_active: "efc10b",
              btn1_bg_color: "efc10b",
              btn2_bg_color: "efc10b",
              btn1_border_color: "efc10b",
              btn2_border_color: "efc10b",
              btn3_bg_color: "efc10b",
              btn3_border_color: "efc10b",
              menu_font_color: "000000",
              btn1_color: "000000",
              btn2_color: "000000",
              btn3_color: "000000",
              cart_value_color: "ffffff"
            },
            cart_value_color: "ffffff",
            menu_font_color_hover: "efc10b",
            menu_font_color_active: "efc10b",
            btn1_bg_color: "efc10b",
            btn2_bg_color: "efc10b",
            btn1_border_color: "efc10b",
            btn2_border_color: "efc10b",
            btn3_bg_color: "efc10b",
            btn3_border_color: "efc10b",
            menu_font_color: "000000",
            btn1_color: "000000",
            btn2_color: "000000",
            btn3_color: "000000",
            color: "000000"
          },
          btn1_bg_color: "efc10b",
          btn2_bg_color: "efc10b",
          btn1_border_color: "efc10b",
          btn2_border_color: "efc10b",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          btn1_color: "000000",
          btn2_color: "000000",
          btn3_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        btn_group: {
          btn1_text: "Log in",
          btn1_bg_color: "efc10b",
          btn1_color: "000000",
          btn1_border_color: "efc10b",
          btn1_is_enable: true,
          btn2_text: "Sign up",
          btn2_bg_color: "efc10b",
          btn2_color: "000000",
          btn2_border_color: "efc10b",
          btn2_is_enable: true,
          btn3_text: "Quick Order",
          btn3_color: "000000",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          btn3_is_enable: true,
          bg_color: "ffffff",
          menu_font_color_hover: "efc10b",
          menu_font_color_active: "efc10b",
          menu_font_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        bg_color: "ffffff",
        contact: {
          bg_color: "ffffff",
          menu_font_color_hover: "efc10b",
          menu_font_color_active: "efc10b",
          btn1_bg_color: "efc10b",
          btn2_bg_color: "efc10b",
          btn1_border_color: "efc10b",
          btn2_border_color: "efc10b",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          menu_font_color: "000000",
          btn1_color: "000000",
          btn2_color: "000000",
          btn3_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        menu_font_color_hover: "efc10b",
        menu_font_color_active: "efc10b",
        btn1_bg_color: "efc10b",
        btn2_bg_color: "efc10b",
        btn1_border_color: "efc10b",
        btn2_border_color: "efc10b",
        btn3_bg_color: "efc10b",
        btn3_border_color: "efc10b",
        menu_font_color: "000000",
        btn1_color: "000000",
        btn2_color: "000000",
        btn3_color: "000000",
        cart_value_color: "ffffff",
        color: "000000"
      },
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/section-five/section_1.png"
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/section-five/section_2.png"
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/section-five/section_3.png"
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/section-five/section_4.png"
        },
        {
          _id: 5,
          is_section_enable: false,
          icon: "/assets/section-five/section_5.png"
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/section-five/section_6.png"
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/section-five/section_7.png"
        },
        {
          _id: 8,
          is_section_enable: false,
          icon: "/assets/section-five/section_8.png"
        }
      ],
      section_1: {
        is_section_enable: true,
        banner_type: "image",
        banner: "/assets/home_page_single_search.jpg",
        banner_two: "https://api.bestatservices.com/storage/compress_images/65c64bab94fb1.jpeg",
        title: "Welcome to BestatServices",
        paragraph: "Unveil an array of innovative white label software solutions crafted to cater to a diverse range of services, spanning across laundry, cleaning, restaurants, salons, nail care, and barber shops.",
        btn_group: {
          btn1: {
            text: "Search",
            bg_color: "efc10b",
            color: "ffffff",
            placeholder: "Enter Postcode",
            paragraph: "Unveil an array of innovative white label software solutions crafted to cater to a diverse range of services, spanning across laundry, cleaning, restaurants, salons, nail care, and barber shops.",
            title: "Welcome to BestatServices"
          }
        },
        bg_color: "efc10b"
      },
      section_4: {
        is_section_enable: true,
        banner: "/assets/img/offer/offer-vector-bg.png",
        offer_tag: "/assets/img/offer/offer-tag.png",
        picture: "/assets/img/offer/offer-wash-img.png",
        paragraph_one: "* Offer applied only to Registered Users (On Selected Shops)"
      },
      section_5: {
        is_section_enable: true,
        banner: "/assets/img/wash-fold-bg.jpg",
        banner_two: "/assets/img/home/heroimg.png",
        title: "WASH & FOLD",
        wash_title: [
          {
            _id: 1,
            wash_list: "Washed under 30 degrees"
          },
          {
            _id: 2,
            wash_list: "Dried at medium heat"
          },
          {
            _id: 3,
            wash_list: "Customerâ€™s responsibility to make sure all items are washable"
          },
          {
            _id: 4,
            wash_list: "Towels and beddings are excluded and will be charged separately."
          }
        ]
      },
      section_6: {
        is_section_enable: true,
        title: "ABOUT BESTATSERVICES",
        sub_heading: "We believe in innovation, fueling success at every step",
        section_image: "https://api.bestatservices.com/storage/compress_images/65c64ad629988.png",
        background_image_color: "2fa0ea",
        paragraph: "It is one of zest and competence, which drives us to create extraordinary solutions adapted to your specific requirements. We want to be your valued partner on the road to success by committing to excellence.",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "Cost Effective",
              collection_image: "/assets/img/about-section/about-icon-01.png",
              isEnabled: true,
              collection_paragraph: "BestatServices provides quality service with reasonable prices to all."
            },
            {
              _id: 2,
              collection_title: "Ease to Use",
              collection_image: "/assets/img/about-section/about-icon-02.png",
              isEnabled: true,
              collection_paragraph: "User friendly website, Book orders with 1 step."
            },
            {
              _id: 3,
              collection_title: "Faster Service",
              collection_image: "/assets/img/about-section/about-icon-03.png",
              isEnabled: true,
              collection_paragraph: "Our team will complete the Service within 48 hours."
            },
            {
              _id: 4,
              collection_title: "Quality of Service",
              collection_image: "/assets/img/about-section/about-icon-04.png",
              isEnabled: true,
              collection_paragraph: "Our promise is to provide an exceptional service to you."
            }
          ],
          _id: 4,
          collection_title: "Quality of Service",
          collection_image: "/assets/img/about-section/about-icon-04.png",
          isEnabled: true,
          collection_paragraph: "Our promise is to provide a exceptional service to you."
        },
        collection_paragraph: "Our promise is to provide an exceptional service to you.",
        collection_title: "Faster Service"
      },
      section_7: {
        is_section_enable: true,
        title: "Ease to Made Services",
        paragraph1: "BestatServices is a trusted white-label SaaS Software provider for businesses and agencies in this digital age.",
        banner: "https://api.bestatservices.com/storage/compress_images/65c64e69e0a75.png",
        paragraph2: "Our product is compatible with desktops, laptops, Macs, iOS, and Android devices",
        paragraph3: "Keep clients updated on their request status via email and SMS.",
        paragraph4: "Generate invoices for customers and business accounts by sending them printable PDFs or via email.",
        card_section: [
          {
            _id: 1,
            text: "Search for your postal code"
          },
          {
            _id: 2,
            text: "Select a convenient shop"
          },
          {
            _id: 3,
            text: "Add required services in your cart"
          },
          {
            _id: 4,
            text: "Choose a convenient date and time slot"
          },
          {
            text: "Place Order"
          }
        ],
        text: "Place Order"
      },
      section_8: {
        is_section_enable: true,
        banner: "/assets/home/iPhone13.png",
        bg_color: "#FBF2FF",
        placeholder: "Eg:-  +4407816545718",
        banner_two: "/assets/img/home/heroimg.png",
        title: "Download the app",
        paragraph: "With our award-winning app, your laundry and dry cleaning needs are just a tap away",
        mobile_link_section: {
          is_enable: true,
          android_link: "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link: "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8"
        }
      },
      footer: {
        custome_banner_design: {
          is_enable: true
        },
        bg_color: "2fa0ea",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        site_title_en: "Â© Best at Services 2024. All rights reserved. ",
        footer_section: {
          needhelp_section: {
            is_enable: true,
            text: "NEED HELP?"
          },
          policy_section: {
            is_enable: true,
            text: "OUR POLICY"
          },
          quickconnect_section: {
            is_enable: true,
            text: "QUICK CONNECT"
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "Phone",
              value: "+447438823475"
            },
            email: {
              label: "Email",
              value: "sales@bestatservices.com"
            }
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com"
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com"
            },
            whatsapp: {
              is_enable: false,
              whatsapp_link: "whatsapp.com"
            },
            linkedin: {
              is_enable: false,
              linkedin_link: "linkedin.com"
            },
            youtube: {
              is_enable: false,
              youtube_link: "youtube.com"
            },
            twitter: {
              is_enable: false,
              twitter_link: "twitter.com"
            }
          },
          mobile_link_section: {
            is_enable: true,
            android_link: "",
            ios_link: "",
            bg_color: "2fa0ea",
            "email.value": "sales@bestatservices.com",
            "mbl_no.value": "+447438823475",
            site_link: "Â© Best at Services 2024. All rights reserved. "
          }
        },
        ios_link: "",
        android_link: "",
        "email.value": "sales@bestatservices.com",
        "mbl_no.value": "+447438823475",
        site_link: "Â© Best at Services 2024. All rights reserved. "
      }
    }
  },
};
export const shop_data_barber = {
  theme_1: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "assets/img/section/section1.png",
        },
        {},
        // {
        //   _id: 2,
        //   is_section_enable: true,
        //   icon: "assets/img/section/section2.png",
        // },
        {
          _id: 3,
          is_section_enable: true,
          icon: "assets/img/section/section3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "assets/img/section/section4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "assets/img/section/section5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "assets/img/section/section6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "assets/img/section/section7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "assets/img/section/section8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "assets/img/section/section9.png",
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "assets/img/section/section10.png",
        },
      ],
      header: {
        primary_color: "ff0059",
        secondary_color: "ffcb00",
        nav: {
          nav_bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            cart_bg_color: "ffffff",
            cart_icon_bg_color: "f07f1b",
            cart_value_color: "000000",
          },
          contact: {
            contact_is_enable: true,
            number: "+(044) 071012345",
            contact_color: "2e3639",
          },
        },
        btn_group: {
          btn1: {
            text: "Login / Register",
            btn_bg_color: "ffffff",
            btn_color: "1b9db3",
            border_color: "1b9db3",
            btn1_is_enable: true,
          },
        },
      },
      section_1: {
        banner: "assets/img/theme1/girl-hari-dresser.png",
        sub_heading_top: "24/7 Salon Service",
        title: "One Solution For All Your Salon Needs",
        paragraph:
          "The Ultimate Salon Experience Awaits—Indulge in Unmatched Luxury Today!",
        btn_group: {
          btn1: {
            text: "Book now",
            placeholder: "Enter Postalcode",
            bg_color: "1b9db3",
            color: "ffffff",
          },
        },
      },
      section_2: {
        title: "Select Your Time & Location For The Service",
        btn_group: {
          btn1: {
            text: "order now",
            placeholder: "Enter Postcode",
            bg_color: "1b9db3",
            color: "ffffff",
          },
        },
      },
      section_3: {
        is_section_enable: true,
        banner: "assets/theme1/trustimg.png",
        title: "Trust the Expert",
        paragraph:
          "Let your inner beauty take center stage as we transform, illuminate, and inspire at our welcoming salon ",
        sub_heading: "Unique solutions for all your Salon needs",
        check_list: [
          {
            _id: 1,
            title: "Smart Offers",
          },
          {
            _id: 1,
            title: "Consultations",
          },
          {
            _id: 1,
            title: "Scheduling App",
          },
          {
            _id: 1,
            title: "Appointment Reminders & more",
          },
        ],
        btn_group: {
          btn1: {
            text: "Read More",
            bg_color: "#ffcb00",
            color: "ffffff",
            is_enable: true,
          },
        },
      },
      section_4: {
        title: "Experience Beauty and Pampering Like Never Before",
        cards: [
          {
            heading: "Exceptional Service",
            icon: "assets/img/icons/priorityicon1.svg",
            image: "assets/img/home/priorityimg1.jpg",
            paragraph:
              "Experience a level of service that goes beyond expectations, leaving you refreshed and rejuvenated after every visit.",
          },
          {
            heading: "Professionalism",
            icon: "assets/img/icons/priorityicon2.svg",
            image: "assets/img/home/priorityimg2.jpg",
            paragraph:
              "Your salon visit reflects our commitment to professionalism—delivering top-notch service with a touch of class and expertise.",
          },
          {
            heading: "Value",
            icon: "assets/img/icons/priorityicon3.svg",
            image: "assets/img/home/priorityimg3.jpg",
            paragraph:
              "Experience a perfect blend of quality service, affordability, and a sense of well-being @ our Salon.",
          },
        ],
      },
      section_5: {
        is_section_enable: true,
        banner: "assets/img/phone.png",
        title: "Just A Click Away",
        paragraph:
          "Our apps got a better and convenient way of getting our 5-star services. Download now and register to get more exclusive offers",
        check_list: [
          {
            _id: 1,
            icons: "assets/img/icons/priorityicon1.svg",
            title: "Users",
            count: "25000",
          },
          {
            _id: 2,
            icons: "assets/img/icons/priorityicon2.svg",
            title: "Downloads",
            count: "40000",
          },
          {
            _id: 3,
            icons: "assets/img/icons/priorityicon3.svg",
            title: "Ratings",
            count: "4.5",
          },
        ],
        mobile_link_section: {
          is_enable: true,
          android_link:
            "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link:
            "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
        },
      },
      section_6: {
        heading:
          "Your Path to Radiant Beauty Begins Here, at Our Salon Sanctuary",
        card_1: {
          card_one_title: "Hair Services",
          card_one_paragraph:
            "Step into a world where your hair's potential is unlocked through expert styling and transformative treatments. From timeless cuts that accentuate your features to cutting-edge styles that make a statement, our skilled stylists craft hair artistry tailored to your personality. Experience the beauty of each strand, where every visit is a brushstroke of elegance and innovation.",
          card_one_image: "assets/img/home/wing1.png",
        },
        card_2: {
          card_two_title: "Skincare Services",
          card_two_paragraph:
            "Revel in a sanctuary of self-care as our dedicated skincare professionals nurture your skin to its ultimate glow. Our skincare services embrace your unique complexion, providing tailored treatments that rejuvenate, hydrate, and bring out your inner radiance. Immerse yourself in a journey of wellness and transformation, where every treatment is a harmonious blend of science, art, and personal attention",
          card_two_image: "assets/img/home/wing2.png",
        },
      },
      section_8: {
        title: "What Our Client Say",
        cards: [
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg1.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser2.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg2.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser3.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg3.jpg",
          },
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg1.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser2.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg2.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser3.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg3.jpg",
          },
        ],
      },
      section_10: {
        title: "Latest News & Events",
        button: {
          text: "View More",
          bg_color: "#ffcb00",
          color: "ffffff",
          is_enable: true,
        },
      },
      section_7: {
        is_section_enable: true,
        title: "Salon on Wheels: Your Beauty Destination, Anywhere You Are",
      },
      section_9: {
        is_section_enable: true,
        title: "Frequently Asked Questions",
        paragraph:
          "Delight in luxury with our salon's top-tier treatments, meticulously curated to enhance your unique beauty.",
        banner: [
          {
            banner: "assets/img/theme1/faqimg.jpg",
          },
        ],
        card_heading: "CALL US FOR A FREE QUOTE ON:",
        card_number: "+(044) 071012345",
        card_buttontext: "Enquire Now",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title:
                "What are the types of hair services offered at Best@Salon?",
              isEnabled: true,
              collection_paragraph:
                "Best@Salon offers a range of hair services including cuts, styling, coloring, extensions, and more.",
            },
            {
              _id: 2,
              collection_title:
                "Is Best@Salon suitable for individuals with sensitive skin or specific allergies?",
              isEnabled: true,
              collection_paragraph:
                "Absolutely, our salon accommodates sensitive skin and specific allergies, ensuring a safe and tailored experience.",
            },
            {
              _id: 3,
              collection_title: "How can I book an appointment at Best@Salon?",
              isEnabled: true,
              collection_paragraph:
                "Booking an appointment is quick and easy - either call us directly or book online through our website for your convenience.",
            },
          ],
        },
      },
      footer: {
        bg_color: "ffffff",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
      },
    },
  },
  theme_2: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/section-two/section1.png",
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/section-two/section2.png",
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/section-two/section3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/section-two/section4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "/assets/section-two/section5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/section-two/section6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/section-two/section7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "/assets/section-two/section8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "/assets/section-two/section9.png",
        },
      ],
      header: {
        primary_color: "ff0059",
        secondary_color: "ffcb00",
        nav: {
          nav_bg_color: "ffffff",
          header_bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
        },
        btn_group: {
          btn1: {
            text: "Continue to order",
            btn_bg_color: "ff0059",
            btn_color: "ffffff",
          },
          btn2: {
            btn2_text: "Order Now",
            btn2_bg_color: "ffcb00",
            btn2_color: "2e3639",
          },
        },
      },
      section_1: {
        title_1: "Solution for your",
        title_2: "Salon Service",
        title_3:
          "Unveil Your Best Self: Your Choices, Perfected by Our Expertise.",
        card_section: [
          "/assets/img/theme_2/banner-slider-01.jpg",
          "/assets/img/theme_2/banner-slider-02.jpg",
          "/assets/img/theme_2/banner-slider-03.jpg",
          "/assets/img/theme_2/banner-slider-04.jpg",
          "/assets/img/theme_2/banner-slider-05.jpg",
        ],
      },
      section_2: {
        is_section_enable: true,
        banner: "/assets/img/trustimg2.png",
        sub_heading_top: "Our Introduction",
        title: "Welcome",
        mainparagraph:
          "Step into a world of luxury and allure. Our salon is your haven for unparalleled beauty and rejuvenating pampering that redefines elegance.",
        card_section_one: [
          {
            _id: 1,
            text: "Dry Cleaning",
            count: "12K",
          },
          {
            _id: 2,
            text: "Satisfied Clients",
            count: "20K",
          },
          {
            _id: 3,
            text: "Happy Customers",
            count: "8K",
          },
        ],
        card_section_title: "Top rated laundry",
        rating: "5",
        card_sub_text: "(30k Reviews)",
        card_section_two: [
          {
            _id: 1,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "Claim Support",
            card_title: "Instant Updates",
            card_paragraph:
              "we keep you updated, always a step ahead for your convenience.",
          },
          {
            _id: 2,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "100% Reliable",
            card_title: "Customers Safety",
            card_paragraph:
              "From sanitized spaces to mindful practices, we're dedicated to your peace of mind and well-being.",
          },
          {
            _id: 3,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "Consultation",
            card_title: "Hygiene Standards",
            card_paragraph:
              "We provide Up-to-date Information, Instant Awareness and Convenience.",
          },
        ],
      },
      section_3: {
        cards: [
          {
            bg_img: "/assets/img/home/wing1.png",
            card_title: "Understanding Your Unique Style",
            card_paragraph:
              "We kick off by engaging in a one-on-one chat, where we eagerly listen to your preferences, expectations, and personal style. Your input guides our approach, ensuring a tailor-made experience.",
              services: ["Laundry", "Dry Cleaning", "Ironing"],
            card_main_title: "Your Personal Consultation",
            card_main_paragraph:
              "Your journey begins with a personal consultation led by one of our skilled stylists. We work closely with you to recommend services that align with your vision and individuality.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/home/wing2.png",
            card_title: "Customized Just for You",
            card_paragraph:
              "Every service is exclusively tailored to suit your hair type, facial features, and desired outcome. Your distinct characteristics shape our approach, resulting in a truly personalized salon adventure.",
              services: [
                "Pay Online in Seconds",
                "Eco-Friendly",
                "Save Time & Money",  
            ],
            card_main_title: "Booking Made Simple",
            card_main_paragraph:
              "Scheduling an appointment is a breeze! Whether you prefer online booking or a friendly phone call, we strive to make this process convenient. We even offer same-day appointments for that spontaneous pampering.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/home/wing3.png",
            card_title: "Welcoming You In",
            card_paragraph:
              "As you step into our salon, expect a warm and inviting atmosphere. Our team is ready to guide you through your visit, making you feel right at home from the very start.",
              services: ["Competitive Price", "5 Star Rated"],
            card_main_title: "Crafting Beauty, Just for You",
            card_main_paragraph:
              "Our skilled professionals meticulously carry out your chosen services, focusing on your unique needs and desires. Your satisfaction is our primary goal, and our expertise is at your service.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/home/wing4.png",
            card_title: "Unwinding in Your Personal Space",
            card_paragraph:
              "Enjoy the soothing ambiance and any extra amenities we provide as you indulge in a rejuvenating experience crafted exclusively for you.",
              services: ["24h turnaround time", "Easy to reschedule"],
            card_main_title: "Your Feedback Matters",
            card_main_paragraph:
              "We value your thoughts and opinions. After your session, we encourage your feedback, allowing us to adjust and improve to ensure your complete delight with our services.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Best Quality",  
            ],
          },
        ],
      },
      section_4: {},
      section_5: {
        title: "Compare our Services",
        cards: [
          {
            card_title: "",
            icon: "/assets/img/icons/serviceicon2.svg",
            card_options: [
              "What is included",
              "Typically included items",
              "Service time",
              "Delivery",
              "Items returned",
              "Eco friendly",
            ],
          },
          {
            card_title: "Laundry Services",
            icon: "/assets/img/icons/serviceicon2.svg",
            card_options: [
              "Wash + Tumble-Dry",
              "T-shirts, trousers, bedsheets.",
              "Service time 24h*",
              "Delivery FREE",
              "Items returned in a bag",
              "Eco friendly",
            ],
          },
          {
            card_title: "Dry Cleaning Services",
            icon: "/assets/img/icons/serviceicon3.svg",
            card_options: [
              "Dry Cleaning + Ironing",
              "Suits, shirts, dresses and coats.",
              "Service time 24h*",
              "Delivery FREE",
              "Items returned in a bag",
              "Eco friendly",
            ],
          },
          {
            card_title: "Ironing Services",
            icon: "/assets/img/icons/serviceicon4.svg",
            card_options: [
              "Ironing",
              "Shirts, t-shirts and bedsheets.",
              "Service time 24h*",
              "Delivery FREE",
              "Items returned in a bag",
              "Eco friendly",
            ],
          },
        ],
      },
      section_6: {
        collection: [
          {
            collection_title: "Do you have a re-cleaning Policy?",
            collection_paragraph:
              "Although we have quality control where we check each garment before we pack, if we are unable to remove stains we usually inform customers before. If something slips through and you are not completely satisfied with the quality of our dry cleaning, we will re-clean your item free of charge, as long as it’s within 24 hours.",
          },
          {
            collection_title: "Do you have a loss policy?",
            collection_paragraph:
              "We take full responsibility for the clothes from the moment we issue a ticket or pick up from you. Even though we exercise the utmost care, there will be instances where items may get misplaced or lost. Should you have reason to believe that we have lost or misplaced your item. Missing items must be reported to us within 24 hours with descriptions so that we can trace your garment. In the unlikely event of losing any of your items, bestatlaundry liability with respect to any lost item shall not exceed ten (10) times our charge for cleaning that garment regardless of brand or condition.",
          },
          {
            collection_title: "Do you have a safe keeping policy?",
            collection_paragraph:
              "We take responsibility to keep your clothes safe for a maximum of 60 days. Should you be unable to pick your clothes within 60 days, due to holiday, illness, etc. please let us know ASAP and we shall arrange to keep your garments safely for an agreed time period. Otherwise, due to storage, we will have to give your clothes to charity.",
          },
          {
            collection_title: "Do you have a privacy statement?",
            collection_paragraph:
              "We respect our customer’s privacy and do not sell any identifiable information regarding our customers to any third party. Any information you give us is held with the utmost care and security, and will not be used in ways to which you have not consented.",
          },
          {
            collection_title: "Do you have a garment damage policy?",
            collection_paragraph:
              "Whilst every care is taken handling your clothes, if you believe that we have damaged your garments in any way please contact as within 24 hours. We will do our best to resolve the situation. We will investigate thoroughly and if we feel that we were negligent we shell pay fair compensation based upon the current value of the garment. This is usually around 6-10 times the cost of the dry cleaning. Our damage policy is in line with the Office of Fair Trades recommendations.",
          },
          {
            collection_title: "How should my garments be cleaned?",
            collection_paragraph:
              "As we are experts at dealing with all garments that come our way. When they arrive at our premises, your garments are examined carefully and then cleaned under the manufacturer’s instructions. Our cleaning is as gentle and delivers better results than traditional dry cleaning. If there’s any doubt or we can’t find a care label, we will clean at what we call “owner’s risk” (as we can sadly not accept any liability for garments where there are no instructions). If we feel there is really too much of a risk to your garment and we cannot get in touch with you, we will return the item to you uncleaned.",
          },
          {
            collection_title: "How long does it take to clean my items?",
            collection_paragraph:
              "We aim to collect your clothes, give them our expert care and attention and deliver them back to you within working 48 hours. There might be some exceptions: In some instances, your preferred delivery slot may not be available. This is mainly because we have already reached full capacity for that time slot, or because we do not visit your area as often as we would like at this moment. We apologise for any inconvenience caused and assure you that we are working very hard to increase our time slot capacity. Some items such as bed linen, curtains, leather, or tailoring may take longer to process as they will be entrusted to various experts. The delivery timing will be provided to you as soon as the respective items have been inspected at our premises. On inspection, we might consider that it will take longer to remove particularly difficult stains or other issues with fabrics. We offer an express service with same-day turnaround* in all our stores however due to logistical constraints thi",
          },
          {
            collection_title:
              "Why can I not select my preferred delivery slot?",
            collection_paragraph:
              "In some instances, your preferred delivery slot may not be available. This could be because we have already reached full capacity for that time slot. We apologize for any inconvenience caused and assure you that we are working very hard to increase our time slot capacity. Should you wish to drop us a line and check to see if we accommodate your chosen slot or to see if there is any cancellation please email info@bestatlaundry.uk",
          },
          {
            collection_title: "How do I order online?",
            collection_paragraph:
              "Simply click Book Now in the top right-hand corner. On the next screen, you will be able to choose a pickup time that we will collect the clothing items from your home/concierge and a dropoff time where we will drop the dry cleaned clothes back to your home/concierge. The next screen is where you can choose your items either select the items directly or choose a category and the system will refine the items to help you choose. Simply choose the quantity and select add to put the item in your basket. When ready click with view basket or the basket in the top right-hand corner of the page you can then check out adding your address and any special requirements. Lastly, you can pay using any credit or debit card using our encrypted service with stripe. We will then process your order and arrive at your requested time slot ready to pick up your items.",
          },
          {
            collection_title: "can I book a regular pickup & delivery?",
            collection_paragraph:
              "Yes! If you want to book a regular pickup and delivery slot each week, just email us to info@bestatlaundry.uk This saves having to deal with laundry and to re-book on a regular basis, and you can cancel at any time if you don’t need a delivery that week. Although it is currently not possible to book a recurring time slot online, you can call our customer care team and we will gladly set this up.",
          },
          {
            collection_title: "How do I know when my driver will arrive?",
            collection_paragraph:
              "We currently offer 1-hour time slots, which allows for traffic and our logistical requirements, if our driver is later than the chosen time slot we will contact you.",
          },
          {
            collection_title: "Why do you not cover my area?",
            collection_paragraph:
              "We are continually growing and expanding and while we might not currently cover your area. Send a message with your details to info@bestatlaundry.uk and as soon as we start to serve your area, we will contact you. You can also mail us to info@bestatlaundry.uk as they will often be able to arrange a special courier or Uber service for you (delivery fees will apply).",
          },
          {
            collection_title: "How will I be charged for the order",
            collection_paragraph:
              "We use a highly secure platform to register your credit or debit card and collect payment. After picking up your clothes, our experts will examine them and determine the total amount of your invoice based on the extent of the work that needs to be carried out on each item (much as a till assistant would do in-store). Until then, you can get an estimate of your order according to our price list here. We are unable to be more exact at this stage because some items require more time and specific treatment, and we can’t know the detail of this before we examine your garments. Once this is done, we will send you an email detailing your invoice, item by item, and charge your card on that basis. If you disagree with anything that we have detailed on your invoice, please feel free email us to info@bestatlaundry.uk and we will be happy to look into this for you. Kindly note that we cannot accept cash payment for deliveries.",
          },
          {
            collection_title: "What does owner's risk mean?",
            collection_paragraph:
              "Some fragile or highly sensitive garments inherently have a risk in cleaning, this could be due to fabric, age, colour, pre-existing damage etc. thus meaning there is a risk that they could be damaged in the cleaning process, in this instance we will refrain from cleaning the garment in question. We will make an attempt to contact you and advise you of the potential risks associated with proceeding with the treatment. Note that if you authorise us to proceed, it will imply that you assume full responsibility for any damage to the garments. In certain circumstances, we may still return the garment and advise not to clean it as damage is inevitable.",
          },
          {
            collection_title:
              "can we dry clean wedding dresses and other bespoke items?",
            collection_paragraph:
              "We are the experts at cleaning these pieces. Please note that the sooner you bring your dress in after your wedding, the better the results we can achieve, as leaving little time for stains to set helps us to get that better finish. We can also clean other bespoke items – just ask one of your store assistants for a quote, email us info@bestatlaundry.uk if you would like more information or to ask for a quote. Kindly note that we assess all those very specific items before cleaning to ensure that we have spotted and highlighted all the potential risks to you before proceeding with the clean.",
          },
        ],
      },
      section_7: {
        title_1: "Latest Testimonial",
        title_2: "What Our Client Say",
        cards: [
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser2.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser3.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser2.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            profile: "/assets/img/services/testimonialuser3.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
        ],
      },
      section_8: {
        title_1: "ARTICLES",
        title_2: "Latest News",
      },
      section_9: {
        title: "We're available on mobile Just download it!",
        paragraph:
          "Download the our app and request our laundry service. Your clothes washed, folded and delivered to your doorstep. So go ahead and say yes to more time with the family, more happy hours, and more slapping the snooze button every morning — we've got laundry day covered.",
        android_link:
          "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
        ios_link:
          "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
      },
      footer: {
        custome_banner_design: {
          is_enable: true,
        },
        bg_color: "151e2e",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          about_section: {
            is_enable: true,
            text: "Ready to take laundry off your to-do list? Bestatlaundry services is a local laundry pickup and delivery service that you can order directly from your smart phone.",
          },
          service_section: {
            is_enable: true,
          },
          quicklink_section: {
            is_enable: true,
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "+442086388690",
              value: "tel:+442086388690",
            },
            email: {
              label: "Email Us",
              value: "sales@bestatlaundry.com",
            },
            address: {
              text: "81 Randall Drive, Hornchurch - RM126TA",
            },
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
      },
    },
  },
  theme_3: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "assets/section/section1.jpg",
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "assets/section/section2.jpg",
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "assets/section/section3.jpg",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "assets/section/section4.jpg",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "assets/section/section5.jpg",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "assets/section/section6.jpg",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "assets/section/section7.jpg",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "assets/section/section8.jpg",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "assets/section/section9.jpg",
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "assets/section/section10.jpg",
        },
        {
          _id: 11,
          is_section_enable: true,
          icon: "assets/section/section11.jpg",
        },
        {
          _id: 12,
          is_section_enable: true,
          icon: "assets/section/section12.jpg",
        },
        {
          _id: 13,
          is_section_enable: true,
          icon: "assets/section/section13.jpg",
        },
        {
          _id: 14,
          is_section_enable: true,
          icon: "assets/section/section14.jpg",
        },
        {
          _id: 15,
          is_section_enable: true,
          icon: "assets/section/section15.jpg",
        },
      ],
      header: {
        is_section_enable: true,
        primary_color: "1B9DB3",
        secondary_color: "F07F1B",
        nav: {
          bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "#6b7385",
            },
            cart_value_color: "000000",
          },
        },
        btn_group: {
          btn1: {
            text: "Login / Register",
            bg_color: "ffffff",
            color: "#1b9db3",
            border_color: "#1b9db3",
            btn1_is_enable: true,
          },
          btn2: {
            text: "Quick Order",
            color: "ffffff",
            bg_color: "000",
            border_color: "#192335",
            btn2_is_enable: true,
          },
        },
      },
      section_1: {
        is_section_enable: true,
        banner: "/assets/img/services/barber/home_page_single_search.jpg",
        sub_heading_top: "24/7 Barber Service",
        title: "Refine Your Style at the UK's Foremost Barber Destination",
        paragraph:
          "Experience superior grooming in the heart of the UK, where tradition meets modern sophistication for flawless style.",
        btn_group: {
          btn1: {
            text: "Request a Schedule",
            bg_color: "1b9db3",
            color: "ffffff",
          },
          btn2: {
            text: "Book now",
            bg_color: "F07F1B",
            color: "ffffff",
          },
        },
        mobile_link_section: {
          is_enable: true,
          mob_title: "Just A Click Away",
          android_link: "#",
          ios_link: "#",
        },
      },
      section_2: {
        is_section_enable: true,
        sub_heading_top: "Book A Service",
        title: "Book Your Preferred Appointment Slot & Venue",
        btn_group: {
          btn1: {
            text: "order now",
            bg_color: "#1b9db3",
            color: "ffffff",
          },
        },
      },
      section_3: {
        is_section_enable: true,
        sub_heading_top: "Exceptional Barbering",
        title: "Timeless Styles for the Modern Gentleman",
        paragraph:
          "Experience the fusion of classic grooming techniques with a modern twist, honoring tradition while embracing contemporary styles.",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/img/services/priorityicon1.png",
              collection_title: "Barbershop Atmosphere",
              collection_paragraph:
                "Our barbershop ambiance caters to a range of preferences, whether it's a modern, classic, or distinctive setting, ensuring a unique and comfortable experience for our clients.",
            },
            {
              _id: 2,
              icon: "/assets/img/services/priorityicon2.png",
              collection_title: "Hair Styling",
              collection_paragraph:
                "Our skilled stylists craft versatile hairstyles tailored to suit any occasion, ranging from timeless classic cuts to the latest modern trends. Whether it's a formal event or a casual outing, our expertise ensures your hairstyle compliments your personality and style.",
            },
            {
              _id: 3,
              icon: "/assets/img/services/priorityicon3.png",
              collection_title: "Grooming",
              collection_paragraph:
                "Experience meticulous precision in beard care with our precision trims and sculpting techniques. We specialize in refining facial appearance, ensuring well-groomed and neatly shaped beards that accentuate your facial features.",
            },
            {
              _id: 4,
              icon: "/assets/img/services/priorityicon4.png",
              collection_title: "Shaving Services",
              collection_paragraph:
                "Our shaving services blend traditional hot towel shaves with modern grooming techniques. Our barbers provide a luxurious and comfortable shaving experience, leaving your skin feeling smooth and rejuvenated.",
            },
            {
              _id: 5,
              icon: "/assets/img/services/priorityicon6.png",
              collection_title: "Scalp Treatments",
              collection_paragraph:
                "Treat your scalp to nourishing care that revitalizes your hair and promotes scalp health. Our specialized treatments aim to rejuvenate the scalp, addressing concerns while ensuring healthy, luscious hair.",
            },
            {
              _id: 6,
              icon: "/assets/img/services/priorityicon5.png",
              collection_title: "Hair Color Services",
              collection_paragraph:
                "Our hair color services offer versatile transformations. From complete color changes to highlights/lowlights for depth, techniques like balayage/ombre ensure natural transitions. Root touch-ups seamlessly blend new growth",
            },
          ],
          btn_group: {
            btn1: {
              text: "More About Best@barber",
              bg_color: "192335",
              color: "ffffff",
            },
          },
        },
      },
      section_4: {
        is_section_enable: true,
        title:
          "BestatBarber - The UK's Top Destination for Superior Haircuts and Grooming!",
      },
      section_5: {
        is_section_enable: true,
        banner: [
          {
            banner:
              "/assets/img/services/salon/client-doing-hair-cut-barber-shop-salon.jpg",
          },
        ],
        title: "Book Your Style Upgrade Now",
        paragraph:
          "Our passion lies in refining your look and crafting personalized styles. Our goal is to create a distinctive style that makes you feel confident, comfortable, and truly yourself.",
          check_list: [
            {
              _id: 1,
              list: "Personalized Consultations",
            },
            {
              _id: 2,
              list: "Eco-Conscious Grooming",
            },
            {
              _id: 3,
              list: "Advanced barber solutions",
            },
            {
              _id: 4,
              list: "Client-focused Service",
            },
            {
              _id: 5,
              list: "Adherence to UK safety standards",
            },
            {
              _id: 6,
              list: "Clear and Upfront Pricing",
            },
          ],
        btn_group: {
          btn1: {
            text: "Schedule a Appointment Now",
            bg_color: "#192335",
            color: "ffffff",
            is_enable: true,
          },
          btn2: {
            text: "Request a schedule",
            bg_color: "#1b9db3",
            color: "ffffff",
            is_enable: true,
          },
        },
      },
      section_6: {
        is_section_enable: true,
        sub_heading_top: "Know More On",
        title: "This is How We Work",
        paragraph:
          "Adopting Our Unique Approach to Exceptional Barber Services",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/img/services/salon/female-hairdresser-using-hairbrush-hair-dryer.jpg",
              card_title: "Explore Our Offerings",
              card_paragraph:
                "Discover a diverse array of haircut styles, grooming services, and trends showcased on our intuitive platform, inspiring your next distinctive look.",
            },
            {
              _id: 2,
              icon: "/assets/img/services/salon/pexels-cottonbro-studio-3992875.jpg",
              card_title: "Choose Your Desired Service",
              card_paragraph:
                "Whether it's a precise haircut, a beard trim, or a complete grooming session, select the service that aligns with your style and preferences.",
            },
            {
              _id: 3,
              icon: "/assets/img/services/salon/professional-makeup-artist-working-with-beautiful-young-woman-man-female-proffesion-gender-equality-concept.jpg",
              card_title: "Personalize Your Style",
              card_paragraph:
                "Customize your grooming experience, from specific styles to hair lengths, ensuring every detail matches your individual taste.",
            },
            {
              _id: 4,
              icon: "/assets/img/services/salon/young-man-barbershop-trimming-hair.jpg",
              card_title: "Schedule Your Appointment",
              card_paragraph:
                "Effortlessly book your appointment online, selecting your preferred date, time, and location. Our flexible scheduling aims to seamlessly accommodate your busy routine.",
            },
          ],
          btn_group: {
            paragraph: "Ready for a New Look? Book Your Appointment Today!",
            btn1: {
              text: "Book Online Now",
              bg_color: "#192335",
              color: "#ffffff",
            },
          },
        },
      },
      section_7: {
        is_section_enable: true,
        title: "Barber services at your beck and call.",
        paragraph:
          "We offer a spectrum of services, from Haircuts, Beard Care, Buzz Cuts and Hot Towel Shave to specialized treatments like facials, massages, and grooming for special occasions. ",
        btn_group: {
          btn_paragraph: "Lorem Ipsum is simply dummy text of the",
          btn1: {
            text: " Request a Schedule",
            bg_color: "192335",
            color: "fff",
          },
        },
      },
      section_8: {
        is_section_enable: true,
        banner: "howwe1",
        title: "About Best@barber",
        section_image:
          "/assets/img/services/salon/pexels-thgusstavo-santana-1813272.jpg",
        paragraph:
          "With a dedication to raising the standard of excellence, BestatBarber embraces the craft of grooming. With a passion for precision and style, we curate exceptional grooming experiences tailored to you.",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "Premium Product Selection",
              collection_image: "/assets/img/services/priorityicon7.png",
              isEnabled: true,
              collection_paragraph:
                "Using high-quality grooming products curated for efficacy, ensuring a luxurious experience and optimal care for your hair and skin.",
            },
            {
              _id: 2,
              collection_title: "Impeccable Hygiene",
              collection_image: "/assets/img/services/priorityicon8.png",
              isEnabled: true,
              collection_paragraph:
                "Adhering rigorously to health protocols, ensuring flawless cleanliness throughout every grooming session.",
            },
            {
              _id: 3,
              collection_title: "Customized Services",
              collection_image: "/assets/img/services/priorityicon9.png",
              isEnabled: true,
              collection_paragraph:
                "Tailoring service packages to accommodate diverse schedules and preferences across the UK, ensuring a personalized grooming journey for every client.",
            },
          ],
        },
        btn_group: {
          btn1_text: "Schedule a Appointment Now",
          btn1_bg_color: "192335",
          btn1_color: "ffffff",
          btn2_text: "Request a Schedule",
          btn2_bg_color: "1b9db3",
          btn2_color: "ffffff",
        },
      },
      section_9: {
        is_section_enable: true,
        section_bg_color: "#1b9db3",
        sub_heading_top: "Book Now",
        title: "Crafting Confidence, Elegance Redefined",
        paragraph: "Your Look Evolves into Timeless Style",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/img/services/salon/man-barbershop-salon-doing-haircut-beard-trim.jpg",
              collection_title: "Versatile Hairstyling",
              collection_paragraph:
                "From classic cuts to contemporary trends, our skilled stylists tailor hairstyles for any occasion, ensuring they reflect your personality and style, be it formal or casual.",
            },
            {
              _id: 2,
              icon: "/assets/img/services/salon/pexels-anete-lusina-5240817.jpg",
              collection_title: "Precision Grooming",
              collection_paragraph:
                "Experience meticulous beard care with precise trims and sculpting techniques, enhancing facial appearance for a polished look that complements your features.",
            },
            {
              _id: 3,
              icon: "/assets/img/services/salon/pexels-cottonbro-studio-3992870.jpg",
              collection_title: "Transformative Hair Color",
              collection_paragraph:
                "Our services offer versatile transformations, from complete color changes to natural-looking highlights/lowlights, ensuring seamless transitions and root touch-ups.",
            },
            {
              _id: 14,
              icon: "/assets/img/services/salon/professional-makeup-artist-working-with-beautiful-young-woman-man-female-proffesion-gender-equality-concept.jpg",
              collection_title: "Facial Rejuvenation",
              collection_paragraph:
                "Indulge in revitalizing treatments and anti-aging procedures for a natural glow and refreshed appearance, addressing specific skin concerns for youthful radiance.",
            },
          ],
          count_section: [
            {
              _id: 1,
              icon: "/assets/img/services/priorityicon1.png",
              text: "Expert Wellness Treatments",
              count: "1639+",
            },
            {
              _id: 2,
              icon: "/assets/img/services/priorityicon2.png",
              text: "Exceptional Client Care",
              count: "24359+",
            },
            {
              _id: 3,
              icon: "/assets/img/services/priorityicon3.png",
              text: "Relaxing Ambiance",
              count: "4793+",
            },
            {
              _id: 4,
              icon: "/assets/img/services/priorityicon4.png",
              text: "Expert Skincare Services",
              count: "25+",
            },
          ],
        },
      },
      section_10: {
        is_section_enable: true,
        banner: "/assets/img/services/salon/pexels-thgusstavo-santana-1813272.jpg",
        sub_heading_top: "Barbering with Safety at the Forefront",
        title: "Our Values",
        mainparagraph: "",
        list_Section: [
          {
            _id: 1,
            text: "Stringent Sanitization Measures",
            paragraph:
              "Our meticulous cleaning protocols maintain a pristine environment, prioritizing your safety and comfort throughout your grooming experience.",
          },
          {
            _id: 2,
            text: "Certified Hygiene Practices",
            paragraph:
              "Committed to upholding certified hygiene standards, we create a safe and hygienic space exclusively for your grooming needs.",
          },
          {
            _id: 3,
            text: "Sterilized Tool Implementation",
            paragraph:
              "Utilizing sterilized tools for every service ensures the highest hygiene and safety standards during your grooming sessions.",
          },
        ],
        card_section_title: "We are an Eco Friendly Barber",
        card_section: [
          {
            _id: 1,
            card_bg_color: "192335",
            card_text: "Cruelty-Free Practices",
            card_paragraph:
              "Our grooming products are developed through cruelty-free practices, affirming our dedication to ethical and responsible sourcing.",
          },
          {
            _id: 2,
            card_bg_color: "f07f1b",
            card_text: "Recycling Initiatives",
            card_paragraph:
              "We actively participate in recycling programs, encouraging responsible disposal of packaging materials and promoting a circular approach to product life cycles.",
          },
          {
            _id: 3,
            card_bg_color: "1bb333",
            card_text: "Carbon-Neutral Commitment",
            card_paragraph:
              "In our pursuit of environmental consciousness, we work towards carbon neutrality, offsetting our carbon footprint through sustainable initiatives and practices.",
          },
        ],
      },
      section_11: {
        is_section_enable: true,
        banner: [
          {
            banner:
              "/assets/img/services/salon/female-hairdresser-using-hairbrush-hair-dryer.jpg",
          },
        ],
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title:
                "What hairstyles are currently trending, and how can I choose the best one for me?",
              isEnabled: true,
              collection_paragraph:
                "Our barbers stay updated on the latest trends, ensuring you're informed about popular styles that suit your face shape and personal preferences.",
            },
            {
              _id: 2,
              collection_title:
                "I'm not sure which grooming service I need. Can I get personalized advice?",
              isEnabled: true,
              collection_paragraph:
                "Absolutely! Our barbers offer consultations to understand your requirements, recommending services tailored to your needs, whether it's a haircut, beard care, or a styling session.",
            },
            {
              _id: 3,
              collection_title:
                "Do you have specific grooming packages for special occasions or events?",
              isEnabled: true,
              collection_paragraph:
                "Yes, we curate special grooming packages tailored for weddings, formal events, or special occasions, ensuring you look your best for those important moments.",
            },
            {
              _id: 4,
              collection_title:
                "What hygiene standards do you maintain, especially in light of current health concerns?",
              isEnabled: true,
              collection_paragraph:
                "We adhere to rigorous sanitization protocols, ensuring a clean and safe environment. All tools and surfaces undergo strict cleaning routines to prioritize your safety.",
            },
            {
              _id: 5,
              collection_title:
                "Can I get advice on hair and beard care between salon visits?",
              isEnabled: true,
              collection_paragraph:
                "Of course! Our barbers provide guidance on at-home care, recommending products and routines to maintain your hairstyle and beard between appointments.",
            },
          ],
        },
        sub_heading_top: "Ask Your Question",
        title: "Frequently Asked Questions",
        paragraph:
          "Elevate Your Beauty without Breaking the Bank. Your Beauty, Our Best Value.",
      },
      section_12: {
        is_section_enable: true,
        banner: "howwe1",
        sub_heading_top: "What Our Client Says",
        title: "Commitment to Client Satisfaction",
        paragraph: "Read what our customers say about Best@barber",
        review_bottom_paragraph:
          "More than 5,000+ reviews s on TrustPilot, Google, Google Play & App Store",
        review_card: [
          {
            _id: 1,
            reviewer_pic: "/assets/img/services/testimonialuser1.jpg",
            reviewer_name: "Ariyan Rusov",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 2,
            reviewer_pic: "/assets/img/services/testimonialuser2.jpg",
            reviewer_name: "Darren Valdez",
            rating: "4",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 3,
            reviewer_pic: "/assets/img/services/testimonialuser3.jpg",
            reviewer_name: "Elinor Dunn",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
        ],
        btn_group: {
          btn1: {
            text: "Book Online Now",
            bg_color: "#192335",
            color: "#ffffff",
            is_enable: true,
          },
        },
      },
      section_13: {
        is_section_enable: true,
        banner: "howwe1",
        title: "Just A Click Away",
        bannerimg: "/assets/img/services/salon/mob.png",
        paragraph: "Download Our Best Active Mobile App",
        check_list: [
          {
            _id: 1,
            icons: "/assets/img/services/priorityicon14.png",
            title: "Cashless payments",
          },
          {
            _id: 2,
            icons: "/assets/img/services/priorityicon15.png",
            title: "Instant Updates",
          },
          {
            _id: 3,
            icons: "/assets/img/services/priorityicon16.png",
            title: "Hygiene Standards",
          },
          {
            _id: 4,
            icons: "/assets/img/services/priorityicon17.png",
            title: "Eco-conscious",
          },
        ],
        mobile_link_section: {
          is_enable: true,
          android_link: "https://play.google.com/store/apps/",
          ios_link: "https://itunes.apple.com/us/app/",
        },
      },
      section_14: {
        is_section_enable: true,
        section_bg_color: "#1b9db3",
        title:
          "Subscribe to our newsletter for updates, exclusive promotions and our latest content!",
        btn_group: {
          btn1: {
            text: "Subscribe",
            bg_color: "#192335",
            color: "ffffff",
          },
        },
      },
      section_15: {
        is_section_enable: true,
        sub_heading_top: "Our Latest",
        title: "News & Events",
        banner1: "/assets/img/services/salon/sec1.jpg",
        banner2: "/assets/img/services/salon/sec2.jpg",
        banner3: "/assets/img/services/salon/sec3.jpg",
        paragraph:
          "Stay in the loop with our barber's pulse! From new service launches and seasonal promotions to special events, we keep you informed about everything.",
        btn_group: {
          btn1: {
            text: "More News & Events",
            bg_color: "#192335",
            color: "ffffff",
          },
        },
      },
      footer: {
        custome_banner_design: {
          is_enable: true,
        },
        bg_color: "151e2e",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          about_section: {
            is_enable: true,
            text: "Ready to take barber off your to-do list? BestatBarber services is a local saloon service that you can book directly from your smart phone.",
          },
          service_section: {
            is_enable: true,
          },
          quicklink_section: {
            is_enable: true,
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "+442086388690",
              value: "tel:+442086388690",
            },
            email: {
              label: "Email Us",
              value: "sales@bestatlaundry.com",
            },
            address: {
              text: "81 Randall Drive, Hornchurch - RM126TA",
            },
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
      },
    },
  },
  theme_4: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_1.png",
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_2.png",
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_9.png",
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_10.png",
        },
        {
          _id: 11,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_11.png",
        },
        {
          _id: 12,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_12.png",
        },
      ],
      header: {
        is_section_enable: true,
        primary_color: "046A38",
        secondary_color: "01190D",
        contact: {
          email_id: "sales@bestatlaundry.com",
          contact_number: "+442086388690",
        },
        nav: {
          bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "#6b7385",
            },
            cart_value_color: "000000",
          },
        },
        btn_group: {
          btn1_text: "Login / Register",
          btn1_bg_color: "046A38",
          btn1_color: "ffffff",
          btn1_border_color: "046A38",
          btn1_is_enable: true,
          btn2_text: "Quick Order",
          btn2_color: "ffffff",
          btn2_bg_color: "000",
          btn2_border_color: "#192335",
          btn2_is_enable: true,
        },
      },
      section_1: {
        is_section_enable: true,
        banner_type: "image",
        banner: "/assets/home_page_single_search.jpg",
        banner_two: "/assets/img/home/heroimg.png",
        sub_heading_top: "24/7 Salon Service",
        title:
          "Discover Your Radiance: Partner with Us for the Salon Experience You Can Trust",
        paragraph: "Select your time & location for the service",
        btn_group: {
          btn1: {
            text: "Order now",
            bg_color: "#046A38",
            color: "ffffff",
            placeholder: "Enter Postcode",
          },
        },
      },
      section_2: {
        is_section_enable: true,
        title: "Trust the Experts",
        paragraph: "Experience Beauty and Pampering Like Never Before",
        first_card_image: "/assets/img/home/trustimg1.jpg",
        image_title: "Why 1000+ customers trust Us?",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/icons/priorityicon1.png",
              collection_title: "Top Quality",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 2,
              icon: "/assets/icons/priorityicon4.png",
              collection_title: "Professionalism",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 3,
              icon: "/assets/icons/priorityicon5.png",
              collection_title: "Convenience",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 4,
              icon: "/assets/icons/priorityicon6.png",
              collection_title: "Cleanliness",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
          ],
        },
      },
      section_3: {
        is_section_enable: true,
        title: "5 star Rated by customers in the UK in categories of Salon",
        banner: "/assets/img/home/review-bg.jpg",
      },
      section_4: {
        is_section_enable: true,
        banner: "/assets/home/trustimg2.jpg",
        title: "About We",
        paragraph_one:
          "Reveal Your Inner Beauty: Transform, Shine, and Inspire at Our Salon",
        paragraph_two:
          "Let your inner beauty take center stage as we transform, illuminate, and inspire at our welcoming salon ",
        option_one: "Smart Offers",
        option_two: "Consultations",
        option_three: "Scheduling App",
        btn_group: {
          btn1: {
            text: "Know More",
            bg_color: "#046A38",
            color: "ffffff",
            is_enable: true,
          },
        },
      },
      section_5: {
        is_section_enable: true,
        title: "How We Works",
        paragraph: "Bringing Beauty to You: Salon Services at Your Doorstep",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/home/howwe1.jpg",
              card_title: "Understanding Your Unique Style",
              card_paragraph:
                "We kick off by engaging in a one-on-one chat, where we eagerly listen to your preferences, expectations, and personal style. Your input guides our approach, ensuring a tailor-made experience.",
            },
            {
              _id: 2,
              icon: "/assets/home/howwe2.jpg",
              card_title: "Your Personal Consultation",
              card_paragraph:
                "Your journey begins with a personal consultation led by one of our skilled stylists. We work closely with you to recommend services that align with your vision and individuality.",
            },
            {
              _id: 3,
              icon: "/assets/home/howwe3.jpg",
              card_title: "Customized Just for You",
              card_paragraph:
                "Every service is exclusively tailored to suit your hair type, facial features, and desired outcome. Your distinct characteristics shape our approach, resulting in a truly personalized salon adventure.",
            },
            {
              _id: 4,
              icon: "/assets/home/howwe4.jpg",
              card_title: "Booking Made Simple",
              card_paragraph:
                "Scheduling an appointment is a breeze! Whether you prefer online booking or a friendly phone call, we strive to make this process convenient. We even offer same-day appointments for that spontaneous pampering.",
            },
          ],
        },
      },
      section_6: {
        is_section_enable: true,
        title: "service for Business & Personal",
        paragraph:
          "Our salon services include hairstyling, manicures, pedicures, facials, and massages in various locations across the UK and its surrounding areas",
        first_card_image: "/assets/home/trustimg3.png",
        sub_title: "For Business",
        sub_title_two: "For Personal",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_list: "Restaurants",
            },
            {
              _id: 2,
              collection_list: "Spa",
            },
            {
              _id: 3,
              collection_list: "Hotels",
            },
            {
              _id: 4,
              collection_list: "Gym",
            },
            {
              _id: 5,
              collection_list: "Factory",
            },
            {
              _id: 6,
              collection_list: "Malls",
            },
            {
              _id: 7,
              collection_list: "Public sectors",
            },
            {
              _id: 8,
              collection_list: "Hospitals",
            },
          ],
        },
        card_section_two: {
          collection: [
            {
              _id: 1,
              collection_list: "Home Stay",
            },
            {
              _id: 2,
              collection_list: "Local Living",
            },
            {
              _id: 3,
              collection_list: "Hostel",
            },
            {
              _id: 4,
              collection_list: "Cottage",
            },
            {
              _id: 5,
              collection_list: "PG Stay",
            },
          ],
        },
      },
      section_7: {
        is_section_enable: true,
        title: "Our Product Category",
        paragraph:
          "Elevate Your Beauty without Breaking the Bank. Your Beauty, Our Best Value.",
      },
      section_8: {
        is_section_enable: true,
        part_one: {
          title: "Our Values",
          sub_title: "Quality Meets Affordability",
          section_image: "/assets/home/trustimg2.jpg",
          paragraph:
            "Elevate Your Beauty without Breaking the Bank. Your Beauty, Our Best Value.",
          card_section: {
            collection: [
              {
                _id: 1,
                collection_title: "Hygiene Standards",
                collection_paragraph:
                  "We provide Up-to-date Information, Instant Awareness and Convenience.",
              },
              {
                _id: 2,
                collection_title: "Customers Safety",
                collection_paragraph:
                  "From sanitized spaces to mindful practices, we're dedicated to your peace of mind and well-being.",
              },
              {
                _id: 3,
                collection_title: "Instant Updates",
                collection_paragraph:
                  "we keep you updated, always a step ahead for your convenience",
              },
            ],
          },
        },
        part_two: {
          bg_color: "046A38",
          text_color: "ffffff",
          title: "We are an Eco Friendly Salon",
          paragraph: "",
          card_section: {
            collection: [
              {
                _id: 1,
                collection_title: "Eco-conscious",
                collection_paragraph:
                  "We utilize Eco-friendly Salon Products: Our commitment to a sustainable salon experience includes the use of environmentally conscious products.",
              },
              {
                _id: 2,
                collection_title: "Animal-friendly",
                collection_paragraph:
                  "Our dedication to vegan practices extends to our salon services. Our products are entirely free from animal products.",
              },
              {
                _id: 3,
                collection_title: "Cruelty-Free",
                collection_paragraph:
                  "Rest assured, our salon services never involve testing on animals, upholding our cruelty-free ethos.",
              },
            ],
          },
        },
      },
      section_9: {
        is_section_enable: true,
        title: "Frequently Asked Question",
        description: "",
        card_image: "/assets/home/trustimg4.jpg",
      },
      section_10: {
        is_section_enable: true,
        banner: "howwe1",
        title: "Client Testimonials",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        review_card: [
          {
            _id: 1,
            reviewer_pic: "/assets/img/services/testimonialuser1.jpg",
            reviewer_name: "Ariyan Rusov",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 2,
            reviewer_pic: "/assets/img/services/testimonialuser2.jpg",
            reviewer_name: "Darren Valdez",
            rating: "4",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 3,
            reviewer_pic: "/assets/img/services/testimonialuser3.jpg",
            reviewer_name: "Elinor Dunn",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
        ],
      },
      section_11: {
        is_section_enable: true,
        title: "Just a Click Away",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        check_list: [
          {
            _id: 1,
            icons: "/assets/icons/priorityicon14.png",
            title: "Cashless payments",
          },
          {
            _id: 2,
            icons: "/assets/icons/priorityicon15.png",
            title: "Expert Wellness Treatments",
          },
          {
            _id: 3,
            icons: "/assets/icons/priorityicon16.png",
            title: "Relaxing Ambiance",
          },
          {
            _id: 4,
            icons: "/assets/icons/priorityicon17.png",
            title: "In-app live chat support",
          },
        ],
        banner: "/assets/home/just_clickaway.png",
      },
      section_12: {
        is_section_enable: true,
        title: "Blogs & Events",
        description:
          " Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
      },
      footer: {
        bg_color: "046A38",
        section_paragraph_color: "c2c2c2",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        social_media: {
          facebook: {
            is_enable: true,
            facebook_link: "facebook.com",
          },
          instagram: {
            is_enable: true,
            instagram_link: "instagram.com",
          },
          whatsapp: {
            is_enable: true,
            whatsapp_link: "whatsapp.com",
          },
          linkedin: {
            is_enable: true,
            linkedin_link: "linkedin.com",
          },
          youtube: {
            is_enable: true,
            youtube_link: "youtube.com",
          },
          twitter: {
            is_enable: true,
            twitter_link: "twitter.com",
          },
        },
      },
    },
  },
  theme_5: {
    home: {
      header: {
        is_section_enable: "on",
        primary_color: "2fa0ea",
        secondary_color: "efc10b",
        nav: {
          bg_color: "ffffff",
          menu_font_color: "000000",
          menu_font_color_active: "efc10b",
          menu_font_color_hover: "efc10b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "000000",
              bg_color: "ffffff",
              menu_font_color_hover: "efc10b",
              menu_font_color_active: "efc10b",
              btn1_bg_color: "efc10b",
              btn2_bg_color: "efc10b",
              btn1_border_color: "efc10b",
              btn2_border_color: "efc10b",
              btn3_bg_color: "efc10b",
              btn3_border_color: "efc10b",
              menu_font_color: "000000",
              btn1_color: "000000",
              btn2_color: "000000",
              btn3_color: "000000",
              cart_value_color: "ffffff"
            },
            cart_value_color: "ffffff",
            menu_font_color_hover: "efc10b",
            menu_font_color_active: "efc10b",
            btn1_bg_color: "efc10b",
            btn2_bg_color: "efc10b",
            btn1_border_color: "efc10b",
            btn2_border_color: "efc10b",
            btn3_bg_color: "efc10b",
            btn3_border_color: "efc10b",
            menu_font_color: "000000",
            btn1_color: "000000",
            btn2_color: "000000",
            btn3_color: "000000",
            color: "000000"
          },
          btn1_bg_color: "efc10b",
          btn2_bg_color: "efc10b",
          btn1_border_color: "efc10b",
          btn2_border_color: "efc10b",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          btn1_color: "000000",
          btn2_color: "000000",
          btn3_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        btn_group: {
          btn1_text: "Log in",
          btn1_bg_color: "efc10b",
          btn1_color: "000000",
          btn1_border_color: "efc10b",
          btn1_is_enable: true,
          btn2_text: "Sign up",
          btn2_bg_color: "efc10b",
          btn2_color: "000000",
          btn2_border_color: "efc10b",
          btn2_is_enable: true,
          btn3_text: "Quick Order",
          btn3_color: "000000",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          btn3_is_enable: true,
          bg_color: "ffffff",
          menu_font_color_hover: "efc10b",
          menu_font_color_active: "efc10b",
          menu_font_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        bg_color: "ffffff",
        contact: {
          bg_color: "ffffff",
          menu_font_color_hover: "efc10b",
          menu_font_color_active: "efc10b",
          btn1_bg_color: "efc10b",
          btn2_bg_color: "efc10b",
          btn1_border_color: "efc10b",
          btn2_border_color: "efc10b",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          menu_font_color: "000000",
          btn1_color: "000000",
          btn2_color: "000000",
          btn3_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        menu_font_color_hover: "efc10b",
        menu_font_color_active: "efc10b",
        btn1_bg_color: "efc10b",
        btn2_bg_color: "efc10b",
        btn1_border_color: "efc10b",
        btn2_border_color: "efc10b",
        btn3_bg_color: "efc10b",
        btn3_border_color: "efc10b",
        menu_font_color: "000000",
        btn1_color: "000000",
        btn2_color: "000000",
        btn3_color: "000000",
        cart_value_color: "ffffff",
        color: "000000"
      },
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/section-five/section_1.png"
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/section-five/section_2.png"
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/section-five/section_3.png"
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/section-five/section_4.png"
        },
        {
          _id: 5,
          is_section_enable: false,
          icon: "/assets/section-five/section_5.png"
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/section-five/section_6.png"
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/section-five/section_7.png"
        },
        {
          _id: 8,
          is_section_enable: false,
          icon: "/assets/section-five/section_8.png"
        }
      ],
      section_1: {
        is_section_enable: true,
        banner_type: "image",
        banner: "/assets/home_page_single_search.jpg",
        banner_two: "https://api.bestatservices.com/storage/compress_images/65c64bab94fb1.jpeg",
        title: "Welcome to BestatServices",
        paragraph: "Unveil an array of innovative white label software solutions crafted to cater to a diverse range of services, spanning across laundry, cleaning, restaurants, salons, nail care, and barber shops.",
        btn_group: {
          btn1: {
            text: "Search",
            bg_color: "efc10b",
            color: "ffffff",
            placeholder: "Enter Postcode",
            paragraph: "Unveil an array of innovative white label software solutions crafted to cater to a diverse range of services, spanning across laundry, cleaning, restaurants, salons, nail care, and barber shops.",
            title: "Welcome to BestatServices"
          }
        },
        bg_color: "efc10b"
      },
      section_4: {
        is_section_enable: true,
        banner: "/assets/img/offer/offer-vector-bg.png",
        offer_tag: "/assets/img/offer/offer-tag.png",
        picture: "/assets/img/offer/offer-wash-img.png",
        paragraph_one: "* Offer applied only to Registered Users (On Selected Shops)"
      },
      section_5: {
        is_section_enable: true,
        banner: "/assets/img/wash-fold-bg.jpg",
        banner_two: "/assets/img/home/heroimg.png",
        title: "WASH & FOLD",
        wash_title: [
          {
            _id: 1,
            wash_list: "Washed under 30 degrees"
          },
          {
            _id: 2,
            wash_list: "Dried at medium heat"
          },
          {
            _id: 3,
            wash_list: "Customerâ€™s responsibility to make sure all items are washable"
          },
          {
            _id: 4,
            wash_list: "Towels and beddings are excluded and will be charged separately."
          }
        ]
      },
      section_6: {
        is_section_enable: true,
        title: "ABOUT BESTATSERVICES",
        sub_heading: "We believe in innovation, fueling success at every step",
        section_image: "https://api.bestatservices.com/storage/compress_images/65c64ad629988.png",
        background_image_color: "2fa0ea",
        paragraph: "It is one of zest and competence, which drives us to create extraordinary solutions adapted to your specific requirements. We want to be your valued partner on the road to success by committing to excellence.",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "Cost Effective",
              collection_image: "/assets/img/about-section/about-icon-01.png",
              isEnabled: true,
              collection_paragraph: "BestatServices provides quality service with reasonable prices to all."
            },
            {
              _id: 2,
              collection_title: "Ease to Use",
              collection_image: "/assets/img/about-section/about-icon-02.png",
              isEnabled: true,
              collection_paragraph: "User friendly website, Book orders with 1 step."
            },
            {
              _id: 3,
              collection_title: "Faster Service",
              collection_image: "/assets/img/about-section/about-icon-03.png",
              isEnabled: true,
              collection_paragraph: "Our team will complete the Service within 48 hours."
            },
            {
              _id: 4,
              collection_title: "Quality of Service",
              collection_image: "/assets/img/about-section/about-icon-04.png",
              isEnabled: true,
              collection_paragraph: "Our promise is to provide an exceptional service to you."
            }
          ],
          _id: 4,
          collection_title: "Quality of Service",
          collection_image: "/assets/img/about-section/about-icon-04.png",
          isEnabled: true,
          collection_paragraph: "Our promise is to provide a exceptional service to you."
        },
        collection_paragraph: "Our promise is to provide an exceptional service to you.",
        collection_title: "Faster Service"
      },
      section_7: {
        is_section_enable: true,
        title: "Ease to Made Services",
        paragraph1: "BestatServices is a trusted white-label SaaS Software provider for businesses and agencies in this digital age.",
        banner: "https://api.bestatservices.com/storage/compress_images/65c64e69e0a75.png",
        paragraph2: "Our product is compatible with desktops, laptops, Macs, iOS, and Android devices",
        paragraph3: "Keep clients updated on their request status via email and SMS.",
        paragraph4: "Generate invoices for customers and business accounts by sending them printable PDFs or via email.",
        card_section: [
          {
            _id: 1,
            text: "Search for your postal code"
          },
          {
            _id: 2,
            text: "Select a convenient shop"
          },
          {
            _id: 3,
            text: "Add required services in your cart"
          },
          {
            _id: 4,
            text: "Choose a convenient date and time slot"
          },
          {
            text: "Place Order"
          }
        ],
        text: "Place Order"
      },
      section_8: {
        is_section_enable: true,
        banner: "/assets/home/iPhone13.png",
        bg_color: "#FBF2FF",
        placeholder: "Eg:-  +4407816545718",
        banner_two: "/assets/img/home/heroimg.png",
        title: "Download the app",
        paragraph: "With our award-winning app, your laundry and dry cleaning needs are just a tap away",
        mobile_link_section: {
          is_enable: true,
          android_link: "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link: "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8"
        }
      },
      footer: {
        custome_banner_design: {
          is_enable: true
        },
        bg_color: "2fa0ea",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        site_title_en: "Â© Best at Services 2024. All rights reserved. ",
        footer_section: {
          needhelp_section: {
            is_enable: true,
            text: "NEED HELP?"
          },
          policy_section: {
            is_enable: true,
            text: "OUR POLICY"
          },
          quickconnect_section: {
            is_enable: true,
            text: "QUICK CONNECT"
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "Phone",
              value: "+447438823475"
            },
            email: {
              label: "Email",
              value: "sales@bestatservices.com"
            }
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com"
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com"
            },
            whatsapp: {
              is_enable: false,
              whatsapp_link: "whatsapp.com"
            },
            linkedin: {
              is_enable: false,
              linkedin_link: "linkedin.com"
            },
            youtube: {
              is_enable: false,
              youtube_link: "youtube.com"
            },
            twitter: {
              is_enable: false,
              twitter_link: "twitter.com"
            }
          },
          mobile_link_section: {
            is_enable: true,
            android_link: "",
            ios_link: "",
            bg_color: "2fa0ea",
            "email.value": "sales@bestatservices.com",
            "mbl_no.value": "+447438823475",
            site_link: "Â© Best at Services 2024. All rights reserved. "
          }
        },
        ios_link: "",
        android_link: "",
        "email.value": "sales@bestatservices.com",
        "mbl_no.value": "+447438823475",
        site_link: "Â© Best at Services 2024. All rights reserved. "
      }
    }
  },
};
export const shop_data_garden = {
  theme_1: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "assets/img/section/section1.png",
        },
        {},
        // {
        //   _id: 2,
        //   is_section_enable: true,
        //   icon: "assets/img/section/section2.png",
        // },
        {
          _id: 3,
          is_section_enable: true,
          icon: "assets/img/section/section3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "assets/img/section/section4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "assets/img/section/section5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "assets/img/section/section6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "assets/img/section/section7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "assets/img/section/section8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "assets/img/section/section9.png",
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "assets/img/section/section10.png",
        },
      ],
      header: {
        primary_color: "ff0059",
        secondary_color: "ffcb00",
        nav: {
          nav_bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            cart_bg_color: "ffffff",
            cart_icon_bg_color: "f07f1b",
            cart_value_color: "000000",
          },
          contact: {
            contact_is_enable: true,
            number: "+(044) 071012345",
            contact_color: "2e3639",
          },
        },
        btn_group: {
          btn1: {
            text: "Login / Register",
            btn_bg_color: "ffffff",
            btn_color: "1b9db3",
            border_color: "1b9db3",
            btn1_is_enable: true,
          },
        },
      },
      section_1: {
        banner: "assets/img/theme1/girl-hari-dresser.png",
        sub_heading_top: "24/7 Salon Service",
        title: "One Solution For All Your Salon Needs",
        paragraph:
          "The Ultimate Salon Experience Awaits—Indulge in Unmatched Luxury Today!",
        btn_group: {
          btn1: {
            text: "Book now",
            placeholder: "Enter Postalcode",
            bg_color: "1b9db3",
            color: "ffffff",
          },
        },
      },
      section_2: {
        title: "Select Your Time & Location For The Service",
        btn_group: {
          btn1: {
            text: "order now",
            placeholder: "Enter Postcode",
            bg_color: "1b9db3",
            color: "ffffff",
          },
        },
      },
      section_3: {
        is_section_enable: true,
        banner: "assets/theme1/trustimg.png",
        title: "Trust the Expert",
        paragraph:
          "Let your inner beauty take center stage as we transform, illuminate, and inspire at our welcoming salon ",
        sub_heading: "Unique solutions for all your Salon needs",
        check_list: [
          {
            _id: 1,
            title: "Smart Offers",
          },
          {
            _id: 1,
            title: "Consultations",
          },
          {
            _id: 1,
            title: "Scheduling App",
          },
          {
            _id: 1,
            title: "Appointment Reminders & more",
          },
        ],
        btn_group: {
          btn1: {
            text: "Read More",
            bg_color: "#ffcb00",
            color: "ffffff",
            is_enable: true,
          },
        },
      },
      section_4: {
        title: "Experience Beauty and Pampering Like Never Before",
        cards: [
          {
            heading: "Exceptional Service",
            icon: "assets/img/icons/priorityicon1.svg",
            image: "assets/img/home/priorityimg1.jpg",
            paragraph:
              "Experience a level of service that goes beyond expectations, leaving you refreshed and rejuvenated after every visit.",
          },
          {
            heading: "Professionalism",
            icon: "assets/img/icons/priorityicon2.svg",
            image: "assets/img/home/priorityimg2.jpg",
            paragraph:
              "Your salon visit reflects our commitment to professionalism—delivering top-notch service with a touch of class and expertise.",
          },
          {
            heading: "Value",
            icon: "assets/img/icons/priorityicon3.svg",
            image: "assets/img/home/priorityimg3.jpg",
            paragraph:
              "Experience a perfect blend of quality service, affordability, and a sense of well-being @ our Salon.",
          },
        ],
      },
      section_5: {
        is_section_enable: true,
        banner: "assets/img/phone.png",
        title: "Just A Click Away",
        paragraph:
          "Our apps got a better and convenient way of getting our 5-star services. Download now and register to get more exclusive offers",
        check_list: [
          {
            _id: 1,
            icons: "assets/img/icons/priorityicon1.svg",
            title: "Users",
            count: "25000",
          },
          {
            _id: 2,
            icons: "assets/img/icons/priorityicon2.svg",
            title: "Downloads",
            count: "40000",
          },
          {
            _id: 3,
            icons: "assets/img/icons/priorityicon3.svg",
            title: "Ratings",
            count: "4.5",
          },
        ],
        mobile_link_section: {
          is_enable: true,
          android_link:
            "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link:
            "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
        },
      },
      section_6: {
        heading:
          "Your Path to Radiant Beauty Begins Here, at Our Salon Sanctuary",
        card_1: {
          card_one_title: "Hair Services",
          card_one_paragraph:
            "Step into a world where your hair's potential is unlocked through expert styling and transformative treatments. From timeless cuts that accentuate your features to cutting-edge styles that make a statement, our skilled stylists craft hair artistry tailored to your personality. Experience the beauty of each strand, where every visit is a brushstroke of elegance and innovation.",
          card_one_image: "assets/img/home/wing1.png",
        },
        card_2: {
          card_two_title: "Skincare Services",
          card_two_paragraph:
            "Revel in a sanctuary of self-care as our dedicated skincare professionals nurture your skin to its ultimate glow. Our skincare services embrace your unique complexion, providing tailored treatments that rejuvenate, hydrate, and bring out your inner radiance. Immerse yourself in a journey of wellness and transformation, where every treatment is a harmonious blend of science, art, and personal attention",
          card_two_image: "assets/img/home/wing2.png",
        },
      },
      section_8: {
        title: "What Our Client Say",
        cards: [
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg1.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser2.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg2.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser3.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg3.jpg",
          },
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg1.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser2.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg2.jpg",
          },
          {
            profile: "assets/img/home/testimonialuser3.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "assets/img/home/testimonialimg3.jpg",
          },
        ],
      },
      section_10: {
        title: "Latest News & Events",
        button: {
          text: "View More",
          bg_color: "#ffcb00",
          color: "ffffff",
          is_enable: true,
        },
      },
      section_7: {
        is_section_enable: true,
        title: "Salon on Wheels: Your Beauty Destination, Anywhere You Are",
      },
      section_9: {
        is_section_enable: true,
        title: "Frequently Asked Questions",
        paragraph:
          "Delight in luxury with our salon's top-tier treatments, meticulously curated to enhance your unique beauty.",
        banner: [
          {
            banner: "assets/img/theme1/faqimg.jpg",
          },
        ],
        card_heading: "CALL US FOR A FREE QUOTE ON:",
        card_number: "+(044) 071012345",
        card_buttontext: "Enquire Now",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title:
                "What are the types of hair services offered at Best@Salon?",
              isEnabled: true,
              collection_paragraph:
                "Best@Salon offers a range of hair services including cuts, styling, coloring, extensions, and more.",
            },
            {
              _id: 2,
              collection_title:
                "Is Best@Salon suitable for individuals with sensitive skin or specific allergies?",
              isEnabled: true,
              collection_paragraph:
                "Absolutely, our salon accommodates sensitive skin and specific allergies, ensuring a safe and tailored experience.",
            },
            {
              _id: 3,
              collection_title: "How can I book an appointment at Best@Salon?",
              isEnabled: true,
              collection_paragraph:
                "Booking an appointment is quick and easy - either call us directly or book online through our website for your convenience.",
            },
          ],
        },
      },
      footer: {
        bg_color: "ffffff",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
      },
    },
  },
  theme_2: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/section-two/section1.png",
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/section-two/section2.png",
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/section-two/section3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/section-two/section4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "/assets/section-two/section5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/section-two/section6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/section-two/section7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "/assets/section-two/section8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "/assets/section-two/section9.png",
        },
      ],
      header: {
        primary_color: "ff0059",
        secondary_color: "ffcb00",
        nav: {
          nav_bg_color: "ffffff",
          header_bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
        },
        btn_group: {
          btn1: {
            text: "Continue to order",
            btn_bg_color: "ff0059",
            btn_color: "ffffff",
          },
          btn2: {
            btn2_text: "Order Now",
            btn2_bg_color: "ffcb00",
            btn2_color: "2e3639",
          },
        },
      },
      section_1: {
        title_1: "Solution for your",
        title_2: "Garden Service",
        title_3:
          "Experience the magic of London's gardens with us.",
        card_section: [
          "/assets/img/services/garden/secimg13.jpg",
          "/assets/img/services/garden/secimg14.jpg",
          "/assets/img/services/garden/secimg15.jpg",
          "/assets/img/services/garden/secimg13.jpg",
          "/assets/img/services/garden/secimg14.jpg",
        ],
      },
      section_2: {
        is_section_enable: true,
        banner: "/assets/img/services/garden/secimg11.jpg",
        sub_heading_top: "Our Introduction",
        title: "Welcome",
        mainparagraph:
          "Step into a realm of natural splendor and tranquility. Our garden service is your sanctuary for exquisite landscaping and revitalizing maintenance that embodies the beauty of nature.",
        card_section_one: [
          {
            _id: 1,
            text: "Select a convenient date and time for our garden service",
          },
          {
            _id: 2,
            text: "We will work wonders with your unruly garden",
          },
          {
            _id: 3,
            text: "Our team will come to your doorstep to collect any necessary materials",
          },
          {
            _id: 4,
            text: "We will deliver exceptional results at the time slot of your choice",
          },
        ],
        card_section_title: "Premier Garden Service",
        rating: "5",
        card_sub_text: "(30k Reviews)",
        card_section_two: [
          {
            _id: 1,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "Claim Support",
            card_title: "Instant Updates",
            card_paragraph:
              "Stay informed with real-time updates, ensuring you're always in the loop for your garden's care.",
          },
          {
            _id: 2,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "100% Reliable",
            card_title: "Customers Safety",
            card_paragraph:
              "Our commitment to your peace of mind and well-being includes maintaining sanitized environments and employing mindful practices.",
          },
          {
            _id: 3,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "Consultation",
            card_title: "Hygiene Standards",
            card_paragraph:
              "We prioritize providing you with up-to-date information, instant awareness, and convenient services to uphold the highest hygiene standards for your garden.",
          },
        ],
      },
      section_3: {
        cards: [
          {
            bg_img: "/assets/img/services/garden/secimg9.jpg",
            card_title: "Understanding Your Unique Style",
            card_paragraph:
              "We initiate our service with a personalized consultation, engaging in a one-on-one discussion to understand your garden preferences, expectations, and unique style. Your insights shape our approach, guaranteeing a customized experience tailored to your garden's needs.",
              services: ["Landscaping", "Lawn Maintenance", "Plant Care"],
            card_main_title: "Your Personal Consultation",
            card_main_paragraph:
              "Embark on your garden journey with a dedicated consultation led by our experienced gardeners. We collaborate closely with you to suggest services that resonate with your vision and reflect the individuality of your outdoor space.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/services/garden/secimg10.jpg",
            card_title: "Customized Just for You",
            card_paragraph:
              "Each garden service is meticulously tailored to match your outdoor space's unique characteristics, environmental conditions, and desired outcomes. Your garden's individuality guides our approach, ensuring a truly personalized outdoor experience.",
              services: [
                "Easy Online Payment",
                "Environmentally Conscious Practices",
                "Time and Cost Efficiency",  
            ],
            card_main_title: "Booking Made Simple",
            card_main_paragraph:
              "Scheduling your garden service appointment couldn't be easier! Whether you opt for online booking or a quick phone call, we prioritize convenience. Plus, enjoy the flexibility of same-day appointments for those spur-of-the-moment garden enhancements.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/services/garden/secimg11.jpg",
            card_title: "Welcoming You In",
            card_paragraph:
              "Upon entering our garden service, anticipate a welcoming and cozy ambiance. Our team is dedicated to ensuring you feel comfortable and supported throughout your visit, creating an atmosphere that feels like home right from the beginning.",
              services: ["Competitive Price", "5-Star Rated Services"],
            card_main_title: "Crafting Natural Beauty, Just for You",
            card_main_paragraph:
              "Our proficient gardeners meticulously execute your selected services, concentrating on the specific needs and desires of your garden. Your satisfaction is paramount, and our expertise is fully dedicated to enhancing the beauty of your outdoor space.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Best Quality",  
            ],
          },
          {
            bg_img: "/assets/img/services/garden/secimg12.jpg",
            card_title: "Unwinding in Your Outdoor Sanctuary",
            card_paragraph:
              "Relish the tranquil atmosphere and additional amenities we offer as you immerse yourself in a rejuvenating experience tailored just for your garden.",
              services: ["24-Hour Turnaround Time", "Convenient Rescheduling Options"],
            card_main_title: "Your Feedback Shapes Our Service",
            card_main_paragraph:
              "We highly value your insights and opinions. Following your garden session, we welcome your feedback, enabling us to adapt and enhance our services to ensure your utmost satisfaction.",
              check_list: [
                "100% Customer Satisfaction",
                "Affordable Prices",
                "Best Quality",  
            ],
          },
        ],
      },
      section_4: {},
      section_5: {
        title: "Compare our Services",
        cards: [
          {
            card_title: "",
            icon: "/assets/img/icons/serviceicon2.svg",
            card_options: [
              "What is included",
              "Typically included items",
              "Service time",
              "Delivery",
              "Items returned",
              "Eco friendly",
            ],
          },
          {
            card_title: "Garden Maintenance Services",
            icon: "/assets/img/icons/serviceicon2.svg",
            card_options: [
              "Comprehensive Garden Maintenance",
              "Lawn mowing, pruning, weeding, and watering",
              "Completion within 24 hours*",
              "Free of charge",
              "Any collected debris returned in a bag",
              "Environmentally conscious practices",
            ],
          },
          {
            card_title: "Landscaping Services",
            icon: "/assets/img/icons/serviceicon3.svg",
            card_options: [
              "Landscaping Design and Implementation",
              "Installation of garden features, planting of flowers and shrubs, and hardscape construction.",
              "Completion within 24 hours*",
              "Free of charge",
              "Any unused materials returned in a bag",
              "Utilization of sustainable materials and techniques",
            ],
          },
          {
            card_title: "Plant Care Services",
            icon: "/assets/img/icons/serviceicon4.svg",
            card_options: [
              "Plant Care and Maintenance",
              "Pruning, fertilizing, and pest control for plants and trees.",
              "Completion within 24 hours*",
              "Free of charge",
              "Any trimmed branches or collected waste returned in a bag",
              "Use of organic fertilizers and environmentally safe pest control methods",
            ],
          },
        ],
      },
      section_6: {
        is_section_enable: true,
        banner: [
          {
            banner:
              "/assets/img/services/garden/secimg12.jpg",
          },
        ],
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title:
                "What gardening services do you offer?",
              isEnabled: true,
              collection_paragraph:
                "We offer a wide range of gardening services including garden design, landscaping, lawn maintenance, planting, pruning, and garden clearance.",
            },
            {
              _id: 2,
              collection_title:
                "Do you provide eco-friendly gardening solutions?",
              isEnabled: true,
              collection_paragraph:
                "Yes, we prioritize eco-friendly practices in our gardening services, including organic gardening methods, sustainable landscaping techniques, and water conservation strategies.",
            },
            {
              _id: 3,
              collection_title:
                "Can you help with specific garden problems like weed control or pest management?",
              isEnabled: true,
              collection_paragraph:
                "Absolutely, our team is experienced in weed control, pest management, and disease prevention to ensure your garden stays healthy and thriving.",
            },
            {
              _id: 4,
              collection_title:
                "How can I schedule a gardening service?",
              isEnabled: true,
              collection_paragraph:
                "You can easily schedule a gardening service by contacting us through phone, email, or our website. We're here to help you schedule an appointment at your convenience.",
            },
          ],
        },
        sub_heading_top: "Ask Your Question",
        title: "Frequently Asked Questions",
        paragraph:
          "",
      },
      section_7: {
        title_1: "Latest Testimonial",
        title_2: "What Our Client Say",
        cards: [
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "Bestatservices Gardening Services consistently delivers expertly crafted gardens with meticulous attention to detail.",
          },
          {
            profile: "/assets/img/services/testimonialuser2.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "Maintaining my garden with Bestatservices Gardening Services has been a pleasure. Their team is highly skilled, ensuring my garden looks immaculate every time.",
          },
          {
            profile: "/assets/img/services/testimonialuser3.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "Bestatservices Gardening Services stands out for their creative and precise approach to landscaping, transforming outdoor spaces into stunning landscapes.",
          },
          {
            profile: "/assets/img/services/testimonialuser1.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "Bestatservices Gardening Services consistently delivers expertly crafted gardens with meticulous attention to detail.",
          },
          {
            profile: "/assets/img/services/testimonialuser2.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "Maintaining my garden with Bestatservices Gardening Services has been a pleasure. Their team is highly skilled, ensuring my garden looks immaculate every time.",
          },
          {
            profile: "/assets/img/services/testimonialuser3.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message:
              "Bestatservices Gardening Services stands out for their creative and precise approach to landscaping, transforming outdoor spaces into stunning landscapes.",
          },
        ],
      },
      section_8: {
        title_1: "ARTICLES",
        title_2: "Latest News",
      },
      section_9: {
        title: "We're available on mobile Just download it!",
        bannerimg: "/assets/img/services/garden/mob.png",
        paragraph:
          "Download our app now and schedule our gardening service. Your garden will be expertly tended to and maintained, allowing you to enjoy more time with your family, more happy hours, and more moments of hitting the snooze button every morning - because we've got your gardening needs covered.",
        android_link:
          "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
        ios_link:
          "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
      },
      footer: {
        custome_banner_design: {
          is_enable: true,
        },
        bg_color: "151e2e",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          about_section: {
            is_enable: true,
            text: "Ready to take laundry off your to-do list? Bestatlaundry services is a local laundry pickup and delivery service that you can order directly from your smart phone.",
          },
          service_section: {
            is_enable: true,
          },
          quicklink_section: {
            is_enable: true,
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "+442086388690",
              value: "tel:+442086388690",
            },
            email: {
              label: "Email Us",
              value: "sales@bestatlaundry.com",
            },
            address: {
              text: "81 Randall Drive, Hornchurch - RM126TA",
            },
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
      },
    },
  },
  theme_3: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "assets/section/section1.jpg",
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "assets/section/section2.jpg",
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "assets/section/section3.jpg",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "assets/section/section4.jpg",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "assets/section/section5.jpg",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "assets/section/section6.jpg",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "assets/section/section7.jpg",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "assets/section/section8.jpg",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "assets/section/section9.jpg",
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "assets/section/section10.jpg",
        },
        {
          _id: 11,
          is_section_enable: true,
          icon: "assets/section/section11.jpg",
        },
        {
          _id: 12,
          is_section_enable: true,
          icon: "assets/section/section12.jpg",
        },
        {
          _id: 13,
          is_section_enable: true,
          icon: "assets/section/section13.jpg",
        },
        {
          _id: 14,
          is_section_enable: true,
          icon: "assets/section/section14.jpg",
        },
        {
          _id: 15,
          is_section_enable: true,
          icon: "assets/section/section15.jpg",
        },
      ],
      header: {
        is_section_enable: true,
        primary_color: "1B9DB3",
        secondary_color: "F07F1B",
        nav: {
          bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "#6b7385",
            },
            cart_value_color: "000000",
          },
        },
        btn_group: {
          btn1: {
            text: "Login / Register",
            bg_color: "ffffff",
            color: "#1b9db3",
            border_color: "#1b9db3",
            btn1_is_enable: true,
          },
          btn2: {
            text: "Quick Order",
            color: "ffffff",
            bg_color: "000",
            border_color: "#192335",
            btn2_is_enable: true,
          },
        },
      },
      section_1: {
        is_section_enable: true,
        banner: "/assets/img/services/garden/home_page_single_search.jpg",
        sub_heading_top: "24/7 Garden Service",
        title: "Experience the magic of London's gardens with us.",
        paragraph:
          "Enhancing London's outdoor spaces with expert care and creativity. From gardens to landscapes, we bring beauty to every corner of the city.",
        btn_group: {
          btn1: {
            text: "Request a Schedule",
            bg_color: "1b9db3",
            color: "ffffff",
          },
          btn2: {
            text: "Book now",
            bg_color: "F07F1B",
            color: "ffffff",
          },
        },
        mobile_link_section: {
          is_enable: true,
          mob_title: "Just A Click Away",
          android_link: "#",
          ios_link: "#",
        },
      },
      section_2: {
        is_section_enable: true,
        sub_heading_top: "Book A Service",
        title: "Book Your Preferred Appointment Slot & Venue",
        btn_group: {
          btn1: {
            text: "order now",
            bg_color: "#1b9db3",
            color: "ffffff",
          },
        },
      },
      section_3: {
        is_section_enable: true,
        sub_heading_top: "Exceptional Barbering",
        title: "Expert hands, flourishing gardens across London",
        paragraph:
          "Whether it's routine upkeep or elaborate garden transformations, count on us as your dependable allies in bringing your outdoor dreams to life.",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/img/services/priorityicon1.png",
              collection_title: "Hard & Soft Landscaping",
              collection_paragraph:
                "Experience the expertise of Bestatservices in transforming outdoor spaces with our diverse range of gardening services. Whether you're envisioning a complete garden transformation or seeking maintenance for an existing landscape, trust our team to bring your ideas to life through both hard and soft landscaping techniques.",
            },
            {
              _id: 2,
              icon: "/assets/img/services/priorityicon2.png",
              collection_title: "Grounds Maintenance",
              collection_paragraph:
                "At Bestatservices, our qualified staff holds horticulture certifications and PA1 and PA6 qualifications for herbicide spraying, ensuring precise weed control, including Japanese Knotweed. Our solutions cater to both commercial and domestic clients, guaranteeing immaculate property grounds maintenance.",
            },
            {
              _id: 3,
              icon: "/assets/img/services/priorityicon3.png",
              collection_title: "Gritting & Snow Clearance",
              collection_paragraph:
                "In addition to our standard services, Bestatservices now offers Gritting & Snow Clearance services. Our prompt and reliable snow clearance ensures safe access to your property during winter weather conditions, providing peace of mind to both new and existing customers. Trust us to deliver timely and effective solutions for your snow clearance needs.",
            },
            {
              _id: 4,
              icon: "/assets/img/services/priorityicon4.png",
              collection_title: "Garden Clearance",
              collection_paragraph:
                "Bestatservices specializes in thorough garden clearance services, ensuring your outdoor space is pristine and ready for transformation. Trust our expert team to clear away debris and create a clean canvas for your next project.",
            },
            {
              _id: 5,
              icon: "/assets/img/services/priorityicon6.png",
              collection_title: "Turfing and Lawn Care",
              collection_paragraph:
                "Experience lush, green lawns with Bestatservices' professional turfing and lawn care solutions. From installation to maintenance, we guarantee impeccable results to elevate the beauty of your outdoor space.",
            },
            {
              _id: 6,
              icon: "/assets/img/services/priorityicon5.png",
              collection_title: "Pruning",
              collection_paragraph:
                "Trust Bestatservices for expert pruning services to maintain the health and aesthetics of your plants. Our skilled team ensures precise pruning techniques to promote growth and enhance the beauty of your garden.",
            },
          ],
          btn_group: {
            btn1: {
              text: "More About Best@barber",
              bg_color: "192335",
              color: "ffffff",
            },
          },
        },
      },
      section_4: {
        is_section_enable: true,
        title:
          "Bestatservices holds the top rank as rated by UK customers in the gardening category.",
      },
      section_5: {
        is_section_enable: true,
        banner: [
          {
            banner:
              "/assets/img/services/garden/secimg1.jpg",
          },
        ],
        title: "Experience the Difference: Expertise, Excellence, and Elegance in Every Garden",
        paragraph:
          "Whether you are a seasoned horticulturist or a novice gardener, our team of highly experienced professionals is dedicated to assisting you at every stage of your gardening journey.",
          check_list: [
            {
              _id: 1,
              list: "Artificial and real turf installation",
            },
            {
              _id: 2,
              list: "Comprehensive garden clearances",
            },
            {
              _id: 3,
              list: "Proficiency in patios and block paving projects",
            },
            {
              _id: 4,
              list: "Garden maintenance and tidy-ups",
            },
            {
              _id: 5,
              list: "Expertise in planting, weeding, and hedge pruning",
            },
            {
              _id: 6,
              list: "Guaranteed quality work and positive client feedback",
            },
          ],
        btn_group: {
          btn1: {
            text: "Schedule a Appointment Now",
            bg_color: "#192335",
            color: "ffffff",
            is_enable: true,
          },
          btn2: {
            text: "Request a schedule",
            bg_color: "#1b9db3",
            color: "ffffff",
            is_enable: true,
          },
        },
      },
      section_6: {
        is_section_enable: true,
        sub_heading_top: "Know More On",
        title: "How Our Gardening Services Simplifies Your Life",
        paragraph:
          "Your oasis awaits, just beyond the door. From seeds to blooms, we nurture every detail.",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/img/services/garden/secimg2.jpg",
              card_title: "Diverse Range of Services",
              card_paragraph:
                "Select from a diverse range of gardening and landscaping services tailored to your specific requirements.",
            },
            {
              _id: 2,
              icon: "/assets/img/services/garden/secimg3.jpg",
              card_title: "Regular or One-off Sessions",
              card_paragraph:
                "Benefit from regular or one-off gardening sessions covering a range of tasks from hedge trimming to lawn mowing and bush pruning.",
            },
            {
              _id: 3,
              icon: "/assets/img/services/garden/secimg4.jpg",
              card_title: "Comprehensive Landscaping and Maintenance",
              card_paragraph:
                "Revitalize your outdoor space with our comprehensive landscaping and maintenance services, whether you're looking for a complete overhaul or targeted enhancements",
            },
            {
              _id: 4,
              icon: "/assets/img/services/garden/secimg5.jpg",
              card_title: "Efficient Team Approach",
              card_paragraph:
                "Save time and effort with our efficient team approach, always working in pairs for optimal results.",
            },
          ],
          btn_group: {
            paragraph: "Ready for a New Look? Book Your Appointment Today!",
            btn1: {
              text: "Book Online Now",
              bg_color: "#192335",
              color: "#ffffff",
            },
          },
        },
      },
      section_7: {
        is_section_enable: true,
        title: "On Demand Gardening Services",
        paragraph:
          "From comprehensive landscape design to meticulous lawn maintenance, we offer a diverse range of services tailored to meet all your gardening needs.",
        btn_group: {
          btn_paragraph: "Lorem Ipsum is simply dummy text of the",
          btn1: {
            text: " Request a Schedule",
            bg_color: "192335",
            color: "fff",
          },
        },
      },
      section_8: {
        is_section_enable: true,
        banner: "howwe1",
        title: "About Us",
        section_image:
          "/assets/img/services/garden/secimg6.jpg",
        paragraph:
          "Bestatservices Gardening Services - London, your premier destination for expert gardening and landscaping services throughout London. We recognize the profound significance of maintaining a beautiful and flourishing outdoor space. Our devoted team of gardeners is committed to delivering exceptional results, utilizing only the finest quality tools and materials to ensure that your garden remains resplendent throughout the year. ",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "Expert Planting Solutions",
              collection_image: "/assets/img/services/priorityicon7.png",
              isEnabled: true,
              collection_paragraph:
                "Bestatservices Gardening services in London offers personalized planting expertise, ensuring the selection and care of plants perfectly suited to your London garden.",
            },
            {
              _id: 2,
              collection_title: "Advanced Weed Control",
              collection_image: "/assets/img/services/priorityicon8.png",
              isEnabled: true,
              collection_paragraph:
                "Trust Bestatservices Gardening services in London for innovative weed control solutions tailored to the unique challenges of urban gardening in the bustling city.",
            },
            {
              _id: 3,
              collection_title: "Precision Pruning and Maintenance",
              collection_image: "/assets/img/services/priorityicon9.png",
              isEnabled: true,
              collection_paragraph:
                "With Bestatservices Gardening services in London, expect meticulous pruning and maintenance techniques, ensuring your garden maintains its beauty and vitality amidst the urban landscape.",
            },
          ],
        },
        btn_group: {
          btn1_text: "Schedule a Appointment Now",
          btn1_bg_color: "192335",
          btn1_color: "ffffff",
          btn2_text: "Request a Schedule",
          btn2_bg_color: "1b9db3",
          btn2_color: "ffffff",
        },
      },
      section_9: {
        is_section_enable: true,
        section_bg_color: "#1b9db3",
        sub_heading_top: "Book Now",
        title: "Your journey to garden perfection starts here.",
        paragraph: "",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/img/services/garden/secimg7.jpg",
              collection_title: "Demolition",
              collection_paragraph:
                "Efficient and safe demolition services tailored to your needs, ensuring a smooth and hassle-free process with Bestatservices Gardening Services in London.",
            },
            {
              _id: 2,
              icon: "/assets/img/services/garden/secimg8.jpg",
              collection_title: "Drainage",
              collection_paragraph:
                "Expert drainage solutions to effectively manage water flow and prevent flooding, providing peace of mind for your property with Bestatservices Gardening Services in London.",
            },
            {
              _id: 3,
              icon: "/assets/img/services/garden/secimg9.jpg",
              collection_title: "Driveway Services",
              collection_paragraph:
                "Transformative driveway services to enhance the curb appeal and functionality of your property, delivered with precision and professionalism by Bestatservices Gardening Services in London.",
            },
            {
              _id: 4,
              icon: "/assets/img/services/garden/secimg7.jpg",
              collection_title: "Demolition",
              collection_paragraph:
                "Efficient and safe demolition services tailored to your needs, ensuring a smooth and hassle-free process with Bestatservices Gardening Services in London.",
            },
          ],
          count_section: [
            {
              _id: 1,
              icon: "/assets/img/services/priorityicon1.png",
              text: "Grounds maintenance",
              count: "1639+",
            },
            {
              _id: 2,
              icon: "/assets/img/services/priorityicon2.png",
              text: "Garden makeovers",
              count: "24359+",
            },
            {
              _id: 3,
              icon: "/assets/img/services/priorityicon3.png",
              text: "Garden clearance",
              count: "4793+",
            },
            {
              _id: 4,
              icon: "/assets/img/services/priorityicon4.png",
              text: "Tree Removal",
              count: "25+",
            },
          ],
        },
      },
      section_10: {
        is_section_enable: true,
        banner: "/assets/img/services/garden/secimg11.jpg",
        sub_heading_top: "Our Commitment to Organic Gardening Practices",
        title: "Our Values",
        mainparagraph: "Empowering you to make environmentally-conscious choices for your garden and contribute to a greener future.",
        list_Section: [
          {
            _id: 1,
            text: "Sustainable Garden Design",
            paragraph:
              "Discover how our eco-friendly gardening services prioritize sustainable design practices, incorporating native plants, rainwater harvesting, and natural materials to create beautiful, environmentally-friendly landscapes.",
          },
          {
            _id: 2,
            text: "Organic Gardening Solutions",
            paragraph:
              "Explore our commitment to organic gardening practices, including chemical-free pest control, composting, and soil enrichment techniques that promote biodiversity and support local ecosystems.",
          },
          {
            _id: 3,
            text: "Water Conservation Strategies",
            paragraph:
              "Learn about our innovative water-saving solutions, such as drip irrigation systems, drought-resistant plant selections, and efficient watering schedules, to minimize water usage and conserve this precious resource.",
          },
        ],
        card_section_title: "Environmentally Conscious Gardening Solutions across London",
        card_section: [
          {
            _id: 1,
            card_bg_color: "192335",
            card_text: "Pollinator Gardens",
            card_paragraph:
              "We create and maintain gardens that support bees, butterflies, and other beneficial insects, fostering biodiversity and ecological health.",
          },
          {
            _id: 2,
            card_bg_color: "f07f1b",
            card_text: "Carbon Footprint Reduction",
            card_paragraph:
              "Through sustainable transportation practices, we minimize carbon emissions and implement eco-friendly garden maintenance techniques.",
          },
          {
            _id: 3,
            card_bg_color: "1bb333",
            card_text: "Native Plant Landscaping",
            card_paragraph:
              "Our incorporation of native plants promotes local biodiversity and sustainability while reducing water and chemical usage for beautiful outdoor spaces.",
          },
        ],
      },
      section_11: {
        is_section_enable: true,
        banner: [
          {
            banner:
              "/assets/img/services/garden/secimg12.jpg",
          },
        ],
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title:
                "What gardening services do you offer?",
              isEnabled: true,
              collection_paragraph:
                "We offer a wide range of gardening services including garden design, landscaping, lawn maintenance, planting, pruning, and garden clearance.",
            },
            {
              _id: 2,
              collection_title:
                "Do you provide eco-friendly gardening solutions?",
              isEnabled: true,
              collection_paragraph:
                "Yes, we prioritize eco-friendly practices in our gardening services, including organic gardening methods, sustainable landscaping techniques, and water conservation strategies.",
            },
            {
              _id: 3,
              collection_title:
                "Can you help with specific garden problems like weed control or pest management?",
              isEnabled: true,
              collection_paragraph:
                "Absolutely, our team is experienced in weed control, pest management, and disease prevention to ensure your garden stays healthy and thriving.",
            },
            {
              _id: 4,
              collection_title:
                "How can I schedule a gardening service?",
              isEnabled: true,
              collection_paragraph:
                "You can easily schedule a gardening service by contacting us through phone, email, or our website. We're here to help you schedule an appointment at your convenience.",
            },
          ],
        },
        sub_heading_top: "Ask Your Question",
        title: "Frequently Asked Questions",
        paragraph:
          "",
      },
      section_12: {
        is_section_enable: true,
        banner: "howwe1",
        sub_heading_top: "What Our Client Says",
        title: "Commitment to Client Satisfaction",
        paragraph: "Read What Our Clients are Saying",
        review_bottom_paragraph:
          "More than 5,000+ reviews s on TrustPilot, Google, Google Play & App Store",
        review_card: [
          {
            _id: 1,
            reviewer_pic: "/assets/img/services/testimonialuser1.jpg",
            reviewer_name: "Annie Green, Landscape Architect",
            rating: "5",
            review_highlight: "",
            review: "Bestatservices Gardening Services consistently delivers expertly crafted gardens with meticulous attention to detail.",
          },
          {
            _id: 2,
            reviewer_pic: "/assets/img/services/testimonialuser2.jpg",
            reviewer_name: "Smith Hawker, Homeowner",
            rating: "4",
            review_highlight: "",
            review:
              "Maintaining my garden with Bestatservices Gardening Services has been a pleasure. Their team is highly skilled, ensuring my garden looks immaculate every time.",
          },
          {
            _id: 3,
            reviewer_pic: "/assets/img/services/testimonialuser3.jpg",
            reviewer_name: "Christina Garcia, Property Developer",
            rating: "5",
            review_highlight: "",
            review:
              "Bestatservices Gardening Services stands out for their creative and precise approach to landscaping, transforming outdoor spaces into stunning landscapes.",
          },
        ],
        btn_group: {
          btn1: {
            text: "Book Online Now",
            bg_color: "#192335",
            color: "#ffffff",
            is_enable: true,
          },
        },
      },
      section_13: {
        is_section_enable: true,
        banner: "howwe1",
        title: "Just A Click Away",
        bannerimg: "/assets/img/services/garden/mob.png",
        paragraph: "Download Our Best Active Mobile App",
        check_list: [
          {
            _id: 1,
            icons: "/assets/img/services/priorityicon14.png",
            title: "Cashless payments",
          },
          {
            _id: 2,
            icons: "/assets/img/services/priorityicon15.png",
            title: "Instant Updates",
          },
          {
            _id: 3,
            icons: "/assets/img/services/priorityicon16.png",
            title: "Hygiene Standards",
          },
          {
            _id: 4,
            icons: "/assets/img/services/priorityicon17.png",
            title: "Eco-conscious",
          },
        ],
        mobile_link_section: {
          is_enable: true,
          android_link: "https://play.google.com/store/apps/",
          ios_link: "https://itunes.apple.com/us/app/",
        },
      },
      section_14: {
        is_section_enable: true,
        section_bg_color: "#1b9db3",
        title: "Subscribe to our newsletter for updates, exclusive promotions and our latest content!",        
        btn_group: {
          btn1: {
            text: "Subscribe",
            bg_color: "#192335",
            color: "ffffff",
          },
        },
      },
      section_15: {
        is_section_enable: true,
        sub_heading_top: "Our Latest",
        title: "News & Events",
        paragraph:
          "Stay updated with our latest News & Events, where we share gardening tips, upcoming workshops, and community initiatives.",
        banner1: "/assets/img/services/garden/secimg13.jpg",
        banner2: "/assets/img/services/garden/secimg14.jpg",
        banner3: "/assets/img/services/garden/secimg15.jpg",
        btn_group: {
          btn1: {
            text: "More News & Events",
            bg_color: "#192335",
            color: "ffffff",
          },
        },
      },
      footer: {
        custome_banner_design: {
          is_enable: true,
        },
        bg_color: "151e2e",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          about_section: {
            is_enable: true,
            text: "Bestatservices holds the top rank as rated by UK customers in the gardening category.",
          },
          service_section: {
            is_enable: true,
          },
          quicklink_section: {
            is_enable: true,
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "+442086388690",
              value: "tel:+442086388690",
            },
            email: {
              label: "Email Us",
              value: "sales@bestatlaundry.com",
            },
            address: {
              text: "81 Randall Drive, Hornchurch - RM126TA",
            },
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com",
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com",
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com",
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com",
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com",
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com",
            },
          },
        },
      },
    },
  },
  theme_4: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_1.png",
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_2.png",
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_3.png",
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_4.png",
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_5.png",
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_6.png",
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_7.png",
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_8.png",
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_9.png",
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_10.png",
        },
        {
          _id: 11,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_11.png",
        },
        {
          _id: 12,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_12.png",
        },
      ],
      header: {
        is_section_enable: true,
        primary_color: "046A38",
        secondary_color: "01190D",
        contact: {
          email_id: "sales@bestatlaundry.com",
          contact_number: "+442086388690",
        },
        nav: {
          bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "#6b7385",
            },
            cart_value_color: "000000",
          },
        },
        btn_group: {
          btn1_text: "Login / Register",
          btn1_bg_color: "046A38",
          btn1_color: "ffffff",
          btn1_border_color: "046A38",
          btn1_is_enable: true,
          btn2_text: "Quick Order",
          btn2_color: "ffffff",
          btn2_bg_color: "000",
          btn2_border_color: "#192335",
          btn2_is_enable: true,
        },
      },
      section_1: {
        is_section_enable: true,
        banner_type: "image",
        banner: "/assets/home_page_single_search.jpg",
        banner_two: "/assets/img/home/heroimg.png",
        sub_heading_top: "24/7 Salon Service",
        title:
          "Discover Your Radiance: Partner with Us for the Salon Experience You Can Trust",
        paragraph: "Select your time & location for the service",
        btn_group: {
          btn1: {
            text: "Order now",
            bg_color: "#046A38",
            color: "ffffff",
            placeholder: "Enter Postcode",
          },
        },
      },
      section_2: {
        is_section_enable: true,
        title: "Trust the Experts",
        paragraph: "Experience Beauty and Pampering Like Never Before",
        first_card_image: "/assets/img/home/trustimg1.jpg",
        image_title: "Why 1000+ customers trust Us?",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/icons/priorityicon1.png",
              collection_title: "Top Quality",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 2,
              icon: "/assets/icons/priorityicon4.png",
              collection_title: "Professionalism",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 3,
              icon: "/assets/icons/priorityicon5.png",
              collection_title: "Convenience",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
            {
              _id: 4,
              icon: "/assets/icons/priorityicon6.png",
              collection_title: "Cleanliness",
              collection_paragraph:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing",
            },
          ],
        },
      },
      section_3: {
        is_section_enable: true,
        title: "5 star Rated by customers in the UK in categories of Salon",
        banner: "/assets/img/home/review-bg.jpg",
      },
      section_4: {
        is_section_enable: true,
        banner: "/assets/home/trustimg2.jpg",
        title: "About We",
        paragraph_one:
          "Reveal Your Inner Beauty: Transform, Shine, and Inspire at Our Salon",
        paragraph_two:
          "Let your inner beauty take center stage as we transform, illuminate, and inspire at our welcoming salon ",
        option_one: "Smart Offers",
        option_two: "Consultations",
        option_three: "Scheduling App",
        btn_group: {
          btn1: {
            text: "Know More",
            bg_color: "#046A38",
            color: "ffffff",
            is_enable: true,
          },
        },
      },
      section_5: {
        is_section_enable: true,
        title: "How We Works",
        paragraph: "Bringing Beauty to You: Salon Services at Your Doorstep",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/home/howwe1.jpg",
              card_title: "Understanding Your Unique Style",
              card_paragraph:
                "We kick off by engaging in a one-on-one chat, where we eagerly listen to your preferences, expectations, and personal style. Your input guides our approach, ensuring a tailor-made experience.",
            },
            {
              _id: 2,
              icon: "/assets/home/howwe2.jpg",
              card_title: "Your Personal Consultation",
              card_paragraph:
                "Your journey begins with a personal consultation led by one of our skilled stylists. We work closely with you to recommend services that align with your vision and individuality.",
            },
            {
              _id: 3,
              icon: "/assets/home/howwe3.jpg",
              card_title: "Customized Just for You",
              card_paragraph:
                "Every service is exclusively tailored to suit your hair type, facial features, and desired outcome. Your distinct characteristics shape our approach, resulting in a truly personalized salon adventure.",
            },
            {
              _id: 4,
              icon: "/assets/home/howwe4.jpg",
              card_title: "Booking Made Simple",
              card_paragraph:
                "Scheduling an appointment is a breeze! Whether you prefer online booking or a friendly phone call, we strive to make this process convenient. We even offer same-day appointments for that spontaneous pampering.",
            },
          ],
        },
      },
      section_6: {
        is_section_enable: true,
        title: "service for Business & Personal",
        paragraph:
          "Our salon services include hairstyling, manicures, pedicures, facials, and massages in various locations across the UK and its surrounding areas",
        first_card_image: "/assets/home/trustimg3.png",
        sub_title: "For Business",
        sub_title_two: "For Personal",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_list: "Restaurants",
            },
            {
              _id: 2,
              collection_list: "Spa",
            },
            {
              _id: 3,
              collection_list: "Hotels",
            },
            {
              _id: 4,
              collection_list: "Gym",
            },
            {
              _id: 5,
              collection_list: "Factory",
            },
            {
              _id: 6,
              collection_list: "Malls",
            },
            {
              _id: 7,
              collection_list: "Public sectors",
            },
            {
              _id: 8,
              collection_list: "Hospitals",
            },
          ],
        },
        card_section_two: {
          collection: [
            {
              _id: 1,
              collection_list: "Home Stay",
            },
            {
              _id: 2,
              collection_list: "Local Living",
            },
            {
              _id: 3,
              collection_list: "Hostel",
            },
            {
              _id: 4,
              collection_list: "Cottage",
            },
            {
              _id: 5,
              collection_list: "PG Stay",
            },
          ],
        },
      },
      section_7: {
        is_section_enable: true,
        title: "Our Product Category",
        paragraph:
          "Elevate Your Beauty without Breaking the Bank. Your Beauty, Our Best Value.",
      },
      section_8: {
        is_section_enable: true,
        part_one: {
          title: "Our Values",
          sub_title: "Quality Meets Affordability",
          section_image: "/assets/home/trustimg2.jpg",
          paragraph:
            "Elevate Your Beauty without Breaking the Bank. Your Beauty, Our Best Value.",
          card_section: {
            collection: [
              {
                _id: 1,
                collection_title: "Hygiene Standards",
                collection_paragraph:
                  "We provide Up-to-date Information, Instant Awareness and Convenience.",
              },
              {
                _id: 2,
                collection_title: "Customers Safety",
                collection_paragraph:
                  "From sanitized spaces to mindful practices, we're dedicated to your peace of mind and well-being.",
              },
              {
                _id: 3,
                collection_title: "Instant Updates",
                collection_paragraph:
                  "we keep you updated, always a step ahead for your convenience",
              },
            ],
          },
        },
        part_two: {
          bg_color: "046A38",
          text_color: "ffffff",
          title: "We are an Eco Friendly Salon",
          paragraph: "",
          card_section: {
            collection: [
              {
                _id: 1,
                collection_title: "Eco-conscious",
                collection_paragraph:
                  "We utilize Eco-friendly Salon Products: Our commitment to a sustainable salon experience includes the use of environmentally conscious products.",
              },
              {
                _id: 2,
                collection_title: "Animal-friendly",
                collection_paragraph:
                  "Our dedication to vegan practices extends to our salon services. Our products are entirely free from animal products.",
              },
              {
                _id: 3,
                collection_title: "Cruelty-Free",
                collection_paragraph:
                  "Rest assured, our salon services never involve testing on animals, upholding our cruelty-free ethos.",
              },
            ],
          },
        },
      },
      section_9: {
        is_section_enable: true,
        title: "Frequently Asked Question",
        description: "",
        card_image: "/assets/home/trustimg4.jpg",
      },
      section_10: {
        is_section_enable: true,
        banner: "howwe1",
        title: "Client Testimonials",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        review_card: [
          {
            _id: 1,
            reviewer_pic: "/assets/img/services/testimonialuser1.jpg",
            reviewer_name: "Ariyan Rusov",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 2,
            reviewer_pic: "/assets/img/services/testimonialuser2.jpg",
            reviewer_name: "Darren Valdez",
            rating: "4",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
          {
            _id: 3,
            reviewer_pic: "/assets/img/services/testimonialuser3.jpg",
            reviewer_name: "Elinor Dunn",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review:
              "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          },
        ],
      },
      section_11: {
        is_section_enable: true,
        title: "Just a Click Away",
        paragraph:
          "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        check_list: [
          {
            _id: 1,
            icons: "/assets/icons/priorityicon14.png",
            title: "Cashless payments",
          },
          {
            _id: 2,
            icons: "/assets/icons/priorityicon15.png",
            title: "Expert Wellness Treatments",
          },
          {
            _id: 3,
            icons: "/assets/icons/priorityicon16.png",
            title: "Relaxing Ambiance",
          },
          {
            _id: 4,
            icons: "/assets/icons/priorityicon17.png",
            title: "In-app live chat support",
          },
        ],
        banner: "/assets/home/just_clickaway.png",
      },
      section_12: {
        is_section_enable: true,
        title: "Blogs & Events",
        description:
          " Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
      },
      footer: {
        bg_color: "046A38",
        section_paragraph_color: "c2c2c2",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        social_media: {
          facebook: {
            is_enable: true,
            facebook_link: "facebook.com",
          },
          instagram: {
            is_enable: true,
            instagram_link: "instagram.com",
          },
          whatsapp: {
            is_enable: true,
            whatsapp_link: "whatsapp.com",
          },
          linkedin: {
            is_enable: true,
            linkedin_link: "linkedin.com",
          },
          youtube: {
            is_enable: true,
            youtube_link: "youtube.com",
          },
          twitter: {
            is_enable: true,
            twitter_link: "twitter.com",
          },
        },
      },
    },
  },
  theme_5: {
    home: {
      header: {
        is_section_enable: "on",
        primary_color: "2fa0ea",
        secondary_color: "efc10b",
        nav: {
          bg_color: "ffffff",
          menu_font_color: "000000",
          menu_font_color_active: "efc10b",
          menu_font_color_hover: "efc10b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "000000",
              bg_color: "ffffff",
              menu_font_color_hover: "efc10b",
              menu_font_color_active: "efc10b",
              btn1_bg_color: "efc10b",
              btn2_bg_color: "efc10b",
              btn1_border_color: "efc10b",
              btn2_border_color: "efc10b",
              btn3_bg_color: "efc10b",
              btn3_border_color: "efc10b",
              menu_font_color: "000000",
              btn1_color: "000000",
              btn2_color: "000000",
              btn3_color: "000000",
              cart_value_color: "ffffff"
            },
            cart_value_color: "ffffff",
            menu_font_color_hover: "efc10b",
            menu_font_color_active: "efc10b",
            btn1_bg_color: "efc10b",
            btn2_bg_color: "efc10b",
            btn1_border_color: "efc10b",
            btn2_border_color: "efc10b",
            btn3_bg_color: "efc10b",
            btn3_border_color: "efc10b",
            menu_font_color: "000000",
            btn1_color: "000000",
            btn2_color: "000000",
            btn3_color: "000000",
            color: "000000"
          },
          btn1_bg_color: "efc10b",
          btn2_bg_color: "efc10b",
          btn1_border_color: "efc10b",
          btn2_border_color: "efc10b",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          btn1_color: "000000",
          btn2_color: "000000",
          btn3_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        btn_group: {
          btn1_text: "Log in",
          btn1_bg_color: "efc10b",
          btn1_color: "000000",
          btn1_border_color: "efc10b",
          btn1_is_enable: true,
          btn2_text: "Sign up",
          btn2_bg_color: "efc10b",
          btn2_color: "000000",
          btn2_border_color: "efc10b",
          btn2_is_enable: true,
          btn3_text: "Quick Order",
          btn3_color: "000000",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          btn3_is_enable: true,
          bg_color: "ffffff",
          menu_font_color_hover: "efc10b",
          menu_font_color_active: "efc10b",
          menu_font_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        bg_color: "ffffff",
        contact: {
          bg_color: "ffffff",
          menu_font_color_hover: "efc10b",
          menu_font_color_active: "efc10b",
          btn1_bg_color: "efc10b",
          btn2_bg_color: "efc10b",
          btn1_border_color: "efc10b",
          btn2_border_color: "efc10b",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          menu_font_color: "000000",
          btn1_color: "000000",
          btn2_color: "000000",
          btn3_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        menu_font_color_hover: "efc10b",
        menu_font_color_active: "efc10b",
        btn1_bg_color: "efc10b",
        btn2_bg_color: "efc10b",
        btn1_border_color: "efc10b",
        btn2_border_color: "efc10b",
        btn3_bg_color: "efc10b",
        btn3_border_color: "efc10b",
        menu_font_color: "000000",
        btn1_color: "000000",
        btn2_color: "000000",
        btn3_color: "000000",
        cart_value_color: "ffffff",
        color: "000000"
      },
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/section-five/section_1.png"
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/section-five/section_2.png"
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/section-five/section_3.png"
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/section-five/section_4.png"
        },
        {
          _id: 5,
          is_section_enable: false,
          icon: "/assets/section-five/section_5.png"
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/section-five/section_6.png"
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/section-five/section_7.png"
        },
        {
          _id: 8,
          is_section_enable: false,
          icon: "/assets/section-five/section_8.png"
        }
      ],
      section_1: {
        is_section_enable: true,
        banner_type: "image",
        banner: "/assets/home_page_single_search.jpg",
        banner_two: "https://api.bestatservices.com/storage/compress_images/65c64bab94fb1.jpeg",
        title: "Welcome to BestatServices",
        paragraph: "Unveil an array of innovative white label software solutions crafted to cater to a diverse range of services, spanning across laundry, cleaning, restaurants, salons, nail care, and barber shops.",
        btn_group: {
          btn1: {
            text: "Search",
            bg_color: "efc10b",
            color: "ffffff",
            placeholder: "Enter Postcode",
            paragraph: "Unveil an array of innovative white label software solutions crafted to cater to a diverse range of services, spanning across laundry, cleaning, restaurants, salons, nail care, and barber shops.",
            title: "Welcome to BestatServices"
          }
        },
        bg_color: "efc10b"
      },
      section_4: {
        is_section_enable: true,
        banner: "/assets/img/offer/offer-vector-bg.png",
        offer_tag: "/assets/img/offer/offer-tag.png",
        picture: "/assets/img/offer/offer-wash-img.png",
        paragraph_one: "* Offer applied only to Registered Users (On Selected Shops)"
      },
      section_5: {
        is_section_enable: true,
        banner: "/assets/img/wash-fold-bg.jpg",
        banner_two: "/assets/img/home/heroimg.png",
        title: "WASH & FOLD",
        wash_title: [
          {
            _id: 1,
            wash_list: "Washed under 30 degrees"
          },
          {
            _id: 2,
            wash_list: "Dried at medium heat"
          },
          {
            _id: 3,
            wash_list: "Customerâ€™s responsibility to make sure all items are washable"
          },
          {
            _id: 4,
            wash_list: "Towels and beddings are excluded and will be charged separately."
          }
        ]
      },
      section_6: {
        is_section_enable: true,
        title: "ABOUT BESTATSERVICES",
        sub_heading: "We believe in innovation, fueling success at every step",
        section_image: "https://api.bestatservices.com/storage/compress_images/65c64ad629988.png",
        background_image_color: "2fa0ea",
        paragraph: "It is one of zest and competence, which drives us to create extraordinary solutions adapted to your specific requirements. We want to be your valued partner on the road to success by committing to excellence.",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "Cost Effective",
              collection_image: "/assets/img/about-section/about-icon-01.png",
              isEnabled: true,
              collection_paragraph: "BestatServices provides quality service with reasonable prices to all."
            },
            {
              _id: 2,
              collection_title: "Ease to Use",
              collection_image: "/assets/img/about-section/about-icon-02.png",
              isEnabled: true,
              collection_paragraph: "User friendly website, Book orders with 1 step."
            },
            {
              _id: 3,
              collection_title: "Faster Service",
              collection_image: "/assets/img/about-section/about-icon-03.png",
              isEnabled: true,
              collection_paragraph: "Our team will complete the Service within 48 hours."
            },
            {
              _id: 4,
              collection_title: "Quality of Service",
              collection_image: "/assets/img/about-section/about-icon-04.png",
              isEnabled: true,
              collection_paragraph: "Our promise is to provide an exceptional service to you."
            }
          ],
          _id: 4,
          collection_title: "Quality of Service",
          collection_image: "/assets/img/about-section/about-icon-04.png",
          isEnabled: true,
          collection_paragraph: "Our promise is to provide a exceptional service to you."
        },
        collection_paragraph: "Our promise is to provide an exceptional service to you.",
        collection_title: "Faster Service"
      },
      section_7: {
        is_section_enable: true,
        title: "Ease to Made Services",
        paragraph1: "BestatServices is a trusted white-label SaaS Software provider for businesses and agencies in this digital age.",
        banner: "https://api.bestatservices.com/storage/compress_images/65c64e69e0a75.png",
        paragraph2: "Our product is compatible with desktops, laptops, Macs, iOS, and Android devices",
        paragraph3: "Keep clients updated on their request status via email and SMS.",
        paragraph4: "Generate invoices for customers and business accounts by sending them printable PDFs or via email.",
        card_section: [
          {
            _id: 1,
            text: "Search for your postal code"
          },
          {
            _id: 2,
            text: "Select a convenient shop"
          },
          {
            _id: 3,
            text: "Add required services in your cart"
          },
          {
            _id: 4,
            text: "Choose a convenient date and time slot"
          },
          {
            text: "Place Order"
          }
        ],
        text: "Place Order"
      },
      section_8: {
        is_section_enable: true,
        banner: "/assets/home/iPhone13.png",
        bg_color: "#FBF2FF",
        placeholder: "Eg:-  +4407816545718",
        banner_two: "/assets/img/home/heroimg.png",
        title: "Download the app",
        paragraph: "With our award-winning app, your laundry and dry cleaning needs are just a tap away",
        mobile_link_section: {
          is_enable: true,
          android_link: "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link: "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8"
        }
      },
      footer: {
        custome_banner_design: {
          is_enable: true
        },
        bg_color: "2fa0ea",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        site_title_en: "Â© Best at Services 2024. All rights reserved. ",
        footer_section: {
          needhelp_section: {
            is_enable: true,
            text: "NEED HELP?"
          },
          policy_section: {
            is_enable: true,
            text: "OUR POLICY"
          },
          quickconnect_section: {
            is_enable: true,
            text: "QUICK CONNECT"
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "Phone",
              value: "+447438823475"
            },
            email: {
              label: "Email",
              value: "sales@bestatservices.com"
            }
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com"
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com"
            },
            whatsapp: {
              is_enable: false,
              whatsapp_link: "whatsapp.com"
            },
            linkedin: {
              is_enable: false,
              linkedin_link: "linkedin.com"
            },
            youtube: {
              is_enable: false,
              youtube_link: "youtube.com"
            },
            twitter: {
              is_enable: false,
              twitter_link: "twitter.com"
            }
          },
          mobile_link_section: {
            is_enable: true,
            android_link: "",
            ios_link: "",
            bg_color: "2fa0ea",
            "email.value": "sales@bestatservices.com",
            "mbl_no.value": "+447438823475",
            site_link: "Â© Best at Services 2024. All rights reserved. "
          }
        },
        ios_link: "",
        android_link: "",
        "email.value": "sales@bestatservices.com",
        "mbl_no.value": "+447438823475",
        site_link: "Â© Best at Services 2024. All rights reserved. "
      }
    }
  },
};
