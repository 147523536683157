import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchShopHomeData } from "../../redux/Actions/shopHomeAction";
import { getSettingsData, setShopHomeCms } from "../../redux/Actions";
import ThemeFour from "./theme-four";
import ThemeOne from "./theme-one";
import ThemeTwo from "./theme-two";
import ThemeThree from "./theme-three";
import ThemeFive from "./theme-five";
import "./shopHome.css";
import AreaCovered from "../../pages/areaCovered";
import ThemeOneOld from "./theme1";
import ThemeTwoOld from "./theme2";
import { useLocation } from "react-router-dom";
import HeaderFiveShop from "./theme-five/headerFive";
import FooterFiveShop from "./theme-five/footerFive";
import { checkShopListFlow } from "../../utility";

function ShopHome() {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const shopHomeData: any = useSelector<any>((state) => state?.shopHome);
  const Theme = shopHomeData?.home_page_settings?.theme_selected;
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  const shopURL = window.location.href;
  useEffect(() => {
    dispatch(getSettingsData());
    dispatch(fetchShopHomeData()).then((response: any) => {
      dispatch(setShopHomeCms(response.data.data.cms_json));
    });
  }, []);

  useEffect(() => {
    if(shopHomeData?.template == 1){
      import("../shopHome/theme-one/sh1.css");
    }else if(shopHomeData?.template == 2){
      import("../shopHome/theme-two/sh2.css");
    }else if(shopHomeData?.template == 3){
      import("../shopHome/theme-three/sh3.css");
    }else if(shopHomeData?.template == 4){
      import("../shopHome/theme-four/sh4.css");
    }else if(shopHomeData?.template == 5){
      import("../shopHome/theme-five/sh5.css");
    }
    if (shopURL.includes("rebeccabeauty") ) {
      import("../../assets/css/rebecca.css");
    }
  }, [shopHomeData]);

  const checkOtherTemplate = () => {
    const template_array = ["1", "2", "3", "4", "5"];
    return template_array.includes(shopHomeData?.template);
  };

  return (
    <>
      <style type="text/css">
        {` 
            :root {
              --primary-color: #${
                jsonData?.[`theme_${shopHomeData?.template}`]?.home?.header
                  ?.primary_color
              }; !important
              --secondary-color: #${
                jsonData?.[`theme_${shopHomeData?.template}`]?.home?.header
                  ?.secondary_color
              }; !important
          }
          `}
      </style>
      <div className="shophome">
        {/* {Theme === "orange_theme" && <ThemeOneOld />}
        {Theme === "blue_theme" && <ThemeTwoOld />} */}
        {shopHomeData?.template == 1 && checkShopListFlow() && (
          <>
            <div className="sh1-body">
              {/* <HeaderFiveShop /> */}
              <ThemeOne />
              {/* <FooterFiveShop /> */}
            </div>
          </>
        )}
        {shopHomeData?.template == 2 && checkShopListFlow() && (
          <>
            <div className="sh2-body">
              {/* <HeaderFiveShop /> */}
              <ThemeTwo />
              {/* <FooterFiveShop /> */}
            </div>
          </>
        )}
        {shopHomeData?.template == 3 && checkShopListFlow() && (
          <>
            <div className="sh3-body">
              {/* <HeaderFiveShop /> */}
              <ThemeThree />
              {/* <FooterFiveShop /> */}
            </div>
          </>
        )}
        {shopHomeData?.template == 4 && checkShopListFlow() && (
          <>
            <div className="sh4-body">
              {/* <HeaderFiveShop /> */}
              <ThemeFour />
              {/* <FooterFiveShop /> */}
            </div>
          </>
        )}
        {shopHomeData?.template == 5 && checkShopListFlow() && (
          <>
            <div className="sh5-body">
              {/* <HeaderFiveShop /> */}
              <ThemeFive />
              {/* <FooterFiveShop /> */}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ShopHome;
