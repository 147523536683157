import React from "react";
import { tick } from "../../../assets/img";
import { useSelector } from "react-redux";
import { shop_data_garden } from "../edit/data";

function SectionThreeThemeTwo() {
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  //const jsonData = shop_data_garden
  const cards = jsonData.theme_2?.home?.section_3?.cards || [];
  return (
    <>
      <section className="sh2-highlight-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sh2-highlight-row">
                <div className="row">
                {jsonData?.theme_2?.home?.section_3?.cards?.map((card:any, index:any) => (
                  <div className="col-md-6" key={index}>
                    <div className={`sh2-highlight-box ${card.bg_class}`}>
                      <div className="sh2-highlight-img">
                        <img src={card.bg_img} alt="" />
                        <div className="sh2-highlight-overlay">
                          <h3>{card.card_title}</h3>
                          <p>{card.card_paragraph}</p>
                          {/* <ul>
                            {card.services.map((service:any, serviceIndex:any) => (
                              <li className="ml-1" key={serviceIndex}>
                                <img src={tick} alt="" />
                                {service}
                              </li>
                            ))}
                          </ul> */}
                        </div>
                      </div>
                      <div className="sh2-highlight-content">
                        <h4>{card.card_main_title}</h4>
                        <p>{card.card_main_paragraph}</p>
                        {/* <ul>
                          {card.check_list.map((checkItem:any, checkIndex:any) => (
                            <li key={checkIndex}>
                              <img src={tick} alt="" />
                              {checkItem}
                            </li>
                          ))}
                        </ul> */}
                      </div>
                    </div>
                  </div>
                ))}
                  {/* <div className="col-md-6">
                    <div className="sh2-highlight-box sh2-highlight-bg1">
                      <div className="sh2-highlight-img">
                        <img
                          src={
                            jsonData.theme_2?.home?.section_3?.cards[0]?.bg_img
                          }
                          alt=""
                        />
                        <div className="sh2-highlight-overlay">
                          <h3>
                            {" "}
                            {
                              jsonData.theme_2?.home?.section_3?.cards[0]?.card_title
                            }{" "}
                          </h3>
                          <p>
                            {
                              jsonData.theme_2?.home?.section_3?.cards[0]?.card_paragraph
                            }
                          </p>
                          <ul>
                            {jsonData.theme_2?.home?.section_3?.cards[0]?.services.map(
                              (services: any) => {
                                return (
                                  <>
                                    <li className="ml-1">
                                      <img src={tick} alt="" />
                                      {services}
                                    </li>
                                  </>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="sh2-highlight-content">
                        <h4>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[0]?.card_main_title
                          }
                        </h4>
                        <p>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[0]?.card_main_paragraph
                          }
                        </p>
                        <ul>
                          {jsonData.theme_2?.home?.section_3?.cards[0]?.check_list?.map(
                            (checkList: any) => {
                              return (
                                <>
                                  <li>
                                    <img src={tick} alt="" />
                                    {checkList}
                                  </li>
                                </>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="sh2-highlight-box sh2-highlight-bg2">
                      <div className="sh2-highlight-img">
                        <img
                          src={
                            jsonData.theme_2?.home?.section_3?.cards[1]?.bg_img
                          }
                          alt=""
                        />
                        <div className="sh2-highlight-overlay">
                          <h3>
                            {" "}
                            {
                              jsonData.theme_2?.home?.section_3?.cards[1]?.card_title
                            }{" "}
                          </h3>
                          <p>
                            {
                              jsonData.theme_2?.home?.section_3?.cards[1]?.card_paragraph
                            }
                          </p>
                          <ul>
                            {jsonData.theme_2?.home?.section_3?.cards[1]?.services.map(
                              (services: any) => {
                                return (
                                  <>
                                    <li className="ml-1">
                                      <img src={tick} alt="" />
                                      {services}
                                    </li>
                                  </>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="sh2-highlight-content">
                        <h4>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[1]?.card_main_title
                          }
                        </h4>
                        <p>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[1]?.card_main_paragraph
                          }
                        </p>
                        <ul>
                          {jsonData.theme_2?.home?.section_3?.cards[1]?.check_list?.map(
                            (checkList: any) => {
                              return (
                                <>
                                  <li>
                                    <img src={tick} alt="" />
                                    {checkList}
                                  </li>
                                </>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              {/* <div className="sh2-highlight-row">
                <div className="row">
                  <div className="col-md-6">
                    <div className="sh2-highlight-box sh2-highlight-bg2">
                      <div className="sh2-highlight-img">
                        <img
                          src={
                            jsonData.theme_2?.home?.section_3?.cards[2]?.bg_img
                          }
                          alt=""
                        />
                        <div className="sh2-highlight-overlay">
                          <h3>
                            {" "}
                            {
                              jsonData.theme_2?.home?.section_3?.cards[2]?.card_title
                            }{" "}
                          </h3>
                          <p>
                            {
                              jsonData.theme_2?.home?.section_3?.cards[2]?.card_paragraph
                            }
                          </p>
                          <ul>
                            {jsonData.theme_2?.home?.section_3?.cards[2]?.services.map(
                              (services: any) => {
                                return (
                                  <>
                                    <li className="ml-1">
                                      <img src={tick} alt="" />
                                      {services}
                                    </li>
                                  </>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="sh2-highlight-content">
                        <h4>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[2]?.card_main_title
                          }
                        </h4>
                        <p>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[2]?.card_main_paragraph
                          }
                        </p>
                        <ul>
                          {jsonData.theme_2?.home?.section_3?.cards[2]?.check_list?.map(
                            (checkList: any) => {
                              return (
                                <>
                                  <li>
                                    <img src={tick} alt="" />
                                    {checkList}
                                  </li>
                                </>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="sh2-highlight-box sh2-highlight-bg1">
                      <div className="sh2-highlight-img">
                        <img
                          src={
                            jsonData.theme_2?.home?.section_3?.cards[3]?.bg_img
                          }
                          alt=""
                        />
                        <div className="sh2-highlight-overlay">
                          <h3>
                            {" "}
                            {
                              jsonData.theme_2?.home?.section_3?.cards[3]?.card_title
                            }{" "}
                          </h3>
                          <p>
                            {
                              jsonData.theme_2?.home?.section_3?.cards[3]?.card_paragraph
                            }
                          </p>
                          <ul>
                            {jsonData.theme_2?.home?.section_3?.cards[3]?.services.map(
                              (services: any) => {
                                return (
                                  <>
                                    <li className="ml-1">
                                      <img src={tick} alt="" />
                                      {services}
                                    </li>
                                  </>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="sh2-highlight-content">
                        <h4>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[3]?.card_main_title
                          }
                        </h4>
                        <p>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[3]?.card_main_paragraph
                          }
                        </p>
                        <ul>
                          {jsonData.theme_2?.home?.section_3?.cards[3]?.check_list?.map(
                            (checkList: any) => {
                              return (
                                <>
                                  <li>
                                    <img src={tick} alt="" />
                                    {checkList}
                                  </li>
                                </>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionThreeThemeTwo;
