import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../redux/Actions";
import { setValue } from "../../utility";
import { fetchGetShopByPinCode } from "../../redux/Actions/checkoutPageActions";
import { fetchFaqData } from "../../redux/Actions/policyActions";
import "swiper/css";
import "swiper/css/navigation";
import axios from "axios";
import { DebounceInput } from "react-debounce-input";

function SectionTwoBannerThemeThree() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const navigate = useNavigate();

  const base_url = process.env.REACT_APP_BACKEND_URL;
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  useEffect(() => {}, [homedata]);

  const [productList, setProductList] = useState<any | null>({});
  const [blogList, setBlogList] = useState<any | null>({});
  useEffect(() => {
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .get(`${base_url}/api/get_categories?group_id=1`, {
        headers: headers,
      })
      .then((e) => {
        setProductList({ data: e.data });
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });

    axios
      .get(`${base_url}/api/blog_list?`, {
        headers: headers,
      })
      .then((e) => {
        setBlogList(e.data.data);
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });
  }, []);

  const faqData: any = useSelector<any>((state) => state?.faq);
  useEffect(() => {
    dispatch(fetchFaqData());
  }, []);

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
      // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }
  }, [state]);

  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;

  // useEffect(() => {
  //     // fetchData();
  //     dispatch(fetchPostalCodes())
  //     // dispatch(fetchPostalCodes());
  // }, [])

  const handleChange = (e: any) => {
    const result = e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
    // setPostalCodeList(e.target.value);
    const { value } = e.target;
    const updateValue = value.replace(/\s/g, "");
    if (value.length > 0) {
      dispatch(fetchPostalCodes(updateValue));
    }
    setPostalCodeValue(updateValue);
  };
  const onSearch = (searchTerm: any) => {
    // setPostalCodeList(searchTerm);
    setPostalCodeValue(searchTerm);
    setValue("postalCode", searchTerm);
    // dispatch(fetchPostalCodes(searchTerm));
  };
  const handleBooknow = async () => {
    const isValid = state.postalCodeList.some(
      (item: any) => item.postcode === postalCodeValue.toUpperCase()
    );
    setValue("postalCode", postalCodeValue);
    const results = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(postalCodeValue))
    );

    if (!results) {
      navigate("/areaNotCovered");
      return;
    }
    if (isValid) {
      navigate("/productLists");
    } else {
      navigate("/areaNotCovered");
    }
  };
  return (
    <>
      {settingsData?.WebmasterSettings?.booking_online_orders == 1 &&
        
        <div className="homesearch" id="book-now">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="section-title">
                <span className="yellow-btn">
                  {homedata?.theme_3?.home?.section_2?.sub_heading_top}
                </span>
                <h2>{homedata?.theme_3?.home?.section_2?.title}</h2>
              </div>
              <div className="postcodeform">
                {/* <input
                  type="text"
                  id="postcode-input"
                  value={postalCodeValue}
                  name="pincode"
                  placeholder="Enter Postcode"
                  className="ui-autocomplete-input"
                  onChange={handleChange}
                  autoComplete="off"
                /> */}
                <DebounceInput debounceTimeout={500} value={postalCodeValue} placeholder="Enter Postcode" className="ui-autocomplete-input" onChange={handleChange} autoComplete="off" />
                <button
                  style={{
                    background: `#${homedata?.theme_3?.home?.section_2?.btn_group?.btn1?.bg_color}`,
                    color: `#${homedata?.theme_3?.home?.section_2?.btn_group?.btn1?.color}`,
                  }}
                  disabled={postalCodeValue.length === 0}
                  onClick={handleBooknow}
                  className="btn hover-btn"
                  id="book_now_btn"
                  type="button"
                >
                  <i className="me-2 uil uil-search"></i>
                  {homedata?.theme_3?.home?.section_2?.btn_group?.btn1?.text}
                </button>
                <div className="dropdown">
                  {postalCodeList
                    .filter((item: any) => {
                      const searchTerm = postalCodeValue.toLowerCase();
                      const mainValue = item.postcode.toLowerCase();

                      return (
                        searchTerm &&
                        mainValue.startsWith(searchTerm) &&
                        mainValue !== searchTerm
                      );
                    })
                    .slice(0, 10)
                    .map((item: any) => (
                      <div
                        onClick={() => onSearch(item.postcode)}
                        className="dropdown-row"
                        key={item.postcode}
                      >
                        <i className="fas fa-map-marker-alt"></i>
                        {item.postcode}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
}

export default SectionTwoBannerThemeThree;