import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import axios from "axios";

const SectionSevenThemeOne = () => {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const [productList, setProductList] = useState<any | null>({});
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const jsonData: any = useSelector<any>((state) => state?.shopHomeJsonList);

  useEffect(() => {
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .get(`${base_url}/api/get_categories?group_id=1`, {
        headers: headers,
      })
      .then((e) => {
        setProductList({ data: e.data });
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });
  }, []);

  return (
    <>
      <section className="sh1-services-section" id="services-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="sh1-section-title">
              {jsonData?.theme_1?.home?.section_7?.title}
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="sh1-services-slider">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 4,
                    },
                    1024: {
                      slidesPerView: 6,
                    },
                  }}
                >
                  {Object.keys(productList).length > 0 &&
                    productList?.data?.data?.category?.map((item: any) => {
                      return (
                        <SwiperSlide>
                          <div className="sh1-services-slider-grid">
                            <img src={item.web_banner_img} alt="" />
                            <h5>{item.main_category_name}</h5>
                            {/* <p>There are many variations of passages of Lorem Ipsum available.</p> */}
                            <a
                              href="#bookNow"
                              className="btn sh1-btnslider hover-btn"
                            >
                              Book Now<i className="fas fa-chevron-right"></i>
                            </a>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
                {settingsData?.WebsiteSettings?.site_title_en.includes("Sasian") && (
                  <div className="sascallbtn py-5 text-center">
                    <a href="tel:+(44)7749300244" className="btn primary-btn">Call Us</a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionSevenThemeOne;
