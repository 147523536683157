import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";

import axios from "axios";
// import './downloadApp.css';
import ThankAppDownload from "../../thankAppDownload";
import FailedAppDownload from "../../failedAppDownload";
import { appstore, googleplay, appdownload } from "../../../assets/img";
import ReCAPTCHA from "react-google-recaptcha";

const base_url = process.env.REACT_APP_BACKEND_URL;
const client_base_url = process.env.REACT_APP_BASE_URL;

function SectionEightThemeFive() {
  const [mobileValue, setMobileValue] = useState("");
  const [result, setResult] = useState({});
  const [thanksAppDownloadPopup, setThanksAppDownloadPopup] = useState(false);
  const [failedAppDownloadPopup, setFailedAppDownloadPopup] = useState(false);
  const [error, genError] = useState("");
  const [appLinks, setappLinks] = useState<any>("");
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  const [isRecaptchaCompleted, setIsRecaptchaCompleted] = useState(true);

  useEffect(() => {}, [jsonData]);

  const handleChange = (e: any) => {
    const result = e.target.value.replace(/[^0-9+]/gi, "");
    if (result.length > 14) {
      genError("Number should not be greater than 14 digits!");
      return;
    } else {
      genError("");
    }
    setMobileValue(result);
  };

  const handleSubmit = (event: any) => {
    const headers = { Accept: "application/json" };

    axios
      .post(
        `${base_url}/api/download_app`,
        { mobile: mobileValue },
        {
          headers: headers,
        }
      )
      .then((e) => {
        setResult({ result: e.data });

        //alert(e.data.Response.response_message)

        if (e.data.Response.response_code == "1") {
          setThanksAppDownloadPopup(true);
        } else {
          setFailedAppDownloadPopup(true);
          /*   setGenError(e.data.Response.response_message);
           event.preventDefault()
           return false*/
        }
      });
    event.preventDefault();
  };

  // useEffect(() => {
  //   if (appLinks === "") {
  //     axios({
  //       method: "get",
  //       url: `${base_url}/api/settings`,
  //       headers: {
  //         Accept: "application/json",
  //         "Access-Control-Allow-Methods": "GET, POST",
  //       },
  //     })
  //       .then((response) => {
  //         if (response.status !== 401) {
  //           setappLinks(response.data.data.WebmasterSettings);
  //         }
  //       })
  //       .catch(function (error) {});
  //   }
  // }, [appLinks]);

  const handleRecaptchaChange = (value: any) => {
    if (value != null) {
      setIsRecaptchaCompleted(false);
    } else {
      setIsRecaptchaCompleted(true);
    }
  };

  return (
    <div
      className="section-app app-download-blk"
      style={{
        background: `#${jsonData?.theme_5?.home?.section_8?.bg_color}`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="app-content col-md-6 aos-init" data-aos="zoom-in-up">
            <form
              id="downloadapp"
              role="form"
              method="POST"
              onSubmit={handleSubmit}
            >
              <h2>{jsonData?.theme_5?.home?.section_8?.title}</h2>
              <p>{jsonData?.theme_5?.home?.section_8?.paragraph}</p>
              <div className="input-group my-32">
                <input
                  id="download_link_input"
                  value={mobileValue}
                  type="text"
                  className="form-control"
                  aria-label=""
                  max={14}
                  placeholder={jsonData?.theme_5?.home?.section_8?.placeholder} // placeholder={`Eg:- ${
                  //   appLinks?.mobile_notification_prefix != undefined
                  //     ? appLinks?.mobile_notification_prefix
                  //     : ""
                  // }07816545718`}
                  onChange={handleChange}
                />
                <span className="input-group-addon custom-style-btn p-0">
                  <button
                    disabled={isRecaptchaCompleted}
                    id="download_link_btn"
                    className="btn"
                  >
                    <i className="fa fa-paper-plane"></i>
                  </button>
                </span>
              </div>
              <h6 className="text-danger mb-8">{error}</h6>
              {mobileValue != "" && isRecaptchaCompleted == true && (
                <div className="row align-items-end">
                  <div className="col-md">
                    <ReCAPTCHA
                      sitekey="6Ld0akMiAAAAAJn3hCRDkEJll1gxFTxsv-9NjeVV"
                      onChange={handleRecaptchaChange}
                    />
                  </div>
                </div>
              )}
              <ul>
                <li>
                  <a
                    target="_blank"
                    className="hvr-float-shadow imggbg"
                    href={
                      jsonData?.theme_5?.home?.section_8?.mobile_link_section
                        ?.ios_link
                    } rel="noreferrer"
                  >
                    <img
                      src={appstore}
                      alt="appstore"
                      className="img img-fluid"
                      style={{maxHeight: "50px"}}
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    className="hvr-float-shadow imggbg2"
                    href={
                      jsonData?.theme_5?.home?.section_8?.mobile_link_section
                        ?.android_link
                    } rel="noreferrer"
                  >
                    <img
                      src={googleplay}
                      alt="googleplay" style={{maxHeight: "50px"}}
                      className="img img-fluid"
                    />
                  </a>
                </li>
                {/* <li>
                  <a
                    target={appLinks.app_store_link == "" ? "" : "_blank"}
                    href={
                      appLinks.app_store_link == ""
                        ? "javascript:;"
                        : appLinks.app_store_link
                    }
                    className="hvr-float-shadow"
                  >
                    <img
                      src={appstore}
                      className="img img-fluid"
                      alt="appstore"
                    />
                  </a>
                </li>
                <li>
                  <a
                    target={appLinks.play_store_link == "" ? "" : "_blank"}
                    href={
                      jsonData?.theme_5?.home?.section_8?.banner}
                    className="hvr-float-shadow"
                  >
                    <img
                      src={googleplay}
                      className="img img-fluid"
                      alt="appstore"
                    />
                  </a>
                </li> */}
              </ul>
            </form>
          </div>
          <div className="app-img col-md-6">
            <img
              src={jsonData?.theme_5?.home?.section_8?.banner}
              className="img-fluid aos-init"
              alt="appdownload"
              data-aos="zoom-in-up"
            />
          </div>
        </div>
      </div>

      {thanksAppDownloadPopup && (
        <ThankAppDownload
          close={() => {
            setIsRecaptchaCompleted(true);
            setThanksAppDownloadPopup(false);
          }}
        />
      )}
      {failedAppDownloadPopup && (
        <FailedAppDownload
          close={() => {
            setIsRecaptchaCompleted(true);
            setFailedAppDownloadPopup(false);
          }}
        />
      )}
    </div>
  );
}

export default SectionEightThemeFive;
