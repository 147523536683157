import React, { useEffect, useState } from "react";
import { ColorPicker } from "primereact/colorpicker";
import { useDispatch, useSelector } from "react-redux";
import { setShopHomeCms, saveThemeJsonData, fetchCroppedImage } from "../../../../redux/Actions";
import ImageCropper from "../../../imageCropper/imageCropper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";


const SectionSevenBannerThemeFive = () => {
    const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [imagedata, setImagedata] = useState<any>("" || undefined);
  const [activeTab, setActiveTab] = useState(0);

  const banner: Array<string> = [
    "assets/img/easy-to-img.png",
    "assets/img/home/wing3.png",
    "assets/img/home/wing4.png",
  ];

  const handleChange = (e: any, index?: any) => {
    const { value, name, type, checked } = e.target;
    let updatedCollection: any = [
        ...editData?.theme_5?.home.section_7.card_section,
      ];
      if (index != undefined) {
        updatedCollection = [...editData?.theme_5?.home.section_7.card_section];
        updatedCollection[index] = {
          ...updatedCollection[index],
          [name]: value,
        };
      }
    setEditData((prevJsonData: any) => ({
        ...prevJsonData,
        theme_5: {
          ...prevJsonData?.theme_5,
          home: {
            ...prevJsonData?.theme_5.home,
            section_7   : {
              ...prevJsonData?.theme_5?.home.section_7,
              [name]: value,              
            card_section: updatedCollection
        },
      },
    },
    }));
  };

  const addCollectionOne = () => {
    const updatedCollection: any = [
      ...editData.theme_5.home.section_7.card_section,
    ];

    const newCard = {
      text: ""
    };
    updatedCollection.splice(activeTab + 1, 0, newCard);
    setActiveTab(activeTab + 1);
    setEditData((prevJsonData: any) => ({
        ...prevJsonData,
        theme_5:{
          ...prevJsonData.theme_5,
        home: {
          ...prevJsonData.theme_5.home,
          section_7: {
            ...prevJsonData.theme_5.home.section_7,                
              card_section: updatedCollection,
          },
        },
      },
    }));
  };

  const removeCollectionOne = (index: any) => {
    const updatedCollection: any = [
      ...editData.theme_5.home.section_7.card_section,
    ];
    updatedCollection.splice(index, 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_5:{
        ...prevJsonData.theme_5,
      home: {
        ...prevJsonData.theme_5.home,
        section_7: {
          ...prevJsonData.theme_5.home.section_7,                
            card_section: updatedCollection,
        },
      },
    },
    }));
  };


  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150")
    // setCropImageWidth("150")
    // setSelectedCropImage(image);
    // setShowCropModal(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        handleCroppedImage(res.target.result);
      };
    }

  };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if(imageLink != false){
      handleBannerImageChange(imageLink)
    }
  };


  const handleBannerImageChange = (image: string) => {
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_5:{
        ...prevJsonData.theme_5,
      home: {
        ...prevJsonData.theme_5.home,
        section_7: {
          ...prevJsonData.theme_5.home.section_7,
          banner: image,
        },
      },
    },
    }));
  };

    const saveJsonData = () => {
        dispatch(setShopHomeCms(editData));
        dispatch(saveThemeJsonData(editData));
      };
    
      useEffect(() => {
        setEditData(jsonData);
      }, [jsonData]);
    
      useEffect(() => {
        if (editData != undefined) {
          setEditData(editData);
        }
      }, [editData]);

return(
    <>
    <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section7 Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
          <div className="edit-section">
          <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 250 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={editData?.theme_5?.home?.section_7?.title}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">paragraph 1</label>
                    <input
                      type="text"
                      className="form-control"
                      name="paragraph1"
                      value={editData?.theme_5?.home?.section_7?.paragraph1}
                      onChange={handleChange}
                      maxLength={1200}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">paragraph 2</label>
                    <input
                      type="text"
                      className="form-control"
                      name="paragraph2"
                      value={editData?.theme_5?.home?.section_7?.paragraph2}
                      onChange={handleChange}
                      maxLength={1200}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">paragraph 3</label>
                    <input
                      type="text"
                      className="form-control"
                      name="paragraph3"
                      value={editData?.theme_5?.home?.section_7?.paragraph3}
                      onChange={handleChange}
                      maxLength={1200}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">paragraph 4</label>
                    <input
                      type="text"
                      className="form-control"
                      name="paragraph4"
                      value={editData?.theme_5?.home?.section_7?.paragraph4}
                      onChange={handleChange}
                      maxLength={1200}
                    />
                  </div>
                  <div className="mb-3">
                        <label className="form-label">Upload Image</label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          name="image"
                          onChange={updateImageSectionOne}
                        />
                      </div>
                </div>
              </div>
          </div>
          <div className="edit-section">
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="img-bg active">
                            <img
                              src={editData?.theme_5?.home?.section_7?.banner}
                              alt="Hero Image"
                            />
                          </div>
                          <div className="img-options">
                    <div className="row">
                      <div className="col-md-12">
                        <Swiper
                          spaceBetween={15}
                          navigation={true}
                          modules={[Navigation]}
                          breakpoints={{
                            640: {
                              slidesPerView: 1,
                            },
                            768: {
                              slidesPerView: 4,
                            },
                            1024: {
                              slidesPerView: 6,
                            },
                          }}
                          className="homejson-slider"
                        >
                          {banner.map((images: string,index:any) => {
                            return (
                              <>
                                <SwiperSlide key={index}>
                                  <div
                                    className="img-option"
                                  >
                                    <img
                                      src={images}
                                      alt="Hero Image"
                                      className="img-fluid"
                                      onClick={() => handleBannerImageChange(images)}
                                    />
                                  </div>
                                </SwiperSlide>
                              </>
                            );
                          })}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="edit-section">
              <h5 className="modal-title">Card Section One</h5>
                <ul className="nav nav-tabs">
                  <Swiper
                    spaceBetween={15}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                      1024: {
                        slidesPerView: 3,
                      },
                    }}
                    className="homejson-slider"
                  >
                    {editData?.theme_5?.home?.section_7?.card_section.map(
                      (card: any, index: any) => (
                        <SwiperSlide key={index}>
                          <li key={index} className="nav-item nav-option">
                            <a
                              className={`nav-link ${
                                activeTab === index ? "active" : ""
                              }`}
                              onClick={() => setActiveTab(index)}
                            >
                              Card {index + 1}
                            </a>
                            <a className="tab-close" onClick={() => {removeCollectionOne(index)}}>
                              <i
                                className="fas fa-times"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                </ul>
                <div className="tab-content">
                  {editData?.theme_5?.home?.section_7?.card_section.map(
                    (card: any, index: any) => (
                      <div
                        key={index}
                        className={`tab-pane fade ${
                          activeTab === index ? "show active" : ""
                        }`}
                      >
                        <div className="mb-3">
                          <label className="form-label">
                            Title<small>(max 250 char)</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="text"
                            value={card.text}
                            onChange={(e) => handleChange(e, index)}
                            maxLength={250}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div className="add-section">
                <div className="row">
                  <div
                    onClick={() => {
                      addCollectionOne();
                    }}
                    className="col text-center"
                  >
                    Add Card<i className="fas fa-plus-square ms-2"></i>
                  </div>
                </div>
              </div>
              </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={saveJsonData}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCropModal ? (
          <>
            <div
              className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
              id="crop-modal"
              tabIndex={-1}
              aria-labelledby="crop-modal"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <ImageCropper
                src={selectedCropImage}
                height={cropImageHeight}
                width={cropImageWidth}
                handleCrop={handleCroppedImage}
                close={() => {
                  setShowCropModal(false);
                }}
              />
            </div>
          </>
        ) : null}
    </>
)
}

export default SectionSevenBannerThemeFive;