import { FirebaseApp, initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyAxd9oGlgR-Fp92yihXJn6upTTimTR2bMw",
  authDomain: "best-at-laundry-a4a57.firebaseapp.com",
  projectId: "best-at-laundry-a4a57",
  storageBucket: "best-at-laundry-a4a57.appspot.com",
  messagingSenderId: "543589103364",
  appId: "1:543589103364:web:03ffc6f13ff52e4b788251"
};

const message_token =
  "BCPmGuB_iKUCtwHS6910N3ILTIkLiHAANxreiP-PsJVe0BOF7ugoeMtd9Og6zKUqWag_C7gIRBXdDi2Mu-_9E8o";


// Initialize Firebase
const app: FirebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();