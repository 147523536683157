import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {
  setShopHomeCms,
  saveThemeJsonData,
  fetchCroppedImage,
} from "../../../../redux/Actions";
import ImageCropper from "../../../imageCropper/imageCropper";
import { ColorPicker } from "primereact/colorpicker";

function SectionElevenThemeThreeEdit() {
  const dispatch = useDispatch<any>();
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [imagedata, setImagedata] = useState<any>("" || undefined);
  const [editData, setEditData] = useState<any>({
    theme_3: {
      home: {
        section_11: {
          title: "", // Default values, update with appropriate defaults if needed
          sub_heading_top: "",
          paragraph: "",
          banner: [], // Ensure it's an array
          card_section: {
            collection: [],
          },
        },
      },
    },
  });
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  const [activeTab, setActiveTab] = useState(0);
  const banner = [
    "/assets/home/trustimg4.jpg",
    "/assets/home/trustimg2.jpg",
    "/assets/home/trustimg2.jpg",
    "/assets/home/trustimg4.jpg",
    "/assets/home/trustimg2.jpg",
    "/assets/home/trustimg4.jpg",
    "/assets/home/trustimg2.jpg",
  ];

  const saveJsonDataToFile = () => {
    dispatch(setShopHomeCms(editData));
    dispatch(saveThemeJsonData(editData));
  };

  const handleSection2Change = (e: any, index?: any) => {
    const { value, name } = e.target;
    if (index !== undefined) {
      setEditData((prevJsonData: any) => {
        const updatedCollection =
          prevJsonData.theme_3.home.section_11.card_section.collection.map(
            (card: any, cardIndex: number) => {
              if (cardIndex === index) {
                return { ...card, [name]: value };
              }
              return card;
            }
          );

        return {
          ...prevJsonData,
          theme_3:{
            home: {
              ...prevJsonData.theme_3.home,
              section_11: {
                ...prevJsonData.theme_3.home.section_11,
                card_section: {
                  ...prevJsonData.theme_3.home.section_11.card_section,
                  collection: updatedCollection,
                },
              },
            },
          }
         
        };
      });
    } else {
      setEditData((prevJsonData: any) => ({
        ...prevJsonData,
        theme_3:{
          home: {
            ...prevJsonData.theme_3.home,
            section_11: {
              ...prevJsonData.theme_3.home.section_11,
              [name]: value,
              title:
                name === "section_11.title"
                  ? value
                  : prevJsonData.theme_3.home.section_11.title,
              sub_heading_top:
                name === "section_11.sub_heading_top"
                  ? value
                  : prevJsonData.theme_3.home.section_11.sub_heading_top,
              paragraph:
                name === "section_11.paragraph"
                  ? value
                  : prevJsonData.theme_3.home.section_11.paragraph,
            },
          },
        }
        
      }));
    }
  };

  const handleImageChange = (image: string, index: any) => {
    const updatedCollection = [...editData.theme_3.home.section_11.banner];
    updatedCollection[index] = {
      ...updatedCollection[index],
      banner: image,
    };

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_3:{
        home: {
          ...prevJsonData.theme_3.home,
          section_11: {
            ...prevJsonData.theme_3.home.section_11,
            banner: updatedCollection,
          },
        },
      }
     
    }));
  };

  const addCollection = () => {
    const updatedCollection: any = [
      ...editData.theme_3.home.section_11.card_section.collection,
    ];

    const newCard = {
      icon: "/assets/icons/priorityicon1.png",
      collection_title: "",
      collection_paragraph: "",
    };
    updatedCollection.splice(activeTab + 1, 0, newCard);
    setActiveTab(activeTab + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_3:{
        home: {
          ...prevJsonData.theme_3.home,
          section_11: {
            ...prevJsonData.theme_3.home.section_11,
            card_section: {
              btn_group: {
                ...prevJsonData.theme_3.home.section_11.card_section.btn_group,
              },
              collection: updatedCollection,
            },
          },
        },
      }
     
    }));
  };
  const removeCollection = (index: any) => {
    const updatedCollection: any = [
      ...editData.theme_3.home.section_11.card_section.collection,
    ];
    updatedCollection.splice(index, 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_3:{
        home: {
          ...prevJsonData.theme_3.home,
          section_11: {
            ...prevJsonData.theme_3.home.section_11,
            card_section: {
              btn_group: {
                ...prevJsonData.theme_3.home.section_11.card_section.btn_group,
              },
              collection: updatedCollection,
            },
          },
        },
      }
      
    }));
  };

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleImageChange(imageLink, activeTab);
    }
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150");
    // setCropImageWidth("300");
    // setSelectedCropImage(image);
    // setShowCropModal(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        handleCroppedImage(res.target.result);
      };
    }
  };

  return (
    <>
      {/* <Draggable> */}
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section11 Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 250 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="section_11.title"
                      value={editData?.theme_3?.home?.section_11?.title}
                      onChange={handleSection2Change}
                      maxLength={250}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Sub Heading Top</label>
                    <input
                      type="text"
                      className="form-control"
                      name="section_11.sub_heading_top"
                      value={
                        editData?.theme_3?.home?.section_11?.sub_heading_top
                      }
                      onChange={handleSection2Change}
                      maxLength={250}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">paragraph</label>
                    <input
                      type="text"
                      className="form-control"
                      name="section_11.paragraph"
                      value={editData?.theme_3?.home?.section_11?.paragraph}
                      onChange={handleSection2Change}
                      maxLength={1200}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 1 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h2color"
                        value={
                          editData?.theme_3?.home?.section_11?.h2color
                        }
                      />
                      <ColorPicker
                        name="h2color"
                        format="hex"
                        onChange={handleSection2Change}
                        value={
                          editData?.theme_3?.home?.section_11?.h2color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">paragraph Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="paracolor"
                        value={
                          editData?.theme_3?.home?.section_11?.paracolor
                        }
                      />
                      <ColorPicker
                        name="paracolor"
                        format="hex"
                        onChange={handleSection2Change}
                        value={
                          editData?.theme_3?.home?.section_11?.paracolor
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <div className="row">
                      <div className="col-md-12">
                        {editData?.theme_3?.home?.section_11?.banner?.map(
                          (card: any, index: any) => (
                            <>
                              <div className="img-bg active">
                                <img
                                  src={card.banner}
                                  alt={`Card Icon ${index + 1}`}
                                />
                              </div>
                              <div className="img-options">
                                  <div className="row">
                                  <div className="col-md-12">
                                      <Swiper
                                      spaceBetween={15}
                                      navigation={true}
                                      modules={[Navigation]}
                                      breakpoints={{
                                          640: {
                                          slidesPerView: 1,
                                          },
                                          768: {
                                          slidesPerView: 4,
                                          },
                                          1024: {
                                          slidesPerView: 6,
                                          },
                                      }}
                                      className="homejson-slider"
                                      >
                                   {banner.map(
                                                (banner, iconIndex) => (
                                                  <SwiperSlide key={iconIndex}>
                                                    <div
                                                      className="img-option"
                                                      onClick={() =>
                                                        handleImageChange(
                                                          banner,
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        src={banner}
                                                        alt="Hero Image"
                                                        className="img-fluid"
                                                      />
                                                    </div>
                                                  </SwiperSlide>
                                                )
                                              )}
                                     
                                      </Swiper>
                                  </div>
                                  </div>
                              </div>
                            </>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Upload Image</label>
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      className="form-control"
                      name="image"
                      onChange={updateImageSectionOne}
                    />
                  </div>
                </div>
              </div>
              <div className="edit-section">
                <ul className="nav nav-tabs">
                  <Swiper
                    spaceBetween={15}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                      1024: {
                        slidesPerView: 3,
                      },
                    }}
                    className="homejson-slider"
                  >
                    {editData?.theme_3?.home?.section_11?.card_section?.collection.map(
                      (card: any, index: any) => (
                        <SwiperSlide key={index}>
                          <li key={index} className="nav-item nav-option">
                            <a
                              className={`nav-link ${
                                activeTab === index ? "active" : ""
                              }`}
                              onClick={() => setActiveTab(index)}
                            >
                              Card Section {index + 1}
                            </a>
                            <a
                              className="tab-close"
                              onClick={() => {
                                removeCollection(index);
                              }}
                            >
                              <i
                                className="fas fa-times"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                </ul>
                <div className="tab-content">
                  {editData?.theme_3?.home?.section_11?.card_section?.collection.map(
                    (card: any, index: any) => (
                      <div
                        key={index}
                        className={`tab-pane fade ${
                          activeTab === index ? "show active" : ""
                        }`}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <div className="img-options w-100">
                              <Swiper
                                spaceBetween={15}
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                  640: {
                                    slidesPerView: 1,
                                  },
                                  768: {
                                    slidesPerView: 4,
                                  },
                                  1024: {
                                    slidesPerView: 6,
                                  },
                                }}
                                className="homejson-slider"
                              ></Swiper>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Question<small>(max 250 char)</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="collection_title"
                            value={card.collection_title}
                            onChange={(e) => handleSection2Change(e, index)}
                            maxLength={250}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Answer</label>
                          <input
                            type="text"
                            className="form-control"
                            name="collection_paragraph"
                            value={card.collection_paragraph}
                            onChange={(e) => handleSection2Change(e, index)}
                            maxLength={1200}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="add-section">
                <div className="row">
                  <div
                    onClick={() => {
                      addCollection();
                    }}
                    className="col text-center"
                  >
                    Add Card<i className="fas fa-plus-square ms-2"></i>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </Draggable> */}
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
}

export default SectionElevenThemeThreeEdit;
