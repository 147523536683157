import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  appstore,
  googleplay,
  paymethod1,
  paymethod2,
  paymethod3,
  paymethod4,
  qrscan,
  logo,
  payment_methods,
} from "../../assets/img";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeJsonList } from "../../redux/Actions";

function FooterThree() {
  const navigate = useNavigate();
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;

  const base_url = process.env.REACT_APP_BACKEND_URL;
  // const base_url = "http://www.bestatlaundry.test";

  const [settings, setSettings] = useState<any>({});

  const appStorel =
    Object.keys(settings).length > 0 &&
    settings.WebmasterSettings.app_store_link;
  const playStore =
    Object.keys(settings).length > 0 &&
    settings.WebmasterSettings.play_store_link;
  const facebook =
    Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link1;
  const twitter =
    Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link2;
  const instagram =
    Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link6;
  const dispatch = useDispatch<any>();

  const [areaCovered, setAreaCovered] = useState<any | null>({});

  const settingsData: any = useSelector<any>((state) => state?.settings);

  const homedata: any = useSelector<any>((state) => state?.homeJsonList);

  useEffect(() => {
    // if (Object.keys(settings).length === 0 && settingsData == undefined) {
    //   axios({
    //     method: "get",
    //     url: `${base_url}/api/settings`,
    //     headers: {
    //       Accept: "application/json",
    //       "Access-Control-Allow-Methods": "GET, POST",
    //     },
    //   })
    //     .then((response) => {
    //       if (response.status != 401) {
    //         setSettings(response.data.data);
    //         dispatch(setSettingsAll(response.data.data));
    //       }
    //     })
    //     .catch(function (error) {});
    // }

    const headers = {
      Accept: "application/json",
    };

    axios
      .get(`${base_url}/api/areas_covered`, {
        headers: headers,
      })
      .then((e) => {
        setAreaCovered({ data: e.data });
      });
  }, []);

  const popAreadCovered = (areaList: any) => {
    const test = Object.keys(areaList).map((location, locationindex) => {
      const cityArr = areaList[location].toString().split(",");

      return (
        <>
          {cityArr.map((city: any) => (
            <li>
              <a
                href="#"
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/service/" + city);
                // }}
              >
                {city}
              </a>
            </li>
          ))}
        </>
      );
    });

    return test;
  };

  const dateObj = new Date();
  const year = dateObj.getUTCFullYear();

  return (
    <div>
      {homedata && (
        <style type="text/css">
          {`
            :root {
              --footer-header: #${homedata?.theme_3?.home?.footer?.section_heading_color};
              --footer-paragraph: #${homedata?.theme_3?.home?.footer?.section_paragraph_color};
          }
          `}
        </style>
      )}
      <footer
        className="footer"
        style={{
          background: `#${homedata?.theme_3?.home?.footer?.bg_color}`,
        }}
      >
        <div className="container">
          <div className="row footer-sectionthree">
            <div className="col-md col-sm-12">
              <div className="logo-footer">
                <img
                  src={`${
                    Object.keys(settings).length > 0 &&
                    settings.WebsiteSettings.logo_path
                  }${
                    Object.keys(settings).length > 0 &&
                    settings.WebsiteSettings.style_logo_en
                  }`}
                  alt=""
                />
              </div>
              {homedata?.theme_3?.home?.footer?.footer_section?.about_section
                ?.is_enable && (
                <p style={{
                  color: `#${homedata?.theme_3?.home?.footer?.section_paragraph_color}`,
                }}>
                  {
                    homedata?.theme_3?.home?.footer?.footer_section
                      ?.about_section?.text
                  }
                </p>
              )}
              <ul className="footer-social">
                {homedata?.theme_3?.home?.footer?.footer_section?.social_media
                  ?.facebook?.is_enable && (
                  <li>
                    <a
                      href={`https://${homedata?.theme_3?.home?.footer?.footer_section?.social_media?.facebook?.facebook_link}`}
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${homedata?.theme_3?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>
                )}
                {homedata?.theme_3?.home?.footer?.footer_section?.social_media
                  ?.instagram?.is_enable && (
                  <li>
                    <a
                      href={`https://${homedata?.theme_3?.home?.footer?.footer_section?.social_media?.instagram?.instagram_link}`}
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${homedata?.theme_3?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                )}
                {homedata?.theme_3?.home?.footer?.footer_section?.social_media
                  ?.whatsapp?.is_enable && (
                  <li>
                    <a
                      href={`https://${homedata?.theme_3?.home?.footer?.footer_section?.social_media?.whatsapp?.whatsapp_link}`}
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${homedata?.theme_3?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-whatsapp"></i>
                    </a>
                  </li>
                )}
                {homedata?.theme_3?.home?.footer?.footer_section?.social_media
                  ?.linkedin?.is_enable && (
                  <li>
                    <a
                      href={`https://${homedata?.theme_3?.home?.footer?.footer_section?.social_media?.linkedin?.linkedin_link}`}
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${homedata?.theme_3?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                )}
                {homedata?.theme_3?.home?.footer?.footer_section?.social_media
                  ?.youtube?.is_enable && (
                  <li>
                    <a
                      href={`https://${homedata?.theme_3?.home?.footer?.footer_section?.social_media?.youtube?.youtube_link}`}
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${homedata?.theme_3?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                )}
                {homedata?.theme_3?.home?.footer?.footer_section?.social_media
                  ?.twitter?.is_enable && (
                  <li>
                    <a
                      href={`https://${homedata?.theme_3?.home?.footer?.footer_section?.social_media?.twitter?.twitter_link}`}
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${homedata?.theme_3?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="col-md col-sm-12">
              <h4 style={{
          color: `#${homedata?.theme_3?.home?.footer?.section_heading_color}`,
        }}>Quick Links</h4>
              <ul>
                <li>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/aboutUs");
                    }}
                    style={{
                      color: `#${homedata?.theme_3?.home?.footer?.section_paragraph_color}`,
                    }}
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a href="/#service-section" style={{
          color: `#${homedata?.theme_3?.home?.footer?.section_paragraph_color}`,
        }}>Our Services</a>
                </li>
                {/* <li><a href=''>Enquiry</a></li> */}
                {/* <li><a href=''>Call a Schedule</a></li> */}
              </ul>
            </div>
            <div className="col-md col-sm-12">
              <h4 style={{
          color: `#${homedata?.theme_3?.home?.footer?.section_heading_color}`,
        }}>Explore</h4>
              <ul>
                <li>
                  <a href="/#how-work-section" style={{
          color: `#${homedata?.theme_3?.home?.footer?.section_paragraph_color}`,
        }}>How It Works</a>
                </li>
                <li>
                  <a href="/#service-section" style={{
          color: `#${homedata?.theme_3?.home?.footer?.section_paragraph_color}`,
        }}>Services</a>
                </li>
                {/* <li><a href=''>Help Center</a></li> */}
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/areaCovered");
                    }}
                    style={{
                      color: `#${homedata?.theme_3?.home?.footer?.section_paragraph_color}`,
                    }}
                  >
                    Locations
                  </a>
                </li>
                {/* <li><a href=''>Carreers</a></li>
                <li><a href=''>Special Requests</a></li> */}
              </ul>
            </div>
            <div className="col-md col-sm-12">
              <h4 style={{
          color: `#${homedata?.theme_3?.home?.footer?.section_heading_color}`,
        }}>Call Now for Services!</h4>
              <ul>
                <li>
                  <a
                    href={`tel:${homedata?.theme_3?.home?.footer?.footer_section?.callnow_for_Services_section?.mbl_no?.label}`}
                    style={{
                      color: `#${homedata?.theme_3?.home?.footer?.section_paragraph_color}`,
                    }}
                  >
                    {/* {settingsData?.WebsiteSettings?.contact_t5} */}
                    {
                      homedata?.theme_3?.home?.footer?.footer_section
                        ?.callnow_for_Services_section?.mbl_no?.label
                    }
                  </a>
                </li>
                <li>
                  <a
                    href={`mailto:${homedata?.theme_3?.home?.footer?.footer_section?.callnow_for_Services_section?.email?.value}`}
                    className="primary-text"
                    style={{
                      color: `#${homedata?.theme_3?.home?.footer?.section_paragraph_color}`,
                    }}
                  >
                    <i className="uil uil-envelopes me-2"></i>
                    {
                      homedata?.theme_3?.home?.footer?.footer_section
                        ?.callnow_for_Services_section?.email?.label
                    }
                  </a>
                </li>
                <li>
                  <p style={{
          color: `#${homedata?.theme_3?.home?.footer?.section_paragraph_color}`,
        }}>
                    {
                      homedata?.theme_3?.home?.footer?.footer_section
                        ?.callnow_for_Services_section?.address?.text
                    }
                  </p>
                </li>
                {/* <li><a href='' className='primary-text'><i className='uil uil-location-pin-alt me-2'></i>Go to Location</a></li> */}
              </ul>
            </div>
            {/* <div className="col-md col-sm-12">
              <div className="footer-two">
                <div
                  className="footerlist-two">
                  <h4>We are here for you..</h4>
                  <ul>
                    <li><span>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t1_en}</span></li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 col-sm-6">
              <div
                className="footer-three">
                <div
                  className="footerlist-two">
                  <h4>Need Help?</h4>
                  <ul>
                    <li><span>Phone :</span><span><a href={`tel:${Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t5}`}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t5}</a></span></li>
                    <li><span>Email :</span><span><a href={`mailto:${Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}`}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}</a></span></li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-2 col-sm-6">
              <div
                className="footer-three">
                <div
                  className="footerlist-two">
                  <h4>Our Policy</h4>
                  <ul>
                    <li><NavLink to={"/termsAndConditions"}><i className="fas fa-angle-right me-1"></i>Conditions of use</NavLink></li>
                    <li><NavLink to={"/privacyPolicy"}><i className="fas fa-angle-right me-1"></i>Privacy Policy</NavLink></li>
                    <li><NavLink to={"/cookiePolicy"}><i className="fas fa-angle-right me-1"></i>Cookie Policy</NavLink></li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-3 col-sm-6">
              <div
                className="footer-three">
                <div
                  className="footerlist-two">
                  <h4>Quick Connect</h4>
                  <div
                    className="app-icons">
                    <ul
                      className="list-inline">
                      <li><a target={appStorel == ""? '' : "_blank"} href={appStorel == ""? 'javascript:;' : appStorel} className="hvr-float-shadow imggbg"><img src={appstore} className="img img-fluid" alt="appstore" /></a></li>
                      <li><a target={playStore == ""? '' : "_blank"} href={playStore == ""? 'javascript:;' : playStore} className="hvr-float-shadow imggbg2"><img src={googleplay} className="img img-fluid" alt="appstore" /></a></li>
                    </ul>
                  </div>
                  
                </div>
              </div>
            </div> */}
          </div>
          <div className="row  footer-sectionfour">
            <div className="col-md-6 col-xs-12">
              <p className="footer-last" style={{
          color: `#${homedata?.theme_3?.home?.footer?.section_paragraph_color}`,
        }}>
                {Object.keys(settings).length > 0 &&
                  settings?.WebsiteSettings?.site_title_en}{" "}
                © {year}. All rights reserved. | Powered by &nbsp;<a href="http://www.bestatservices.com" target="_blank" rel="noreferrer">www.bestatservices.com</a>
                {/* © <a href={Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.site_title_en}</a> 2023. All rights reserved. |
                Designed &amp; Developed By : <a href={Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url} target="_blank">{Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url}</a> */}
              </p>
            </div>
            {/* <div className="col-md-6 col-xs-12">
              <div className='footer-paymethods'>
                <p>Accepted payment methods:</p>
                <ul>
                  <li>
                    <img src={paymethod1} alt="" />
                  </li>
                  <li>
                    <img src={paymethod2} alt="" />
                  </li>
                  <li>
                    <img src={paymethod3} alt="" />
                  </li>
                  <li>
                    <img src={paymethod4} alt="" />
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default FooterThree;
