import React from "react";
import { maintenance } from "../../assets/img"
function UnderMaintenance() {
    return (
        <>
            <div className="maintenance">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="maintenance-card">
                                <div className="maintenance-img">
                                    <img src={maintenance} alt="" />
                                </div>
                                <h2>We Are Under Maintenance</h2>
                                <p></p>
                                <h4>We'll Be Back Shortly</h4>
                                {/* <div>
                                    <NavLink to={'/'} className="btn btn-primary">Back to Home</NavLink>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default UnderMaintenance;