import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {eyebrows,eyelashextension,facial,haircolours,hotwax,massage,threading,waxing,doubledrawnindianhairextension} from "../../assets/img";

function Facial () {

return (
    <>
        <section className="rbpsec">
            <div className="container">                
                <div className="row justify-content-center">                    
                    <div className="col-md-5">
                        <div className="rbptable">
                            <h3>Facial</h3>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>24 Carat gold</td>
                                        <td className="text-end w-cus1">£4.00</td>
                                    </tr>
                                    <tr>
                                        <td>Diamond</td>
                                        <td className="text-end w-cus1">£40.00</td>
                                    </tr>
                                    <tr>
                                        <td>Oxygen</td>
                                        <td className="text-end w-cus1">£30.00</td>
                                    </tr>
                                    <tr>
                                        <td>Pigmentation</td>
                                        <td className="text-end w-cus1">£40.00</td>
                                    </tr>
                                    <tr>
                                        <td>Vitamin C</td>
                                        <td className="text-end w-cus1">£40.00</td>
                                    </tr>
                                    <tr>
                                        <td>High frequency</td>
                                        <td className="text-end w-cus1">£40.00</td>
                                    </tr>
                                    <tr>
                                        <td>Skin polish</td>
                                        <td className="text-end w-cus1">£30.00</td>
                                    </tr>
                                    <tr>
                                        <td>Deep cleanser</td>
                                        <td className="text-end w-cus1">£25.00</td>
                                    </tr>
                                    <tr>
                                        <td>Fruit</td>
                                        <td className="text-end w-cus1">£25.00</td>
                                    </tr>
                                    <tr>
                                        <td>Rebecca special<span className="rbprctext">with LED light & eyebrow FREE</span></td>
                                        <td className="text-end w-cus1">£60.00</td>
                                    </tr>
                                    <tr>
                                        <td>Herbal<span className="rbprctext">with Kerala oil & eyebrow FREE</span></td>
                                        <td className="text-end w-cus1">£60.00</td>
                                    </tr>
                                    <tr>
                                        <td>Dermaplaning</td>
                                        <td className="text-end w-cus1">£60.00</td>
                                    </tr>
                                    <tr>
                                        <td>Hydrafacial</td>
                                        <td className="text-end w-cus1">£80.00</td>
                                    </tr>
                                    <tr>
                                        <td>Carbon Laser Peel<span className="rbprctext">Crystal Clear</span></td>
                                        <td className="text-end w-cus1">£80.00</td>
                                    </tr>
                                    <tr>
                                        <td>Microdermabrasion<span className="rbprctext">*above £40 facial neck,back,shoulder & head massage</span></td>
                                        <td className="text-end w-cus1">£80.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="rbpimg">
                            <img src={facial} alt="facial" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
)
}
export default Facial;