import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { appdownload, appstore, googleplay } from "../../assets/img";

function SectionNineThemeTwo() {
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  return (
    <>
      <section className="mobapp-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="mobapp-subtract-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mobapp-content">
                      <div className="section-title2">
                        <h2>{jsonData?.theme_2?.home?.section_9?.title}</h2>
                      </div>
                      <p>{jsonData?.theme_2?.home?.section_9?.paragraph}</p>
                      <ul className="applinks">
                        <li>
                          <a
                            target="_blank"
                            href={
                              jsonData?.theme_2?.home?.section_9?.ios_link
                            } rel="noreferrer"
                          >
                            <img src={appstore} alt="" style={{maxHeight: "50px"}} />
                          </a>
                        </li>

                        <li>
                          <a
                            target="_blank"
                            href={jsonData?.theme_2?.home?.section_9?.android_link} rel="noreferrer"
                          >
                            <img src={googleplay} alt="" style={{maxHeight: "50px"}} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mobapp-img text-center">
                      <img src="assets/home/iPhone13.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionNineThemeTwo;
