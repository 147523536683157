import React from 'react'
import { easytoimg } from '../../../assets/img'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
// import "./laundryService.css";

function SectionSevenThemeFive() {
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const navigate = useNavigate();
  const jsonData: any = useSelector<any>((state) => state?.shopHomeJsonList);
  const settingsData: any = useSelector<any>((state) => state?.settings);
  return (
    <div className="easelaundry-wrapper">
        
<div className="container">
<div className="row">
<div className="col-lg-6">
<div className="easelaundry-img-right aos-init" data-aos="zoom-in-up">
<img src={jsonData?.theme_5?.home?.section_7?.banner} className="img-fluid aos-init"  alt="Ease to Made Laundry" data-aos="zoom-in-up" />
</div>
</div>
<div className="col-lg-6">
<div data-aos="zoom-in-up" className="easelaundry-content-left text-start aos-init">
<div className="section-title text-start">
<h2 className="text-start primary-text">{jsonData?.theme_5?.home?.section_7?.title}</h2>
</div>
<div className="">
                        <p>{jsonData?.theme_5?.home?.section_7?.paragraph1}</p>

                            <p>{jsonData?.theme_5?.home?.section_7?.paragraph2}</p>
                            <ul>
                              {jsonData?.theme_5?.home?.section_7?.card_section?.map(
                                (items: any,index: any) => {
                                  return (
                                    <>
                                      <li>Step {index+1} : {items.text}</li>
                                    </>
                                  );
                                }
                              )}
                            </ul>
                           <p>{jsonData?.theme_5?.home?.section_7?.paragraph3}</p>

                            <p>{jsonData?.theme_5?.home?.section_7?.paragraph4}</p>
                       
                    </div>
</div>
</div>
</div>

</div>

    </div>
  )
}

export default SectionSevenThemeFive;