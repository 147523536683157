import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHomeJsonList,
  saveThemeJsonData,
  fetchCroppedImage,
} from "../../../redux/Actions";
import { ColorPicker } from "primereact/colorpicker";
import CreatableSelect from "react-select/creatable";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import ImageCropper from "../../imageCropper/imageCropper";
import { data } from "../data";

const SectionThreeThemeOneEdit = () => {
  const [checklist, setChecklist] = useState<any>([{ id: 1, title: "" }]);
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  // const jsonData = data
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [imagedata, setImagedata] = useState<any>("" || undefined);
  const [activeTab, setActiveTab] = useState(0);

  const images: Array<string> = [
    "assets/img/trustimg.png",
    "assets/img/easy-to-img.png",
    "assets/img/trustimg.png",
    "assets/img/easy-to-img.png",
    "assets/img/trustimg.png",
    "assets/img/easy-to-img.png",
    "assets/img/trustimg.png",
    "assets/img/easy-to-img.png",
  ];

  const saveJsonData = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  const addCollection = () => {
    let updatedCollection: any = [
      ...editData.theme_1.home.section_3.check_list,
    ];

    let newCard = {
      list: "",
    };
    updatedCollection.splice(activeTab + 1, 0, newCard);
    setActiveTab(activeTab + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      
      theme_1: {
        ...prevJsonData.theme_1,
        home: {
          ...prevJsonData.theme_1.home,
          section_3: {
            ...prevJsonData.theme_1.home.section_3,
            check_list: updatedCollection,
          },
        },
      },
    }));
  };

  const removeCollection = (index: any) => {
    let updatedCollection: any = [
      ...editData.theme_1.home.section_3.check_list,
    ];
    updatedCollection.splice(index, 1);
    setActiveTab(activeTab - 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1:{
        ...prevJsonData.theme_1,

        home: {
          ...prevJsonData.theme_1.home,
          section_3: {
            ...prevJsonData.theme_1.home.section_3,
            check_list: updatedCollection,
          },
        },
      }
    
    }));
  };

  const handleHeaderChange = (e: any,index?: any) => {
    const { value, name } = e.target;

    let updatedCollection: any = [...editData.theme_1.home.section_3.check_list];
    if (index !== undefined) {
      updatedCollection = [...editData.theme_1.home.section_3.check_list];
      updatedCollection[index] = {
        ...updatedCollection[index],
        [name]: value,
      };
    }

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1: {
        ...prevJsonData.theme_1,
        home: {
          ...prevJsonData.theme_1.home,
          section_3: {
            ...prevJsonData.theme_1.home.section_3,
            [name]: value,
            btn_group: {
              ...prevJsonData.theme_1.home.section_3.btn_group,
              btn1: {
                ...prevJsonData.theme_1.home.section_3.btn_group.btn1,
                [name]: value,
              },
            },
            check_list: updatedCollection,
          },
        },
      },
    }));
  };

  const handleImageChange = (image: string) => {
    //Update the banner value in the JSON data
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1: {
        ...prevJsonData.theme_1,
        home: {
          ...prevJsonData.theme_1.home,
          section_3: {
            ...prevJsonData.theme_1.home.section_3,
            banner: image,
          },
        },
      },
    }));
  };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleImageChange(imageLink);
    }
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150");
    // setCropImageWidth("150");
    // setSelectedCropImage(image);
    // setShowCropModal(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        handleCroppedImage(res.target.result);
      };
    }
  };

  useEffect(() => {
    const checklistData = editData?.theme_1?.home?.section_3?.check_list;
    if (checklistData) {
      setChecklist(checklistData);
    }
  }, [editData]);

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);
  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);

  return (
    <>
      {/* <Draggable> */}
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section Three Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Title</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={editData?.theme_1?.home?.section_3?.title}
                        onChange={handleHeaderChange}
                        maxLength={1200}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Paragraph</label>
                      <input
                        type="text"
                        className="form-control"
                        name="paragraph"
                        value={editData?.theme_1?.home?.section_3?.paragraph}
                        onChange={handleHeaderChange}
                        maxLength={1200}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Sub Heading</label>
                      <input
                        type="text"
                        className="form-control"
                        name="sub_heading"
                        value={editData?.theme_1?.home?.section_3?.sub_heading}
                        onChange={handleHeaderChange}
                        maxLength={1200}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Upload Image</label>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        className="form-control"
                        name="image"
                        onChange={updateImageSectionOne}
                      />
                    </div>
                    <div className="edit-section">
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="img-bg active">
                                  <img
                                    src={
                                      editData?.theme_1?.home?.section_3?.banner
                                    }
                                    alt="Card Icon"
                                  />
                                </div>
                              </div>
                              <div className="img-options">
                                <div className="row">
                                  <div className="col-md-12">
                                    <Swiper
                                      spaceBetween={15}
                                      navigation={true}
                                      modules={[Navigation]}
                                      breakpoints={{
                                        640: {
                                          slidesPerView: 1,
                                        },
                                        768: {
                                          slidesPerView: 4,
                                        },
                                        1024: {
                                          slidesPerView: 6,
                                        },
                                      }}
                                      className="homejson-slider"
                                    >
                                      {images.map(
                                        (images: string, index: any) => {
                                          return (
                                            <>
                                              <SwiperSlide>
                                                <div
                                                  className="img-option"
                                                  key={index}
                                                  onClick={() =>
                                                    handleImageChange(images)
                                                  }
                                                >
                                                  <img
                                                    src={images}
                                                    alt="Hero Image"
                                                    className="img-fluid"
                                                  />
                                                </div>
                                              </SwiperSlide>
                                            </>
                                          );
                                        }
                                      )}
                                    </Swiper>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                  {editData?.theme_1?.home?.section_3?.check_list.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={index}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              activeTab === index ? "active" : ""
                            }`}
                            onClick={() => setActiveTab(index)}
                          >
                            Check List {index + 1}
                          </a>
                          <a
                            className="tab-close"
                            onClick={() => {
                              removeCollection(index);
                            }}
                          >
                            <i className="fas fa-times" aria-hidden="true"></i>
                          </a>
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
                {editData?.theme_1?.home?.section_3?.check_list.map(
                  (card: any, index: any) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        activeTab === index ? "show active" : ""
                      }`}
                    >
                   
                      <div className="mb-3">
                        <label className="form-label">
                          Check List<small>(max 250 char)</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="list"
                          value={card.list}
                          onChange={(e) => handleHeaderChange(e, index)}
                          maxLength={250}
                        />
                      </div>
                      
           
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="add-section">
              <div className="row">
                <div
                  onClick={() => {
                    addCollection();
                  }}
                  className="col text-center"
                >
                  Add Card<i className="fas fa-plus-square ms-2"></i>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className=" mb-3">
                    <label className="form-label">Button</label>
                    <input
                      type="text"
                      className="form-control"
                      name="text"
                      value={
                        editData?.theme_1?.home?.section_3?.btn_group?.btn1
                          ?.text
                      }
                      onChange={handleHeaderChange}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className=" mb-3">
                    <label className="form-label">Bg Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="bg_color"
                        value={
                          editData?.theme_1?.home?.section_3?.btn_group?.btn1
                            ?.bg_color
                        }
                      />
                      <ColorPicker
                        name="bg_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.theme_1?.home?.section_3?.btn_group?.btn1
                            ?.bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className=" mb-3">
                    <label className="form-label">Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="color"
                        value={
                          editData?.theme_1?.home?.section_3?.btn_group?.btn1
                            ?.color
                        }
                      />
                      <ColorPicker
                        name="color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.theme_1?.home?.section_3?.btn_group?.btn1
                            ?.color
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonData();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </Draggable> */}
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default SectionThreeThemeOneEdit;
