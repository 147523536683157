import React from "react";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";

const SectionNineThemeOne = () => {
  const jsonData: any = useSelector<any>((state) => state?.shopHomeJsonList);
  // HTML
  return (
    <>
      <section className="sh1-faq-section">
        <div className="sh1-faq-subtract-top"></div>
        <div className="sh1-faq-subtract-body">
          <div className="container-fluid">
            <div className="row justify-content-end">
              <div className="col-md-5">
                <div className="sh1-faq-content">
                  <h2 className="sh1-section-title">
                  {jsonData?.theme_1?.home?.section_9?.title}
                  </h2>
                  <p>
                  {jsonData?.theme_1?.home?.section_9?.paragraph}
                  </p>
                  <Accordion className="sh1-faq-home">
                {jsonData?.theme_1?.home?.section_9?.card_section?.collection &&
                    Object.keys(jsonData.theme_1?.home?.section_9?.card_section?.collection).map(
                      (key) => (
                      <Accordion.Item eventKey={key}>
                        <Accordion.Header>
                        {jsonData.theme_1?.home?.section_9?.card_section?.collection[key].collection_title}
                
                          </Accordion.Header>
                        <Accordion.Body>
                        {jsonData.theme_1?.home?.section_9?.card_section?.collection[key].collection_paragraph}

      
                          </Accordion.Body>
                      </Accordion.Item>
                       )
                       )}
                </Accordion>
                </div>
              </div>
              <div className="col-md-6">
                <div className="sh1-mobapp-img sh1-mobapp-img-faq text-center">
                  <img src={jsonData?.theme_1?.home?.section_9?.banner[0]?.banner} alt="" />
                  <div className="sh1-mobapp-img-overlay">
                    <p>{jsonData?.theme_1?.home?.section_9?.card_heading}</p>
                    <h4>{jsonData?.theme_1?.home?.section_9?.card_number}</h4>
                    <a
                      href={`tel:${jsonData?.theme_1?.home?.section_9?.card_number}`}
                      className="btn secondary-btn hover-btn"
                    >
                      {jsonData?.theme_1?.home?.section_9?.card_buttontext}<i className="fas fa-chevron-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sh1-faq-subtract-bottom d-none"></div>
      </section>
    </>
  );
};

export default SectionNineThemeOne;
