import React, { useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  alphabet,
  email,
  phoneNumber,
  alphaNumericPattern,
} from "../core/regex";
import { postContactForm } from "../../redux/Actions";

const contactUsFormSchema = yup.object({
  name: yup
    .string()
    .required("Name is required")
    .max(50, "Enter cannot exceed 50 character")
    .matches(alphabet, "Name should be only alphabet")
    .trim(),
  email: yup
    .string()
    .required("Email address is required")
    .matches(email, "Enter a valid email address")
    .max(96, "Enter cannot exceed 96 character")
    .trim(),
  mobile: yup
    .string()
    .required("Mobile number is required")
    .matches(phoneNumber, "Enter a valid mobile number")
    .trim(),
  message: yup
    .string()
    .required("Message is required")
    .matches(
      alphaNumericPattern,
      "Message cannot not contain any special character"
    )
    .max(250, "Message cannot exceed 250 character")
    .trim(),
  contact: yup.number().default(1),
});

function ContactWheatley() {
  const [message, setMessage] = useState<string>("");

  const {
    handleSubmit: contactFormSubmit,
    control: contactFormControl,
    reset: contactFormReset,
    formState: { errors: contactFormErrors },
  } = useForm({
    resolver: yupResolver(contactUsFormSchema),
    defaultValues: {
      email: "",
      message: "",
      name: "",
      mobile: "",
      contact: 1,
    },
  });

  const submitContactForm = async (data: any) => {
    const response: any = await postContactForm(data);
    if (response.status) {
      // setContactUs("contactus-grid");
      contactFormReset();
      setMessage(response.message);
      setTimeout(() => {
        setMessage("");
      }, 3000);
    } else {
      setMessage(response.message);
    }
  };

  return (
    <>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Contact Us</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span>Contact Us</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="my-4">
              <h3 className="text-primary">
                Contact <span className="text-secondary">Us</span>
              </h3>
              <p>
                Send your comments through this form and we will get back to
                you.
              </p>
              <form onSubmit={contactFormSubmit(submitContactForm)}>
                <div className="contact-form mt-4">
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <Controller
                          name="name"
                          control={contactFormControl}
                          render={({ field }) => (
                            <input
                              placeholder="Your name"
                              {...field}
                              className={`form-control ${
                                contactFormErrors?.name ? "invalid" : ""
                              }`}
                            />
                          )}
                        />
                        {contactFormErrors.name ? (
                          <small className="text-danger">
                            {contactFormErrors.name?.message}
                          </small>
                        ) : null}
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <Controller
                          name="email"
                          control={contactFormControl}
                          render={({ field }) => (
                            <input
                              placeholder="Enter email"
                              {...field}
                              className={`form-control ${
                                contactFormErrors?.email ? "invalid" : ""
                              }`}
                            />
                          )}
                        />
                        {contactFormErrors.email ? (
                          <small className="text-danger">
                            {contactFormErrors.email?.message}
                          </small>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <Controller
                      name="mobile"
                      control={contactFormControl}
                      render={({ field }) => (
                        <input
                          type="text"
                          placeholder="Enter phone number"
                          {...field}
                          className={`form-control ${
                            contactFormErrors?.mobile ? "invalid" : ""
                          }`}
                        />
                      )}
                    />
                    {contactFormErrors.mobile ? (
                      <small className="text-danger">
                        {contactFormErrors.mobile?.message}
                      </small>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Controller
                      name="message"
                      control={contactFormControl}
                      render={({ field }) => (
                        <textarea
                          {...field}
                          placeholder="Enter Message"
                          className={`form-control ${
                            contactFormErrors?.message
                              ? "invalid text-danger"
                              : ""
                          }`}
                        ></textarea>
                      )}
                    />
                    {contactFormErrors.message ? (
                      <small className="text-danger">
                        {contactFormErrors.message?.message}
                      </small>
                    ) : null}
                  </div>
                  {message != "" ? (
                    <div className="mb-3">
                      <small className="text-danger">{message}</small>
                    </div>
                  ) : null}
                  <div className="mb-3">
                    <button className="btn btn-primary">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="my-4">
              <h3 className="text-primary">
                Google <span className="text-secondary">Map</span>
              </h3>
              <div className="">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19221.6255543033!2d-1.1371800000000003!3d51.746769!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876ea0e03fc5b55%3A0xe7b36b5f85addd53!2s68%20High%20St%2C%20Wheatley%2C%20Oxford%20OX33%201XP%2C%20UK!5e1!3m2!1sen!2sus!4v1700747851137!5m2!1sen!2sus"
                  width="600"
                  height="400"
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ContactWheatley;
