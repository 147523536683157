import React from "react";
import Home from "./home";

function UpLands() {
    return (
        <>
            <Home/>
        </>
    )
}

export default UpLands;