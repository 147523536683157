import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import Notification from "../../notification";
import Profile from "../../profile";
import QuickOrderPopup from "../../quickOrderPopup";
import CartSummary from "../../cartSummery";
import { useDispatch, useSelector } from "react-redux/es/exports";
import AOS from "aos";
import "aos/dist/aos.css";
import Pusher from "pusher-js";
import { mobilemenu, moreitems } from "../../../assets/img";
import { fetchHomeJsonList, getHomeJsonList } from "../../../redux/Actions";
import { checkShopListFlow, getValue, setValue } from "../../../utility";
import { fetchPostalCodes } from "../../../redux/Actions";
import axios from "axios";

// import ReactGA from 'react-ga';

// const TRACKING_ID = "UA-265690492-2"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

const getUser = () => {
  const token: any = localStorage.getItem("userDetails");
  return token ? JSON.parse(token) : "";
};

function HeaderThreeShop() {
  const [changePopup, setChangePopup] = useState(false);
  const [changePopupProfile, setChangePopupProfile] = useState(false);
  const [quickBookPopup, setQuickBookPopup] = useState(false);
  const [cartSummary, setCartSummary] = useState(false);
  const [userName, setUserName] = useState<any>("");
  const [userToken, setUserToken] = useState();

  const [cartValue, setCartValue] = useState<any>([]);
  const [pusherKey, setpusherKey] = useState<any>("");
  const state: any = useSelector<any>((state) => state);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const { cartCount }: any = useSelector<any>((state) => state);
  const [notifyCount, setNotifyCount] = useState<any>([]);
  const [cartCounts, setCartCount] = useState<number>(0);
  const [finalTotalPrice, setFinalTotalPrice] = useState<number>(0);
  const name = localStorage.getItem("username");
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const dispatch = useDispatch<any>();
  const shopHomeData: any = useSelector<any>((state) => state?.shopHome);
  const homedata: any = useSelector<any>((state) => state?.shopHomeJsonList);
  const shopURL = window.location.href;
  const clientPostcode: any = shopHomeData?.postcode;
  useEffect(() => {}, [homedata]);

  useEffect(() => {}, [window.location.href]);

  const selectedCategories: any = useSelector<any>(
    (state) => state.userSelectedCategories
  );

  const settingsData: any = useSelector<any>((state) => state?.settings);

  const base_url = process.env.REACT_APP_BACKEND_URL;
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;
  const activeUrl = window.location.pathname;
  const urlParts = activeUrl.split("/");
  const splitUrl = urlParts[3];
  const [settings, setSettings] = useState<any>({});
  const [notificationList, setNotificationList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    dispatch(getHomeJsonList());
  }, []);
  useEffect(() => {
    setSettings(settingsData);
  }, [settingsData]);
  useEffect(() => {
    if (state.userDetails.data) {
      const user_info = state.userDetails.data?.user_info;
      const { name = "" } = user_info;
      // const token = getToken();
      setUserToken(name);
      setUserName(name);
    }

    const selectedMainCategory = selectedCategories?.filter((item: any) => {
      return item?.sub_categories?.some(
        (subItem: any) => parseInt(subItem?.quantity) > 0
      );
    });

    let finalTotalPrice = 0;
    let itemsCount = 0;

    if (selectedMainCategory.length) {
      selectedMainCategory?.map((item: any) => {
        const subcategoryList = item?.sub_categories?.filter(
          (subItem: any) => parseInt(subItem.quantity) > 0
        );
        itemsCount = itemsCount + subcategoryList?.length;
        subcategoryList?.map((subcategory: any) => {
          finalTotalPrice =
            finalTotalPrice +
            parseInt(subcategory?.quantity) *
              parseFloat(subcategory?.total_price);
        });
      });

      // cartInformationData?.map((item: any) => {
      //   const subcategoryList = item?.sub_categories?.filter(
      //     (subItem: any) => parseInt(subItem.quantity) > 0);
      // });
    }
    setFinalTotalPrice(finalTotalPrice);
    setCartCount(itemsCount);
  }, [state]);

  const navigate = useNavigate();

  useEffect(() => { 
    if(shopURL.includes("rebeccabeauty")){
    localStorage.setItem("postalCode", JSON.stringify(clientPostcode));
    //setPinCodeFormValue("pinCode", clientPostcode);
    dispatch(fetchPostalCodes(clientPostcode));
    setPostalCodeValue(clientPostcode);
    }
  },[]);

  const navigateToproducts = () => {
    if (checkShopListFlow()) {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      const cartProduct = getValue('ProductsFrom')
      if(cartProduct == 0){
        setValue("cartInformationData", []);
      }
      navigate(`/shop/${finalResult}/Listsproduct`);
    } else {
      navigate("/Listsproduct");
    }
  } else {
    navigate("/Listsproduct");
}
} else {
  navigate("/Listsproduct");
}
  };

  const navigateToHome = () => {
    if (checkShopListFlow()) {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}`);
    } else {
      navigate("/");
    }
  } else {
    navigate("/");
}
} else {
  navigate("/");
}
  };

  const navigateToPricing = () => {
    if (checkShopListFlow()) {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/pricing`);
    } else {
      navigate("/pricing");
    }
  } else {
    navigate("/pricing");
}
} else {
  navigate("/pricing");
}
  };
  const navigateToThreading = () => {
    if (checkShopListFlow()) {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/threading`);
    } else {
      navigate("/threading");
    }
  } else {
    navigate("/threading");
}
} else {
  navigate("/threading");
}
  };
  
  const navigateTohaircolour = () => {
    if (checkShopListFlow()) {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/haircolour`);
    } else {
      navigate("/haircolour");
    }
  } else {
    navigate("/haircolour");
}
} else {
  navigate("/haircolour");
}
  };
  const navigateToMassage = () => {
    if (checkShopListFlow()) {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/massage`);
    } else {
      navigate("/massage");
    }
  } else {
    navigate("/massage");
}
} else {
  navigate("/massage");
}
  };
  const navigateToFacial = () => {
    if (checkShopListFlow()) {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/facial`);
    } else {
      navigate("/facial");
    }
  } else {
    navigate("/facial");
}
} else {
  navigate("/facial");
}
  };
  const navigateToEyelash = () => {
    if (checkShopListFlow()) {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/eyelash`);
    } else {
      navigate("/eyelash");
    }
  } else {
    navigate("/eyelash");
}
} else {
  navigate("/eyelash");
}
  };
  const navigateToWaxing = () => {
    if (checkShopListFlow()) {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/waxing`);
    } else {
      navigate("/waxing");
    }
  } else {
    navigate("/waxing");
}
} else {
  navigate("/waxing");
}
  };
  const navigateTonailservice = () => {
    if (checkShopListFlow()) {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/nailservices`);
    } else {
      navigate("/nailservices");
    }
  } else {
    navigate("/nailservices");
}
} else {
  navigate("/nailservices");
}
  };
  const navigateTomakup = () => {
    if (checkShopListFlow()) {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/makup`);
    } else {
      navigate("/makup");
    }
  } else {
    navigate("/makup");
}
} else {
  navigate("/makup");
}
  };
  const navigateToAreaCovered = () => {
    if (checkShopListFlow()) {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/areaCovered`);
    } else {
      navigate("/areaCovered");
    }
  } else {
    navigate("/areaCovered");
}
} else {
  navigate("/areaCovered");
}
  };

  const navigateToFAQ = () => {
    if (checkShopListFlow()) {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/faq`);
    } else {
      navigate("/faq");
    }
  } else {
    navigate("/faq");
}
} else {
  navigate("/faq");
}
  };

  const handleLogout = (e: any) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };
  useEffect(() => {
    AOS.init();
    // AOS.refresh();
  }, []);

  const [sticky, setSticky] = useState("");

  // on render, set listener
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 100 ? "fixed" : "";
    setSticky(stickyClass);
  };

  const fixedheader = `sh3-header ${sticky}`;
  function addNotify() {
    setNotifyCount((prevState: any) => [...prevState, "1"]);
  }

  let oldNotify = "";

  useEffect(() => {
    if (localStorage.getItem("userDetails")) {
      const userInfo = JSON.parse(localStorage.getItem("userDetails")!);

      const { data } = userInfo;

      if (data != null) {
        // const notifyLocalCount = localStorage.getItem('notifyCount');

        const interest = data.user_info.notification_interest_list;

        if (interest[1] != undefined) {
          const intrst = interest[1];
          const pusher = new Pusher(pusherKey, {
            cluster: "eu",
          });

          const channel = pusher.subscribe(intrst);
          channel.bind("my-event", function (datas: any) {
            const event = datas.order;
            // alert(event.title);
            // toast.dismiss();

            // setNotifyCount(notifyCount);

            // var newArray = notifyCount.concat('1');

            //

            // const rand = Math.random();

            // setNotifyCount(notifyCount.concat(rand));
            // setNotifyCount((prevState: any) => ([...prevState, '1']))

            if (oldNotify != event.order_id) {
              addNotify();
              oldNotify = event.order_id;
            }

            // localStorage.setItem('notifyCount', `${parseInt(notifyLocalCount!) + 1}`);

            // setNotifyCount(notifyCount + 1);
            // toast(<div>{event.title}<br />{event.body}</div>, { autoClose: false });
          });
        }
      }

      if (data) {
        const user_info = data?.user_info;
        const { name = "" } = user_info;
        // const token = getToken();
        setUserToken(name);
        setUserName(name);
      }

      // setUserToken(token);
    }
    const handler = (event: { target: Node | null }) => {
      if (menuRef.current) {
        if (!menuRef.current.contains(event.target)) {
          setChangePopupProfile(false);
          setCartSummary(false);
        }
      }
    };
    document.addEventListener("mousedown", function name(params: any) {
      handler(params);
    });
    document.addEventListener("scroll", function name() {
      // AOS.init();
      AOS.refresh();
    });
  }, []);

  const location = useLocation();

  useEffect(() => {
    sidebarClose();
    cartboxClose();
  }, [location]);

  const sidebar = document.getElementById("sidebar");
  const overlay = document.getElementById("overlay");
  const cartoverlay = document.getElementById("cd-shadow-layer");
  const cdcart = document.getElementById("cd-cart");

  const cartboxClose = () => {
    cdcart?.classList.remove("speed-in");
    cartoverlay?.classList.remove("is-visible");
  };
  const cartboxOpen = () => {
    cdcart?.classList.add("speed-in");
    cartoverlay?.classList.add("is-visible");
  };

  const sidebarClose = () => {
    sidebar?.classList.remove("active");
    overlay?.classList.remove("active");
  };
  const sidebarOpen = () => {
    sidebar?.classList.add("active");
    overlay?.classList.add("active");
  };

  function openQuickOrderPopup() {
    setQuickBookPopup(!quickBookPopup);
    localStorage.setItem("disableLoader", "yes");
  }
  function closeQuickOrderPopup() {
    setQuickBookPopup(false);
    localStorage.removeItem("disableLoader");
  }

  function fetchNotifications(){
    const token = localStorage.getItem("token");
    axios({
      method: "get",
      url: `${base_url}/api/user_notification_list`,
      headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST', 'Authorization': 'Bearer ' + token },
    }).then((response) => {
      if (response.status != 401) {
        setNotificationList(response.data.data);
      }
    }).catch(function (error) {
      
    })
  }

  function loadNotifications() {
    fetchNotifications();
    setChangePopup(!changePopup);
  }

  return (
    <div>
      {/* <div className="home-offer">
          <p className="title">Limited Time Offer 32% Discount on First Booking</p><button className="btn">Know More</button>
          <a className="home-offer-close"><i className="uil uil-times-circle"></i></a>
      </div> */}
      {homedata && (
        <style type="text/css">
          {` 
            :root {
              --primary-color: #${homedata?.theme_3?.home?.header?.primary_color};
              --secondary-color: #${homedata?.theme_3?.home?.header?.secondary_color};
              --nav-color: #${homedata?.theme_3?.home?.header?.nav?.menu_font_color};
              --nav-color-hover: #${homedata?.theme_3?.home?.header?.nav?.menu_font_color_hover};
              --nav-color-active: #${homedata?.theme_3?.home?.header?.nav?.menu_font_color_active};
              --btn1-color:#${homedata?.theme_3?.home?.header?.btn_group?.btn1?.color};
              --btn1-border_color:#${homedata?.theme_3?.home?.header?.btn_group?.btn1?.border_color};
              --btn1-bg_color:#${homedata?.theme_3?.home?.header?.btn_group?.btn1?.bg_color};
              --btn2-color:#${homedata?.theme_3?.home?.header?.btn_group?.btn2?.color};
              --btn2-border_color:#${homedata?.theme_3?.home?.header?.btn_group?.btn2?.border_color};
              --btn2-bg_color:#${homedata?.theme_3?.home?.header?.btn_group?.btn2?.bg_color};
          }
          `}
        </style>
      )}
      <header
        className={fixedheader}
        style={{
          background: `#${homedata?.theme_3?.home?.header?.nav?.bg_color}`,
        }}
      >
        <div className="sh3-header-top">
          <div className="container-fluid">
            <div className="row sh3-header-nav">
              <div className="col-md-auto col-sm-12">
                <div className="sh3-logo">
                  <span
                    id="sidebarCollapse"
                    className="d-block d-md-none"
                    onClick={sidebarOpen}
                  >
                    <img src={mobilemenu} className="img-fluid" />
                  </span>
                  <Link to="/">
                    <img
                      id="site_logo"
                      src={`${shopHomeData?.home_page_settings?.shop_home_page_image.logo[0].file_name}`}
                      alt=""
                    />
                  </Link>
                  <span className="mob-user hidden-md dropdown d-block d-md-none">
                    <img
                      src={moreitems}
                      className="img-fluid"
                      alt=""
                      data-bs-toggle="dropdown"
                    />
                    <ul
                      role="menu"
                      className="dropdown-menu"
                      aria-labelledby="drop1"
                    >
                      {homedata?.theme_3?.home?.header?.btn_group?.btn2
                        ?.btn2_is_enable && (
                          <>
                          {shopHomeData?.quick_order_mode !=
                        0 &&
                        <li onClick={() => setQuickBookPopup(!quickBookPopup)}>
                          <a
                            href="javascript:void(0);"
                            data-bs-toggle="modal"
                            data-bs-target="#quickordermodal"
                            style={{
                              background: `${homedata?.theme_3?.home?.btn_group?.btn2?.bg_color}`,
                              color: `${homedata?.theme_3?.homedata?.theme_3?.home?.btn_group?.btn2?.color}`,
                              borderColor: `${homedata?.theme_3?.home?.btn_group?.btn2?.border_color}`,
                            }}
                          >
                            <i className="uil uil-shopping-cart-alt me-2"></i>
                            {
                              homedata?.theme_3?.home?.header?.btn_group?.btn2
                                ?.text
                            }
                          </a>
                        </li>
                          }
                        </>
                      )}
                      {!userToken && (
                        <li className="login">
                          <Link
                            to="/login"
                            style={{
                              background: `${homedata?.theme_3?.home?.btn_group?.btn1?.bg_color}`,
                              color: `${homedata?.theme_3?.homedata?.theme_3?.home?.btn_group?.btn1?.color}`,
                              borderColor: `${homedata?.theme_3?.home?.btn_group?.btn1?.border_color}`,
                            }}
                          >
                            <i className="uil uil-user-circle me-2"></i>{" "}
                            {
                              homedata?.theme_3?.home?.header?.btn_group?.btn1
                                ?.text
                            }
                          </Link>
                        </li>
                      )}
                      {userToken && (
                        <li className="dropdown menu-hover-dropdown">
                          <a
                            className="menuitem"
                            href="#"
                            onClick={(e) => handleLogout(e)}
                            style={{
                              background: `${homedata?.theme_3?.home?.btn_group?.btn1?.bg_color}`,
                              color: `${homedata?.theme_3?.homedata?.theme_3?.home?.btn_group?.btn1?.color}`,
                              borderColor: `${homedata?.theme_3?.home?.btn_group?.btn1?.border_color}`,
                            }}
                          >
                            <i className="uil uil-sign-out-alt me-2"></i>
                            {
                              homedata?.theme_3?.home?.header?.btn_group?.btn1
                                ?.text
                            }
                          </a>
                        </li>
                      )}
                    </ul>
                  </span>
                </div>
                {settings?.WebsiteSettings?.site_title_en ==
                  "Insta Laundry" && (
                  <div className="contact">
                    <ul className="header-topnav-contact">
                      <li>
                        <a
                          href={`tel:${homedata?.home?.footer?.footer_section?.callnow_for_Services_section?.mbl_no?.label}`}
                          className=""
                        >
                          <i className="uil uil-phone me-2"></i>
                          {
                            homedata?.home?.footer?.footer_section
                              ?.callnow_for_Services_section?.mbl_no?.label
                          }
                        </a>
                      </li>
                      <li>
                        <a
                          href={`mailto:${homedata?.home?.footer?.footer_section?.callnow_for_Services_section?.email?.value}`}
                          className=""
                        >
                          <i className="uil uil-envelope me-2"></i>
                          {
                            homedata?.home?.footer?.footer_section
                              ?.callnow_for_Services_section?.email?.value
                          }
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="col-md col-sm-12">
                <div
                  className="d-none d-md-flex align-items-center h-100"
                  ref={menuRef}
                >
                  <ul className="sh3-header-link">
                    <li className="menu-hover">
                      {splitUrl === undefined ? (
                        <a className="nav-bar-link active" 
                        onClick={navigateToHome}
                          style={{ cursor: "pointer" }}>
                          Home
                        </a>
                      ) : (
                        <a className="nav-bar-link"
                        onClick={navigateToHome}
                          style={{ cursor: "pointer" }}>
                          Home
                        </a>
                      )}
                    </li>
                    <li className="menu-hover">
                      {splitUrl === "pricing" ? (
                        <a
                          className="nav-bar-link active"
                          onClick={navigateToPricing}
                          style={{ cursor: "pointer" }}
                        >
                          Pricing
                        </a>
                      ) : (
                        <a
                          className="nav-bar-link"
                          onClick={navigateToPricing}
                          style={{ cursor: "pointer" }}
                        >
                          Pricing
                        </a>
                      )}
                    </li>
                    {shopURL.includes("rebeccabeauty") && (
                      <>                        
                        <li>
                        {splitUrl === "threading" ? (
                        <a
                            className="nav-bar-link active"
                            onClick={navigateToThreading}
                            style={{ cursor: "pointer" }}
                          >
                            Threading
                          </a>
                        ) : (
                          <a
                            className="nav-bar-link"
                            onClick={navigateToThreading}
                            style={{ cursor: "pointer" }}
                          >
                            Threading
                          </a>
                        )}
                        </li>
                        <li>
                        {splitUrl === "waxing" ? (
                        <a
                            className="nav-bar-link active"
                            onClick={navigateToWaxing}
                            style={{ cursor: "pointer" }}
                          >
                            Waxing
                          </a>
                        ) : (
                          <a
                            className="nav-bar-link"
                            onClick={navigateToWaxing}
                            style={{ cursor: "pointer" }}
                          >
                            Waxing
                          </a>
                        )}
                        </li>
                        <li>
                        {splitUrl === "facial" ? (
                        <a
                            className="nav-bar-link active"
                            onClick={navigateToFacial}
                            style={{ cursor: "pointer" }}
                          >
                            Facial
                          </a>
                        ) : (
                          <a
                            className="nav-bar-link"
                            onClick={navigateToFacial}
                            style={{ cursor: "pointer" }}
                          >
                            Facial
                          </a>
                        )}
                        </li>
                        <li>
                        {splitUrl === "eyelash" ? (
                        <a
                            className="nav-bar-link active"
                            onClick={navigateToEyelash}
                            style={{ cursor: "pointer" }}
                          >
                            Eyelash Extension
                          </a>
                        ) : (
                          <a
                            className="nav-bar-link"
                            onClick={navigateToEyelash}
                            style={{ cursor: "pointer" }}
                          >
                            Eyelash Extension
                          </a>
                        )}
                        </li>
                        <li>
                        {splitUrl === "massage" ? (
                        <a
                            className="nav-bar-link active"
                            onClick={navigateToMassage}
                            style={{ cursor: "pointer" }}
                          >
                            Massage
                          </a>
                        ) : (
                          <a
                            className="nav-bar-link"
                            onClick={navigateToMassage}
                            style={{ cursor: "pointer" }}
                          >
                            Massage
                          </a>
                        )}
                        </li>
                        <li>
                        {splitUrl === "haircolour" ? (
                        <a
                            className="nav-bar-link active"
                            onClick={navigateTohaircolour}
                            style={{ cursor: "pointer" }}
                          >
                            Hair & Colours
                          </a>
                        ) : (
                          <a
                            className="nav-bar-link"
                            onClick={navigateTohaircolour}
                            style={{ cursor: "pointer" }}
                          >
                            Hair & Colours
                          </a>
                        )}
                        </li>
                        <li>
                        {splitUrl === "nailservices" ? (
                        <a
                            className="nav-bar-link active"
                            onClick={navigateTonailservice}
                            style={{ cursor: "pointer" }}
                          >
                            Nail Services
                          </a>
                        ) : (
                          <a
                            className="nav-bar-link"
                            onClick={navigateTonailservice}
                            style={{ cursor: "pointer" }}
                          >
                            Nail Services
                          </a>
                        )}
                        </li>
                        <li>
                        {splitUrl === "makup" ? (
                        <a
                            className="nav-bar-link active"
                            onClick={navigateTomakup}
                            style={{ cursor: "pointer" }}
                          >
                            Makeup
                          </a>
                        ) : (
                          <a
                            className="nav-bar-link"
                            onClick={navigateTomakup}
                            style={{ cursor: "pointer" }}
                          >
                            Makeup
                          </a>
                        )}
                        </li>
                        <li>
                          {splitUrl === "Listsproduct" ? (
                          <a
                              className="nav-bar-link active"
                              onClick={navigateToproducts}
                              style={{ cursor: "pointer" }}
                            >
                              Products
                            </a>
                          ) : (
                            <a
                              className="nav-bar-link"
                              onClick={navigateToproducts}
                              style={{ cursor: "pointer" }}
                            >
                              Products
                            </a>
                          )}
                        </li>
                      </>
                    )}
                    {shopHomeData?.profile?.address_required == 0 ? ("") :(
                      <li className="menu-hover">
                      {splitUrl === "areaCovered" ? (
                        <a
                          className="nav-bar-link active"
                          onClick={navigateToAreaCovered}
                          style={{ cursor: "pointer" }}
                        >
                          Area Covered
                        </a>
                      ) : (
                        <a
                          className="nav-bar-link"
                          onClick={navigateToAreaCovered}
                          style={{ cursor: "pointer" }}
                        >
                          Area Covered
                        </a>
                      )}
                    </li>
                    ) }
                    
                    {/* <li className="faq-menu menu-hover">
                      {splitUrl === "faq" ? (
                        <a
                          className="nav-bar-link active"
                          onClick={navigateToFAQ}
                          style={{ cursor: "pointer" }}
                        >
                          FAQ
                        </a>
                      ) : (
                        <a
                          className="nav-bar-link"
                          onClick={navigateToFAQ}
                          style={{ cursor: "pointer" }}
                        >
                          FAQ
                        </a>
                      )}
                    </li> */}
                    {settings?.WebsiteSettings?.site_title_en ==
                      "Insta Laundry" && (
                      <li className="faq-menu menu-hover">
                        {activeUrl === "/aboutUs" ? (
                          <NavLink
                            className="nav-bar-link active"
                            to="/aboutUs"
                          >
                            About Us
                          </NavLink>
                        ) : (
                          <NavLink className="nav-bar-link" to="/aboutUs">
                            About Us
                          </NavLink>
                        )}
                      </li>
                    )}
                    {/* <li className="faq-menu menu-hover">
                    <a target="_blank" href={blogUrl}>Blog</a>
                  </li> */}

                    {userToken && (
                      <li className="dropdown menu-hover-dropdown login-link cart-list dropdown-notification">
                        <Link
                          to="#"
                          id="drop1"
                          data-bs-toggle="dropdown"
                          className="dropdown-toggle"
                        >
                          <i
                            className="fa fa-bell pt-2 float-left"
                            aria-hidden="true"
                            onClick={() => loadNotifications()}
                          ></i>
                          {/* <span
                            id="notify_total_count"
                            className="count-blk pb-1"
                          >
                            {notifyCount.length}
                          </span> */}
                        </Link>
                        <div className="dropdown-menu">
                        <Notification
                            notifications={notificationList}
                            loaded={isLoaded}
                           />
                        </div>
                      </li>
                    )}

                    {userToken && (
                      <li className="dropdown menu-hover-dropdown">
                        <Link
                          to="#"
                          id="drop1"
                          data-bs-toggle="dropdown"
                          className="dropdown-toggle"
                          role="button"
                          onClick={() =>
                            setChangePopupProfile(!changePopupProfile)
                          }
                        >
                          {`Hi ${name || userName}!`}
                          <b className="caret"></b>
                        </Link>
                        <div className="dropdown-menu">{<Profile />}</div>
                      </li>
                    )}
                  </ul>
                  {activeUrl != "/checkout" && (
                    <ul className="sh3-header-link-right">
                      {/* <li className="d-none d-xl-inline-block"><a href={`tel:${settingsData?.WebsiteSettings?.contact_t5}`}><img src={phonecall} alt="" /><span>{settingsData?.WebsiteSettings?.contact_t5}</span></a></li> */}
                      <li>
                        <div
                          className="sh3-header-cart-box"
                          onClick={cartboxOpen}
                          style={{
                            background: `#${homedata?.theme_3?.home?.header?.nav?.cart_section?.bg_color}`,
                          }}
                        >
                          <i
                            className="uil uil-shopping-cart"
                            style={{
                              background: `#${homedata?.theme_3?.home?.header?.nav?.cart_section?.cart_icon_bg_color}`,
                              color: `#${homedata?.theme_3?.home?.header?.nav?.cart_section?.cart_label_section?.cart_icon_color}`,
                            }}
                          ></i>
                          <div className="sh3-cart-box-info">
                            <span
                              id="cart_total_count"
                              style={{
                                color: `#${homedata?.theme_3?.home?.header?.nav?.cart_section?.cart_label_section?.color}`,
                              }}
                            >
                              {
                                homedata?.theme_3?.home?.header?.nav
                                  ?.cart_section?.cart_label_section?.label
                              }{" "}
                              {cartCounts}
                            </span>
                            <span
                              style={{
                                color: `#${homedata?.theme_3?.home?.header?.nav?.cart_section?.cart_value_color}`,
                              }}
                              className="sh3-count-blk title"
                            >
                              {settingsData?.WebmasterSettings?.currency}
                              {finalTotalPrice}
                            </span>
                          </div>
                        </div>
                      </li>
                      {!userToken &&
                        homedata?.theme_3?.home?.header?.btn_group?.btn1
                          ?.btn1_is_enable === true && (
                          <>
                            <li className="sh3-header-auth sh3-header-box menu-hover">
                              <Link
                                to="/login"
                                style={{
                                  border: `2px solid #${homedata?.theme_3?.home?.header?.btn_group?.btn1?.border_color}`,
                                  background: `#${homedata?.theme_3?.home?.header?.btn_group?.btn1?.bg_color}`,
                                  color: `#${homedata?.theme_3?.home?.header?.btn_group?.btn1?.color}`,
                                }}
                              >
                                <i className="uil uil-users-alt"></i>
                                {
                                  homedata?.theme_3?.home?.header?.btn_group
                                    ?.btn1?.text
                                }
                              </Link>
                            </li>
                          </>
                        )}
                      {settingsData?.WebmasterSettings?.booking_online_orders ==
                        1 &&
                      homedata?.theme_3?.home?.header?.btn_group?.btn2
                        ?.btn2_is_enable === true ? (
                        <>
                        
                        {shopHomeData?.quick_order_mode !=
                        0 &&
                          <li
                            className="login menu-hover "
                            onClick={openQuickOrderPopup}
                          >
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#quickordermodal"
                              className="title-btn btn"
                              style={{
                                border: `2px solid #${homedata?.theme_3?.home?.header?.btn_group?.btn2?.border_color}`,
                                background: `#${homedata?.theme_3?.home?.header?.btn_group?.btn2?.bg_color}`,
                                color: `#${homedata?.theme_3?.home?.header?.btn_group?.btn2?.color}`,
                              }}
                            >
                              <i className="uil uil-shuffle"></i>
                              {
                                homedata?.theme_3?.home?.header?.btn_group?.btn2
                                  ?.text
                              }
                            </Link>
                          </li>
                        }
                        </>
                      ) : null}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <nav
        id="sidebar"
        className="mCustomScrollbar _mCS_1 mCS-autoHide mCS_no_scrollbar"
        style={{ overflow: "visible" }}
      >
        <div
          id="mCSB_1"
          className="mCustomScrollBox mCS-minimal mCSB_vertical mCSB_outside"
          style={{ maxHeight: "480px" }}
        >
          <div
            id="mCSB_1_container"
            className="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
            style={{ position: "relative", top: "0px", left: "0px" }}
            dir="ltr"
          >
            <div id="dismiss" onClick={sidebarClose}>
              <i className="fa fa-arrow-left"></i>
            </div>
            <div className="sidebar-header"></div>
            <ul className="list-unstyled components">
              <li>
                <a className="moblink" onClick={navigateToHome}>Home</a>
                </li>
                <li>
                <a className="moblink" onClick={navigateToPricing}>Pricing</a>
                </li>
                {shopHomeData?.profile?.address_required == 0 ? ("") :(
                <li>
                <a className="moblink" onClick={navigateToAreaCovered}>Area Covered</a>
                </li>
                )};
                {shopURL.includes("rebeccabeauty") && (
                  <>
                    <li>
                      <a className="moblink" onClick={navigateToThreading}>Threading</a>
                    </li>
                    <li>
                      <a className="moblink" onClick={navigateToWaxing}>Waxing</a>
                    </li>
                    <li>
                      <a className="moblink" onClick={navigateToFacial}>Facial</a>
                    </li>
                    <li>
                      <a className="moblink" onClick={navigateToEyelash}>Eyelash Extension</a>
                    </li>
                    <li>
                      <a className="moblink" onClick={navigateToMassage}>Massage</a>
                    </li>
                    <li>
                      <a className="moblink" onClick={navigateTohaircolour}>Hair & Colours</a>
                    </li>
                    <li>
                      <a className="moblink" onClick={navigateTonailservice}>Nail Services</a>
                    </li>
                    <li>
                      <a className="moblink" onClick={navigateTomakup}>Makeup</a>
                    </li>
                  </>
                )}
            </ul>
          </div>
        </div>
        <div
          id="mCSB_1_scrollbar_vertical"
          className="mCSB_scrollTools mCSB_1_scrollbar mCS-minimal mCSB_scrollTools_vertical"
          style={{ display: "none" }}
        >
          <div className="mCSB_draggerContainer">
            <div
              id="mCSB_1_dragger_vertical"
              className="mCSB_dragger"
              style={{
                position: "absolute",
                minHeight: "0px",
                height: "0px",
                top: "0px",
                display: "block",
              }}
            >
              <div
                className="mCSB_dragger_bar"
                style={{ lineHeight: "0px" }}
              ></div>
            </div>
            <div className="mCSB_draggerRail"></div>
          </div>
        </div>
      </nav>
      <div id="overlay" onClick={sidebarClose}></div>
      <div
        className="modal fade"
        id="quickordermodal"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            {quickBookPopup && (
              <QuickOrderPopup close={closeQuickOrderPopup} />
              // <QuickOrderPopup close={() => setQuickBookPopup(false)} />
            )}
          </div>
        </div>
      </div>
      <div id="cd-shadow-layer" onClick={cartboxClose}></div>
      {<CartSummary />}
    </div>
  );
}

export default HeaderThreeShop;
