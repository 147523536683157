import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../redux/Actions";
import { setValue } from "../../utility";
import { fetchGetShopByPinCode } from "../../redux/Actions/checkoutPageActions";
import { titleborder, play } from "../../assets/img";
import SampleVideo from "./../../images/sample.mp4";
import { Accordion } from "react-bootstrap";
import { fetchFaqData } from "../../redux/Actions/policyActions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import axios from "axios";
import {
  trustimg1,
  priorityicon1,
  priorityicon5,
  priorityicon6,
  priorityicon4,
} from "../../assets/img";

function SectionTwoBannerThemeFour() {
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const navigate = useNavigate();
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const jsonData: any = useSelector<any>((state) => state?.homeJsonList);

  const [blogList, setBlogList] = useState<any | null>({});
  useEffect(() => {
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    axios
      .get(`${base_url}/api/blog_list?`, {
        headers: headers,
      })
      .then((e) => {
        setBlogList(e.data.data);
      });
  }, []);

  const faqData: any = useSelector<any>((state) => state?.faq);
  useEffect(() => {
    dispatch(fetchFaqData());
  }, []);

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
    }
  }, [state]);

  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;

  const handleChange = (e: any) => {
    const result = e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
    // setPostalCodeList(e.target.value);
    const { value } = e.target;
    const updateValue = value.replace(/\s/g, "");
    if (value.length > 0) {
      dispatch(fetchPostalCodes(updateValue));
    }
    setPostalCodeValue(updateValue);
  };
  const onSearch = (searchTerm: any) => {
    // setPostalCodeList(searchTerm);
    setPostalCodeValue(searchTerm);
    setValue("postalCode", searchTerm);
    // dispatch(fetchPostalCodes(searchTerm));
  };
  const handleBooknow = async () => {
    const isValid = state.postalCodeList.some(
      (item: any) => item.postcode === postalCodeValue.toUpperCase()
    );
    setValue("postalCode", postalCodeValue);
    const results = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(postalCodeValue))
    );

    if (!results) {
      navigate("/areaNotCovered");
      return;
    }
    if (isValid) {
      navigate("/productLists");
    } else {
      navigate("/areaNotCovered");
    }
  };
  return (
    <>
      <section className="priority-section">
        <div className="section-title">
          <h2>{jsonData?.theme_4?.home?.section_2?.title}</h2>
          <p>{jsonData?.theme_4?.home?.section_2?.paragraph}</p>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <div className="priority-card-img">
                <img
                  src={jsonData?.theme_4?.home?.section_2?.first_card_image}
                  alt=""
                />
                <span className="priority-card-img-overlay">
                  <p>{jsonData?.theme_4?.home?.section_2?.image_title}</p>
                </span>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                {jsonData?.theme_4?.home?.section_2?.card_section?.collection.map(
                  (item: any) => {
                    return (
                      <>
                        <div className="col-md-6">
                          <div className="priority-card">
                            <span className="priority-card-icon">
                              <img src={item.icon} />
                            </span>
                            <h3>{item.collection_title}</h3>
                            <p>{item.collection_paragraph}</p>
                          </div>
                        </div>
                      </>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionTwoBannerThemeFour;
