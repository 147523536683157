import React, { useEffect, useState } from "react";
import {
  fetchCroppedImage,
  fetchHomeJsonList,
  saveThemeJsonData,
} from "../../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ImageCropper from "../../imageCropper/imageCropper";
const SectionEightThemeOneEdit = () => {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const [activeTab, setActiveTab] = useState(0);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);

  const icons = [
    "assets/img/home/testimonialuser1.jpg",
    "assets/img/home/testimonialuser2.jpg",
    "assets/img/home/testimonialuser3.jpg",
    "assets/img/home/testimonialuser1.jpg",
    "assets/img/home/testimonialuser2.jpg",
    "assets/img/home/testimonialuser3.jpg",
    "assets/img/home/testimonialuser1.jpg",
    "assets/img/home/testimonialuser2.jpg",
    "assets/img/home/testimonialuser3.jpg",
  ];
  const images = [
    "assets/img/home/testimonialimg1.jpg",
    "assets/img/home/testimonialimg2.jpg",
    "assets/img/home/testimonialimg3.jpg",
    "assets/img/home/testimonialimg1.jpg",
    "assets/img/home/testimonialimg2.jpg",
    "assets/img/home/testimonialimg3.jpg",
    "assets/img/home/testimonialimg1.jpg",
    "assets/img/home/testimonialimg2.jpg",
    "assets/img/home/testimonialimg3.jpg",
  ];
  const handleHeaderChange = (e: any, index?: any) => {
    const { value, name } = e.target;
    let updatedCards: any = [...editData.theme_1.home.section_8.cards];
    if (index != undefined) {
      updatedCards = [...editData.theme_1.home.section_8.cards];
      updatedCards[index] = {
        ...updatedCards[index],
        [name]: value,
      };
    }
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1: {
        home: {
          ...prevJsonData.theme_1.home,
          section_8: {
            ...prevJsonData.theme_1.home.section_8,
            [name]: value,
            cards: updatedCards,
          },
        },
      },
    }));
  };

  const addCollection = () => {
    const updatedCards: any = [...editData.theme_1.home.section_8.cards];

    const newCard = {
      position: "",
      profile: "assets/img/home/testimonialuser1.jpg",
      backgroundImage: "assets/img/home/testimonialimg1.jpg",
      message: "",
      name: "",
    };
    updatedCards.splice(activeTab + 1, 0, newCard);
    setActiveTab(activeTab + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1: {
        home: {
          ...prevJsonData.theme_1.home,
          section_8: {
            ...prevJsonData.theme_1.home.section_8,
            cards: updatedCards,
          },
        },
      },
    }));
  };
  const removeCard = (index: any) => {
    const updatedCards: any = [...editData.theme_1.home.section_8.cards];
    updatedCards.splice(index, 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1: {
        home: {
          ...prevJsonData.theme_1.home,
          section_8: {
            ...prevJsonData.theme_1.home.section_8,
            cards: updatedCards,
          },
        },
      },
    }));
  };

  const handleIconChange = (image: string, type: string) => {
    const updatedCards = [...editData.theme_1.home.section_8.cards];
    if (type === "icon") {
      updatedCards[activeTab] = {
        ...updatedCards[activeTab],
        profile: image,
      };
    } else {
      updatedCards[activeTab] = {
        ...updatedCards[activeTab],
        backgroundImage: image,
      };
    }

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1: {
        home: {
          ...prevJsonData.theme_1.home,
          section_8: {
            ...prevJsonData.theme_1.home.section_8,
            cards: updatedCards,
          },
        },
      },
    }));
  };

  const updateImageSection = async (e: any, type: string) => {
    const image = e.target.files[0];
      if (image instanceof File) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (res: any) => {
          handleCroppedImage(res.target.result , type);
        };
      }
  };

  const handleCroppedImage = async (image: any, type:string) => {
    setShowCropModal(false);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleIconChange(imageLink, type);
    }
  };

  const saveJsonData = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);
  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);
  useEffect(() => {
    setActiveTab(0);
  }, []);
  // HTML
  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section Eight Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Section Title</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={editData?.theme_1?.home?.section_8?.title}
                        maxLength={250}
                        onChange={handleHeaderChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                  {editData?.theme_1?.home?.section_8?.cards?.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={index}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              activeTab === index ? "active" : ""
                            }`}
                            onClick={() => setActiveTab(index)}
                          >
                            Card Section {index + 1}
                          </a>
                          <a
                            className="tab-close"
                            onClick={() => {
                              removeCard(index);
                            }}
                          >
                            <i className="fas fa-times" aria-hidden="true"></i>
                          </a>
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
                {editData?.theme_1?.home?.section_8?.cards.map(
                  (card: any, index: any) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        activeTab === index ? "show active" : ""
                      }`}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="img-bg active">
                            <img
                              src={card.profile}
                              alt={`Card Icon ${index + 1}`}
                            />
                          </div>
                          <div className="img-options w-100">
                            <Swiper
                              spaceBetween={15}
                              navigation={true}
                              modules={[Navigation]}
                              breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                },
                                768: {
                                  slidesPerView: 4,
                                },
                                1024: {
                                  slidesPerView: 6,
                                },
                              }}
                              className="homejson-slider"
                            >
                              {icons.map((icons: any, iconIndex: any) => (
                                <SwiperSlide key={iconIndex}>
                                  <div className="img-option">
                                    <img
                                      src={icons}
                                      onClick={() => {
                                        handleIconChange(icons, "icon");
                                      }}
                                      alt="Hero Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Upload Icon</label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(e: any) => {
                            updateImageSection(e, "icon");
                          }}
                          className="form-control"
                          name="image"
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="img-bg active">
                            <img
                              src={card.backgroundImage}
                              alt={`Card Icon ${index + 1}`}
                            />
                          </div>
                          <div className="img-options w-100">
                            <Swiper
                              spaceBetween={15}
                              navigation={true}
                              modules={[Navigation]}
                              breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                },
                                768: {
                                  slidesPerView: 4,
                                },
                                1024: {
                                  slidesPerView: 6,
                                },
                              }}
                              className="homejson-slider"
                            >
                              {images.map((img: any, iconIndex: any) => (
                                <SwiperSlide key={iconIndex}>
                                  <div className="img-option">
                                    <img
                                      src={img}
                                      onClick={() => {
                                        handleIconChange(img, "img");
                                      }}
                                      alt="Hero Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Upload Image</label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          onChange={(e: any) => {
                            updateImageSection(e, "img");
                          }}
                          name="image"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Name<small>(max 250 char)</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={card.name}
                          onChange={(e: any) => {
                            handleHeaderChange(e, activeTab);
                          }}
                          maxLength={250}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Position<small>(max 250 char)</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="position"
                          value={card.position}
                          onChange={(e: any) => {
                            handleHeaderChange(e, activeTab);
                          }}
                          maxLength={250}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Message</label>
                        <input
                          type="text"
                          className="form-control"
                          name="message"
                          value={card.message}
                          onChange={(e: any) => {
                            handleHeaderChange(e, activeTab);
                          }}
                          maxLength={1200}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="add-section">
              <div className="row">
                <div onClick={addCollection} className="col text-center">
                  Add Card<i className="fas fa-plus-square ms-2"></i>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={saveJsonData}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default SectionEightThemeOneEdit;
