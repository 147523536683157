import React, { useEffect, useState } from "react";
import {
  fetchCroppedImage,
  setShopHomeCms,
  saveThemeJsonData,
} from "../../../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { ColorPicker } from "primereact/colorpicker";
import ImageCropper from "../../../imageCropper/imageCropper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { shop_data_laundry } from "../data";

const SectionSixBannerThemeOne = () => {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  //const jsonData: any = shop_data_laundry;
  const images: Array<string> = [
    "/assets/img/home/wing1.png",
    "/assets/img/home/wing2.png",
    "/assets/img/home/wing1.png",
    "/assets/img/home/wing2.png",
    "/assets/img/home/wing1.png",
    "/assets/img/home/wing2.png",
    "/assets/img/home/wing1.png",
    "/assets/img/home/wing2.png",
    "/assets/img/home/wing1.png",
    "/assets/img/home/wing2.png",
  ];

  // IMG
  const [cropImageHeight, setCropImageHeight] = useState("280");
  const [cropImageWidth, setCropImageWidth] = useState("315");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [imageType, setImageType] = useState("");

  const handleJsonChange = (e: any) => {
    const { value, name } = e.target;
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1: {
        ...prevJsonData.theme_1,
      home: {
        ...prevJsonData.theme_1.home,
        section_6: {
          ...prevJsonData.theme_1.home.section_6,
          [name]: value,
          card_1: {
            [name]: value,
          },
          card_2: {
            [name]: value,
          },
        },
      },
    }
    }));
  };

  const saveJsonData = () => {
    dispatch(setShopHomeCms(editData));
    dispatch(saveThemeJsonData(editData));
  };

  const updateImageSection = async (e: any, type: string) => {
    setImageType(type);
    const image = e.target.files[0];
    setSelectedCropImage(image);
    setShowCropModal(true);
  };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleImageChange(imageLink, imageType);
    }
  };
  const handleImageChange = (image: string, type: string) => {
    setImageType(type);
    //Update the banner value in the JSON data
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1: {
        ...prevJsonData.theme_1,
      home: {
        ...prevJsonData.theme_1.home,
        section_6: {
          ...prevJsonData.theme_1.home.section_6,
          card_one_image:
              type === "one"
                ? image
                : prevJsonData.theme_1.home.section_6.card_one_image,
          card_two_image:
              type === "two"
                ? image
                : prevJsonData.theme_1.home.section_6.card_two_image,
        },
      },
    }
    }));
  };
  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);
  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);
  // HTML
  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section Six Theme Edit</h5> 
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <h4 className="edit-section-hdr">Section </h4>
              <div className="mb-3">
                <label className="form-label">
                  Title <small>(max 250 char)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="heading"
                  value={editData?.theme_1?.home?.section_6?.heading}
                  onChange={handleJsonChange}
                  maxLength={250}
                />
              </div>
            </div>
            <div className="edit-section">
              <h4 className="edit-section-hdr">Card One</h4>
              <div className="mb-3">
                <label className="form-label">
                  Title <small>(max 250 char)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="card_one_title"
                  value={editData?.theme_1?.home?.section_6?.card_one_title}
                  onChange={handleJsonChange}
                  maxLength={250}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Paragraph <small>(max 1200 char)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="card_one_paragraph"
                  value={editData?.theme_1?.home?.section_6?.card_one_paragraph}
                  onChange={handleJsonChange}
                  maxLength={1200}
                />
              </div>
              <div className="mb-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="img-bg active">
                      <img
                        src={editData?.theme_1?.home?.section_6?.card_one_image}
                        alt={`Card Icon`}
                      />
                    </div>
                    <div className="img-options w-100">
                      <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        breakpoints={{
                          640: {
                            slidesPerView: 1,
                          },
                          768: {
                            slidesPerView: 4,
                          },
                          1024: {
                            slidesPerView: 6,
                          },
                        }}
                        className="homejson-slider"
                      >
                        {images.map((img: any, iconIndex: any) => (
                          <SwiperSlide key={iconIndex}>
                            <div className="img-option">
                              <img
                                src={img}
                                alt="Hero Image"
                                onClick={(e: any) => {
                                  handleImageChange(img, "one");
                                }}
                                className="img-fluid"
                              />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Upload Icon</label>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    className="form-control"
                    onChange={(e: any) => {
                      updateImageSection(e, "one");
                    }}
                    name="image"
                  />
                </div>
              </div>
            </div>
            <div className="edit-section">
              <h4 className="edit-section-hdr">Card Two</h4>
              <div className="mb-3">
                <label className="form-label">
                  Title <small>(max 250 char)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="card_two_title"
                  value={editData?.theme_1?.home?.section_6?.card_two_title}
                  onChange={handleJsonChange}
                  maxLength={250}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Paragraph <small>(max 1200 char)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="card_two_paragraph"
                  value={editData?.theme_1?.home?.section_6?.card_two_paragraph}
                  onChange={handleJsonChange}
                  maxLength={1200}
                />
              </div>
              <div className="mb-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="img-bg active">
                      <img
                        src={editData?.theme_1?.home?.section_6?.card_two_image}
                        alt={`Card Icon`}
                      />
                    </div>
                    <div className="img-options w-100">
                      <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        breakpoints={{
                          640: {
                            slidesPerView: 1,
                          },
                          768: {
                            slidesPerView: 4,
                          },
                          1024: {
                            slidesPerView: 6,
                          },
                        }}
                        className="homejson-slider"
                      >
                        {images.map((img: any, iconIndex: any) => (
                          <SwiperSlide key={iconIndex}>
                            <div className="img-option">
                              <img
                                src={img}
                                alt="Hero Image"
                                onClick={(e: any) => {
                                  handleImageChange(img, "two");
                                }}
                                className="img-fluid"
                              />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Upload Icon</label>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    className="form-control"
                    onChange={(e: any) => {
                      updateImageSection(e, "two");
                    }}
                    name="image"
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={saveJsonData}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default SectionSixBannerThemeOne;
