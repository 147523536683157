import React, { useEffect, useState } from "react";
import { ColorPicker } from "primereact/colorpicker";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCroppedImage,
  fetchHomeJsonList,
  saveThemeJsonData,
} from "../../../redux/Actions";
import ImageCropper from "../../imageCropper/imageCropper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

const SectionOneEditThemeFive = () => {
  const dispatch = useDispatch<any>();
  const [editedData, setEditedData] = useState<any>();
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [imagedata, setImagedata] = useState<any>("" || undefined);
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [showCropModalTwo, setShowCropModalTwo] = useState<boolean>(false);
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const images: Array<string> = [
    "assets/home_page_single_search.jpg",
    "assets/home_page_single_search_wonder.jpg",
    "assets/home_page_single_search.jpg",
    "assets/home_page_single_search_wonder.jpg",
    "assets/home_page_single_search.jpg",
    "assets/home_page_single_search_wonder.jpg",
  ];
  const banner: Array<string> = [
    "assets/img/home/heroimg.png",
    "assets/img/home/hero-img-wonderwash.png",
    "assets/home/heroimg.png",
    "assets/img/home/heroimg.png",
    "assets/img/home/hero-img-wonderwash.png",
    "assets/home/heroimg.png",
  ];
  const saveJsonDataToFile = () => {
    dispatch(fetchHomeJsonList(editedData));
    dispatch(saveThemeJsonData(editedData));
  };

  useEffect(() => {
    setEditedData(jsonData);
  }, [jsonData]);

  useEffect(() => {
    if (editedData != undefined) {
      setEditedData(editedData);
    }
  }, [editedData]);

  const handleChange = (e: any) => {
    const { value, name, type, checked } = e.target;

    setEditedData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_5: {
        ...prevJsonData?.theme_5,
        home: {
          ...prevJsonData?.theme_5.home,
          section_1: {
            ...prevJsonData?.theme_5?.home.section_1,
            [name]: value,
            btn_group: {
              ...prevJsonData?.theme_5?.home.section_1.btn_group,
              btn1: {
                ...prevJsonData?.theme_5?.home.section_1.btn_group.btn1,
                [name]: value,
              },
            },
          },
        },
      },
    }));
  };
  const handleImageChange = (image: string) => {
    //Update the banner value in the JSON data
    setEditedData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_5: {
        ...prevJsonData.theme_5,
        home: {
          ...prevJsonData.theme_5.home,
          section_1: {
            ...prevJsonData.theme_5.home.section_1,
            banner: image,
          },
        },
      },
    }));
  };
  const handleImageChangeTwo = (image: string) => {
    //Update the banner value in the JSON data
    setEditedData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_5: {
        ...prevJsonData.theme_5,
        home: {
          ...prevJsonData.theme_5.home,
          section_1: {
            ...prevJsonData.theme_5.home.section_1,
            banner_two: image,
          },
        },
      },
    }));
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150");
    // setCropImageWidth("300");
    // setSelectedCropImage(image);
    // setShowCropModal(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        handleCroppedImage(res.target.result);
      };
    }
  };

  const updateImageSectionTwo = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("300");
    // setCropImageWidth("500");

    if (image.type.startsWith("image/")) {
      // setSelectedCropImage(image);
      // setShowCropModalTwo(true);

      setBannerType("image");
      if (image instanceof File) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (res: any) => {
          handleCroppedImageTwo(res.target.result);
        };
      }
    } else if (image.type.startsWith("video/")) {
      // handleCroppedImageTwo(image);
      // setBannerType("video");
      if (image instanceof File) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (res: any) => {
          handleCroppedImageTwo(res.target.result);
        };
      }
    }
  };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleImageChange(imageLink);
    }
  };
  const handleCroppedImageTwo = async (image: any) => {
    setShowCropModalTwo(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleImageChangeTwo(imageLink);
    }
  };
  const setBannerType = (type: string) => {
    setEditedData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_5: {
        ...prevJsonData.theme_5,
        home: {
          ...prevJsonData.theme_5.home,
          section_1: {
            ...prevJsonData.theme_5.home.section_1,
            banner_type: type,
          },
        },
      },
    }));
  };
  // HTML
  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title"> Section1 Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            {/* <div className="mb-3">
              <label className="form-label">
                Sub Title <small>(max 250 char)</small>
              </label>
              <input
                type="text"
                className="form-control"
                name="sub_heading_top"
                value={editedData?.theme_5?.home?.section_1?.sub_heading_top}
                onChange={handleChange}
                maxLength={250}
              />
            </div> */}
            <div className="mb-3">
              <label className="form-label">
                Title<small>(max 250 char)</small>
              </label>
              <input
                type="text"
                className="form-control"
                name="title"
                value={editedData?.theme_5?.home?.section_1?.title}
                onChange={handleChange}
                maxLength={250}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Description</label>
              <input
                type="text"
                className="form-control"
                name="paragraph"
                value={editedData?.theme_5?.home?.section_1?.paragraph}
                onChange={handleChange}
                maxLength={1200}
              />
            </div>
            <div className="mb-3">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Button</label>
                    <input
                      type="text"
                      className="form-control"
                      name="text"
                      value={
                        editedData?.theme_5?.home?.section_1?.btn_group?.btn1
                          ?.text
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Background</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="bg_color"
                        value={
                          editedData?.theme_5?.home?.section_1?.btn_group?.btn1
                            ?.bg_color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="bg_color"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        format="hex"
                        value={
                          editedData?.theme_5?.home?.section_1?.btn_group?.btn1
                            ?.bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="color"
                        value={
                          editedData?.theme_5?.home?.section_1?.btn_group?.btn1
                            ?.color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="color"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        format="hex"
                        value={
                          editedData?.theme_5?.home?.section_1?.btn_group?.btn1
                            ?.color
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col">
                  <div className=" mb-3">
                    <label className="form-label">Button 2</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="btn2.text"
                        value={
                          editedData?.theme_5?.home?.section_1?.btn_group?.btn2
                            ?.text
                        }
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Background</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="btn2.bg_color"
                        value={
                          editedData?.theme_5?.home?.section_1?.btn_group?.btn2
                            ?.bg_color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="btn2.bg_color"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        format="hex"
                        value={
                          editedData?.theme_5?.home?.section_1?.btn_group?.btn2
                            ?.bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="btn2.color"
                        value={
                          editedData?.theme_5?.home?.section_1?.btn_group?.btn2
                            ?.color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="btn2.color"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        format="hex"
                        value={
                          editedData?.theme_5?.home?.section_1?.btn_group?.btn2
                            ?.color
                        }
                      />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="mb-3">
              <label className="form-label">
                Placeholder <small>(max 50 char)</small>
              </label>
              <input
                type="text"
                className="form-control"
                name="placeholder"
                value={
                  editedData?.theme_5?.home?.section_1?.btn_group?.btn1
                    ?.placeholder
                }
                onChange={handleChange}
                maxLength={50}
              />
            </div>
            <div className="mb-3">
              <div className="row">
                <div className="col-md-12">
                  <label className="form-label">Background Banner</label>
                  <div className="img-bg active">
                    <img
                      src={editedData?.theme_5?.home?.section_1?.banner}
                      alt="Hero Image"
                    />
                  </div>
                  <div className="img-options">
                    <div className="row">
                      <div className="col-md-12">
                        <Swiper
                          spaceBetween={15}
                          navigation={true}
                          modules={[Navigation]}
                          breakpoints={{
                            640: {
                              slidesPerView: 1,
                            },
                            768: {
                              slidesPerView: 4,
                            },
                            1024: {
                              slidesPerView: 6,
                            },
                          }}
                          className="homejson-slider"
                        >
                          {images.map((image: string) => {
                            return (
                              <>
                                <SwiperSlide>
                                  <div
                                    className="img-option"
                                    onClick={() => handleImageChange(image)}
                                  >
                                    <img
                                      src={image}
                                      alt="Hero Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </SwiperSlide>
                              </>
                            );
                          })}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Upload Image</label>
              <input
                type="file"
                accept="image/png, image/jpeg"
                className="form-control"
                name="image"
                onChange={updateImageSectionOne}
              />
            </div>
            <div className="mb-3">
              <div className="row">
                <div className="col-md-12">
                  <label className="form-label">Banner</label>
                  <div className="img-bg active">
                    {editedData?.theme_5?.home?.section_1?.banner_type ==
                    "image" ? (
                      <img
                        src={editedData?.theme_5?.home?.section_1?.banner_two}
                        alt="Hero Image"
                        className="img-fluid"
                      />
                    ) : (
                      <>
                        <video
                          loop
                          autoPlay
                          className="img-fluid"
                          src={editedData?.theme_5?.home?.section_1?.banner_two}
                        ></video>
                      </>
                    )}
                  </div>
                  <div className="img-options">
                    <div className="row">
                      <div className="col-md-12">
                        <Swiper
                          spaceBetween={15}
                          navigation={true}
                          modules={[Navigation]}
                          breakpoints={{
                            640: {
                              slidesPerView: 1,
                            },
                            768: {
                              slidesPerView: 4,
                            },
                            1024: {
                              slidesPerView: 6,
                            },
                          }}
                          className="homejson-slider"
                        >
                          {banner.map((images: string) => {
                            return (
                              <>
                                <SwiperSlide>
                                  <div
                                    className="img-option"
                                    onClick={() => {
                                      handleImageChangeTwo(images);
                                      setBannerType("image");
                                    }}
                                  >
                                    <img
                                      src={images}
                                      alt="Hero Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </SwiperSlide>
                              </>
                            );
                          })}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Upload Image or Video</label>
              <input
                type="file"
                accept="image/*, video/*"
                className="form-control"
                name="banner_two"
                onChange={updateImageSectionTwo}
              />
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
      {showCropModalTwo ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImageTwo}
              close={() => {
                setShowCropModalTwo(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default SectionOneEditThemeFive;
