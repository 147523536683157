import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeJsonList, saveThemeJsonData } from "../../../redux/Actions";
 function SectionSixBannerThemeTwo(){

    const dispatch = useDispatch<any>();
    const [editData, setEditData] = useState<any>();
    const jsonData: any = useSelector<any>((state) => state.homeJsonList);
    const [activeTab, setActiveTab] = useState(0);
    const [showCropModal, setShowCropModal] = useState<boolean>(false);
    
  
    // const handleHeaderChange = (e: any) => {
    //   const { value, name, type, checked } = e.target;
    //   let cards: Array<any> = [...editData.theme_2.home.section_6.collection];
    //   cards[activeTab] = { [name]: value };
    //   setEditData((prevJsonData: any) => ({
    //     ...prevJsonData,
    //     theme_2: {
    //       home: {
    //         ...prevJsonData.theme_2.home,
    //         section_6: {
    //           collection: cards
          

    //         },
            
    //       },
    //     },
    //   }));
    // };
    const handleChange = (e: any, index?: any) => {
      const { value, name, type, checked } = e.target;
      let updatedCollection: any = [
        ...editData?.theme_2?.home.section_6.card_section.collection,
      ];
      if (index != undefined) {
        updatedCollection = [...editData?.theme_2?.home.section_6.card_section.collection];
        updatedCollection[index] = {
          ...updatedCollection[index],
          [name]: value,
        };
      }
      setEditData((prevJsonData: any) => ({
        ...prevJsonData,
        theme_2: {
          ...prevJsonData.theme_2,
        home: {
          ...prevJsonData.theme_2.home,
          section_6: {
            ...prevJsonData.theme_2.home.section_6,
            [name]: value,
            card_section: {
              
              collection: updatedCollection,
            },
          },
        },
      }
      }));
    };
  
    useEffect(() => {
      setEditData(jsonData);
    }, [jsonData]);
  
    useEffect(() => {
      if (editData != undefined) {
        setEditData(editData);
      }
    }, [editData]);

    const saveJsonData = () => {
     dispatch(fetchHomeJsonList(editData));
      dispatch(saveThemeJsonData(editData));
    };  
    
    return (
      <>
        {/* <Draggable> */}
          <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Section6 Theme Edit</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-content modal-body">
                <div className="edit-section">
                <h4 className="edit-section-hdr">Frequently Asked Questions</h4>
                <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                      {editData?.theme_2?.home?.section_6?.card_section?.collection.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={1}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              activeTab === index ? "active" : ""
                            }`}
                            onClick={() => setActiveTab(index)}
                          >
                            Question {index + 1}
                          </a>
                          {/* <a className="tab-close" onClick={() => {removeCollection(index)}}>
                                <i
                                  className="fas fa-times"
                                  aria-hidden="true"
                                ></i>
                              </a> */}
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
              {editData?.theme_2?.home?.section_6?.card_section?.collection?.map(
                  (card: any, index: any) => (
                    <div
                        key={index}
                      className={`tab-pane fade ${
                        activeTab === index ? "show active" : ""
                      }`}
                    >
                      
                    
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label className="form-label">Question</label>
                            <input
                              type="text"
                              className="form-control"
                              name="collection_title"
                              value={card.collection_title}
                              onChange={(e) => handleChange(e, index)}
                              maxLength={250}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label className="form-label">Answer</label>
                            <input
                              type="text"
                              className="form-control"
                              name="collection_paragraph"
                              value={card.collection_paragraph}
                              onChange={(e) => handleChange(e, index)}
                              maxLength={250}
                            />
                          </div>
                        </div>
                      </div>
</div>
                  )
                )}
              </div>
              </div>
              <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonData();
                }}
              >
                Save Changes
              </button>
            </div>
              </div>
            </div>
          </div>
        {/* </Draggable> */}
        {showCropModal ? (
            <>
              <div
                className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
                id="crop-modal"
                tabIndex={-1}
                aria-labelledby="crop-modal"
                aria-hidden="true"
                style={{ display: "block" }}
              >
              
              </div>
            </>
          ) : null}
      </>
    );

 }
 
 export default SectionSixBannerThemeTwo; 