import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {mk1,mk2,mk3,mk4} from "../../assets/img";

function Makup () {

return (
    <>
        <section className="rbpsec">
            <div className="container">                
                <div className="row justify-content-center">                    
                    <div className="col-md-4">
                        <div className="rbpimg">
                            <img src={mk1} alt="massage" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="rbpimg">
                            <img src={mk4} alt="massage" />
                        </div>
                        <div className="rbpimg">
                            <img src={mk3} alt="massage" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="rbpimg">
                            <img src={mk2} alt="massage" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
)
}
export default Makup;