import React, { useState } from "react";
import Cookies from "universal-cookie";
import ManageCookiesPopup from "./manageCookiesPopup";

function Cookie() {
  const cookies = new Cookies();
  const [accepted, setAccepted] = useState(cookies.get("cookiesAccepted") || cookies.get("necessaryCookiesAccepted"));
  const [manageCookies, setManageCookies] = useState(false)

  const acceptCookies = () => {
    cookies.set("cookiesAccepted", true, { path: "/" });
    setAccepted(true);
  };

  const declineCookies = () => {
    cookies.remove("cookiesAccepted");
    cookies.remove("necessaryCookiesAccepted");
    setAccepted(false);
  };

  if (accepted === undefined) {
    return (
      <div className="cookies-banner">
        <div className="cookies-banner-grid">
        <p>
          We use cookies to provide more personalized services to you on this
          website. To find out more about the cookies we use, see our Privacy
          Policy.
        </p>
        <div className="cookies-banner-grid-links">
            <a
              style={{ cursor: "pointer" }}
              data-bs-target="#deleteprofile"
              data-id="1"
              onClick={() => setManageCookies(!manageCookies)}
              href="#"
            >
              <span>ManageCookies</span>
            </a>
          <button onClick={acceptCookies} className="cookies-btn1">Accept</button>
          <button onClick={declineCookies} className="cookies-btn2">Decline</button>
        </div>
        {manageCookies && <ManageCookiesPopup close={() => setManageCookies(false)} accept={() => acceptCookies()} accepted={() => setAccepted(true)} />}
        </div>
      </div>
    );
  }

  return null;
}

export default Cookie;
