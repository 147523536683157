import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { fetchCroppedImage, fetchThemeEdit, getHomeJsonList } from "../../redux/Actions";
import ImageCropper from "../imageCropper/imageCropper";
import FooterOne from "../footer/footerOne";
import FooterEdit from "./footer-edit/footer3-edit";
import HeaderOne from "../header/headerOne";
import HeaderThree from "../header/headerThree";
import HeaderThreeEdit from "./header-edit/header3-edit";
import HeaderOneEdit from "./header-edit/header1-edit";
import SectionOneBannerThemeThree from "../theme-three/SectionOneBannerThemeThree";
import SectionOneEditThemeThree from "./section1Edit/SectionOneEditThemeThree";
import SectionOneBannerThemeOne from "../theme-one/SectionOneBannerThemeOne";
import SectionTwoEditThemeThree from "./section2Edit/sectionTwoEditThemeThree";
import SectionTwoEditThemeOne from "./section2Edit/sectionTwoEditThemeOne";
import SectionTwoBannerThemeOne from "../theme-one/SectionTwoBannerThemeOne";
import SectionTwoBannerThemeThree from "../sectionTwoBanner/SectionTwoBannerThemeThree";
import SectionThreeThemeThree from "../theme-three/sectionThreeThemeThree";
import SectionThreeThemeOne from "../theme-one/sectionThreeThemeOne";
import SectionThreeThemeThreeEdit from "./SectionThreeEdit/SectionThreeThemeThreeEdit";
import SectionThreeThemeOneEdit from "./SectionThreeEdit/SectionThreeThemeOneEdit";
import SectionFiveThemeThree from "../theme-three/sectionFiveThemeThree";
import SectionFiveThemeOne from "../theme-one/SectionFiveThemeOne";
import SectionFourThemeThree from "../theme-three/sectionFourThemeThree";
import SectionFourThemeOne from "../theme-one/sectionFourThemeOne";
import SectionFourBannerThemeThree from "./sectionFourBanner/SectionFourBannerThemeThree";
import SectionFourBannerThemeOne from "./sectionFourBanner/SectionFourBannerThemeOne";
import SectionSixThemeThree from "../theme-three/sectionSixThemeThree";
import SectionSixThemeOne from "../theme-one/sectionSixThemeOne";
import SectionSixBannerThemeThree from "./sectionSixBanner/SectionSixBannerThemeThree";
import SectionSixBannerThemeOne from "./sectionSixBanner/SectionSixBannerThemeOne";
import SectionSevenThemeThree from "../theme-three/sectionSevenThemeThree";
import SectionSevenThemeOne from "../theme-one/sectionSevenThemeOne";
import SectionSevenBannerThemeThree from "./sectionSevenBanner/SectionSevenBannerThemeThree";
import SectionSevenBannerThemeOne from "./sectionSevenBanner/SectionSevenBannerThemeOne";
import SectionNineThemeOne from "../theme-one/sectionNineThemeOne";
import SectionNineThemeThree from "../theme-three/sectionNineThemeThree";
import SectionNineBannerThemeOne from "./sectionNineBanner/SectionNineBannerThemeOne";
import SectionNineBannerThemeThree from "./sectionNineBanner/SectionNineBannerThemeThree";
import SectionFiveThemeThreeEdit from "./SectionFiveEdit/SectionFiveThemeThreeEdit";
import SectionFiveThemeOneEdit from "./SectionFiveEdit/SectionFiveThemeOneEdit";
import SectionOneEditThemeOne from "./section1Edit/sectionOneEditThemeOne";
import SectionTenBannerThemeThree from "../theme-three/SectionTenBannerThemeThree";
import SectionTenThemeThree from "./section10Edit/sectionTenThemeThree";
import SectionTenBannerThemeOne from "../theme-one/SectionTenBannerThemeOne";
import SectionTenThemeOne from "./section10Edit/sectionTenThemeOne";
import SectionTwelveBannerThemeThree from "../theme-three/SectionTwelveBannerThemeThree";
import SectionTwelveThemeThree from "./section12Edit/sectionTwelveThemeThree";
import SectionEightThemeOneEdit from "./SectionEightThemeThreeEdit/SectionEightThemeOneEdit";
import SectionEightThemeThreeEdit from "./SectionEightThemeThreeEdit/SectionEightThemeThreeEdit";
import SectionElevenThemeThreeEdit from "./SectionElevenEdit/SectionElevenThemeThreeEdit";
import SectionFifteenThemeThreeEdit from "./SectionFifteenEdit/SectionFifteenThemeThreeEdit";
import SectionThirteenThemeThreeEdit from "./clickAway/SectionThirteenThemeThreeEdit";
import SectionElevenThemeThree from "../theme-three/SectionElevenThemeThree";
import SectionThirteenThemeThree from "../theme-three/SectionThirteenThemeThree";
import SectionFifteenThemeThree from "../theme-three/SectionFifteenThemeThree";
import SectionEightThemeOne from "../theme-one/SectionEightThemeOne";
import SectionEightThemeThree from "../theme-three/SectionEightThemeThree";
import SectionThemeThreeEditor from "./ThemeEditor/SectionThemeThreeEditor";
import SectionThemeOneEditor from "./ThemeEditor/SectionThemeOneEditor";
import HeaderTwo from "../header/headerTwo";
import HeaderTwoEdit from "./header-edit/header2-edit";
import SectionTwoBannerThemeTwo from "../theme-two/section-two";
import SectionOneThemeTwo from "../theme-two/section-one";
import SectionTwoEditThemeTwo from "./section2Edit/sectionTwoEditThemeTwo";
import SectionThreeThemeTwo from "../theme-two/section-three";
import SectionFiveThemeTwo from "../theme-two/section-five";
import SectionSixThemeTwo from "../theme-two/section-six";
import SectionSevenThemeTwo from "../theme-two/section-seven";
import SectionEightThemeTwo from "../theme-two/section-eight";
import SectionNineThemeTwo from "../theme-two/section-nine";
import SectionOneThemeTwoEdit from "./section1Edit/sectionOneEditThemeTwo";
import SectionThreeThemeTwoEdit from "./section3/sectionThreeThemeTwo";
import SectionFiveThemeTwoEdit from "./SectionFiveEdit/SectionFiveThemeTwoEdit";
import SectionSevenBannerThemeTwo from "./sectionSevenBanner/SectionSevenBannerThemeTwo";
import SectionEightThemeTwoEdit from "./SectionEightThemeThreeEdit/SectionEightThemeTwoEdit";
import SectionNineBannerThemeTwo from "./sectionNineBanner/SectionNineBannerThemeTwo";
import SectionThemeTwoEditor from "./ThemeEditor/SectionThemeTwoEditor";
import SectionSixBannerThemeTwo from "./sectionSixBanner/SectionSixBannerThemeTwo";
import FooterTwo from "../footer/footerTwo";
import FooterThree from "../footer/footerThree";
import FooterOneEdit from "./footer-edit/footer1-edit";
import FooterThreeEdit from "./footer-edit/footer3-edit";
import FooterTwoEdit from "./footer-edit/footer2-edit";
import HeaderFour from "../header/headerFour";
import SectionOneThemeFour from "../theme-four/section-one";
import SectionTwoBannerThemeFour from "../theme-four/section-two";
import SectionThreeThemeFour from "../theme-four/section-three";
import SectionFourThemeFour from "../theme-four/section-four";
import SectionFiveThemeFour from "../theme-four/section-five";
import SectionSixThemeFour from "../theme-four/section-six";
import SectionSevenThemeFour from "../theme-four/section-seven";
import SectionEightThemeFour from "../theme-four/section-eight";
import SectionNineThemeFour from "../theme-four/section-nine";
import SectionTenThemeFour from "../theme-four/section-ten";
import SectionElevenThemeFour from "../theme-four/section-eleven";
import SectionTwelveThemeFour from "../theme-four/section-twelve";
import SectionThirteenThemeFour from "../theme-four/section-thirteen";
import SectionFourteenThemeFour from "../theme-four/section-fourteen";
import FooterFour from "../footer/footerFour";
import HeaderFourEdit from "./header-edit/header4-edit";
import SectionOneEditThemeFour from "./section1Edit/SectionOneEditThemeFour";
import SectionTwoThemeFourEdit from "./section2Edit/sectionTwoEditThemeFour";
import SectionThreeThemeFourEdit from "./SectionThreeEdit/SectionThreeThemeFourEdit";
import SectionFourThemeFourEdit from "./sectionFourBanner/SectionFourBannerThemeFour";
import SectionEightThemeFourEdit from "./SectionEightThemeThreeEdit/SectionEightThemeFourEdit";
import SectionSixBannerThemeFour from "./sectionSixBanner/SectionSixBannerThemeFour";
import SectionNineBannerThemeFour from "./sectionNineBanner/SectionNineBannerThemeFour";
import SectionTwelveBannerThemeFour from "./sectionTwelveBanner/SectionTwelveBannerThemeFour";
import SectionFiveThemeFourEdit from "./SectionFiveEdit/SectionFiveThemeFourEdit";
import SectionTenThemeFourEdit from "./section10Edit/sectionTenThemeFour";
import SectionThemeFourEditor from "./ThemeEditor/SectionThemeFourEditor";
import SectionSevenBannerThemeFour from "./sectionSevenBanner/SectionSevenBannerThemeFour";
import SectionElevenThemeFourEdit from "./SectionElevenEdit/SectionElevenThemeFourEdit";
import FooterFourEdit from "./footer-edit/footer4-edit";
import SectionOneThemeFive from "../theme-five/section-one";
import SectionOneEditThemeFive from "./section1Edit/SectionOneEditThemeFive";
// import HeaderFive from "../header/headerFive";
// import "../../assets/css/style1.css"
import HeaderFive from "../header/headerFive";
import HeaderFiveEdit from "./header-edit/header5-edit";
import SectionThreeThemeFive from "../theme-five/section-three";
import SectionTwoThemeFive from "../theme-five/section-two";
import SectionFourThemeFive from "../theme-five/section-four";
import SectionFiveThemeFive from "../theme-five/section-five";
import SectionSixThemeFive from "../theme-five/section-six";
import SectionSevenThemeFive from "../theme-five/section-seven";
import SectionEightThemeFive from "../theme-five/section-eight";
import FooterFive from "../footer/footerFive";
import SectionSevenBannerThemeFive from "./sectionSevenBanner/SectionSevenBannerThemeFive";
import SectionFourThemeFiveEdit from "./sectionFourBanner/SectionFourBannerThemeFive";
import SectionFiveThemeFiveEdit from "./SectionFiveEdit/SectionFiveThemeFiveEdit";
import FooterFiveEdit from "./footer-edit/footer5-edit";
import SectionEightThemeFiveEdit from "./SectionEightThemeThreeEdit/SectionEightThemeFiveEdit";
import SectionThemeFiveEditor from "./ThemeEditor/SectionThemeFiveEditor";
import SectionSixBannerThemeFive from "./sectionSixBanner/SectionSixBannerThemeFive";

function Edit() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  useEffect(() => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get("token");
    const host = window.location.hostname;
    // checks the token if its not in localhost 
    if (host !== "localhost") {
      if (token != null) {
        dispatch(fetchThemeEdit(token)).then((res: any) => {
          if (res == false) {
            navigate("/");
          }
        });
      } else {
        navigate("/");
      }
    }
  }, []);
  useEffect(() => {
    dispatch(getHomeJsonList());
  }, []);
  return (
    <>
      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#headerEditmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {settingsData?.template == 1 && <HeaderOne />}
        {settingsData?.template == 2 && <HeaderTwo />}
        {settingsData?.template == 3 && <HeaderThree />}
        {settingsData?.template == 4 && <HeaderFour />}
        {settingsData?.template == 5 && <HeaderFive />}
      </div>
      <div
        className={`section-editor ${
          settingsData?.template == 4 ? "section-editor4" : ""
        }`}
      >
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#sectiononeEditmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {settingsData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[0]?.is_section_enable === true && (
            <>
              <SectionOneBannerThemeOne />
            </>
          )}
        {settingsData?.template == 2 &&
          jsonData?.theme_2?.home?.sections[0]?.is_section_enable === true && (
            <>
              <SectionOneThemeTwo />
            </>
          )}
        {settingsData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[0]?.is_section_enable === true && (
            <>
              <SectionOneBannerThemeThree />
            </>
          )}
        {settingsData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[0]?.is_section_enable === true && (
            <>
              <SectionOneThemeFour />
            </>
          )}
        {settingsData?.template == 5 &&
          jsonData?.theme_5?.home?.sections[0]?.is_section_enable === true && (
            <>
              <SectionOneThemeFive />
            </>
          )}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section2Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {/* {settingsData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[1]?.is_section_enable === true && (
            <>
              <SectionTwoBannerThemeOne />
            </>
          )} */}
        {settingsData?.template == 2 &&
          jsonData?.theme_2?.home?.sections[1]?.is_section_enable === true && (
            <>
              <SectionTwoBannerThemeTwo />
            </>
          )}
        {/* {settingsData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[1]?.is_section_enable === true && (
            <>
              <SectionTwoBannerThemeThree />
            </>
          )} */}
        {settingsData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[1]?.is_section_enable === true && (
            <>
              <SectionTwoBannerThemeFour />
            </>
          )}
        {/* {settingsData?.template == 5 &&
          jsonData?.theme_5?.home?.sections[1]?.is_section_enable === true && (
            <>
              <SectionTwoThemeFive />
            </>
          )} */}
      </div>
      <div
        className="modal fade"
        id="section2Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {/* {settingsData?.template == 1 && <SectionTwoEditThemeOne />} */}
        {settingsData?.template == 2 && <SectionTwoEditThemeTwo />}
        {/* {settingsData?.template == 3 && <SectionTwoEditThemeThree />} */}
        {settingsData?.template == 4 && <SectionTwoThemeFourEdit />}
      </div>
      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section3Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {settingsData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[2]?.is_section_enable === true && (
            <SectionThreeThemeOne />
          )}
        {settingsData?.template == 2 &&
          jsonData?.theme_2?.home?.sections[2]?.is_section_enable === true && (
            <>
              <SectionThreeThemeTwo />
            </>
          )}
        {settingsData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[1]?.is_section_enable === true && (
            <SectionThreeThemeThree />
          )}
        {settingsData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[2]?.is_section_enable === true && (
            <SectionThreeThemeFour />
          )}
        {/* {settingsData?.template == 5 &&
          jsonData?.theme_5?.home?.sections[2]?.is_section_enable === true && (
            <SectionThreeThemeFive />
          )} */}
      </div>

      <div
        className="modal fade"
        id="section3Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {settingsData?.template == 1 && <SectionThreeThemeOneEdit />}
        {settingsData?.template == 2 && <SectionThreeThemeTwoEdit />}
        {settingsData?.template == 3 && <SectionThreeThemeThreeEdit />}
        {settingsData?.template == 4 && <SectionThreeThemeFourEdit />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section4Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {settingsData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[3]?.is_section_enable === true && (
            <SectionFourThemeOne />
          )}
        {settingsData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[2]?.is_section_enable === true && (
            <SectionFourThemeThree />
          )}
        {settingsData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[3]?.is_section_enable === true && (
            <SectionFourThemeFour />
          )}
        {settingsData?.template == 5 &&
          jsonData?.theme_5?.home?.sections[3]?.is_section_enable === true && (
            <SectionFourThemeFive />
          )}
      </div>
      <div
        className="modal fade"
        id="section4Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {settingsData?.template == 1 && <SectionFourBannerThemeOne />}
        {settingsData?.template == 3 && <SectionFourBannerThemeThree />}
        {settingsData?.template == 4 && <SectionFourThemeFourEdit />}
        {settingsData?.template == 5 && <SectionFourThemeFiveEdit />}
      </div>
      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section5Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {settingsData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[4]?.is_section_enable === true && (
            <SectionFiveThemeOne />
          )}
        {settingsData?.template == 2 &&
          jsonData?.theme_2?.home?.sections[4]?.is_section_enable === true && (
            <>
              <SectionFiveThemeTwo />
            </>
          )}
        {settingsData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[3]?.is_section_enable === true && (
            <SectionFiveThemeThree />
          )}
        {settingsData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[4]?.is_section_enable === true && (
            <SectionFiveThemeFour />
          )}
        {settingsData?.template == 5 &&
          jsonData?.theme_5?.home?.sections[4]?.is_section_enable === true && (
            <SectionFiveThemeFive />
          )}
      </div>

      <div
        className="modal fade"
        id="section5Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {settingsData?.template == 1 && <SectionFiveThemeOneEdit />}
        {settingsData?.template == 2 && <SectionFiveThemeTwoEdit />}
        {settingsData?.template == 3 && <SectionFiveThemeThreeEdit />}
        {settingsData?.template == 4 && <SectionFiveThemeFourEdit />}
        {settingsData?.template == 5 && <SectionFiveThemeFiveEdit />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section6Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {settingsData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[5]?.is_section_enable === true && (
            <SectionSixThemeOne />
          )}
        {settingsData?.template == 2 &&
          jsonData?.theme_2?.home?.sections[5]?.is_section_enable === true && (
            <>
              <SectionSixThemeTwo />
            </>
          )}
        {settingsData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[4]?.is_section_enable === true && (
            <SectionSixThemeThree />
          )}
        {settingsData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[5]?.is_section_enable === true && (
            <SectionSixThemeFour />
          )}
        {settingsData?.template == 5 &&
          jsonData?.theme_5?.home?.sections[5]?.is_section_enable === true && (
            <SectionSixThemeFive />
          )}
      </div>

      <div
        className="modal fade"
        id="section6Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {settingsData?.template == 1 && <SectionSixBannerThemeOne />}
        {settingsData?.template == 2 && <SectionSixBannerThemeTwo />}
        {settingsData?.template == 3 && <SectionSixBannerThemeThree />}
        {settingsData?.template == 4 && <SectionSixBannerThemeFour />}
        {settingsData?.template == 5 && <SectionSixBannerThemeFive />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section7Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {settingsData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[6]?.is_section_enable === true && (
            <SectionSevenThemeOne />
          )}
        {settingsData?.template == 2 &&
          jsonData?.theme_2?.home?.sections[6]?.is_section_enable === true && (
            <>
              <SectionSevenThemeTwo />
            </>
          )}
        {settingsData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[5]?.is_section_enable === true && (
            <SectionSevenThemeThree />
          )}
        {settingsData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[6]?.is_section_enable === true && (
            <SectionSevenThemeFour />
          )}
        {settingsData?.template == 5 &&
          jsonData?.theme_5?.home?.sections[5]?.is_section_enable === true && (
            <SectionSevenThemeFive />
          )}
      </div>

      <div
        className="modal fade"
        id="section7Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {settingsData?.template == 1 && <SectionSevenBannerThemeOne />}
        {settingsData?.template == 2 && <SectionSevenBannerThemeTwo />}
        {settingsData?.template == 3 && <SectionSevenBannerThemeThree />}
        {settingsData?.template == 4 && <SectionSevenBannerThemeFour />}
        {settingsData?.template == 5 && <SectionSevenBannerThemeFive />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section8Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {settingsData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[7]?.is_section_enable === true && (
            <SectionEightThemeOne />
          )}
        {settingsData?.template == 2 &&
          jsonData?.theme_2?.home?.sections[7]?.is_section_enable === true && (
            <>
              <SectionEightThemeTwo />
            </>
          )}
        {settingsData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[6]?.is_section_enable === true && (
            <SectionEightThemeThree />
          )}
        {settingsData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[7]?.is_section_enable === true && (
            <SectionEightThemeFour />
          )}
        {settingsData?.template == 5 &&
          jsonData?.theme_5?.home?.sections[7]?.is_section_enable === true && (
            <SectionEightThemeFive />
          )}
      </div>

      <div
        className="modal fade"
        id="section8Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {settingsData?.template == 1 && <SectionEightThemeOneEdit />}
        {settingsData?.template == 2 && <SectionEightThemeTwoEdit />}
        {settingsData?.template == 3 && <SectionEightThemeThreeEdit />}
        {settingsData?.template == 4 && <SectionEightThemeFourEdit />}
        {settingsData?.template == 5 && <SectionEightThemeFiveEdit />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section9Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {settingsData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[8]?.is_section_enable === true && (
            <SectionNineThemeOne />
          )}
        {settingsData?.template == 2 &&
          jsonData?.theme_2?.home?.sections[8]?.is_section_enable === true && (
            <>
              <SectionNineThemeTwo />
            </>
          )}
        {settingsData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[7]?.is_section_enable === true && (
            <SectionNineThemeThree />
          )}
        {settingsData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[8]?.is_section_enable === true && (
            <SectionNineThemeFour />
          )}
      </div>

      <div
        className="modal fade"
        id="section9Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {settingsData?.template == 1 && <SectionNineBannerThemeOne />}
        {settingsData?.template == 2 && <SectionNineBannerThemeTwo />}
        {settingsData?.template == 3 && <SectionNineBannerThemeThree />}
        {settingsData?.template == 4 && <SectionNineBannerThemeFour />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section10Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {settingsData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[9]?.is_section_enable === true && (
            <SectionTenBannerThemeOne />
          )}
        {settingsData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[8]?.is_section_enable === true && (
            <SectionTenBannerThemeThree />
          )}
        {settingsData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[9]?.is_section_enable === true && (
            <SectionTenThemeFour />
          )}
      </div>

      <div
        className="modal fade"
        id="section10Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {settingsData?.template == 1 && <SectionTenThemeOne />}
        {settingsData?.template == 3 && <SectionTenThemeThree />}
        {settingsData?.template == 4 && <SectionTenThemeFourEdit />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section11Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {settingsData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[9]?.is_section_enable === true && (
            <SectionElevenThemeThree />
          )}
        {settingsData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[10]?.is_section_enable === true && (
            <SectionElevenThemeFour />
          )}
      </div>

      <div
        className="modal fade"
        id="section11Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {settingsData?.template == 3 && <SectionElevenThemeThreeEdit />}
        {settingsData?.template == 4 && <SectionElevenThemeFourEdit />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section12Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {settingsData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[10]?.is_section_enable === true && (
            <SectionTwelveBannerThemeThree />
          )}
        {settingsData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[11]?.is_section_enable === true && (
            <SectionTwelveThemeFour />
          )}
      </div>

      <div
        className="modal fade"
        id="section12Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {settingsData?.template == 3 && <SectionTwelveThemeThree />}

        {/* <SectionTwelveThemeThree /> */}
        {settingsData?.template == 4 && <SectionTwelveBannerThemeFour />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section13Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {settingsData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[11]?.is_section_enable === true && (
            <SectionThirteenThemeThree />
          )}
        {settingsData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[12]?.is_section_enable === true && (
            <SectionThirteenThemeFour />
          )}
      </div>

      <div
        className="modal fade"
        id="section13Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {settingsData?.template == 3 && <SectionThirteenThemeThreeEdit />}
      </div>
      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section15Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {settingsData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[12]?.is_section_enable === true && (
            <SectionFifteenThemeThree />
          )}
        {settingsData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[14]?.is_section_enable === true && (
            <SectionFourteenThemeFour />
          )}
      </div>

      <div
        className="modal fade"
        id="section15Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {settingsData?.template == 3 && <SectionFifteenThemeThreeEdit />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#footerEditmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {settingsData?.template == 1 && <FooterOne />}
        {settingsData?.template == 2 && <FooterTwo />}
        {settingsData?.template == 3 && <FooterThree />}
        {settingsData?.template == 4 && <FooterFour />}
        {settingsData?.template == 5 && <FooterFive />}
      </div>

      <div
        className="modal fade"
        id="sectiononeEditmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {settingsData?.template == 1 && <SectionOneEditThemeOne />}
        {settingsData?.template == 2 && <SectionOneThemeTwoEdit />}
        {settingsData?.template == 3 && <SectionOneEditThemeThree />}
        {settingsData?.template == 4 && <SectionOneEditThemeFour />}
        {settingsData?.template == 5 && <SectionOneEditThemeFive />}
      </div>
      <div
        className="modal fade"
        id="headerEditmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {settingsData?.template == 1 && <HeaderOneEdit />}
        {settingsData?.template == 2 && <HeaderTwoEdit />}
        {settingsData?.template == 3 && <HeaderThreeEdit />}
        {settingsData?.template == 4 && <HeaderFourEdit />}
        {settingsData?.template == 5 && <HeaderFiveEdit />}
      </div>

      <div
        className="modal fade"
        id="footerEditmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {/* <FooterEdit /> */}
        {settingsData?.template == 1 && <FooterOneEdit />}
        {settingsData?.template == 2 && <FooterTwoEdit />}
        {settingsData?.template == 3 && <FooterThreeEdit />}
        {settingsData?.template == 4 && <FooterFourEdit />}
        {settingsData?.template == 5 && <FooterFiveEdit />}
      </div>
      <div className="theme-changer-home">
        <a href="/">
          <i className="fas fa-sign-out-alt"></i>
        </a>
      </div>
      <div className="theme-changer">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#themeColorEditmodal"
        >
          <i className="fas fa-cog"></i>
        </a>
      </div>
      <div
        className="modal fade"
        id="themeColorEditmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {settingsData?.template == 1 && <SectionThemeOneEditor />}
        {settingsData?.template == 2 && <SectionThemeTwoEditor />}
        {settingsData?.template == 3 && <SectionThemeThreeEditor />}
        {settingsData?.template == 4 && <SectionThemeFourEditor />}
        {settingsData?.template == 5 && <SectionThemeFiveEditor />}
      </div>
    </>
  );
}

export default Edit;
