import React, { useEffect, useState } from "react";
import axios from "../../config";

import { useNavigate } from "react-router-dom";
import { getUserDetails, setUserdetails } from "../../redux/Actions";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { toast, ToastContainer } from "react-toastify";
import { favicon } from "../../assets/img";
import { checkShopListFlow } from "../../utility";

const base_url = process.env.REACT_APP_BACKEND_URL;
const client_base_url = "http://localhost:3000";

function GuestLogin() {

  const dispatch = useDispatch<any>();
  const [login, setLogin] = useState(true);
  const navigate = useNavigate();
  const [nameValue, setNameValue] = useState("");
  const [mobileValue, setMobileValue] = useState("");
  const [loginResult, setLoginResult] = useState<any | null>({});
  const [emailValue, setEmailValue] = useState("");
  const [pwdValue, setPwdValue] = useState("");
  const [genError, setGenError] = useState("");
  const handleNameChange = (e: any) => {
    setNameValue(e.target.value);
  };
  const handleMobileChange = (e: any) => {
    setMobileValue(e.target.value);
  };

  const handleChange = (e: any) => {
    setEmailValue(e.target.value);
  };
  const handlePwdChange = (e: any) => {
    setPwdValue(e.target.value);
  };

  useEffect(() => {
    document
      .getElementById("guestlogo")
      ?.setAttribute(
        "src",
        document.getElementById("site_logo")?.getAttribute("src")!
      );
    document
      .getElementById("loginlogo")
      ?.setAttribute(
        "src",
        document.getElementById("site_logo")?.getAttribute("src")!
      );
  });

  const order_type: any = useSelector<any>((state) => state?.orderType);

  const settings: any = useSelector<any>((state) => state.settings);
  const navigateToCheckout = () => {
    if (checkShopListFlow()) {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toLowerCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());

    if (shopName) {
      navigate(`/shop/${finalResult}/checkOut`);
    } else {
      navigate("/checkout");
    }
  }else {
    navigate("/checkout");
}
}else {
  navigate("/checkout");
}
  };
  const handleSubmit = (event: any) => {
    const headers = { Accept: "application/json" };

    axios
      .post(
        `${base_url}/api/guest/user_registration`,
        { name: nameValue, mobile_number: mobileValue },
        {
          headers: headers,
        }
      )
      .then((e) => {
        setLoginResult({ result: e.data });

        // alert(e.data.Response.response_message)
        if (
          e.data.Response.response_code == "1" &&
          e.data.Response.response_message == "registered successfully"
        ) {
          dispatch(getUserDetails(e.data));
          localStorage.setItem("token", e.data.Response.token);
          localStorage.setItem("isCheckout", "1");
          const isFromCheckout = localStorage.getItem("isCheckout");
          if (order_type == 1) {
            navigateToCheckout();
            return;
          }
          if (isFromCheckout) {
            navigateToCheckout();
          } else {
            navigate("/");
            window.location.reload();
          }

          return false;
        } else {
          setGenError(e.data.Response.response_message);
          event.preventDefault();
          return false;
        }
      });
    event.preventDefault();
  };

  const handleLoginSubmit = async (event?: any) => {
    event.preventDefault();

    const base_url = process.env.REACT_APP_BACKEND_URL;
    const headers = {
      Accept: "application/json",
    };
    try {
      const response: any = await axios.post(
        `${base_url}/api/user_login`,
        { email: emailValue, password: pwdValue },
        { headers: headers }
      );
      // const result = await response.then(response => response);

      if (response) {
        if (response.data.Response.response_code == "-1") {
          toast(response.data.Response.response_message);
          return false;
        }
        // setPostalCodeList(response.data.data[0])
        dispatch(getUserDetails(response.data));
        // setLoginResult(response.data);
        localStorage.setItem("token", response.data.Response.token);

        const isFromCheckout = localStorage.getItem("isCheckout");

        if (order_type == 1) {
          navigateToCheckout();
          return;
        }
        if (isFromCheckout) {
          navigateToCheckout();
        } else {
          navigate("/");
          window.location.reload();
        }
      }
    } catch (error) {}

    // axios.post(`${base_url}/api/user_login`, { email: emailValue, password: pwdValue }, {
    //   headers: headers

    // }).then(e => {
    //   // setLoginResult({ result: e.data })

    //   if (e.data.Response.response_code == "1" && e.data.Response.response_message == "successfully logged in") {

    //     window.location.href = `${client_base_url}/`
    //     return false;
    //   }
    //   else {

    //     setGenError(e.data.Response.response_message);
    //     event.preventDefault();

    //     return false;
    //   }

    // })
    // event.preventDefault()
  };

  return (
    <div className="section-signin">
      <div className="container">
        {login ? (
          <div className=" signinModal" id="signinModal" role="dialog">
            <div className="row justify-content-center">
              <div className="col-md-5">
                <div className="signin">
                  <div className="signin-header">
                    <div className="img-container">
                      <img
                        id="guestlogo"
                        src={favicon}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <h4 id="proceed_as_guest_heading">
                      {" "}
                      Proceed as guest to place order
                    </h4>
                    <h4 id="login_heading" className="d-none">
                      {" "}
                      Login to place order
                    </h4>
                  </div>
                  <div className="tab-content">
                    <div className=" in active" id="new_user">
                      <form
                        name="form"
                        className="form-signin"
                        method="POST"
                        action={client_base_url + "/"}
                        onSubmit={handleSubmit}
                      >
                        <div className="signin-conent">
                          <div className="mandatory-message text-center">
                            <small>
                              Provide us your name and mobile number to place
                              order.
                            </small>
                          </div>
                          <input
                            type="hidden"
                            id="popup_api_key"
                            value="418094302484961"
                          />
                          <div className="form-group">
                            <label>Name</label>
                            <input
                              placeholder="John Doe"
                              id="popup_name"
                              type="text"
                              className="form-control signin-form"
                              name="popup_name"
                              value={nameValue}
                              required
                              onChange={handleNameChange}
                            />
                            <input
                              type="hidden"
                              name="_token"
                              value="Xj0VmvfCzNLcuoxjqpoWhrU8FAQelWYe2QLrPzaX"
                            />
                            <div className="popup_name_error text-danger text-center"></div>
                          </div>

                          <div className="form-group signup-mobno focused">
                            <label>Mobile</label>
                            <div>
                              <div className="input-mob-no">
                                <div className="input-group">
                                  <span className="input-group-addon">
                                    {
                                      settings?.WebmasterSettings
                                        ?.mobile_notification_prefix
                                    }
                                  </span>
                                  <input
                                    placeholder="7123456789"
                                    id="popup_mobile"
                                    type="tel"
                                    className="form-control signin-form filled"
                                    name="popup_mobile"
                                    value={mobileValue}
                                    required
                                    onChange={handleMobileChange}
                                  />
                                </div>
                              </div>
                              <div className="popup_mobile_number_error text-danger text-center"></div>
                            </div>
                          </div>
                          <div className="name_error text-danger">
                            {genError}
                          </div>
                          <div className="form-group text-center">
                            <input
                              type="hidden"
                              id="api_key"
                              value="418094302484961"
                            />
                            <button
                              id="submit_btn"
                              type="submit"
                              className="btn account-btn popup_register_btn"
                            >
                              Proceed as guest
                            </button>
                          </div>
                          <div className="text-center text-bold tabaction">
                            Already have an account?{" "}
                            <a
                              data-login-type="login"
                              className="login_popup_toggle"
                              href="/login"
                            >
                              <span className="label label-success">
                                Log in
                              </span>
                            </a>
                          </div>
                        </div>
                      </form>
                      <div className="clearfix"></div>
                    </div>
                    {/* <div className="tab-pane fade" id="user">
                                            <input type="hidden" name="_token" value="Xj0VmvfCzNLcuoxjqpoWhrU8FAQelWYe2QLrPzaX" />
                                            <div className="signin-conent">
                                                <div className="form-group">
                                                    <label>Email<span className="required">*</span></label>
                                                    <input placeholder="john@example.com" type="email" name="popup_login_email" value="" className="form-control signin-form" required />
                                                </div>
                                                <div className="form-group focused">
                                                    <label>Password<span className="required">*</span></label>
                                                    <input placeholder="your password" type="password" name="popup_login_password" className="form-control signin-form filled" required />
                                                </div>
                                                <div className="form-group hidden">
                                                    <label>Confirm Password<span className="required">*</span></label>
                                                    <input type="password" id="popup_login_password_confirmation" name="popup_login_password_confirmation" className="form-control signin-form" required />
                                                </div>
                                                <div className="form-group">
                                                    <label className="md-check">
                                                        <input type="checkbox" name="remember" /> <i className="primary"></i> <span style={{ verticalAlign: "text-bottom" }}>Keep me signed in</span>
                                                    </label>
                                                </div>
                                                <div className="login_error text-danger text-center popup_login_error"></div>
                                                <div className="form-group text-center">
                                                    <input type="hidden" value="" name="redirect_to" />
                                                    <button type="submit" className="btn account-btn popup_login_btn">Log In</button>
                                                </div>
                                                <div className="form-group text-center hidden">
                                                    <button type="submit" className="btn account-btn popup_login_reset_btn">Sign in</button>
                                                </div>
                                                <div className="text-center">
                                                    <a href="#">Forgot password?</a>
                                                </div>
                                                <div className="text-center text-bold tabaction">
                                                    New to BestatLaundry? <a data-login-type="new" className="login_popup_toggle" href="#new_user" role="tab" data-bs-toggle="tab"><span className="label label-success">Proceed as guest</span></a>
                                                </div>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className=" signinModal" id="signinModal" role="dialog">
            <div className="row justify-content-center">
              <div className="col-md-5">
                <div className="signin">
                  <div className="signin-header">
                    <div className="img-container">
                      <img
                        id="loginlogo"
                        src={favicon}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <h4 id="proceed_as_guest_heading"> Login to place order</h4>
                    <h4 id="login_heading" className="d-none">
                      {" "}
                      Login to place order
                    </h4>
                  </div>
                  <form
                    name="form"
                    className="form-signin"
                    method="GET"
                    action={client_base_url + "/"}
                    onSubmit={handleLoginSubmit}
                  >
                    <div className="signin-conent">
                      <div className="form-group">
                        <label>
                          Email <span className="required">*</span>
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={emailValue}
                          className="form-control signin-form"
                          required
                          onChange={handleChange}
                        />
                        <input
                          type="hidden"
                          name="_token"
                          value="FBbQwFf2DmbwnlueNvqwZjDULKy5w9DwqGPKwXYO"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Password <span className="required">*</span>
                        </label>
                        <input
                          type="password"
                          name="password"
                          className="form-control signin-form"
                          required
                          value={pwdValue}
                          onChange={handlePwdChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="md-check">
                          <input type="checkbox" name="remember" />{" "}
                          <i className="primary"></i>{" "}
                          <span style={{ verticalAlign: "text-bottom" }}>
                            {" "}
                            Keep me signed in
                          </span>
                        </label>
                      </div>
                      <div className="name_error text-danger">{genError}</div>

                      <div className="form-group text-center">
                        <input
                          type="hidden"
                          name="_token"
                          value="FBbQwFf2DmbwnlueNvqwZjDULKy5w9DwqGPKwXYO"
                        />
                        <input type="hidden" value=" " name="redirect_to" />
                        <button type="submit" className="btn account-btn">
                          Sign in
                        </button>
                      </div>
                      <div className="text-center">
                        <a href="/forgotPassword" className="Forgot-password">
                          Forgot password?
                        </a>
                      </div>
                      <div className="text-center text-bold tabaction">
                        New to BestAtLaundry Laundry &amp; Drycleaning?{" "}
                        <a
                          data-login-type="new"
                          className="login_popup_toggle"
                          href="#"
                          role="tab"
                          data-bs-toggle="tab"
                          aria-expanded="true"
                          onClick={(e) => setLogin(true)}
                        >
                          <span className="label label-success">
                            Proceed as guest
                          </span>
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          {/* <OrderOnline /> */}
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}

export default GuestLogin;
