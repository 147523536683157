import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {eyebrows,eyelashextension,facial,haircolours,hotwax,massage,threading,waxing,doubledrawnindianhairextension} from "../../assets/img";

function Waxing () {

return (
    <>
        <section className="rbpsec">
            <div className="container">                
                <div className="row justify-content-center">                    
                    <div className="col-md-5">
                        <div className="rbptable">
                            <h3>Waxing</h3>
                            <table className="table">
                                <thead>
                                    <th></th>
                                    <th></th>
                                    <th className="text-end">Hot Wax</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Eyebrow</td>
                                        <td>£3.50</td>
                                        <td className="text-end w-cus1">£4.00</td>
                                    </tr>
                                    <tr>
                                        <td>Upperlip</td>
                                        <td>£2.50</td>
                                        <td className="text-end w-cus1">£3.00</td>
                                    </tr>
                                    <tr>
                                        <td>Chin</td>
                                        <td>£3.50</td>
                                        <td className="text-end w-cus1">£5.00</td>
                                    </tr>
                                    <tr>
                                        <td>Forehead</td>
                                        <td>£2.50</td>
                                        <td className="text-end w-cus1">£3.00</td>
                                    </tr>
                                    <tr>
                                        <td>Sides</td>
                                        <td>£5.00</td>
                                        <td className="text-end w-cus1">£5.00</td>
                                    </tr>
                                    <tr>
                                        <td>Full face (inc Eyebrows)</td>
                                        <td>£15.00</td>
                                        <td className="text-end w-cus1">£15.00</td>
                                    </tr>
                                    <tr>
                                        <td>Nostrils</td>
                                        <td></td>
                                        <td className="text-end w-cus1">£5.00</td>
                                    </tr>
                                    <tr>
                                        <td>Under arm</td>
                                        <td>£5.00</td>
                                        <td className="text-end w-cus1">£7.00</td>
                                    </tr>
                                    <tr>
                                        <td>Half arm</td>
                                        <td>£10.00</td>
                                        <td className="text-end w-cus1"></td>
                                    </tr>
                                    <tr>
                                        <td>Full arm</td>
                                        <td>£12.00</td>
                                        <td className="text-end w-cus1"></td>
                                    </tr>
                                    <tr>
                                        <td>Half leg</td>
                                        <td>£12.00</td>
                                        <td className="text-end w-cus1"></td>
                                    </tr>
                                    <tr>
                                        <td>Full leg</td>
                                        <td>£20.00</td>
                                        <td className="text-end w-cus1"></td>
                                    </tr>
                                    <tr>
                                        <td>Full back</td>
                                        <td>£15.00</td>
                                        <td className="text-end w-cus1"></td>
                                    </tr>
                                    <tr>
                                        <td>Full front</td>
                                        <td>£15.00</td>
                                        <td className="text-end w-cus1"></td>
                                    </tr>
                                    <tr>
                                        <td>Bikini line</td>
                                        <td>£5.00</td>
                                        <td className="text-end w-cus1">£7.00</td>
                                    </tr>
                                    <tr>
                                        <td>Hollywood</td>
                                        <td>£35.00</td>
                                        <td className="text-end w-cus1">£40.00</td>
                                    </tr>
                                    <tr>
                                        <td>Men eyebrow</td>
                                        <td>£5.00</td>
                                        <td className="text-end w-cus1"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="rbpimg">
                            <img src={waxing} alt="Waxing" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
)
}
export default Waxing;