import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getHomeJsonList } from "../../redux/Actions";
import ThemeTwo from "../../components/theme-two";
import ThemeFour from "../../components/theme-four";
import UpLands from "../../components/upLands";
import ThemeFive from "../../components/theme-five";
import ThemeOne from "../../components/theme-one";
import ThemeThree from "../../components/theme-three";
import Rogers from "../../components/rogersofpimlico";
import Wheatley from "../../components/wheatleyLaundry";

function Home() {
  const dispatch = useDispatch<any>();
  const settingsData: any = useSelector<any>((state) => state?.settings);

  useEffect(() => {
    dispatch(getHomeJsonList());
    localStorage.removeItem("currentShopHome")
  }, []);


  return (
    <div className="sec-home">
      {/* Theme One */}
      {settingsData?.template == 1 && <ThemeOne />}
      {/* Theme One */}

      {/* Theme Two */}
      {settingsData?.template == 2 && <ThemeTwo />}
      {/* Theme Two */}

      {/* Theme Three */}
      {settingsData?.template == 3 && <ThemeThree />}

      {/* Theme Four */}
      {settingsData?.template == 4 && <ThemeFour />}      
      {/* Theme Four */}

      {/* Theme Five */}
      {settingsData?.template == 5 && <ThemeFive />}
      {/* Theme Five */}

      {/* Uplands */}
      {settingsData?.template == 6 && <UpLands />}
      {/* Uplands */}

      {/* Rogers */}
      {settingsData?.template == 7 && <Rogers />}
      {/* Rogers */}

      {/* Wheatley */}      
      {settingsData?.template == 8 && <Wheatley />}
      {/* Wheatley */}

    </div>
  );
}

export default Home;
