import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import { fetchFaqData } from "../../../redux/Actions/policyActions";

import axios from "axios";
import { shop_data_laundry } from "../edit/data";

function SectionSevenThemeThree() {
  const navigate = useNavigate();
  const [productList, setProductList] = useState<any | null>({});
  const [blogList, setBlogList] = useState<any | null>({});
  const faqData: any = useSelector<any>((state) => state?.faq);
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const dispatch = useDispatch<any>();
  const data: any = useSelector<any>((state) => state.shopHomeJsonList);
  //const data: any = shop_data_laundry;
  const jsonData = data?.theme_3.home?.section_7;
  let currentShopId = localStorage.getItem("currentShopHome") || null;
  const url = new URL(window.location.href);
  const queryParamsShopId = url.searchParams.get("shop_id");
  const url_array = url.pathname.split("/").filter(Boolean);
  let shop_name = url_array[1];
  if (queryParamsShopId) {
    shop_name = queryParamsShopId;
    currentShopId = queryParamsShopId;
  }
  useEffect(() => {
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .get(`${base_url}/api/get_categories?group_id=1&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e) => {
        setProductList({ data: e.data });
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });

    axios
      .get(`${base_url}/api/blog_list?`, {
        headers: headers,
      })
      .then((e) => {
        setBlogList(e.data.data);
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });
  }, []);
  useEffect(() => {
    dispatch(fetchFaqData());
  }, []);

  const navigateToPricing = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/pricing`);
    } else {
      navigate("/pricing");
    }
  } else {
    navigate("/pricing");
  }
  };
  return (
    <>
      <section className="sh3-cate-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sh3-section-title text-start">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <h2>
                      {jsonData.title}
                      {/* On Demand Laundry,{" "}
                      <span className="primary-text">
                        Dry Cleaning And Ironing
                      </span>{" "}
                      Categories */}
                    </h2>
                  </div>
                  <div className="col-md">
                    <p>{jsonData.paragraph}</p>
                  </div>
                  {/* <div className="col-md-auto">
                    <a
                      style={{
                        background: `#${jsonData.btn_group.btn1.bg_color}`,
                        color: `#${jsonData.btn_group.btn1.color}`,
                      }}
                      href="#book-now"
                      className="btn title-btn hover-btn"
                    >
                      <i className="uil uil-package me-2"></i>{" "}
                      {jsonData.btn_group.btn1.text}
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>        
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 px-md-0">
              <Swiper
                  spaceBetween={5}
                  navigation={true}
                  modules={[Navigation]}
                  centerInsufficientSlides={true}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 1,
                    },
                    1024: {
                      slidesPerView: 2,
                    },
                  }}
                  className="home-slider"
                >
                  {jsonData?.card_section?.collection.map((item: any,index: any) => {
                      return (
                        <SwiperSlide>
                          <div className="sh3-card-cate" key={index}>
                            <img src={item.icon} alt="" onClick={(e) => {
                                  e.preventDefault();
                                  navigateToPricing();
                                }}/>
                            <div className="sh3-card-cate-body d-none">
                            <h3>{item.card_title}</h3>
                            <div className="chips-container">
                              {item.chips &&
                                item.chips.slice(0,4).map((chip:any, chipIndex:any) => (
                                  <span key={chipIndex} className="chip">
                                    {chip}
                                  </span>
                                ))}
                                {
                                  item.chips.length > 4 && (
                                  <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigateToPricing();
                                }}
                                className="viewMore"
                              >view More</a>
                      )}
                                
                            </div>
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigateToPricing();
                                }}
                                className="btn primary-btn rounded-circle hover-btn"
                              >
                                <i className="uil uil-arrow-right"></i>
                              </a>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
                {/* <Swiper
                  spaceBetween={5}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 4,
                    },
                    1024: {
                      slidesPerView: 8,
                    },
                  }}
                  className="home-slider"
                >
                  {Object.keys(productList).length > 0 &&
                    productList?.data?.data?.category?.map((item: any) => {
                      return (
                        <SwiperSlide>
                          <div className="sh3-card-cate">
                              <div className="sh3-card-cateimg">
                                <img src={item.web_banner_img} alt="" />
                              </div>
                            <div className="sh3-card-cate-body">
                              <p className="secondary-text">
                                {item.sub_categories.length} Items
                              </p>
                              <h3>{item.main_category_name}</h3>
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate("pricing");
                                }}
                                className="btn primary-btn rounded-circle hover-btn"
                              >
                                <i className="uil uil-arrow-right"></i>
                              </a>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper> */}
              </div>
            </div>
          </div>
      </section>
    </>
  );
}

export default SectionSevenThemeThree;
