import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { shopimg } from "../../assets/img";
import { fetchRandomShopList } from "../../redux/Actions/checkoutPageActions";

function HomeShopList() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const settings: any = useSelector<any>((state) => state.settings);
  const shopsList: any = useSelector<any>(
    (state) => state.shopsList?.data
  );

  useEffect(() => {
    dispatch(fetchRandomShopList());  
  }, []);
  const handleShopClick = (currentShopHome: any, currentShopName: any) => {
    localStorage.setItem("shop_id", currentShopHome);
    localStorage.setItem("currentShopHome", currentShopHome);
    localStorage.setItem("currentShopName", currentShopName);
    const shopName = currentShopName;
    const resultOne = shopName.replace(/\s(.)/g, function ($1: string) {
      return $1.toLowerCase();
    });
    const resultTwo = resultOne.replace(/\s/g, "");
    const finalResult = resultTwo.replace(/^(.)/, function ($1: string) {
      return $1.toLowerCase();
    });
    navigate(`/shop/${finalResult}`);
  };

  return (
    <>      
      <div className="container my-5">
        <div className="row">
          <div className="col-md-12">
            <div className="home-shop-hdr">
              <h2 className="d-inline-block">Most Popular Shops</h2>
              {/* <NavLink className="ms-2" to={'./shoplist'}>View All <i className="fas fa-angle-right ms-2"></i></NavLink> */}
            </div>
          </div>
        </div>
        <div className="row">
          {shopsList?.shops?.slice(0, 8).map((item: any,index:number) => {
            return (
              <>
                <div key={index} className="col-md-3 d-flex">
                  <div className="shop-grid">
                    <div className="shop-img text-center">
                      {item?.setting?.shop_logo_url ? <img className="p-3" src={item?.setting?.shop_logo_url || shopimg} alt="" /> : <img src={shopimg} alt="" />}
                      {/* <img src={item?.setting?.shop_logo_url || shopimg} alt="" /> */}
                    </div>
                    <div className="shop-details">
                      <h3
                        onClick={() => handleShopClick(item?.id, item?.name)}
                        style={{ cursor: "pointer" }}
                      >
                        {item?.name}
                      </h3>
                      <p className="clr-grey shop-details-adrs">
                        <i className="fas fa-map-marker-alt me-2"></i>
                        {item?.address?.text_address}
                      </p>
                      <ul className="d-none">
                        {item?.recent_products.map((services: any,index:number) => {
                          return (
                            <>
                              <li key={index}>
                                <div>
                                  {services?.groupcategory?.subcategory.name}
                                  <span className="clr-grey ms-4">
                                    <i className="far fa-clock me-2"></i>
                                    {services?.groupcategory?.process_time}hrs
                                  </span>
                                </div>
                                <div className="shop-price">
                                  {settings?.WebmasterSettings?.currency}{" "}
                                  {
                                    services?.group_category_default_option_price?.price
                                  }
                                </div>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                      <div className="shop-serv-link">
                        <a onClick={() => handleShopClick(item?.id, item?.name)} className="btn btn1">View Shop<i className="fas fa-angle-right"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default HomeShopList;
