import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHomeJsonList,
  saveThemeJsonData,
  fetchCroppedImage,
} from "../../../redux/Actions";
import { ColorPicker } from "primereact/colorpicker";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import ImageCropper from "../../imageCropper/imageCropper";
import CreatableSelect from "react-select/creatable";
import { data } from "../data";

const SectionFiveThemeTwoEdit = () => {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const data: any = useSelector<any>((state) => state.homeJsonList);
  // const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  // // const jsonData = data
  const [activeTab, setActiveTab] = useState(0);
  // const [activeTabTwo, setActiveTabTwo] = useState(0);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);

  const icons = [
    "assets/img/icons/serviceicon2.svg",
    "assets/img/icons/serviceicon3.svg",
    "assets/img/icons/serviceicon4.svg",
    "assets/img/icons/serviceicon2.svg",
    "assets/img/icons/serviceicon3.svg",
    "assets/img/icons/serviceicon4.svg",
    "assets/img/icons/serviceicon2.svg",
    "assets/img/icons/serviceicon3.svg",
    "assets/img/icons/serviceicon4.svg",
  ];

  const saveJsonDataToFile = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  useEffect(() => {
    setEditData(data);
  }, [data]);

  //   setEditData(jsonData);
  // }, [jsonData]);

  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);

  const handleChange = (e: any, index?: any) => {
    const { value, name, type, checked } = e.target;
    let updatedCollection: any = [
      ...editData.theme_2.home.section_5.cards,
    ];
    if (index != undefined) {
      updatedCollection = [...editData.theme_2.home.section_5.cards];
      updatedCollection[index] = {
        ...updatedCollection[index],
        [name]: value,
      }
      setEditData((prevJsonData: any) => ({
        ...prevJsonData,
        theme_2: {
          home: {
            ...prevJsonData.theme_2.home,
            section_5: {
              ...prevJsonData.theme_2.home.section_5,
              [name]: value,
              cards: updatedCollection,
            },
          },
        },
      }));
    };
  };
  

  

  // const addCollection = () => {
  //   setActiveTabTwo(activeTabTwo + 1);
  //   setEditData((prevJsonData: any) => {
  //     const updatedCards = [...prevJsonData.theme_2.home.section_5.cards];
  //     const newChecklistItem = { id: (updatedCards[activeTab]?.card_options.length + 1).toString(), list: "" };
  
  //     const updatedCardOptions = [
  //       ...updatedCards[activeTab].card_options.slice(0, activeTabTwo + 1),
  //       newChecklistItem,
  //       ...updatedCards[activeTab].card_options.slice(activeTabTwo + 1)
  //     ];
  

  //     updatedCards[activeTab] = {
  //       ...updatedCards[activeTab],
  //       card_options: updatedCardOptions
  //     };
  

  //     return {
  //       ...prevJsonData,
  //       theme_2: {
  //         ...prevJsonData.theme_2,
  //         home: {
  //           ...prevJsonData.theme_2.home,
  //           section_5: {
  //             ...prevJsonData.theme_2.home.section_5,
  //             cards: updatedCards,
  //           },
  //         },
  //       },
  //     };
  //   });
  // };
  
  // const removeCollection = (cardIndex: number, checklistIndex: number) => {
  //   setActiveTabTwo(activeTabTwo - 1);
  //   setEditData((prevJsonData: any) => {
  //     const updatedCards = [...prevJsonData.theme_2.home.section_5.cards];
  

  //     const updatedCardOptions = [
  //       ...updatedCards[cardIndex].card_options.slice(0, checklistIndex),
  //       ...updatedCards[cardIndex].card_options.slice(checklistIndex + 1)
  //     ];
  
  //     updatedCards[cardIndex] = {
  //       ...updatedCards[cardIndex],
  //       card_options: updatedCardOptions
  //     };
  
  //     return {
  //       ...prevJsonData,
  //       theme_2: {
  //         ...prevJsonData.theme_2,
  //         home: {
  //           ...prevJsonData.theme_2.home,
  //           section_5: {
  //             ...prevJsonData.theme_2.home.section_5,
  //             cards: updatedCards,
  //           },
  //         },
  //       },
  //     };
  //   });
  // };
  
  // const handleChange = (e: any, index?: any, checklistIndex?: any) => {
  //   const { value, name } = e.target;
  
  //   setEditData((prevJsonData: any) => {
  //     const updatedCards = [...prevJsonData.theme_2.home.section_5.cards];
  
  //     if (index !== undefined && checklistIndex !== undefined) {

  //       updatedCards[index].card_options[checklistIndex] = {
  //         ...updatedCards[index].card_options[checklistIndex],
  //         [name]: value,
  //       };
  //     } else if (index !== undefined) {
  //       updatedCards[index] = {
  //         ...updatedCards[index],
  //         [name]: value,
  //       };
  //     }
  
  //     return {
  //       ...prevJsonData,
  //       theme_2: {
  //         ...prevJsonData.theme_2,
  //         home: {
  //           ...prevJsonData.theme_2.home,
  //           section_5: {
  //             ...prevJsonData.theme_2.home.section_5,
  //             cards: updatedCards,
  //           },
  //         },
  //       },
  //     };
  //   });
  // };  

  const handleImageChange = (image: string, index: any) => {
    const updatedCollection = [...editData.theme_2.home.section_5.cards];
    updatedCollection[index] = {
      ...updatedCollection[index],
      icon: image,
    };

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_2: {
        home: {
          ...prevJsonData.theme_2.home,
          section_5: {
            ...prevJsonData.theme_2.home.section_5,
            ...prevJsonData.theme_2.home.section_5.cards,
            cards: updatedCollection,
          },
        },
      },
    }));
  };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleImageChange(imageLink, activeTab);
    }
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150");
    // setCropImageWidth("150");
    // setSelectedCropImage(image);
    // setShowCropModal(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        handleCroppedImage(res.target.result);
      };
    }
  };

  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section Five Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 250 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={editData?.theme_2?.home?.section_5?.title}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 1 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h2color"
                        value={
                          editData?.theme_2?.home?.section_5?.h2color
                        }
                      />
                      <ColorPicker
                        name="h2color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_2?.home?.section_5?.h2color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 2 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h3color"
                        value={
                          editData?.theme_2?.home?.section_5?.h3color
                        }
                      />
                      <ColorPicker
                        name="h3color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_2?.home?.section_5?.h3color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">paragraph Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="paracolor"
                        value={
                          editData?.theme_2?.home?.section_5?.paracolor
                        }
                      />
                      <ColorPicker
                        name="paracolor"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_2?.home?.section_5?.paracolor
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                  {editData?.theme_2?.home?.section_5?.cards.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={index}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              activeTab === index ? "active" : ""
                            }`}
                            onClick={() => setActiveTab(index)}
                          >
                            Card {index + 1}
                          </a>
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
                {editData?.theme_2?.home?.section_5?.cards.map(
                  (card: any, index: any) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        activeTab === index ? "show active" : ""
                      }`}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="img-bg active">
                            <img
                              src={card.icon}
                              alt={`Card Icon ${index + 1}`}
                            />
                          </div>
                          <div className="img-options w-100">
                            <Swiper
                              spaceBetween={15}
                              navigation={true}
                              modules={[Navigation]}
                              breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                },
                                768: {
                                  slidesPerView: 4,
                                },
                                1024: {
                                  slidesPerView: 6,
                                },
                              }}
                              className="homejson-slider"
                            >
                              {icons.map((icons, iconIndex) => (
                                <SwiperSlide key={iconIndex}>
                                  <div
                                    className="img-option"
                                    onClick={() =>
                                      handleImageChange(icons, index)
                                    }
                                  >
                                    <img
                                      src={icons}
                                      alt="Hero Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
      <label className="form-label">Check List</label>
      <CreatableSelect
        isMulti
        name="checklist"
        options={card?.card_options.map((item: string) => ({
          label: item,
          value: item,
        }))}
        value={card?.card_options.map((item: string) => ({
          label: item,
          value: item,
        }))}
        onChange={(selectedOptions: any) => {
          const updatedChecklist = selectedOptions.map(
            (option: any) => option.value
          );
          const updatedCards = [...editData.theme_2.home.section_5.cards];
          updatedCards[index] = {
            ...updatedCards[index],
            card_options: updatedChecklist,
          };
          setEditData((prevJsonData: any) => ({
            ...prevJsonData,
            theme_2: {
              home: {
                ...prevJsonData.theme_2.home,
                section_5: {
                  ...prevJsonData.theme_2.home.section_5,
                  cards: updatedCards,
                },
              },
            },
          }));
        }}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </div>

                      {/* <label className="form-label">Checklist</label>
                      <div className="edit-section">
                        <ul className="nav nav-tabs">
                          <Swiper
                            spaceBetween={15}
                            navigation={true}
                            modules={[Navigation]}
                            breakpoints={{
                              640: {
                                slidesPerView: 1,
                              },
                              768: {
                                slidesPerView: 2,
                              },
                              1024: {
                                slidesPerView: 3,
                              },
                            }}
                            className="homejson-slider"
                          >
                            {card.card_options.map(
                              (checklist: any, checklistIndex: any) => (
                                <SwiperSlide key={checklistIndex}>
                                  <li
                                    key={checklistIndex}
                                    className="nav-item nav-option"
                                  >
                                    <a
                                      className={`nav-link ${
                                        activeTabTwo === checklistIndex
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        setActiveTabTwo(checklistIndex)
                                      }
                                    >
                                      Check List {checklistIndex + 1}
                                    </a>
                                    <a
                                      className="tab-close"
                                      onClick={() => {
                                        removeCollection(index,checklistIndex);
                                      }}
                                    >
                                      <i
                                        className="fas fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </li>
                                </SwiperSlide>
                              )
                            )}
                          </Swiper>
                        </ul>
                        <div className="tab-content">
                          {card.card_options.map(
                            (checklist: any, checklistIndex: any) => (
                              <div
                                key={checklistIndex}
                                className={`tab-pane fade ${
                                  activeTabTwo === checklistIndex
                                    ? "show active"
                                    : ""
                                }`}
                              >
                                <div className="mb-3">
                                  <label className="form-label">
                                    Check List<small>(max 250 char)</small>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="list"
                                    value={checklist.list}
                                    onChange={(e) => handleChange(e, index, checklistIndex )}
                                    maxLength={250}
                                  />
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="add-section">
                        <div className="row">
                          <div
                            onClick={() => {
                              addCollection();
                            }}
                            className="col text-center"
                          >
                            Add Card<i className="fas fa-plus-square ms-2"></i>
                          </div>
                        </div>
                      </div> */}
                      <div className="mb-3">
                        <label className="form-label">Upload Image</label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          name="image"
                          onChange={updateImageSectionOne}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default SectionFiveThemeTwoEdit;
