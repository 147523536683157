import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {eyebrows,eyelashextension,facial,haircolours,hotwax,massage,threading,waxing,doubledrawnindianhairextension} from "../../assets/img";

function Haircolour () {

return (
    <>
        <section className="rbpsec">
            <div className="container">                
                <div className="row justify-content-center">                    
                    <div className="col-md-5">
                        <div className="rbptable">
                            <h3>Hair & Colours</h3>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>Dry cut kids</td>
                                        <td></td>
                                        <td className="text-end w-cus1">£12.00</td>
                                    </tr>
                                    <tr>
                                        <td>Dry cut ladies</td>
                                        <td></td>
                                        <td className="text-end w-cus1">£20.00</td>
                                    </tr>
                                    <tr>
                                        <td>Wash,cut & blowdry</td>
                                        <td className="rbpstext"></td>
                                        <td className="text-end w-cus1">£35.00</td>
                                    </tr>
                                    <tr>
                                        <td>Dandruff Treatment</td>
                                        <td className="rbpstext">From</td>
                                        <td className="text-end w-cus1">£30.00</td>
                                    </tr>
                                    <tr>
                                        <td>Hair Spa</td>
                                        <td className="rbpstext">From</td>
                                        <td className="text-end w-cus1">£30.00</td>
                                    </tr>
                                    <tr>
                                        <td>Root Colour</td>
                                        <td className="rbpstext"></td>
                                        <td className="text-end w-cus1">£30.00</td>
                                    </tr>
                                    <tr>
                                        <td>Full head colour</td>
                                        <td className="rbpstext">From</td>
                                        <td className="text-end w-cus1">£35.00</td>
                                    </tr>
                                    <tr>
                                        <td>Full head highlight</td>
                                        <td className="rbpstext">From</td>
                                        <td className="text-end w-cus1">£80.00</td>
                                    </tr>
                                    <tr>
                                        <td>Balayage with any haircut</td>
                                        <td className="rbpstext"></td>
                                        <td className="text-end w-cus1">£120.00</td>
                                    </tr>
                                    <tr>
                                        <td>Permanent straightening</td>
                                        <td className="rbpstext">From</td>
                                        <td className="text-end w-cus1">£80.00</td>
                                    </tr>
                                    <tr>
                                        <td>Permanent curling</td>
                                        <td className="rbpstext">From</td>
                                        <td className="text-end w-cus1">£100.00</td>
                                    </tr>
                                    <tr>
                                        <td>Keratin Treatment</td>
                                        <td className="rbpstext">From</td>
                                        <td className="text-end w-cus1">£100.00</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>(price varies depending on hair length)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="rbpimg">
                            <img src={haircolours} alt="haircolours" />
                        </div>
                        {/* <div className="rbpimg mt-3">
                            <img src={doubledrawnindianhairextension} alt="doubledrawnindianhairextension" />
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    </>
)
}
export default Haircolour;