import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {slider1,slider2,slider3,slider4,slider5,slider6,slider7,slider8} from "../../assets/img";

function Contact () {

return (
    <>
        <div className="container">
            <div className="row">
                <div className="col-md-12">                    
                    <div className="slider-up">
                    <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        className="slider-up-slider"
                        >
                            
                            <SwiperSlide >                            
                                <img src={slider8} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider1} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider2} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider3} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider4} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider5} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider6} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider7} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                      </Swiper>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">   
                    <div className="up-content">
                        <h2 className="up-h2">CONTACT US</h2>
                        <p>Please drop us an email with any questions to may have or call on 01792 644238.</p>
                        <p className="my-4">We'll be happy to help.</p>
                        <p>Opening Hours:</p>
                        <p>Monday - Saturday 08.30am till 19.30pm.</p>
                        <p>Sunday 09.00am till 19.30pm</p>                                     
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="up-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2967.3408966878837!2d-3.9706749838196544!3d51.6181717518076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486ef52a91479f1d%3A0x67ae71ecc4a59ef9!2s73%20Uplands%20Cres%2C%20Uplands%2C%20Swansea%20SA2%200EX%2C%20UK!5e0!3m2!1sen!2sin!4v1696047251663!5m2!1sen!2sin" width="100%" height="520" style={{border:"0"}} loading="lazy"></iframe>
                    </div>
                    <div className="up-card-bg">
                        <p>Book a Pickup - Call 01792 644238 or<br/> text 07999 999294</p>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}
export default Contact;