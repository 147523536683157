import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { data } from "../data";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { trustimg, trustimg2, trustimg3, trustimg4 } from "../../../assets/img";
import {
  fetchHomeJsonList,
  saveThemeJsonData,
  fetchCroppedImage,
} from "../../../redux/Actions";
import { ColorPicker } from "primereact/colorpicker";
import CreatableSelect from "react-select/creatable";
import { useSelector } from "react-redux";
import ImageCropper from "../../imageCropper/imageCropper";

function SectionFiveThemeThreeEdit() {
  const [checklist, setChecklist] = useState<any>([
    { id: 1, title: "" }, // Initial empty checklist item
  ]);
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  // const jsonData = data
  const [activeTab, setActiveTab] = useState(0);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [imagedata, setImagedata] = useState<any>("" || undefined);
  const banner = [
    "assets/home/howwe1.jpg",
    "assets/home/howwe2.jpg",
    "assets/home/howwe3.jpg",
    "assets/home/howwe4.jpg",
    "assets/home/howwe1.jpg",
    "assets/home/howwe2.jpg",
    "assets/home/howwe3.jpg",
    "assets/home/howwe4.jpg",
  ];

  const saveJsonDataToFile = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };
 
  const addCollection = () => {
    let updatedCollection: any = [
      ...editData.theme_3.home.section_5.check_list,
    ];

    let newCard = {
      list: "",
    };
    updatedCollection.splice(activeTab + 1, 0, newCard);
    setActiveTab(activeTab + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      
      theme_3: {
        ...prevJsonData.theme_3,
        home: {
          ...prevJsonData.theme_3.home,
          section_5: {
            ...prevJsonData.theme_3.home.section_5,
            check_list: updatedCollection,
          },
        },
      },
    }));
  };

  const removeCollection = (index: any) => {
    let updatedCollection: any = [
      ...editData.theme_3.home.section_5.check_list,
    ];
    updatedCollection.splice(index, 1);
    setActiveTab(activeTab - 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_3:{
        ...prevJsonData.theme_3,

        home: {
          ...prevJsonData.theme_3.home,
          section_5: {
            ...prevJsonData.theme_3.home.section_5,
            check_list: updatedCollection,
          },
        },
      }
    
    }));
  };
  const handleSection2Change = (e: any, index?: any) => {
    const { value, name, type, checked } = e.target;
  
    let updatedCollection: any = [...editData.theme_3.home.section_5.check_list];
    if (index !== undefined) {
      updatedCollection = [...editData.theme_3.home.section_5.check_list];
      updatedCollection[index] = {
        ...updatedCollection[index],
        [name]: value,
      };
    }
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_3: {
        home: {
          ...prevJsonData.theme_3.home,
          section_5: {
            ...prevJsonData.theme_3.home.section_5,
            [name]: value,
            title:
              name === "section_5.title"
                ? value
                : prevJsonData.theme_3.home.section_5.title,
            btn_group: {
              ...prevJsonData.theme_3.home.section_5.btn_group,
              btn1: {
                ...prevJsonData.theme_3.home.section_5.btn_group.btn1,
                text:
                  name === "section_5btn1.text"
                    ? value
                    : prevJsonData.theme_3.home.section_5.btn_group.btn1.text,
                bg_color:
                  name === "section_5btn1.bg_color"
                    ? value
                    : prevJsonData.theme_3.home.section_5.btn_group.btn1.bg_color,
                color:
                  name === "section_5btn1.color"
                    ? value
                    : prevJsonData.theme_3.home.section_5.btn_group.btn1.color,
              },
              btn2: {
                ...prevJsonData.theme_3.home.section_5.btn_group.btn2,
                text:
                  name === "section_5btn2.text"
                    ? value
                    : prevJsonData.theme_3.home.section_5.btn_group.btn2.text,
                bg_color:
                  name === "section_5btn2.bg_color"
                    ? value
                    : prevJsonData.theme_3.home.section_5.btn_group.btn2.bg_color,
                color:
                  name === "section_5btn2.color"
                    ? value
                    : prevJsonData.theme_3.home.section_5.btn_group.btn2.color,
              },
            },
            check_list: updatedCollection,
          },
        },
      },
    }));
  };

  const handleImageChange = (image: string, index: any) => {
    const updatedCollection = [...editData.theme_3.home.section_5.banner];
    updatedCollection[0] = {
      ...updatedCollection[0],
      banner: image,
    };

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_3: {
        home: {
          ...prevJsonData.theme_3.home,
          section_5: {
            ...prevJsonData.theme_3.home.section_5,
            banner: updatedCollection,
          },
        },
      },
    }));
  };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleImageChange(imageLink, activeTab);
    }
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150");
    // setCropImageWidth("150");
    // setSelectedCropImage(image);
    // setShowCropModal(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        handleCroppedImage(res.target.result);
      };
    }
  };

  useEffect(() => {
    const checklistData = editData?.theme_3?.home?.section_5?.check_list;
    if (checklistData) {
      setChecklist(checklistData);
    }
  }, [editData]);
  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);
  useEffect(() => {
    if (editData != undefined) {
      // dispatch(fetchHomeJsonList(editData));
      setEditData(editData);
    }
  }, [editData]);
  return (
    <>
      {/* <Draggable> */}
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section5 Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Title</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="section_5.title"
                        value={editData?.theme_3?.home?.section_5?.title}
                        onChange={handleSection2Change}
                        maxLength={1200}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">paragraph</label>
                      <input
                        type="text"
                        className="form-control"
                        name="paragraph"
                        value={editData?.theme_3?.home?.section_5?.paragraph}
                        onChange={handleSection2Change}
                        maxLength={1200}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Upload Image</label>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        className="form-control"
                        name="image"
                        onChange={updateImageSectionOne}
                      />
                    </div>
                    <div className="edit-section">
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-md-12">
                                {/* {editData?.theme_3?.home?.section_5?.banner?.map(
                                  (card: any, index: any) => (
                                    <> */}
                                      <div className="img-bg active">
                                        <img
                                          src={editData?.theme_3?.home?.section_5?.banner[0].banner}
                                          alt={`Card Icon`}
                                        />
                                      </div>
                                      <div className="img-options w-100">
                              <Swiper
                                spaceBetween={15}
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                  640: {
                                    slidesPerView: 1,
                                  },
                                  768: {
                                    slidesPerView: 4,
                                  },
                                  1024: {
                                    slidesPerView: 6,
                                  },
                                }}
                                className="homejson-slider"
                              >
                                {banner.map((icons, iconIndex) => (
                                  <SwiperSlide key={iconIndex}>
                                    <div
                                      className="img-option"
                                      onClick={() =>
                                        handleImageChange(icons, iconIndex)
                                      }
                                    >
                                      <img
                                        src={icons}
                                        alt="Hero Image"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                            </div>
                                    {/* </>
                                  )
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                  {editData?.theme_3?.home?.section_5?.check_list.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={index}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              activeTab === index ? "active" : ""
                            }`}
                            onClick={() => setActiveTab(index)}
                          >
                            Check List {index + 1}
                          </a>
                          <a
                            className="tab-close"
                            onClick={() => {
                              removeCollection(index);
                            }}
                          >
                            <i className="fas fa-times" aria-hidden="true"></i>
                          </a>
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
                {editData?.theme_3?.home?.section_5?.check_list.map(
                  (card: any, index: any) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        activeTab === index ? "show active" : ""
                      }`}
                    >
                   
                      <div className="mb-3">
                        <label className="form-label">
                          Check List<small>(max 250 char)</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="list"
                          value={card.list}
                          onChange={(e) => handleSection2Change(e, index)}
                          maxLength={250}
                        />
                      </div>
                      
           
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="add-section">
              <div className="row">
                <div
                  onClick={() => {
                    addCollection();
                  }}
                  className="col text-center"
                >
                  Add Card<i className="fas fa-plus-square ms-2"></i>
                </div>
              </div>
            </div>
            {/* <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className=" mb-3">
                    <label className="form-label">Button 1</label>
                    <input
                      type="text"
                      className="form-control"
                      name="section_5btn1.text"
                      value={
                        editData?.theme_3?.home?.section_5?.btn_group?.btn1
                          ?.text
                      }
                      onChange={handleSection2Change}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Bg Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="section_5btn1.bg_color"
                        value={
                          editData?.theme_3?.home?.section_5?.btn_group?.btn1
                            ?.bg_color
                        }
                        style={{
                          background: `#${editData?.theme_3?.home?.section_5?.btn_group?.btn1?.bg_color}`,
                        }}
                        onChange={handleSection2Change}
                        maxLength={250}
                      />
                      <ColorPicker
                        name="section_5btn1.bg_color"
                        onChange={(e) => {
                          handleSection2Change(e);
                        }}
                        format="hex"
                        value={
                          editData?.theme_3?.home?.section_5?.btn_group?.btn1
                            ?.bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className=" mb-3">
                    <label className="form-label">Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="section_5btn1.color"
                        value={
                          editData?.theme_3?.home?.section_5?.btn_group?.btn1
                            ?.color
                        }
                        style={{
                          background: `${editData?.theme_3?.home?.section_5?.btn_group?.btn1?.color}`,
                        }}
                        onChange={handleSection2Change}
                      />
                      <ColorPicker
                        name="section_5btn1.color"
                        onChange={(e) => {
                          handleSection2Change(e);
                        }}
                        format="hex"
                        value={
                          editData?.theme_3?.home?.section_5?.btn_group?.btn1
                            ?.color
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className=" mb-3">
                    <label className="form-label">Button 2</label>
                    <input
                      type="text"
                      className="form-control"
                      name="section_5btn2.text"
                      value={
                        editData?.theme_3?.home?.section_5?.btn_group?.btn2
                          ?.text
                      }
                      onChange={handleSection2Change}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Bg Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="section_5btn2.bg_color"
                        value={
                          editData?.theme_3?.home?.section_5?.btn_group?.btn2
                            ?.bg_color
                        }
                        style={{
                          background: `#${editData?.theme_3?.home?.section_5?.btn_group?.btn2?.bg_color}`,
                        }}
                        onChange={handleSection2Change}
                        maxLength={250}
                      />
                      <ColorPicker
                        name="section_5btn2.bg_color"
                        onChange={(e) => {
                          handleSection2Change(e);
                        }}
                        format="hex"
                        value={
                          editData?.theme_3?.home?.section_5?.btn_group?.btn2
                            ?.bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className=" mb-3">
                    <label className="form-label">Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="section_5btn2.color"
                        value={
                          editData?.theme_3?.home?.section_5?.btn_group?.btn2
                            ?.color
                        }
                        style={{
                          background: `${editData?.theme_3?.home?.section_5?.btn_group?.btn2?.color}`,
                        }}
                        onChange={handleSection2Change}
                      />
                      <ColorPicker
                        name="section_5btn2.color"
                        onChange={(e) => {
                          handleSection2Change(e);
                        }}
                        format="hex"
                        value={
                          editData?.theme_3?.home?.section_5?.btn_group?.btn2
                            ?.color
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </Draggable> */}
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
}

export default SectionFiveThemeThreeEdit;
