import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {eyebrows,eyelashextension,eyelash2} from "../../assets/img";

function Eyelash () {

return (
    <>
        <section className="rbpsec">
            <div className="container">                
                <div className="row justify-content-center">                    
                    <div className="col-md-5">
                        <div className="rbptable">
                            <h3>Eyelash Extension</h3>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>Eyebrow tint</td>
                                        <td className="text-end w-cus1">£5.00</td>
                                    </tr>
                                    <tr>
                                        <td>Eyelash tint</td>
                                        <td className="text-end w-cus1">£10.00</td>
                                    </tr>
                                    <tr>
                                        <td>Classic Lash</td>
                                        <td className="text-end w-cus1">£40.00</td>
                                    </tr>
                                    <tr>
                                        <td>Hybrid Lash</td>
                                        <td className="text-end w-cus1">£45.00</td>
                                    </tr>
                                    <tr>
                                        <td>Volume Lash</td>
                                        <td className="text-end w-cus1">£45.00</td>
                                    </tr>
                                    <tr>
                                        <td>LVL Lash Lift</td>
                                        <td className="text-end w-cus1">£30.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="rbpimg">
                            <img src={eyebrows} alt="eyebrows" />
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="rbpimg">
                            <img src={eyelash2} alt="eyelashextension" style={{ maxHeight: "600px"}}/>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">      
                    <div className="col-md-5">
                        
                    </div>
                </div>
            </div>
        </section>
    </>
)
}
export default Eyelash;