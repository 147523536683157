import React, { useEffect, useState } from "react";
import { trustimg4 } from "../../../assets/img";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../../edit/data";
import { fetchHomeJsonList } from "../../../redux/Actions";

function SectionElevenThemeThree() {
  const faqData: any = useSelector<any>((state) => state?.faq);
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);

  const homedata: any = useSelector<any>((state) => state?.shopHomeJsonList);
  useEffect(() => {}, [homedata]);
  return (
    <>
      <section className="sh3-faq-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sh3-section-title">
                <span className="sh3-yellow-btn">
                  {homedata?.theme_3?.home?.section_11?.sub_heading_top}
                </span>
                <h2 style={{
                      color: `#${homedata?.theme_3?.home?.section_11?.h2color}`
                    }}>{homedata?.theme_3?.home?.section_11?.title}</h2>
                <p style={{
                      color: `#${homedata?.theme_3?.home?.section_11?.paracolor}`
                    }}>{homedata?.theme_3?.home?.section_11?.paragraph}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="sh3-split-img">
                <img
                  src={homedata?.theme_3?.home?.section_11?.banner[0]?.banner}
                  alt=""
                />
                <div className="sh3-split-img-framegrid">
                  <div className="sh3-split-img-frame1"></div>
                  <div className="sh3-split-img-frame2"></div>
                  <div className="sh3-split-img-frame3"></div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="sh3-faq-content">
                <Accordion className="sh3-faq-home">
                  {homedata?.theme_3?.home?.section_11?.card_section
                    ?.collection &&
                    homedata?.theme_3?.home?.section_11?.card_section?.collection?.map(
                      (key: any) => {
                        return (
                          <>
                            <Accordion.Item eventKey={key._id}>
                              <Accordion.Header>
                                {
                                  key.collection_title
                                }
                              </Accordion.Header>
                              <Accordion.Body>
                                {
                                  key.collection_paragraph
                                }
                              </Accordion.Body>
                            </Accordion.Item>
                          </>
                        );
                      }
                    )}
                </Accordion>
                {/* {homedata?.theme_3?.home?.section_5?.check_list &&
                    Object.keys(homedata?.home?.section_5?.check_list).map(
                      (key) => (
                        <li key={key}>
                          {homedata?.home?.section_5?.check_list[key].title}
                        </li>
                      )
                    )} */}
                {/* <Accordion className="faq-home">
                  {faqData?.slice(0, 6).map((item: any) => {
                    return (
                      <Accordion.Item eventKey={JSON.stringify(item?.id)}>
                        <Accordion.Header>{item.question}</Accordion.Header>
                        <Accordion.Body>{item.answer}</Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionElevenThemeThree;
