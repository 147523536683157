import React, { useEffect, useState } from "react";
import { ColorPicker } from "primereact/colorpicker";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeJsonList, saveThemeJsonData } from "../../../redux/Actions";

function FooterFiveEdit() {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);

  const saveJsonDataToFile = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);

  const handleChange = (e: any, index?: any) => {
    const { value, name, type, checked } = e.target;

    setEditData((prevJsonData: any) => ({
        
      ...prevJsonData,
      theme_5: {
        ...prevJsonData.theme_5,
        home: {
          ...prevJsonData.theme_5.home,
          footer: {
            ...prevJsonData.theme_5.home.footer,
            [name]: value,
            
            site_title_en:
            name === "site_link"
              ? value
              : prevJsonData.theme_5.home.footer.site_title_en,
            footer_section: {
              ...prevJsonData.theme_5.home.footer.footer_section,
              needhelp_section: {
                ...prevJsonData.theme_5.home.footer.footer_section.needhelp_section,
                is_enable:
                  type === "checkbox" && name === "needhelp_is_enable"
                    ? !prevJsonData.theme_5.home.footer.footer_section
                        .needhelp_section.is_enable
                    : prevJsonData.theme_5.home.footer.footer_section
                        .needhelp_section.is_enable,
                text:
                  name === "needhelp_section"
                    ? value
                    : prevJsonData.theme_5.home.footer.footer_section
                        .needhelp_section.text,
              },
              policy_section: {
                ...prevJsonData.theme_5.home.footer.footer_section.policy_section,
                is_enable:
                  type === "checkbox" && name === "policy_is_enable"
                    ? !prevJsonData.theme_5.home.footer.footer_section
                        .policy_section.is_enable
                    : prevJsonData.theme_5.home.footer.footer_section
                        .policy_section.is_enable,
                text:
                  name === "policy_section"
                    ? value
                    : prevJsonData.theme_5.home.footer.footer_section
                        .policy_section.text,
              },
              quickconnect_section: {
                ...prevJsonData.theme_5.home.footer.footer_section.quickconnect_section,
                is_enable:
                  type === "checkbox" && name === "quickconnect_is_enable"
                    ? !prevJsonData.theme_5.home.footer.footer_section
                        .quickconnect_section.is_enable
                    : prevJsonData.theme_5.home.footer.footer_section
                        .quickconnect_section.is_enable,
                text:
                  name === "quickconnect_section"
                    ? value
                    : prevJsonData.theme_5.home.footer.footer_section
                        .quickconnect_section.text,
              },
              callnow_for_Services_section: {
                ...prevJsonData.theme_5.home.footer.footer_section
                  .callnow_for_Services_section,
                mbl_no: {
                  ...prevJsonData.theme_5.home.footer.footer_section
                    .callnow_for_Services_section.mbl_no,
                  label:
                    name === "mbl_no.label"
                      ? value
                      : prevJsonData.theme_5.home.footer.footer_section
                          .callnow_for_Services_section.mbl_no.label,
                  value:
                    name === "mbl_no.value"
                      ? value
                      : prevJsonData.theme_5.home.footer.footer_section
                          .callnow_for_Services_section.mbl_no.value,
                },
                email: {
                  ...prevJsonData.theme_5.home.footer.footer_section
                    .callnow_for_Services_section.email,
                  label:
                    name === "email.label"
                      ? value
                      : prevJsonData.theme_5.home.footer.footer_section
                          .callnow_for_Services_section.email.label,
                  value:
                    name === "email.value"
                      ? value
                      : prevJsonData.theme_5.home.footer.footer_section
                          .callnow_for_Services_section.email.value,
                },
                
              },
              social_media: {
                ...prevJsonData.theme_5.home.footer.footer_section.social_media,
                facebook: {
                  is_enable:
                    type === "checkbox" && name === "facebook_check"
                      ? !prevJsonData.theme_5.home.footer.footer_section
                          .social_media.facebook.is_enable
                      : prevJsonData.theme_5.home.footer.footer_section
                          .social_media.facebook.is_enable,
                  facebook_link:
                    name === "facebook"
                      ? value
                      : prevJsonData.theme_5.home.footer.footer_section
                          .social_media.facebook.facebook_link,
                },
                instagram: {
                  is_enable:
                    type === "checkbox" && name === "instagram_check"
                      ? !prevJsonData.theme_5.home.footer.footer_section
                          .social_media.instagram.is_enable
                      : prevJsonData.theme_5.home.footer.footer_section
                          .social_media.instagram.is_enable,
                  instagram_link:
                    name === "instagram"
                      ? value
                      : prevJsonData.theme_5.home.footer.footer_section
                          .social_media.instagram.instagram_link,
                },
                whatsapp: {
                  is_enable:
                    type === "checkbox" && name === "whatsapp_check"
                      ? !prevJsonData.theme_5.home.footer.footer_section
                          .social_media.whatsapp.is_enable
                      : prevJsonData.theme_5.home.footer.footer_section
                          .social_media.whatsapp.is_enable,
                  whatsapp_link:
                    name === "whatsapp"
                      ? value
                      : prevJsonData.theme_5.home.footer.footer_section
                          .social_media.whatsapp.whatsapp_link,
                },
                linkedin: {
                  is_enable:
                    type === "checkbox" && name === "linkedin_check"
                      ? !prevJsonData.theme_5.home.footer.footer_section
                          .social_media.linkedin.is_enable
                      : prevJsonData.theme_5.home.footer.footer_section
                          .social_media.linkedin.is_enable,
                  linkedin_link:
                    name === "linkedin"
                      ? value
                      : prevJsonData.theme_5.home.footer.footer_section
                          .social_media.linkedin.linkedin_link,
                },
                youtube: {
                  is_enable:
                    type === "checkbox" && name === "youtube_check"
                      ? !prevJsonData.theme_5.home.footer.footer_section
                          .social_media.youtube.is_enable
                      : prevJsonData.theme_5.home.footer.footer_section
                          .social_media.youtube.is_enable,
                  youtube_link:
                    name === "youtube"
                      ? value
                      : prevJsonData.theme_5.home.footer.footer_section
                          .social_media.youtube.youtube_link,
                },
                twitter: {
                  is_enable:
                    type === "checkbox" && name === "twitter_check"
                      ? !prevJsonData.theme_5.home.footer.footer_section
                          .social_media.twitter.is_enable
                      : prevJsonData.theme_5.home.footer.footer_section
                          .social_media.twitter.is_enable,
                  twitter_link:
                    name === "twitter"
                      ? value
                      : prevJsonData.theme_5.home.footer.footer_section
                          .social_media.twitter.twitter_link,
                },
              },
              mobile_link_section: {
                  ...prevJsonData.theme_5.home.footer.footer_section.mobile_link_section,
                  [name]: value,
                },
        
            },
          },
        },
      },
    }));
    
  };

  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Footer Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Footer Background</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="bg_color"
                        value={editData?.theme_5?.home?.footer?.bg_color}
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="bg_color"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        format="hex"
                        value={editData?.theme_5?.home?.footer?.bg_color}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Section Header</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="section_heading_color"
                        value={editData?.theme_5?.home?.footer?.section_heading_color}
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="section_heading_color"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        format="hex"
                        value={editData?.theme_5?.home?.footer?.section_heading_color}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className= "mb-3">
                    <label className="form-label">Paragraph color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="section_paragraph_color"
                        value={editData?.theme_5?.home?.footer?.section_paragraph_color}
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="section_paragraph_color"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        format="hex"
                        value={editData?.theme_5?.home?.footer?.section_paragraph_color}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                <div className="mb-3">
                    <label className="form-label">
                      Site Link<small>(max 250 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="site_link"
                      value={editData?.theme_5?.home?.footer?.site_title_en}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Title one<small>(max 250 char)</small></label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="needhelp_section"
                        value={
                          editData?.theme_5?.home?.footer?.footer_section?.needhelp_section
                            ?.text
                        }
                        onChange={handleChange}
                        maxLength={250}
                      />
                      
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="mb-3">
                    <label className="form-label">&nbsp;</label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="needhelp_is_enable"
                        onClick={handleChange}
                        checked={
                          editData?.theme_5?.home?.footer?.footer_section?.needhelp_section
                            ?.is_enable
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Title Two<small>(max 250 char)</small></label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="policy_section"
                        value={
                          editData?.theme_5?.home?.footer?.footer_section?.policy_section
                            ?.text
                        }
                        onChange={handleChange}
                        maxLength={250}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="mb-3">
                    <label className="form-label">&nbsp;</label>
                    <div className="form-check">
                   
                      <input
                          className="form-check-input"
                          type="checkbox"
                          name="policy_is_enable"
                          onClick={handleChange}
                            checked={
                                editData?.theme_5?.home?.footer?.footer_section?.policy_section
                                ?.is_enable
                            }
                        />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Title Three<small>(max 250 char)</small></label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="quickconnect_section"
                        value={
                          editData?.theme_5?.home?.footer?.footer_section?.quickconnect_section
                            ?.text
                        }
                        onChange={handleChange}
                        maxLength={250}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="mb-3">
                    <label className="form-label">&nbsp;</label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="quickconnect_is_enable"
                        onChange={handleChange}
                        checked={
                          editData?.theme_5?.home?.footer?.footer_section?.quickconnect_section
                            ?.is_enable
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
            <div className="row">
                <div className="col">
                <div className="mb-3">
                    <label className="form-label">Mobile</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="mbl_no.label"
                        value={
                          editData?.theme_5?.home?.footer?.footer_section
                            ?.callnow_for_Services_section?.mbl_no?.label
                        }
                        onChange={handleChange}
                      />
                      <input
                        type="text"
                        className="form-control"
                        name="mbl_no.value"
                        value={
                          editData?.theme_5?.home?.footer?.footer_section
                            ?.callnow_for_Services_section?.mbl_no?.value
                        }
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                <div className="mb-3">
                    <label className="form-label">Email</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="email.label"
                        value={
                          editData?.theme_5?.home?.footer?.footer_section
                            ?.callnow_for_Services_section?.email?.label
                        }
                        onChange={handleChange}
                      />
                      <input
                        type="text"
                        className="form-control"
                        name="email.value"
                        value={
                          editData?.theme_5?.home?.footer?.footer_section
                            ?.callnow_for_Services_section?.email?.value
                        }
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
            <label className="form-label">Social Links<small>Ex:- www.facebook.com</small></label>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Facebook</label>
                    <div className="input-group mb-3">
                      <div className="input-group-text">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          name="facebook_check"
                          onClick={handleChange}
                            checked={
                              editData?.theme_5?.home?.footer?.footer_section
                            ?.social_media?.facebook?.is_enable
                            }
                        />
                      </div>
                      <span className="urlplaceholder">
                      <input
                        type="text"
                        className="form-control"
                        name="facebook"
                        onChange={handleChange}
                        value={
                          editData?.theme_5?.home?.footer?.footer_section
                            ?.social_media?.facebook?.facebook_link
                        }
                      />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Instagram</label>
                    <div className="input-group mb-3">
                      <div className="input-group-text">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          name="instagram_check"
                          onClick={handleChange}
                            checked={
                              editData?.theme_5?.home?.footer?.footer_section
                            ?.social_media?.instagram?.is_enable
                            }
                        />
                      </div>
                      <span className="urlplaceholder">
                      <input
                        type="text"
                        className="form-control"
                        name="instagram"
                        onChange={handleChange}
                        value={
                          editData?.theme_5?.home?.footer?.footer_section
                            ?.social_media?.instagram?.instagram_link
                        }
                      />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Whatsapp</label>
                    <div className="input-group mb-3">
                      <div className="input-group-text">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          name="whatsapp_check"
                          onClick={handleChange}
                            checked={
                              editData?.theme_5?.home?.footer?.footer_section
                            ?.social_media?.whatsapp?.is_enable
                            }
                        />
                      </div>
                      <span className="urlplaceholder">
                      <input
                        type="text"
                        className="form-control"
                        name="whatsapp"
                        onChange={handleChange}
                        value={
                          editData?.theme_5?.home?.footer?.footer_section
                            ?.social_media?.whatsapp?.whatsapp_link
                        }
                      />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Linkedin</label>
                    <div className="input-group mb-3">
                      <div className="input-group-text">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          name="linkedin_check"
                          onClick={handleChange}
                            checked={
                              editData?.theme_5?.home?.footer?.footer_section
                            ?.social_media?.linkedin?.is_enable
                            }
                        />
                      </div>
                      <span className="urlplaceholder">
                      <input
                        type="text"
                        className="form-control"
                        name="linkedin"
                        onChange={handleChange}
                        value={
                          editData?.theme_5?.home?.footer?.footer_section
                            ?.social_media?.linkedin?.linkedin_link
                        }
                      />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Youtube</label>
                    <div className="input-group mb-3">
                      <div className="input-group-text">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          name="youtube_check"
                          onClick={handleChange}
                            checked={
                              editData?.theme_5?.home?.footer?.footer_section
                            ?.social_media?.youtube?.is_enable
                            }
                        />
                      </div>
                      <span className="urlplaceholder">
                      <input
                        type="text"
                        className="form-control"
                        name="youtube"
                        onChange={handleChange}
                        value={
                          editData?.theme_5?.home?.footer?.footer_section
                            ?.social_media?.youtube?.youtube_link
                        }
                      />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Twitter</label>
                    <div className="input-group mb-3">
                      <div className="input-group-text">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          name="twitter_check"
                          onClick={handleChange}
                            checked={
                              editData?.theme_5?.home?.footer?.footer_section
                            ?.social_media?.twitter?.is_enable
                            }
                        />
                      </div>
                      <span className="urlplaceholder">
                      <input
                        type="text"
                        className="form-control"
                        name="twitter"
                        onChange={handleChange}
                        value={
                          editData?.theme_5?.home?.footer?.footer_section
                            ?.social_media?.twitter?.twitter_link
                        }
                      />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <h4>Mobile Link Section</h4>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Android Link</label>
                    <input
                      type="text"
                      className="form-control"
                      name="android_link"
                      value={
                        editData?.theme_5?.home?.footer?.footer_section?.mobile_link_section
                          ?.android_link
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Ios Link</label>
                    <input
                      type="text"
                      className="form-control"
                      name="ios_link"
                      value={
                        editData?.theme_5?.home?.footer?.footer_section?.mobile_link_section?.ios_link
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterFiveEdit;
