import React from "react";
import {
  tick,
} from "../../assets/img";
import { useSelector } from "react-redux";
// import { data } from "../edit/data";

function SectionThreeThemeTwo() {
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  // const jsonData = data
  return (
    <>
      <section className="highlight-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="highlight-row">
                <div className="row">
                  <div className="col-md-6">
                    <div className="highlight-box highlight-bg1">
                      <div className="highlight-img">
                        <img
                          src={
                            jsonData.theme_2?.home?.section_3?.cards[0]?.bg_img
                          }
                          alt=""
                        />
                        <div className="highlight-overlay">
                          <h3>
                            {" "}
                            {
                              jsonData.theme_2?.home?.section_3?.cards[0]?.card_title
                            }{" "}
                          </h3>
                          <p>
                            {
                              jsonData.theme_2?.home?.section_3?.cards[0]?.card_paragraph
                            }
                          </p>
                          <ul>
                            {jsonData.theme_2?.home?.section_3?.cards[0]?.services.map(
                              (services: any) => {
                                return (
                                  <>
                                    <li className="ml-1">
                                      <img src={tick} alt="" />
                                      {services}
                                    </li>
                                  </>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="highlight-content">
                        <h4>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[0]?.card_main_title
                          }
                        </h4>
                        <p>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[0]?.card_main_paragraph
                          }
                        </p>
                        <ul>
                          {jsonData.theme_2?.home?.section_3?.cards[0]?.check_list.map(
                            (checkList: any) => {
                              return (
                                <>
                                  <li>
                                    <img src={tick} alt="" />
                                    {checkList}
                                  </li>
                                </>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="highlight-box highlight-bg2">
                      <div className="highlight-img">
                        <img src={
                            jsonData.theme_2?.home?.section_3?.cards[1]?.bg_img
                          } alt="" />
                        <div className="highlight-overlay">
                          <h3>
                            {" "}
                            {
                              jsonData.theme_2?.home?.section_3?.cards[1]?.card_title
                            }{" "}
                          </h3>
                          <p>
                            {
                              jsonData.theme_2?.home?.section_3?.cards[1]?.card_paragraph
                            }
                          </p>
                          <ul>
                            {jsonData.theme_2?.home?.section_3?.cards[1]?.services.map(
                              (services: any) => {
                                return (
                                  <>
                                    <li className="ml-1">
                                      <img src={tick} alt="" />
                                      {services}
                                    </li>
                                  </>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="highlight-content">
                        <h4>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[1]?.card_main_title
                          }
                        </h4>
                        <p>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[1]?.card_main_paragraph
                          }
                        </p>
                        <ul>
                          {jsonData.theme_2?.home?.section_3?.cards[1]?.check_list?.map(
                            (checkList: any) => {
                              return (
                                <>
                                  <li>
                                    <img src={tick} alt="" />
                                    {checkList}
                                  </li>
                                </>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="highlight-row">
                <div className="row">
                  <div className="col-md-6">
                    <div className="highlight-box highlight-bg2">
                      <div className="highlight-img">
                        <img src={
                            jsonData.theme_2?.home?.section_3?.cards[2]?.bg_img
                          } alt="" />
                        <div className="highlight-overlay">
                          <h3>
                            {" "}
                            {
                              jsonData.theme_2?.home?.section_3?.cards[2]?.card_title
                            }{" "}
                          </h3>
                          <p>
                            {
                              jsonData.theme_2?.home?.section_3?.cards[2]?.card_paragraph
                            }
                          </p>
                          <ul>
                            {jsonData.theme_2?.home?.section_3?.cards[2]?.services.map(
                              (services: any) => {
                                return (
                                  <>
                                    <li className="ml-1">
                                      <img src={tick} alt="" />
                                      {services}
                                    </li>
                                  </>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="highlight-content">
                        <h4>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[2]?.card_main_title
                          }
                        </h4>
                        <p>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[2]?.card_main_paragraph
                          }
                        </p>
                        <ul>
                          {jsonData.theme_2?.home?.section_3?.cards[2]?.check_list?.map(
                            (checkList: any) => {
                              return (
                                <>
                                  <li>
                                    <img src={tick} alt="" />
                                    {checkList}
                                  </li>
                                </>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="highlight-box highlight-bg1">
                      <div className="highlight-img">
                        <img src={
                            jsonData.theme_2?.home?.section_3?.cards[3]?.bg_img
                          } alt="" />
                        <div className="highlight-overlay">
                          <h3>
                            {" "}
                            {
                              jsonData.theme_2?.home?.section_3?.cards[3]?.card_title
                            }{" "}
                          </h3>
                          <p>
                            {
                              jsonData.theme_2?.home?.section_3?.cards[3]?.card_paragraph
                            }
                          </p>
                          <ul>
                            {jsonData.theme_2?.home?.section_3?.cards[3]?.services.map(
                              (services: any) => {
                                return (
                                  <>
                                    <li className="ml-1">
                                      <img src={tick} alt="" />
                                      {services}
                                    </li>
                                  </>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="highlight-content">
                        <h4>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[3]?.card_main_title
                          }
                        </h4>
                        <p>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[3]?.card_main_paragraph
                          }
                        </p>
                        <ul>
                          {jsonData.theme_2?.home?.section_3?.cards[3]?.check_list?.map(
                            (checkList: any) => {
                              return (
                                <>
                                  <li>
                                    <img src={tick} alt="" />
                                    {checkList}
                                  </li>
                                </>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionThreeThemeTwo;
