import axios from "../../config";
export const SET_MYORDER_LIST = "SET_MYORDER_LIST";
export const CLEAR_MYORDER_LIST = "CLEAR_MYORDER_LIST";

export const setMyOrderList = (payload: any) => ({
  type: SET_MYORDER_LIST,
  payload,
});
export const fetchMyOrders =
  (limit: number, page: number, sort: number, order_status: string) =>
  async (dispatch: any) => {
    const base_url = process.env.REACT_APP_BACKEND_URL;

    try {
      const bodyFormData = new FormData();
      const token = localStorage.getItem("token");
      let shopId = localStorage.getItem("shop_id");

      const URL = window.location.href.split("/");
      if (URL.length > 4) {
        shopId = localStorage.getItem("currentShopHome");
      }

      const response = await axios({
        method: "get",
        url: `${base_url}/api/fetch_my_orders?limit=${limit}&shop_id=${shopId}&page=${page}&sort=${sort}&order_status=${order_status}`,
        data: bodyFormData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
          "Access-Control-Allow-Methods": "GET, POST",
        },
      });
      if (response) {
        if (response.data.Response.response_code === "-1") {
          return;
        }
        dispatch(setMyOrderList(response.data));
      }
    } catch (error) {}
  };
