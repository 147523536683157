import axios from "../../config";
import { getValue, removeDuplicates } from "../../utility.js";
import { setCartCount } from "./cartCountAction";
export const SET_ALL_CATEGORIES = "SET_ALL_CATEGORIES";
export const CLEAR_ALL_CATEGORIES = "CLEAR_ALL_CATEGORIES";
export const USER_SELECT_CATEGORIES = "USER_SELECT_CATEGORIES";

export const setProductAllCategories = (payload: any) => ({
  type: SET_ALL_CATEGORIES,
  payload,
});

export const setUserProductCategories = (payload: any) => ({
  type: USER_SELECT_CATEGORIES,
  payload,
});

export const clearUserProductCategories = () => ({
  type: CLEAR_ALL_CATEGORIES,
});

export const fetchProductAllCategories = (ecomvar:any) => async (dispatch: any) => {
  const postalCode = JSON.parse(localStorage.getItem("postalCode") || "");
  const base_url = process.env.REACT_APP_BACKEND_URL;
  try {
    const bodyFormData = new FormData();
    const currentShopId = localStorage.getItem("currentShopHome");
    const URL = window.location.href.split("/"); 
    const shopName: any = localStorage.getItem("currentShopName");
    
    let api_url = `${base_url}/api/get_all_categories?group_id=1&pincode=${postalCode}&ecom=${ecomvar}`;
    if (URL.length > 4) {
      api_url = `${base_url}/api/get_all_categories?group_id=1&shop_id=${currentShopId}&pincode=${postalCode}&ecom=${ecomvar}`;
    }
    // bodyFormData.append('keyword', value);
    // res.header('Access-Control-Allow-Methods', 'GET, POST');
    const response = await axios({
      method: "get",
      url: api_url,
      data: bodyFormData,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Methods": "GET, POST",
      },
    });
    // const response = await axios.post(`${base_url}/api/get_postal_codes`,{keyword:"Gu11"});
    // const result = await response.then(response => response);

    if (response) {
      // setPostalCodeList(response.data.data[0])
      let cartInformationData = getValue("cartInformationData", []);
      if (cartInformationData.length && response.data) {
        const cartCount = cartInformationData
          .map((item: any) =>
            item.sub_categories.reduce(
              (a: number, b: any) => a + Number(b.quantity),
              0
            )
          )
          .reduce((a: number, b: number) => a + b, 0);
        dispatch(setCartCount(cartCount));
        cartInformationData = [
          ...cartInformationData,
          ...response.data.data.category,
        ];
        response.data.data.category = removeDuplicates(
          cartInformationData,
          "main_category_id"
        );
        dispatch(setProductAllCategories(response.data));
      } else {
        dispatch(setProductAllCategories(response.data));
      }
      return response.data;
    }
  } catch (error) {}
};
