import React, { useEffect, useState } from "react";
import {
  testimonialimg1,
  testimonialimg2,
  testimonialimg3,
  titleborder,
} from "../../../assets/img";

import axios from "axios";
import { useSelector } from "react-redux";

function SectionEightThemeTwo() {
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);

  const [blogList, setBlogList] = useState<any | null>({});
  useEffect(() => {
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    axios
      .get(`${base_url}/api/blog_list?`, {
        headers: headers,
      })
      .then((e) => {
        setBlogList(e.data.data);
      });
  }, []);

  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;

  return (
    <>
      <section className="sh2-latestnews-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sh2-section-title">
                <p>{jsonData?.theme_2?.home?.section_8?.title_1}</p>
                <h2>{jsonData?.theme_2?.home?.section_8?.title_2}</h2>
                <img src={titleborder} alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            {blogList?.length > 0 && (
              <div className="col-md-4">
                <div className="sh2-latestnews-card">
                  <div className="sh2-latestnews-date">
                    {blogList[0].formatted_date} {blogList[0].formatted_month}
                  </div>
                  <div className="sh2-latestnews-info-grid">
                    <div className="sh2-latestnews-img">
                      <img src={testimonialimg1} alt="" />
                      <div className="sh2-latestnews-overlay">Trending</div>
                    </div>
                    <div className="sh2-latestnews-info">
                      <a
                        target="_blank"
                        href={`${blogUrl}/blogg/${blogList[0].id}/${blogList[0].url_slug}`} rel="noreferrer"
                      >
                        <h6>
                          {blogList[0].page_title.substring(0, 55)}
                          {blogList[0].page_title.length > 55 ? "..." : ""}
                        </h6>
                      </a>
                      <p>
                        {blogList[0].preview_text.substring(0, 130)}
                        {blogList[0].preview_text.length > 130 ? "..." : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {blogList?.length > 1 && (
              <div className="col-md-4">
                <div className="sh2-latestnews-card">
                  <div className="sh2-latestnews-date">
                    {blogList[1].formatted_date} {blogList[1].formatted_month}
                  </div>
                  <div className="sh2-latestnews-info-grid">
                    <div className="sh2-latestnews-img">
                      <img src={testimonialimg2} alt="" />
                      <div className="sh2-latestnews-overlay">Trending</div>
                    </div>
                    <div className="sh2-latestnews-info">
                      <a
                        target="_blank"
                        href={`${blogUrl}/blogg/${blogList[1].id}/${blogList[1].url_slug}`} rel="noreferrer"
                      >
                        <h6>
                          {blogList[1].page_title.substring(0, 55)}
                          {blogList[1].page_title.length > 55 ? "..." : ""}
                        </h6>
                      </a>
                      <p>
                        {blogList[1].preview_text.substring(0, 130)}
                        {blogList[1].preview_text.length > 130 ? "..." : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {blogList?.length > 2 && (
              <div className="col-md-4">
                <div className="sh2-latestnews-card">
                  <div className="sh2-latestnews-date">
                    {blogList[2].formatted_date} {blogList[2].formatted_month}
                  </div>
                  <div className="sh2-latestnews-info-grid">
                    <div className="sh2-latestnews-img">
                      <img src={testimonialimg3} alt="" />
                      <div className="sh2-latestnews-overlay">Trending</div>
                    </div>
                    <div className="sh2-latestnews-info">
                      <a
                        target="_blank"
                        href={`${blogUrl}/blogg/${blogList[2].id}/${blogList[2].url_slug}`} rel="noreferrer"
                      >
                        <h6>
                          {blogList[2].page_title.substring(0, 55)}
                          {blogList[2].page_title.length > 55 ? "..." : ""}
                        </h6>
                      </a>
                      <p>
                        {blogList[2].preview_text.substring(0, 130)}
                        {blogList[2].preview_text.length > 130 ? "..." : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="sh2-loademore-card">
                <a target="_blank" href={blogUrl} className="btn gr-btn" rel="noreferrer">
                  Load More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionEightThemeTwo;
