import React from "react";
import { award } from "../../assets/img";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

function SectionThreeThemeFour() {
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const navigate = useNavigate();
  return (
    <>
      <section
        className="review-section"
        style={{
          background: `url(${jsonData?.theme_4?.home?.section_3?.banner}) no-repeat`,
          backgroundSize: "cover"
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="review-info">
                <div className="review-star">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  {/* <p>
                    <a
                      className="text-white"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("reviews");
                      }}
                    >
                      Read more Reviews
                    </a>
                  </p> */}
                </div>
                <h2>{jsonData?.theme_4?.home?.section_3?.title}</h2>
              </div>
            </div>
            <div className="col-md-6 text-end">
              <img src={award} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionThreeThemeFour;
