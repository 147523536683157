import React, { useEffect, useState } from "react";
import { ColorPicker } from "primereact/colorpicker";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCroppedImage,
  setShopHomeCms,
  saveThemeJsonData,
} from "../../../../redux/Actions";
import ImageCropper from "../../../imageCropper/imageCropper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

const SectionEightThemeFiveEdit = () => {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [imagedata, setImagedata] = useState<any>("" || undefined);
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [showCropModalTwo, setShowCropModalTwo] = useState<boolean>(false);
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  const images: Array<string> = [
    "assets/home/iPhone13.png",
    "assets/home/iPhone13.png",
    "assets/home/iPhone13.png",
    "assets/home/iPhone13.png",
    "assets/home/iPhone13.png",
  ];
  const banner: Array<string> = [
    "assets/img/home/heroimg.png",
    "assets/img/home/heroimg.png",
    "assets/img/home/heroimg.png",
  ];
  const saveJsonDataToFile = () => {
    dispatch(setShopHomeCms(editData));
    dispatch(saveThemeJsonData(editData));
  };

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);

  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);

  const handleChange = (e: any) => {
    const { value, name, type, checked } = e.target;

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_5: {
        ...prevJsonData?.theme_5,
        home: {
          ...prevJsonData?.theme_5.home,
          section_8: {
            ...prevJsonData?.theme_5?.home.section_8,
            [name]: value,
            title:
              name === "section8.title"
                ? value
                : prevJsonData.theme_5.home.section_8.title,
            mobile_link_section: {
              ...prevJsonData.theme_5.home.section_8.mobile_link_section,
              [name]: value,
            },
          },
        },
      },
    }));
  };
  const handleImageChange = (image: string) => {
    //Update the banner value in the JSON data
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_5: {
        ...prevJsonData.theme_5,
        home: {
          ...prevJsonData.theme_5.home,
          section_8: {
            ...prevJsonData.theme_5.home.section_8,
            banner: image,
          },
        },
      },
    }));
  };
  const handleImageChangeTwo = (image: string) => {
    //Update the banner value in the JSON data
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_5: {
        ...prevJsonData.theme_5,
        home: {
          ...prevJsonData.theme_5.home,
          section_8: {
            ...prevJsonData.theme_5.home.section_8,
            banner_two: image,
          },
        },
      },
    }));
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150");
    // setCropImageWidth("300");
    // setSelectedCropImage(image);
    // setShowCropModal(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        handleCroppedImage(res.target.result);
      };
    }

  };

  const updateImageSectionTwo = async (e: any) => {
    const image = e.target.files[0];
    setCropImageHeight("300");
    setCropImageWidth("500");
    setSelectedCropImage(image);
    setShowCropModalTwo(true);
  };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleImageChange(imageLink);
    }
  };
  const handleCroppedImageTwo = async (image: any) => {
    setShowCropModalTwo(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleImageChangeTwo(imageLink);
    }
  };

  // HTML
  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title"> Section8 Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="mb-3">
              <label className="form-label">Background</label>
              <div className="input-group color-change">
                <input
                  type="text"
                  className="form-control"
                  name="bg_color"
                  value={editData?.theme_5?.home?.section_8?.bg_color}
                  maxLength={250}
                />
                <ColorPicker
                  name="bg_color"
                  format="hex"
                  onChange={handleChange}
                  value={editData?.theme_5?.home?.section_8?.bg_color}
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">
                Title<small>(max 250 char)</small>
              </label>
              <input
                type="text"
                className="form-control"
                name="section8.title"
                value={editData?.theme_5?.home?.section_8?.title}
                onChange={handleChange}
                maxLength={250}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Description</label>
              <input
                type="text"
                className="form-control"
                name="paragraph"
                value={editData?.theme_5?.home?.section_8?.paragraph}
                onChange={handleChange}
                maxLength={1200}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">
                Placeholder <small>(max 50 char)</small>
              </label>
              <input
                type="text"
                className="form-control"
                name="placeholder"
                value={editData?.theme_5?.home?.section_8?.placeholder}
                onChange={handleChange}
                maxLength={50}
              />
            </div>
            <div className="mb-3">
              <div className="row">
                <div className="col-md-12">
                  <label className="form-label">Background Banner</label>
                  <div className="img-bg active">
                    <img
                      src={editData?.theme_5?.home?.section_8?.banner}
                      alt="Hero Image"
                    />
                  </div>
                  <div className="img-options">
                    <div className="row">
                      <div className="col-md-12">
                        <Swiper
                          spaceBetween={15}
                          navigation={true}
                          modules={[Navigation]}
                          breakpoints={{
                            640: {
                              slidesPerView: 1,
                            },
                            768: {
                              slidesPerView: 4,
                            },
                            1024: {
                              slidesPerView: 6,
                            },
                          }}
                          className="homejson-slider"
                        >
                          {images.map((image: string) => {
                            return (
                              <>
                                <SwiperSlide>
                                  <div
                                    className="img-option"
                                    onClick={() => handleImageChange(image)}
                                  >
                                    <img
                                      src={image}
                                      alt="Hero Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </SwiperSlide>
                              </>
                            );
                          })}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Upload Image</label>
              <input
                type="file"
                accept="image/png, image/jpeg"
                className="form-control"
                name="image"
                onChange={updateImageSectionOne}
              />
            </div>

            <div className="mb-3">
              <div className="row">
                <div className="col">
                  <h3>Mobile Link Section</h3>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Android Link</label>
                    <input
                      type="text"
                      className="form-control"
                      name="android_link"
                      value={
                        editData?.theme_5?.home?.section_8?.mobile_link_section
                          ?.android_link
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Ios Link</label>
                    <input
                      type="text"
                      className="form-control"
                      name="ios_link"
                      value={
                        editData?.theme_5?.home?.section_8?.mobile_link_section
                          ?.ios_link
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
      {showCropModalTwo ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImageTwo}
              close={() => {
                setShowCropModalTwo(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default SectionEightThemeFiveEdit;
