import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {slider1,slider2,slider3,slider4,slider5,slider6,slider7,slider8,upimg6} from "../../assets/img";

function CarpetCleaning () {

return (
    <>
        <div className="container">
            <div className="row">
                <div className="col-md-12">                    
                    <div className="slider-up">
                    <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        className="slider-up-slider"
                        >     
                            <SwiperSlide >                            
                                <img src={slider7} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>                            
                            <SwiperSlide >                            
                                <img src={slider8} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider1} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider2} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide> 
                            <SwiperSlide >                            
                                <img src={slider3} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider4} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider5} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider6} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                      </Swiper>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">   
                    <div className="up-content">
                        <h2 className="up-h2">CARPET CLEANING</h2>
                        <p>We offer professional carpet and upholstery cleaning, which includes:</p>
                        <ul className="up-list up-list-white">
                            <li><p>Professional Carpet Cleaning</p></li>
                            <li><p>Upholstery</p></li>
                            <li><p>Area Rugs</p></li>
                            <li><p>Car Upholstery</p></li>
                            <li><p>Leather Sofa Cleaning</p></li>
                            <li><p>Mattress Cleaning</p></li>
                        </ul>
                        <p>Contact us to arrange an appointment on 01792 644238</p>         
                        <p>You to can have brillantly clean and fresh smelling carpets & upholstery.</p>              
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="up-img">
                        <img src={upimg6} alt="" />
                    </div>
                    <div className="up-card-bg">
                        <p>Book a Pickup - Call 01792 644238 or<br/> text 07999 999294</p>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}
export default CarpetCleaning;