import axios from 'axios';
export const SET_ADD_TO_CART = "SET_ADD_TO_CART";
export const CLEAR_ADD_TO_CART = "CLEAR_ADD_TO_CART";

export const setAddToCart = (payload: any) => ({
    type: SET_ADD_TO_CART,
    payload
})

export const fetchAddToCart = (
   payload:any
) => async (dispatch: any) => {
    localStorage.setItem("payload",JSON.stringify(payload))
    const base_url = process.env.REACT_APP_BACKEND_URL;
   
    try {
        const response = await axios({
            method: "post",
            url: `${base_url}/api/add_to_cart`,
            data: payload,
            headers: {
                 "Accept": "application/json", 
                 'Access-Control-Allow-Methods': 'GET, POST' },
        })
        if (response) {  
            dispatch(setAddToCart(response.data))
        }
    } catch (error) {
        
    }
   
};