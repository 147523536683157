import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {ns1,ns2,ns3} from "../../assets/img";

function NailService () {

return (
    <>
        <section className="rbpsec">
            <div className="container">                
                <div className="row justify-content-center">                    
                    <div className="col-md-4">
                        <div className="rbpimg">
                            <img src={ns1} alt="nail Service" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="rbpimg">
                            <img src={ns2} alt="nail Service" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="rbpimg">
                            <img src={ns3} alt="nail Service" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
)
}
export default NailService;