export const data = {
  home: {
    sections: [
      {
        _id: 1,
        is_section_enable: true,
        icon: "/assets/section/section1.jpg"
      },
      {
        _id: 2,
        is_section_enable: true,
        icon: "/assets/section/section2.jpg"
      },
      {
        _id: 3,
        is_section_enable: true,
        icon: "/assets/section/section3.jpg"
      },
      {
        _id: 4,
        is_section_enable: true,
        icon: "/assets/section/section4.jpg"
      },
      {
        _id: 5,
        is_section_enable: true,
        icon: "/assets/section/section5.jpg"
      },
      {
        _id: 6,
        is_section_enable: true,
        icon: "/assets/section/section6.jpg"
      },
      {
        _id: 7,
        is_section_enable: true,
        icon: "/assets/section/section7.jpg"
      },
      {
        _id: 8,
        is_section_enable: true,
        icon: "/assets/section/section8.jpg"
      },
      {
        _id: 9,
        is_section_enable: true,
        icon: "/assets/section/section9.jpg"
      },
      {
        _id: 10,
        is_section_enable: true,
        icon: "/assets/section/section10.jpg"
      },
      {
        _id: 11,
        is_section_enable: true,
        icon: "/assets/section/section11.jpg"
      },
      {
        _id: 12,
        is_section_enable: true,
        icon: "/assets/section/section12.jpg"
      },
      {
        _id: 13,
        is_section_enable: true,
        icon: "/assets/section/section13.jpg"
      },
      {
        _id: 15,
        is_section_enable: true,
        icon: "/assets/section/section15.jpg"
      }
    ],
    header: {
      is_section_enable: true,
      primary_color: "1B9DB3",
      secondary_color: "F07F1B",
      nav: {
        bg_color: "ffffff",
        menu_font_color: "2e3639",
        menu_font_color_active: "1b9db3",
        menu_font_color_hover: "f07f1b",
        cart_section: {
          is_enable: true,
          bg_color: "ffffff",
          cart_icon_color: "ffffff",
          cart_icon_bg_color: "#f07f1b",
          cart_label_section: {
            label: "items : ",
            color: "#6b7385"
          },
          cart_value_color: "000000"
        }
      },
      btn_group: {
        btn1: {
          text: "Login / Register",
          bg_color: "ffffff",
          color: "#1b9db3",
          border_color: "#1b9db3",
          btn1_is_enable: true
        },
        btn2: {
          text: "Quick Order",
          color: "ffffff",
          bg_color: "000",
          border_color: "#192335",
          btn2_is_enable: true
        }
      }
    },
    section_1: {
      is_section_enable: true,
      banner: "/assets/home_page_single_search.jpg",
      sub_heading_top: "24/7 Dry Cleaning & Laundry Service",
      title: "One Solution for all your Washing Needs",
      paragraph: "Bestatlaundry is synonymous with quality, reliability, and trust. We provide fast dry cleaning and laundry services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers.",
      btn_group: {
        btn1: {
          text: "Book now",
          bg_color: "#1b9db3",
          color: "ffffff",
          placeholder: "Enter Postalcode"
        }
      },
      mobile_link_section: {
        is_enable: true,
        mob_title: "Just A Click Away",
        android_link: "#",
        ios_link: "#"
      }
    },
    section_2: {
      is_section_enable: true,
      sub_heading_top: "Book A Service",
      title: "Select Your Time & Location For The Service",
      btn_group: {
        btn1: {
          text: "order now",
          bg_color: "#1b9db3",
          color: "ffffff",
          placeholder: "Enter Postcode"
        }
      }
    },
    section_3: {
      is_section_enable: true,
      title: "Trust the Experts",
      paragraph: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
      card_section: {
        collection: [
          {
            _id: 1,
            icon: "/assets/icons/priorityicon1.png",
            collection_title: "Top Quality",
            collection_paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing"
          },
          {
            _id: 2,
            icon: "/assets/icons/priorityicon2.png",
            collection_title: "Best Service",
            collection_paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing"
          },
          {
            _id: 3,
            icon: "/assets/icons/priorityicon3.png",
            collection_title: "Value",
            collection_paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing"
          },
          {
            _id: 4,
            icon: "/assets/icons/priorityicon4.png",
            collection_title: "Professionalism",
            collection_paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing"
          },
          {
            _id: 5,
            icon: "/assets/icons/priorityicon5.png",
            collection_title: "Convenience",
            collection_paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing"
          },
          {
            _id: 6,
            icon: "/assets/icons/priorityicon6.png",
            collection_title: "Cleanliness",
            collection_paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing"
          }
        ],
        btn_group: {
          btn1: {
            text: "More About Us",
            bg_color: "192335",
            color: "ffffff"
          }
        }
      }
    },
    section_4: {
      is_section_enable: true,
      title: "Bestatlaundry is Ranked #1 by customers in the UK in categories of dry cleaning"
    },
    section_5: {
      is_section_enable: true,
      banner: [
        {
          banner: "/assets/home/howwe1.jpg"
        }
      ],
      title: "We collect, clean, & deliver your laundry & dry cleaning.",
      paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took",
      check_list: [
        {
          _id: 1,
          title: "Free same day collection"
        },
        {
          _id: 1,
          title: "24h turnaround time"
        },
        {
          _id: 1,
          title: "Dedicated 24/7 support"
        },
        {
          _id: 1,
          title: "Standard wash for just Â£14"
        },
        {
          _id: 1,
          title: "Wash & Fold for items that don't need pressing"
        },
        {
          _id: 1,
          title: "Free collection and delivery"
        }
      ],
      btn_group: {
        btn1: {
          text: "Schedule a Collection Now",
          bg_color: "#192335",
          color: "ffffff",
          is_enable: true
        },
        btn2: {
          text: "Request a Pickup",
          bg_color: "#1b9db3",
          color: "ffffff",
          is_enable: true
        }
      }
    },
    section_6: {
      is_section_enable: true,
      sub_heading_top: "Know More On",
      title: "How We Works",
      paragraph: "We collect, clean and deliver your clothes within 24-48 hrs.",
      card_section: {
        collection: [
          {
            _id: 1,
            icon: "/assets/home/howwe1.jpg",
            card_title: "Online Order",
            card_paragraph: "Use our friendly and easy access website or app to place your laundry order. choose a suitable pickup location and time."
          },
          {
            _id: 2,
            icon: "/assets/home/howwe2.jpg",
            card_title: "Transport",
            card_paragraph: "Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities."
          },
          {
            _id: 3,
            icon: "/assets/home/howwe3.jpg",
            card_title: "Dry Cleaning",
            card_paragraph: "Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities."
          },
          {
            _id: 4,
            icon: "/assets/home/howwe4.jpg",
            card_title: "Door Delivery",
            card_paragraph: "Dry and clean clothes were carefully bagged and delivered to your doorstep."
          }
        ],
        btn_group: {
          paragraph: "Lorem Ipsum is simply dummy text of the",
          btn1: {
            text: "Book Online Now",
            bg_color: "#192335",
            color: "#ffffff"
          }
        }
      }
    },
    section_7: {
      is_section_enable: true,
      title: "On Demand Laundry, Dry Cleaning And Ironing Categories",
      paragraph: "We provide commercial laundry, shoe repair, clothing alteration, tailoring and wedding dress fitting services in London, Essex, Kent, Westminster, Chelsea, Dartford, Croydon, Enfield, Basildon, South On Sea, Bromley, Mayfair, Romford and other areas in London.",
      btn_group: {
        btn_paragraph: "Lorem Ipsum is simply dummy text of the",
        btn1: {
          text: " Request a Pickup",
          bg_color: "192335",
          color: "fff"
        }
      }
    },
    section_8: {
      is_section_enable: true,
      banner: "howwe1",
      title: "About Us",
      section_image: "/assets/home/trustimg2.jpg",
      paragraph: "Bestatlaundry is synonymous with quality, reliability, and trust. We provide fast dry cleaning and laundry services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers.",
      card_section: {
        collection: [
          {
            _id: 1,
            icon: "James please use React import aliase",
            collection_title: "Free Pick up and Delivery",
            collection_image: "/assets/icons/priorityicon7.png",
            isEnabled: true,
            collection_paragraph: "We offer a convenient way to have laundry picked up and delivered for free."
          },
          {
            _id: 2,
            icon: "James please use React import aliase",
            collection_title: "High Quality of Services",
            collection_image: "/assets/icons/priorityicon8.png",
            isEnabled: true,
            collection_paragraph: "Our laundry team perfectly cleans, iron, fold, pack, and deliver to you on time."
          },
          {
            _id: 3,
            icon: "James please use React import aliase",
            collection_title: "Affordable Cost",
            collection_image: "/assets/icons/priorityicon9.png",
            isEnabled: true,
            collection_paragraph: "We offer affordable prices when compared to the current market."
          }
        ]
      },
      btn_group: {
        btn1_text: "Schedule a Collection Now",
        btn1_bg_color: "192335",
        btn1_color: "ffffff",
        btn2_text: "Request a Pickup",
        btn2_bg_color: "1b9db3",
        btn2_color: "ffffff"
      }
    },
    section_9: {
      is_section_enable: true,
      section_bg_color: "#1b9db3",
      sub_heading_top: "Our Promising Services",
      title: "On Demand Laundry, Dry Cleaning And Ironing Service",
      paragraph: "We collect, clean and deliver your clothes within 24-48 hrs.",
      card_section: {
        collection: [
          {
            _id: 1,
            icon: "/assets/home/priorityimg1.jpg",
            collection_title: "Laundry",
            collection_paragraph: "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics."
          },
          {
            _id: 2,
            icon: "/assets/home/priorityimg2.jpg",
            collection_title: "Dry Cleaning",
            collection_paragraph: "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics."
          },
          {
            _id: 3,
            icon: "/assets/home/priorityimg3.jpg",
            collection_title: "Alteration & Repair",
            collection_paragraph: "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics."
          },
          {
            _id: 14,
            icon: "/assets/home/priorityimg1.jpg",
            collection_title: "Laundry",
            collection_paragraph: "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics."
          }
        ],
        count_section: [
          {
            _id: 1,
            icon: "/assets/icons/priorityicon1.png",
            text: "Laundry Clothes",
            count: "1639+"
          },
          {
            _id: 2,
            icon: "/assets/icons/priorityicon2.png",
            text: "Steam Ironing",
            count: "24359+"
          },
          {
            _id: 3,
            icon: "/assets/icons/priorityicon3.png",
            text: "Dry Cleaning",
            count: "4793+"
          },
          {
            _id: 4,
            icon: "/assets/icons/priorityicon4.png",
            text: "Cleaning programs",
            count: "25+"
          }
        ]
      }
    },
    section_10: {
      is_section_enable: true,
      banner: "/assets/home/trustimg3.png",
      sub_heading_top: "Your Health & Safety Is Our Priority",
      title: "Our Values",
      mainparagraph: "online laundry & dry cleaning companies who simply just own the technology and farm the cleaning to other providers with little to no process system checks.",
      list_Section: [
        {
          _id: 1,
          text: "Contactless deliveries",
          paragraph: "For our customers safety, we enabled the feature to allow customers to conduct contactless pick up & deliveries."
        },
        {
          _id: 2,
          text: "Instant updates",
          paragraph: "Weâ€™ll keep you posted throughout the process with texts/emails when driver is on their way and order has been cleaned."
        },
        {
          _id: 3,
          text: "Monitoring the situation",
          paragraph: "Weâ€™re keeping up to date with all developments from government to ensure the safety of our customers and staff."
        }
      ],
      card_section_title: "We use Eco-friendly Laundry Detergent Sheets.",
      card_section: [
        {
          _id: 1,
          card_bg_color: "192335",
          card_text: "Vegan",
          card_paragraph: "Our vegan-friendly laundry detergent is free from any animal product."
        },
        {
          _id: 2,
          card_bg_color: "f07f1b",
          card_text: "Cruelty Free",
          card_paragraph: "We never test on animals â€“ so our Laundry Leaves will always be cruelty-free."
        },
        {
          _id: 3,
          card_bg_color: "1bb333",
          card_text: "Eco-Friendly",
          card_paragraph: "Free from any harmful chemicals or pollutants and are devoid of harmful toxins."
        }
      ]
    },
    section_11: {
      is_section_enable: true,
      banner: [
        {
          banner: "/assets/home/howwe1.jpg"
        }
      ],
      card_section: {
        collection: [
          {
            _id: 1,
            collection_title: "Free Pick up and Delivery",
            isEnabled: true,
            collection_paragraph: "We offer a convenient way to have laundry picked up and delivered for free."
          },
          {
            _id: 2,
            collection_title: "High Quality of Services",
            isEnabled: true,
            collection_paragraph: "Our laundry team perfectly cleans, iron, fold, pack, and deliver to you on time."
          },
          {
            _id: 3,
            collection_title: "Affordable Cost",
            isEnabled: true,
            collection_paragraph: "We offer affordable prices when compared to the current market."
          }
        ]
      },
      sub_heading_top: "Ask Your Question",
      title: "Frequently Asked Questions",
      paragraph: "online laundry & dry cleaning companies who simply just own the technology and farm the cleaning to other providers with little to no process system checks."
    },
    section_12: {
      is_section_enable: true,
      banner: "howwe1",
      sub_heading_top: "What Our Client Says",
      title: "A Trusted Laundry And Dry Cleaning Company",
      paragraph: "Read what our customers say about us",
      review_bottom_paragraph: "More than 5,000+ reviews s on TrustPilot, Google, Google Play & App Store",
      review_card: [
        {
          _id: 1,
          reviewer_pic: "/assets/home/testimonialuser1.jpg",
          reviewer_name: "Ariyan Rusov",
          rating: "5",
          review_highlight: "Finibus mauris. Aliquam consectetur",
          review: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me."
        },
        {
          _id: 2,
          reviewer_pic: "/assets/home/testimonialuser2.jpg",
          reviewer_name: "Darren Valdez",
          rating: "4",
          review_highlight: "Finibus mauris. Aliquam consectetur",
          review: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me."
        },
        {
          _id: 3,
          reviewer_pic: "/assets/home/testimonialuser3.jpg",
          reviewer_name: "Elinor Dunn",
          rating: "5",
          review_highlight: "Finibus mauris. Aliquam consectetur",
          review: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me."
        }
      ],
      btn_group: {
        btn1: {
          text: "Book Online Now",
          bg_color: "#192335",
          color: "#ffffff",
          is_enable: true
        }
      }
    },
    section_13: {
      is_section_enable: true,
      banner: "howwe1",
      title: "Just A Click Away",
      paragraph: "Download Our Best Active Mobile App",
      check_list: [
        {
          _id: 1,
          icons: "/assets/icons/priorityicon14.png",
          title: "Cashless payments"
        },
        {
          _id: 2,
          icons: "/assets/icons/priorityicon15.png",
          title: "Delivery in less then 24 hours"
        },
        {
          _id: 3,
          icons: "/assets/icons/priorityicon16.png",
          title: "Standard wash for just Â£14"
        },
        {
          _id: 4,
          icons: "/assets/icons/priorityicon17.png",
          title: "In-app live chat support"
        }
      ],
      mobile_link_section: {
        is_enable: true,
        android_link: "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
        ios_link: "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8"
      }
    },
    section_14: {
      is_section_enable: true,
      section_bg_color: "#1b9db3",
      title: "Subscribe to our newsletter for updates, exclusive promotions and our latest content!",
      btn_group: {
        btn1: {
          text: "Subscribe",
          bg_color: "#192335",
          color: "ffffff"
        }
      }
    },
    section_15: {
      is_section_enable: true,
      sub_heading_top: "Our Latest",
      title: "News & Events",
      paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
      btn_group: {
        btn1: {
          text: "More News & Events",
          bg_color: "#192335",
          color: "ffffff"
        }
      }
    },
    footer: {
      custome_banner_design: {
        is_enable: true
      },
      bg_color: "151e2e",
      section_heading_color: "ffffff",
      section_paragraph_color: "c2c2c2",
      footer_section: {
        about_section: {
          is_enable: true,
          text: "Ready to take laundry off your to-do list? Bestatlaundry services is a local laundry pickup and delivery service that you can order directly from your smart phone."
        },
        service_section: {
          is_enable: true
        },
        quicklink_section: {
          is_enable: true
        },
        callnow_for_Services_section: {
          is_enable: true,
          mbl_no: {
            label: "+442086388690",
            value: "tel:+442086388690"
          },
          email: {
            label: "Email Us",
            value: "sales@bestatlaundry.com"
          },
          address: {
            text: "81 Randall Drive, Hornchurch - RM126TA"
          }
        },
        social_media: {
          facebook: {
            is_enable: true,
            facebook_link: "facebook.com"
          },
          instagram: {
            is_enable: true,
            instagram_link: "instagram.com"
          },
          whatsapp: {
            is_enable: true,
            whatsapp_link: "whatsapp.com"
          },
          linkedin: {
            is_enable: true,
            linkedin_link: "linkedin.com"
          },
          youtube: {
            is_enable: true,
            youtube_link: "youtube.com"
          },
          twitter: {
            is_enable: true,
            twitter_link: "twitter.com"
          }
        }
      }
    }
  },
  theme_1: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/img/section/section1.png"
        },
        {},
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/img/section/section3.png"
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/img/section/section4.png"
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "/assets/img/section/section5.png"
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/img/section/section6.png"
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/img/section/section7.png"
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "/assets/img/section/section8.png"
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "/assets/img/section/section9.png"
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "/assets/img/section/section10.png"
        }
      ],
      header: {
        primary_color: "ff0059",
        secondary_color: "ffcb00",
        nav: {
          nav_bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            cart_bg_color: "ffffff",
            cart_icon_bg_color: "f07f1b",
            cart_value_color: "000000"
          },
          contact: {
            contact_is_enable: true,
            number: "+(044) 071012345",
            contact_color: "2e3639"
          }
        },
        btn_group: {
          btn1: {
            text: "Login / Register",
            btn_bg_color: "ffffff",
            btn_color: "1b9db3",
            border_color: "1b9db3",
            btn1_is_enable: true
          }
        }
      },
      section_1: {
        banner: "/assets/img/home_page_single_search.jpg",
        sub_heading_top: "24/7 Dry Cleaning & Laundry Service",
        title: "One Solution for all your Washing Needs",
        paragraph: "While you are doing something important, we will put things in order in the apartment, private house or office, clean the upholstered furniture and wash the windows.",
        btn_group: {
          btn1: {
            text: "Book now",
            placeholder: "Enter Postalcode",
            bg_color: "1b9db3",
            color: "ffffff"
          }
        }
      },
      section_2: {
        title: "Select Your Time & Location For The Service",
        btn_group: {
          btn1: {
            text: "order now",
            placeholder: "Enter Postcode",
            bg_color: "1b9db3",
            color: "ffffff"
          }
        }
      },
      section_3: {
        is_section_enable: true,
        banner: "/assets/img/trustimg.png",
        title: "Trust the Expert",
        paragraph:
          "Champion Cleaners aim has always been to provide 5 star premium dry cleaning and laundry services to expat and local populations of Dubai and the United Arab Emirates",
        sub_heading: "Unique solutions for all your laundry needs",
        check_list: [
          {
            _id: 1,
            list: "Clean for professional garment care",
          },
          {
            _id: 2,
            list: "Wash & Fold for items that don't need pressing",
          },
          {
            _id: 3,
            list: "Press Only for clean clothes that need pressing",
          },
          {
            _id: 4,
            list: "ShoeCare for cleaned and restored shoes",
          },
        ],
        btn_group: {
          btn1: {
            text: "Read More",
            bg_color: "#ffcb00",
            color: "ffffff",
            is_enable: true,
          },
        },
      },
      section_4: {
        title: "Your Health & Safety Is Our Priority",
        cards: [
          {
            heading: "Contactless deliveries",
            icon: "/assets/img/icons/priorityicon1.svg",
            image: "/assets/img/home/priorityimg1.jpg",
            paragraph: "For our customers safety, we enabled the feature to allow customers to conduct contactless pick up & deliveries."
          },
          {
            heading: "Monitoring the situation",
            icon: "/assets/img/icons/priorityicon2.svg",
            image: "/assets/img/home/priorityimg2.jpg",
            paragraph: "Weâ€™re keeping up to date with all developments from government to ensure the safety of our customers and staff."
          },
          {
            heading: "Instant updates",
            icon: "/assets/img/icons/priorityicon3.svg",
            image: "/assets/img/home/priorityimg3.jpg",
            paragraph: "Weâ€™ll keep you posted throughout the process with texts/emails when driver is on their way and order has been cleaned."
          }
        ]
      },
      section_5: {
        is_section_enable: true,
        banner: "/assets/img/phone.png",
        title: "Just A Click Away",
        paragraph: "Our apps got a better and convenient way of getting your laundry and dry cleaning with our 5-star services. Download now and register to get more exclusive offers",
        check_list: [
          {
            _id: 1,
            icons: "/assets/img/icons/priorityicon1.svg",
            title: "Users",
            count: "25000"
          },
          {
            _id: 2,
            icons: "/assets/img/icons/priorityicon2.svg",
            title: "Downloads",
            count: "40000"
          },
          {
            _id: 3,
            icons: "/assets/img/icons/priorityicon3.svg",
            title: "Ratings",
            count: "4.5"
          }
        ],
        mobile_link_section: {
          is_enable: true,
          android_link: "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link: "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8"
        }
      },
      section_6: {
        heading: "Save with Our Subscriptions",
        card_one_title: "Laundry Bag",
        card_one_paragraph:
          "One bag = one price, fill it up and get it washed! Includes FREE next day delivery & all perks from Laundry Go. Ideal for couples & families. Plans start from £25/month",
        card_one_image: "/assets/img/home/wing1.png",
        card_two_title: "Ongoing discount on All Services",
        card_two_paragraph:
          "10% OFF all services (apart from laundry) for just £3.99/month. This includes Eco Dry Cleaning, shirt service & bed linen service. Ideal for single busy professionals & couples.",
        card_two_image: "/assets/img/home/wing2.png",
        card_1: {
          card_one_title: "Laundry Bag",
          card_one_paragraph: "One bag = one price, fill it up and get it washed! Includes FREE next day delivery & all perks from Laundry Go. Ideal for couples & families. Plans start from Â£25/month",
          card_one_image: "/assets/img/home/wing1.png"
        },
        card_2: {
          card_two_title: "Ongoing discount on All Services",
          card_two_paragraph: "10% OFF all services (apart from laundry) for just Â£3.99/month. This includes Eco Dry Cleaning, shirt service & bed linen service. Ideal for single busy professionals & couples.",
          card_two_image: "/assets/img/home/wing2.png"
        }
      },
      section_8: {
        title: "What Our Client Say",
        cards: [
          {
            profile: "/assets/img/home/testimonialuser1.jpg",
            name: "Judy Wells",
            position: "Chairman and founder",
            message: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "/assets/img/home/testimonialimg1.jpg"
          },
          {
            profile: "/assets/img/home/testimonialuser2.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "/assets/img/home/testimonialimg2.jpg"
          },
          {
            profile: "/assets/img/home/testimonialuser3.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "/assets/img/home/testimonialimg3.jpg"
          },
          {
            profile: "/assets/img/home/testimonialuser1.jpg",
            name: "Judy Wells",
            position: "Chairman and founder",
            message: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "/assets/img/home/testimonialimg1.jpg"
          },
          {
            profile: "/assets/img/home/testimonialuser2.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "/assets/img/home/testimonialimg2.jpg"
          },
          {
            profile: "/assets/img/home/testimonialuser3.jpg",
            name: "Judy Wells two",
            position: "Chairman and founder",
            message: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
            backgroundImage: "/assets/img/home/testimonialimg3.jpg"
          }
        ]
      },
      section_10: {
        title: "Latest News & Events",
        button: {
          text: "View More",
          bg_color: "#ffcb00",
          color: "ffffff",
          is_enable: true
        }
      },
      section_7: {
        is_section_enable: true,
        title: "On Demand Laundry, Dry Cleaning and Ironing Service"
      },
      section_9: {
        is_section_enable: true,
        title: "Frequently Asked Questions",
        paragraph: "online laundry & dry cleaning companies who simply just own the technology and farm the cleaning to other providers with little to no process system checks.",
        banner: [
          {
            banner: "/assets/img/faqimg.jpg"
          }
        ],
        card_heading: "CALL US FOR A FREE QUOTE ON:",
        card_number: "+(044) 071012345",
        card_buttontext: "Enquire Now",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "Do you have a re-cleaning Policy?",
              isEnabled: true,
              collection_paragraph: "Although we have quality control where we check each garment before we pack, if we are unable to remove stains we usually inform customers before. If something slips through and you are not completely satisfied with the quality of our dry cleaning, we will re-clean your item free of charge, as long as itâ€™s within 24 hours."
            },
            {
              _id: 2,
              collection_title: "Do you have policy grantees?",
              isEnabled: true,
              collection_paragraph: "Yes"
            }
          ]
        }
      },
      footer: {
        bg_color: "ffffff",
        section_heading_color: "000",
        section_paragraph_color: "c2c2c2",
        mbl_number : "+442086388690",
        email_val: "sales@bestatservices.com",
        address_val: "81 Randall Drive, Hornchurch - RM126TA",
        footer_section: {
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com"
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com"
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com"
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com"
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com"
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com"
            }
          }
        }
      }
    }
  },
  theme_2: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/section-two/section1.png"
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/section-two/section2.png"
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/section-two/section3.png"
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/section-two/section4.png"
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "/assets/section-two/section5.png"
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/section-two/section6.png"
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/section-two/section7.png"
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "/assets/section-two/section8.png"
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "/assets/section-two/section9.png"
        }
      ],
      header: {
        primary_color: "ff0059",
        secondary_color: "ffcb00",
        nav: {
          nav_bg_color: "ffffff",
          header_bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b"
        },
        btn_group: {
          btn1: {
            text: "Continue to order",
            btn_bg_color: "ff0059",
            btn_color: "ffffff"
          },
          btn2: {
            btn2_text: "Order Now",
            btn2_bg_color: "ffcb00",
            btn2_color: "2e3639"
          }
        },
        nav_bg_color: "ffffff"
      },
      section_1: {
        title_1: "Solution for your",
        title_2: "Laundry & Dry Cleaning Service",
        title_3: "Highest quality and lowest price guaranteed.",
        card_section: [
          "/assets/img/theme_2/banner-slider-01.jpg",
          "/assets/img/theme_2/banner-slider-02.jpg",
          "/assets/img/theme_2/banner-slider-03.jpg",
          "/assets/img/theme_2/banner-slider-04.jpg",
          "/assets/img/theme_2/banner-slider-05.jpg"
        ]
      },
      section_2: {
        is_section_enable: true,
        banner: "/assets/img/trustimg2.png",
        sub_heading_top: "Our Introduction",
        title: "Welcome",
        mainparagraph: "There are many variations of passag of lorem ipsum available but the majority have suffered alteration in some form by injected humour randomised words which don't look even slightly believable.There are many variations of passag of lorem ipsum available but the majority have suffered alteration in some form by injected humour randomised words which don't look even slightly believable.",
        card_section_one: [
          {
            _id: 1,
            text: "Dry Cleaning",
            count: "12K"
          },
          {
            _id: 2,
            text: "Satisfied Clients",
            count: "20K"
          },
          {
            _id: 3,
            text: "Happy Customers",
            count: "8K"
          }
        ],
        card_section_title: "Top rated laundry",
        rating: "5",
        card_sub_text: "(30k Reviews)",
        card_section_two: [
          {
            _id: 1,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "Claim Support",
            card_title: "24/7 Supports",
            card_paragraph: "We love to help you to get your clothes fresh and clean so we are here for you 24/7 for any question, concern or suggestion."
          },
          {
            _id: 2,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "100% Reliable",
            card_title: "Offers & cares",
            card_paragraph: "Every item sent to dry cleaning or Laundromat, hands of experts who take care of your garments in detailed way."
          },
          {
            _id: 3,
            card_icon: "/assets/icons/priorityicon1_2.svg",
            card_sub_heading_top: "Consultation",
            card_title: "Expert teams",
            card_paragraph: "With our subscription, you can set up your services and never worry about laundry or for dry cleaning again."
          }
        ]
      },
      section_3: {
        cards: [
          {
            bg_img: "/assets/img/home/wing1.png",
            card_title: "Any Special Cleaning Instructions for us?",
            card_paragraph:
              "Please Handle carefully, Plink sweater has a delicate embroidery and needs additional care.",
            // services: [
            //   { id: "1", list: "Laundry" },
            //   { id: "2", list: "Dry Cleaning" },
            //   { id: "3", list: "Ironing" },
            // ],
            services: [
              "Laundry",
              "Dry Cleaning",
              "Ironing"
            ],
            card_main_title: "We pick up your valuable Clothes",
            card_main_paragraph:
              "Our delivery services work no matter where you are. Do you need your clothes dropped off at your place of work? We can do that for you. We’ll drop it off at your house, apartment, or even at your hotel.",
            // card_options: [
            //   { id: "1", list: "100% Customer Satisfaction" },
            //   { id: "2", list: "Affordable Prices" },
            //   { id: "3", list: "Free Collection & Delivery" },
            //   { id: "4", list: "Best Quality" },
            // ],
            check_list: [
              "100% Customer Satisfaction",
              "Affordable Prices",
              "Free Collection & Delivery",
              "Best Quality"
            ]
          },
          {
            bg_img: "/assets/img/home/wing2.png",
            card_title: "Order Scheduled",
            card_paragraph:
              "Download our app or visit our website to start your laundry order.",
            // services: [
            //   { id: "1", list: "Pay Online in Seconds" },
            //   { id: "2", list: "Eco-Friendly" },
            //   { id: "3", list: "Save Time & Money" },
            // ],
            services: [
              "Pay Online in Seconds",
              "Eco-Friendly",
              "Save Time & Money"
            ],
            card_main_title: "Pack up your valuable Clothes",
            card_main_paragraph:
              "Pack the item in a disposable bag, use one bag per service, our driver will transfer them to a reusable laundry heap bag that you can save for your next order, pack one bag per service, no need to count or weight the items, efficient water use",
            // card_options: [
            //   { id: "1", list: "100% Customer Satisfaction" },
            //   { id: "2", list: "Affordable Prices" },
            //   { id: "3", list: "Free Collection & Delivery" },
            //   { id: "4", list: "Best Quality" },
            // ],
            check_list: [
              "100% Customer Satisfaction",
              "Affordable Prices",
              "Free Collection & Delivery",
              "Best Quality"
            ]
          },
          {
            bg_img: "/assets/img/home/wing3.png",
            card_title: "Order Scheduled",
            card_paragraph:
              "Download our app or visit our website to start your laundry order.",
            // services: [
            //   { id: "1", list: "Competitive Price" },
            //   { id: "2", list: "5 Star Rated" },
            // ],
            services: [
              "Competitive Price",
              "5 Star Rated"
            ],
            card_main_title: "Wait for driver to pick up Clothes",
            card_main_paragraph:
              "Get notified when your driver is nearby. They will pick up your bag and take it to a local cleaning facility. Regular order updates, live driver tracking, zero-emission delivery vehicles, trusted local cleaners, and convenient laundry solutions those help save the environment.",
            // card_options: [
            //   { id: "1", list: "100% Customer Satisfaction" },
            //   { id: "2", list: "Affordable Prices" },
            //   { id: "3", list: "Free Collection & Delivery" },
            //   { id: "4", list: "Best Quality" },
            // ],
            check_list: [
              "100% Customer Satisfaction",
              "Affordable Prices",
              "Free Collection & Delivery",
              "Best Quality"
            ]
          },
          {
            bg_img: "/assets/img/home/wing4.png",
            card_title: "Order Picked",
            card_paragraph:
              "Download our app or visit our website to start your laundry order.",
            // services: [
            //   { id: "1", list: "24h turnaround time" },
            //   { id: "2", list: "Easy to reschedule" },
            // ],
            services: [
              "24h turnaround time",
              "Easy to reschedule"
            ],
            card_main_title: "We take care of your Clothes",
            card_main_paragraph:
              "Items will be meticulously cleaned by a local partner facility. Our driver will bring them whenever you want. You are in complete control of your deliveries and can reschedule at any time if you are not home.24 hour processing time, real-time order status and easy rescheduling",
            // card_options: [
            //   { id: "1", list: "100% Customer Satisfaction" },
            //   { id: "2", list: "Affordable Prices" },
            //   { id: "3", list: "Free Collection & Delivery" },
            //   { id: "4", list: "Best Quality" },
            // ],
            check_list: [
              "100% Customer Satisfaction",
              "Affordable Prices",
              "Free Collection & Delivery",
              "Best Quality"
            ]
          },
        ],
      },
      section_4: {},
      section_5: {
        title: "Compare our Services",
        cards: [
          {
            card_title: "",
            icon: "/assets/img/icons/serviceicon2.svg",
            // card_options: [
            //   { id: "1", list: "What is included" },
            //   { id: "2", list: "Typically included items" },
            //   { id: "3", list: "Service time" },
            //   { id: "4", list: "Delivery" },
            //   { id: "5", list: "Items returned" },
            //   { id: "6", list: "Eco friendly" },
            // ],
            card_options: [
              "What is included",
              "Typically included items",
              "Service time",
              "Delivery",
              "Items returned",
              "Eco friendly"
            ]
          },
          {
            card_title: "Laundry Services",
            icon: "/assets/img/icons/serviceicon2.svg",
            // card_options: [
            //   { id: "1", list: "Wash + Tumble-Dry" },
            //   { id: "2", list: "T-shirts, trousers, bedsheets." },
            //   { id: "3", list: "Service time 24h*" },
            //   { id: "4", list: "Delivery FREE" },
            //   { id: "5", list: "Items returned in a bag" },
            //   { id: "6", list: "Eco friendly" },
            // ],
            card_options: [
              "Wash + Tumble-Dry",
              "T-shirts, trousers, bedsheets.",
              "Service time 24h*",
              "Delivery FREE",
              "Items returned in a bag",
              "Eco friendly"
            ]
          },
          {
            card_title: "Dry Cleaning Services",
            icon: "/assets/img/icons/serviceicon3.svg",
            // card_options: [
            //   { id: "1", list: "Dry Cleaning + Ironing" },
            //   { id: "2", list: "Suits, shirts, dresses and coats." },
            //   { id: "3", list: "Service time 24h*" },
            //   { id: "4", list: "Delivery FREE" },
            //   { id: "5", list: "Items returned in a bag" },
            //   { id: "6", list: "Eco friendly" },
            // ],
            card_options: [
              "Dry Cleaning + Ironing",
              "Suits, shirts, dresses and coats.",
              "Service time 24h*",
              "Delivery FREE",
              "Items returned in a bag",
              "Eco friendly"
            ]
          },
          {
            card_title: "Ironing Services",
            icon: "/assets/img/icons/serviceicon4.svg",
            // card_options: [
            //   { id: "1", list: "Ironing" },
            //   { id: "2", list: "Shirts, t-shirts and bedsheets." },
            //   { id: "3", list: "Service time 24h*" },
            //   { id: "4", list: "Delivery FREE" },
            //   { id: "5", list: "Items returned in a bag" },
            //   { id: "6", list: "Eco friendly" },
            // ],
            card_options: [
              "Ironing",
              "Shirts, t-shirts and bedsheets.",
              "Service time 24h*",
              "Delivery FREE",
              "Items returned in a bag",
              "Eco friendly"
            ]
          },
        ],
      },
      section_6: {
        collection: [
          {
            collection_title: "Do you have a re-cleaning Policy?",
            collection_paragraph: "Although we have quality control where we check each garment before we pack, if we are unable to remove stains we usually inform customers before. If something slips through and you are not completely satisfied with the quality of our dry cleaning, we will re-clean your item free of charge, as long as itâ€™s within 24 hours."
          },
          {
            collection_title: "Do you have a loss policy?",
            collection_paragraph: "We take full responsibility for the clothes from the moment we issue a ticket or pick up from you. Even though we exercise the utmost care, there will be instances where items may get misplaced or lost. Should you have reason to believe that we have lost or misplaced your item. Missing items must be reported to us within 24 hours with descriptions so that we can trace your garment. In the unlikely event of losing any of your items, bestatlaundry liability with respect to any lost item shall not exceed ten (10) times our charge for cleaning that garment regardless of brand or condition."
          },
          {
            collection_title: "Do you have a safe keeping policy?",
            collection_paragraph: "We take responsibility to keep your clothes safe for a maximum of 60 days. Should you be unable to pick your clothes within 60 days, due to holiday, illness, etc. please let us know ASAP and we shall arrange to keep your garments safely for an agreed time period. Otherwise, due to storage, we will have to give your clothes to charity."
          },
          {
            collection_title: "Do you have a privacy statement?",
            collection_paragraph: "We respect our customerâ€™s privacy and do not sell any identifiable information regarding our customers to any third party. Any information you give us is held with the utmost care and security, and will not be used in ways to which you have not consented."
          },
          {
            collection_title: "Do you have a garment damage policy?",
            collection_paragraph: "Whilst every care is taken handling your clothes, if you believe that we have damaged your garments in any way please contact as within 24 hours. We will do our best to resolve the situation. We will investigate thoroughly and if we feel that we were negligent we shell pay fair compensation based upon the current value of the garment. This is usually around 6-10 times the cost of the dry cleaning. Our damage policy is in line with the Office of Fair Trades recommendations."
          },
          {
            collection_title: "How should my garments be cleaned?",
            collection_paragraph: "As we are experts at dealing with all garments that come our way. When they arrive at our premises, your garments are examined carefully and then cleaned under the manufacturerâ€™s instructions. Our cleaning is as gentle and delivers better results than traditional dry cleaning. If thereâ€™s any doubt or we canâ€™t find a care label, we will clean at what we call â€œownerâ€™s riskâ€\u009d (as we can sadly not accept any liability for garments where there are no instructions). If we feel there is really too much of a risk to your garment and we cannot get in touch with you, we will return the item to you uncleaned."
          },
          {
            collection_title: "How long does it take to clean my items?",
            collection_paragraph: "We aim to collect your clothes, give them our expert care and attention and deliver them back to you within working 48 hours. There might be some exceptions: In some instances, your preferred delivery slot may not be available. This is mainly because we have already reached full capacity for that time slot, or because we do not visit your area as often as we would like at this moment. We apologise for any inconvenience caused and assure you that we are working very hard to increase our time slot capacity. Some items such as bed linen, curtains, leather, or tailoring may take longer to process as they will be entrusted to various experts. The delivery timing will be provided to you as soon as the respective items have been inspected at our premises. On inspection, we might consider that it will take longer to remove particularly difficult stains or other issues with fabrics. We offer an express service with same-day turnaround* in all our stores however due to logistical constraints thi"
          },
          {
            collection_title: "Why can I not select my preferred delivery slot?",
            collection_paragraph: "In some instances, your preferred delivery slot may not be available. This could be because we have already reached full capacity for that time slot. We apologize for any inconvenience caused and assure you that we are working very hard to increase our time slot capacity. Should you wish to drop us a line and check to see if we accommodate your chosen slot or to see if there is any cancellation please email info@bestatlaundry.uk"
          },
          {
            collection_title: "How do I order online?",
            collection_paragraph: "Simply click Book Now in the top right-hand corner. On the next screen, you will be able to choose a pickup time that we will collect the clothing items from your home/concierge and a dropoff time where we will drop the dry cleaned clothes back to your home/concierge. The next screen is where you can choose your items either select the items directly or choose a category and the system will refine the items to help you choose. Simply choose the quantity and select add to put the item in your basket. When ready click with view basket or the basket in the top right-hand corner of the page you can then check out adding your address and any special requirements. Lastly, you can pay using any credit or debit card using our encrypted service with stripe. We will then process your order and arrive at your requested time slot ready to pick up your items."
          },
          {
            collection_title: "can I book a regular pickup & delivery?",
            collection_paragraph: "Yes! If you want to book a regular pickup and delivery slot each week, just email us to info@bestatlaundry.uk This saves having to deal with laundry and to re-book on a regular basis, and you can cancel at any time if you donâ€™t need a delivery that week. Although it is currently not possible to book a recurring time slot online, you can call our customer care team and we will gladly set this up."
          },
          {
            collection_title: "How do I know when my driver will arrive?",
            collection_paragraph: "We currently offer 1-hour time slots, which allows for traffic and our logistical requirements, if our driver is later than the chosen time slot we will contact you."
          },
          {
            collection_title: "Why do you not cover my area?",
            collection_paragraph: "We are continually growing and expanding and while we might not currently cover your area. Send a message with your details to info@bestatlaundry.uk and as soon as we start to serve your area, we will contact you. You can also mail us to info@bestatlaundry.uk as they will often be able to arrange a special courier or Uber service for you (delivery fees will apply)."
          },
          {
            collection_title: "How will I be charged for the order",
            collection_paragraph: "We use a highly secure platform to register your credit or debit card and collect payment. After picking up your clothes, our experts will examine them and determine the total amount of your invoice based on the extent of the work that needs to be carried out on each item (much as a till assistant would do in-store). Until then, you can get an estimate of your order according to our price list here. We are unable to be more exact at this stage because some items require more time and specific treatment, and we canâ€™t know the detail of this before we examine your garments. Once this is done, we will send you an email detailing your invoice, item by item, and charge your card on that basis. If you disagree with anything that we have detailed on your invoice, please feel free email us to info@bestatlaundry.uk and we will be happy to look into this for you. Kindly note that we cannot accept cash payment for deliveries."
          },
          {
            collection_title: "What does owner's risk mean?",
            collection_paragraph: "Some fragile or highly sensitive garments inherently have a risk in cleaning, this could be due to fabric, age, colour, pre-existing damage etc. thus meaning there is a risk that they could be damaged in the cleaning process, in this instance we will refrain from cleaning the garment in question. We will make an attempt to contact you and advise you of the potential risks associated with proceeding with the treatment. Note that if you authorise us to proceed, it will imply that you assume full responsibility for any damage to the garments. In certain circumstances, we may still return the garment and advise not to clean it as damage is inevitable."
          },
          {
            collection_title: "can we dry clean wedding dresses and other bespoke items?",
            collection_paragraph: "We are the experts at cleaning these pieces. Please note that the sooner you bring your dress in after your wedding, the better the results we can achieve, as leaving little time for stains to set helps us to get that better finish. We can also clean other bespoke items â€“ just ask one of your store assistants for a quote, email us info@bestatlaundry.uk if you would like more information or to ask for a quote. Kindly note that we assess all those very specific items before cleaning to ensure that we have spotted and highlighted all the potential risks to you before proceeding with the clean."
          }
        ]
      },
      section_7: {
        title_1: "Latest Testimonial",
        title_2: "What Our Client Say",
        cards: [
          {
            profile: "/assets/home/testimonialuser1.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me."
          },
          {
            profile: "/assets/home/testimonialuser2.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me."
          },
          {
            profile: "/assets/home/testimonialuser3.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me."
          },
          {
            profile: "/assets/home/testimonialuser1.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me."
          },
          {
            profile: "/assets/home/testimonialuser2.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me."
          },
          {
            profile: "/assets/home/testimonialuser3.jpg",
            name: "Judy Wells",
            designation: "Chairman and founder",
            message: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me."
          }
        ]
      },
      section_8: {
        title_1: "ARTICLES",
        title_2: "Latest News"
      },
      section_9: {
        title: "We're available on mobile Just download it!",
        paragraph: "Download the our app and request our laundry service. Your clothes washed, folded and delivered to your doorstep. So go ahead and say yes to more time with the family, more happy hours, and more slapping the snooze button every morning â€” we've got laundry day covered.",
        android_link: "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
        ios_link: "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8"
      },
      footer: {
        custome_banner_design: {
          is_enable: true
        },
        bg_color: "151e2e",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          about_section: {
            is_enable: true,
            text: "Ready to take laundry off your to-do list? Bestatlaundry services is a local laundry pickup and delivery service that you can order directly from your smart phone."
          },
          service_section: {
            is_enable: true
          },
          quicklink_section: {
            is_enable: true
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "+442086388690",
              value: "tel:+442086388690"
            },
            email: {
              label: "Email Us",
              value: "sales@bestatlaundry.com"
            },
            address: {
              text: "81 Randall Drive, Hornchurch - RM126TA"
            }
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com"
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com"
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com"
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com"
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com"
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com"
            }
          }
        }
      }
    }
  },
  theme_3: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/section/section1.jpg"
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/section/section3.jpg"
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/section/section4.jpg"
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "/assets/section/section5.jpg"
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/section/section6.jpg"
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/section/section7.jpg"
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "/assets/section/section8.jpg"
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "/assets/section/section9.jpg"
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "/assets/section/section10.jpg"
        },
        {
          _id: 11,
          is_section_enable: true,
          icon: "/assets/section/section11.jpg"
        },
        {
          _id: 12,
          is_section_enable: true,
          icon: "/assets/section/section12.jpg"
        },
        {
          _id: 13,
          is_section_enable: true,
          icon: "/assets/section/section13.jpg"
        },
        {
          _id: 15,
          is_section_enable: true,
          icon: "/assets/section/section15.jpg"
        }
      ],
      header: {
        is_section_enable: true,
        primary_color: "1B9DB3",
        secondary_color: "F07F1B",
        nav: {
          bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "#6b7385"
            },
            cart_value_color: "000000"
          }
        },
        btn_group: {
          btn1: {
            text: "Login / Register",
            bg_color: "ffffff",
            color: "#1b9db3",
            border_color: "#1b9db3",
            btn1_is_enable: true
          },
          btn2: {
            text: "Quick Order",
            color: "ffffff",
            bg_color: "000",
            border_color: "#192335",
            btn2_is_enable: true
          }
        }
      },
      section_1: {
        is_section_enable: true,
        banner_type: "image",
        banner: "/assets/home_page_single_search.jpg",
        banner_two: "/assets/home/heroimg.png",
        sub_heading_top: "24/7 Dry Cleaning & Laundry Service",
        title: "One Solution for all your Washing Needs",
        paragraph: "Bestatlaundry is synonymous with quality, reliability, and trust. We provide fast dry cleaning and laundry services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers.",
        btn_group: {
          btn1: {
            text: "Book now",
            bg_color: "#1b9db3",
            color: "ffffff",
            placeholder: "Enter Postalcode"
          }
        },
        mobile_link_section: {
          is_enable: true,
          mob_title: "Just A Click Away",
          android_link: "#",
          ios_link: "#"
        }
      },
      section_2: {
        is_section_enable: true,
        sub_heading_top: "Book A Service",
        title: "Select Your Time & Location For The Service",
        btn_group: {
          btn1: {
            text: "order now",
            bg_color: "#1b9db3",
            color: "ffffff"
          }
        }
      },
      section_3: {
        is_section_enable: true,
        sub_heading_top: "Dry Cleaning & Laundry",
        title: "Trust The Expert On Laundry Services",
        paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/icons/priorityicon1.png",
              collection_title: "Top Quality",
              collection_paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing"
            },
            {
              _id: 2,
              icon: "/assets/icons/priorityicon2.png",
              collection_title: "Best Service",
              collection_paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing"
            },
            {
              _id: 3,
              icon: "/assets/icons/priorityicon3.png",
              collection_title: "Value",
              collection_paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing"
            },
            {
              _id: 4,
              icon: "/assets/icons/priorityicon4.png",
              collection_title: "Professionalism",
              collection_paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing"
            },
            {
              _id: 5,
              icon: "/assets/icons/priorityicon5.png",
              collection_title: "Convenience",
              collection_paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing"
            },
            {
              _id: 6,
              icon: "/assets/icons/priorityicon6.png",
              collection_title: "Cleanliness",
              collection_paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing"
            }
          ],
          btn_group: {
            btn1: {
              text: "More About Us",
              bg_color: "192335",
              color: "ffffff"
            }
          }
        }
      },
      section_4: {
        is_section_enable: true,
        title: "Bestatlaundry is Ranked #1 by customers in the UK in categories of dry cleaning"
      },
      section_5: {
        is_section_enable: true,
        banner: [
          {
            banner: "/assets/home/howwe1.jpg"
          }
        ],
        title: "We collect, clean, & deliver your laundry & dry cleaning.",
        paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took",
        check_list: [
          {
            _id: 1,
            list: "Free same day collection",
          },
          {
            _id: 2,
            list: "24h turnaround time",
          },
          {
            _id: 3,
            list: "Dedicated 24/7 support",
          },
          {
            _id: 4,
            list: "Standard wash for just £14",
          },
          {
            _id: 5,
            list: "Wash & Fold for items that don't need pressing",
          },
          {
            _id: 6,
            list: "Free collection and delivery",
          },
        ],
        btn_group: {
          btn1: {
            text: "Schedule a Collection Now",
            bg_color: "#192335",
            color: "ffffff",
            is_enable: true
          },
          btn2: {
            text: "Request a Pickup",
            bg_color: "#1b9db3",
            color: "ffffff",
            is_enable: true
          }
        }
      },
      section_6: {
        is_section_enable: true,
        sub_heading_top: "Know More On",
        title: "How We Works",
        paragraph: "We collect, clean and deliver your clothes within 24-48 hrs.",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/home/howwe1.jpg",
              card_title: "Online Order",
              card_paragraph: "Use our friendly and easy access website or app to place your laundry order. choose a suitable pickup location and time."
            },
            {
              _id: 2,
              icon: "/assets/home/howwe2.jpg",
              card_title: "Transport",
              card_paragraph: "Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities."
            },
            {
              _id: 3,
              icon: "/assets/home/howwe3.jpg",
              card_title: "Dry Cleaning",
              card_paragraph: "Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities."
            },
            {
              _id: 4,
              icon: "/assets/home/howwe4.jpg",
              card_title: "Door Delivery",
              card_paragraph: "Dry and clean clothes were carefully bagged and delivered to your doorstep."
            }
          ],
          btn_group: {
            paragraph: "Lorem Ipsum is simply dummy text of the",
            btn1: {
              text: "Book Online Now",
              bg_color: "#192335",
              color: "#ffffff"
            }
          }
        }
      },
      section_7: {
        is_section_enable: true,
        title: "On Demand Laundry, Dry Cleaning And Ironing Categories",
        paragraph: "We provide commercial laundry, shoe repair, clothing alteration, tailoring and wedding dress fitting services in London, Essex, Kent, Westminster, Chelsea, Dartford, Croydon, Enfield, Basildon, South On Sea, Bromley, Mayfair, Romford and other areas in London.",
        btn_group: {
          btn_paragraph: "Lorem Ipsum is simply dummy text of the",
          btn1: {
            text: " Request a Pickup",
            bg_color: "192335",
            color: "fff"
          }
        }
      },
      section_8: {
        is_section_enable: true,
        banner: "howwe1",
        title: "About Us",
        section_image: "/assets/home/trustimg2.jpg",
        paragraph: "Bestatlaundry is synonymous with quality, reliability, and trust. We provide fast dry cleaning and laundry services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers.",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "James please use React import aliase",
              collection_title: "Free Pick up and Delivery",
              collection_image: "/assets/icons/priorityicon7.png",
              isEnabled: true,
              collection_paragraph: "We offer a convenient way to have laundry picked up and delivered for free."
            },
            {
              _id: 2,
              icon: "James please use React import aliase",
              collection_title: "High Quality of Services",
              collection_image: "/assets/icons/priorityicon8.png",
              isEnabled: true,
              collection_paragraph: "Our laundry team perfectly cleans, iron, fold, pack, and deliver to you on time."
            },
            {
              _id: 3,
              icon: "James please use React import aliase",
              collection_title: "Affordable Cost",
              collection_image: "/assets/icons/priorityicon9.png",
              isEnabled: true,
              collection_paragraph: "We offer affordable prices when compared to the current market."
            }
          ]
        },
        btn_group: {
          btn1_text: "Schedule a Collection Now",
          btn1_bg_color: "192335",
          btn1_color: "ffffff",
          btn2_text: "Request a Pickup",
          btn2_bg_color: "1b9db3",
          btn2_color: "ffffff"
        }
      },
      section_9: {
        is_section_enable: true,
        section_bg_color: "#1b9db3",
        sub_heading_top: "Our Promising Services",
        title: "On Demand Laundry, Dry Cleaning And Ironing Service",
        paragraph: "We collect, clean and deliver your clothes within 24-48 hrs.",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/home/priorityimg1.jpg",
              collection_title: "Laundry",
              collection_paragraph: "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics."
            },
            {
              _id: 2,
              icon: "/assets/home/priorityimg2.jpg",
              collection_title: "Dry Cleaning",
              collection_paragraph: "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics."
            },
            {
              _id: 3,
              icon: "/assets/home/priorityimg3.jpg",
              collection_title: "Alteration & Repair",
              collection_paragraph: "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics."
            },
            {
              _id: 14,
              icon: "/assets/home/priorityimg1.jpg",
              collection_title: "Laundry",
              collection_paragraph: "A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics."
            }
          ],
          count_section: [
            {
              _id: 1,
              icon: "/assets/icons/priorityicon1.png",
              text: "Laundry Clothes",
              count: "1639+"
            },
            {
              _id: 2,
              icon: "/assets/icons/priorityicon2.png",
              text: "Steam Ironing",
              count: "24359+"
            },
            {
              _id: 3,
              icon: "/assets/icons/priorityicon3.png",
              text: "Dry Cleaning",
              count: "4793+"
            },
            {
              _id: 4,
              icon: "/assets/icons/priorityicon4.png",
              text: "Cleaning programs",
              count: "25+"
            }
          ]
        }
      },
      section_10: {
        is_section_enable: true,
        banner: "/assets/home/trustimg3.png",
        sub_heading_top: "Your Health & Safety Is Our Priority",
        title: "Our Values",
        mainparagraph: "online laundry & dry cleaning companies who simply just own the technology and farm the cleaning to other providers with little to no process system checks.",
        list_Section: [
          {
            _id: 1,
            text: "Contactless deliveries",
            paragraph: "For our customers safety, we enabled the feature to allow customers to conduct contactless pick up & deliveries."
          },
          {
            _id: 2,
            text: "Instant updates",
            paragraph: "Weâ€™ll keep you posted throughout the process with texts/emails when driver is on their way and order has been cleaned."
          },
          {
            _id: 3,
            text: "Monitoring the situation",
            paragraph: "Weâ€™re keeping up to date with all developments from government to ensure the safety of our customers and staff."
          }
        ],
        card_section_title: "We use Eco-friendly Laundry Detergent Sheets.",
        card_section: [
          {
            _id: 1,
            card_bg_color: "192335",
            card_text: "Vegan",
            card_paragraph: "Our vegan-friendly laundry detergent is free from any animal product."
          },
          {
            _id: 2,
            card_bg_color: "f07f1b",
            card_text: "Cruelty Free",
            card_paragraph: "We never test on animals â€“ so our Laundry Leaves will always be cruelty-free."
          },
          {
            _id: 3,
            card_bg_color: "1bb333",
            card_text: "Eco-Friendly",
            card_paragraph: "Free from any harmful chemicals or pollutants and are devoid of harmful toxins."
          }
        ]
      },
      section_11: {
        is_section_enable: true,
        banner: [
          {
            banner: "/assets/home/howwe1.jpg"
          }
        ],
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "Free Pick up and Delivery",
              isEnabled: true,
              collection_paragraph: "We offer a convenient way to have laundry picked up and delivered for free."
            },
            {
              _id: 2,
              collection_title: "High Quality of Services",
              isEnabled: true,
              collection_paragraph: "Our laundry team perfectly cleans, iron, fold, pack, and deliver to you on time."
            },
            {
              _id: 3,
              collection_title: "Affordable Cost",
              isEnabled: true,
              collection_paragraph: "We offer affordable prices when compared to the current market."
            }
          ]
        },
        sub_heading_top: "Ask Your Question",
        title: "Frequently Asked Questions",
        paragraph: "online laundry & dry cleaning companies who simply just own the technology and farm the cleaning to other providers with little to no process system checks."
      },
      section_12: {
        is_section_enable: true,
        banner: "howwe1",
        sub_heading_top: "What Our Client Says",
        title: "A Trusted Laundry And Dry Cleaning Company",
        paragraph: "Read what our customers say about us",
        review_bottom_paragraph: "More than 5,000+ reviews s on TrustPilot, Google, Google Play & App Store",
        review_card: [
          {
            _id: 1,
            reviewer_pic: "/assets/home/testimonialuser1.jpg",
            reviewer_name: "Ariyan Rusov",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me."
          },
          {
            _id: 2,
            reviewer_pic: "/assets/home/testimonialuser2.jpg",
            reviewer_name: "Darren Valdez",
            rating: "4",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me."
          },
          {
            _id: 3,
            reviewer_pic: "/assets/home/testimonialuser3.jpg",
            reviewer_name: "Elinor Dunn",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me."
          }
        ],
        btn_group: {
          btn1: {
            text: "Book Online Now",
            bg_color: "#192335",
            color: "#ffffff",
            is_enable: true
          }
        }
      },
      section_13: {
        is_section_enable: true,
        banner: "howwe1",
        title: "Just A Click Away",
        paragraph: "Download Our Best Active Mobile App",
        check_list: [
          {
            _id: 1,
            icons: "/assets/icons/priorityicon14.png",
            title: "Cashless payments"
          },
          {
            _id: 2,
            icons: "/assets/icons/priorityicon15.png",
            title: "Delivery in less then 24 hours"
          },
          {
            _id: 3,
            icons: "/assets/icons/priorityicon16.png",
            title: "Standard wash for just Â£14"
          },
          {
            _id: 4,
            icons: "/assets/icons/priorityicon17.png",
            title: "In-app live chat support"
          }
        ],
        mobile_link_section: {
          is_enable: true,
          android_link: "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link: "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8"
        }
      },
      section_14: {
        is_section_enable: true,
        section_bg_color: "#1b9db3",
        title: "Subscribe to our newsletter for updates, exclusive promotions and our latest content!",
        btn_group: {
          btn1: {
            text: "Subscribe",
            bg_color: "#192335",
            color: "ffffff"
          }
        }
      },
      section_15: {
        is_section_enable: true,
        sub_heading_top: "Our Latest",
        title: "News & Events",
        paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
        btn_group: {
          btn1: {
            text: "More News & Events",
            bg_color: "#192335",
            color: "ffffff"
          }
        }
      },
      footer: {
        custome_banner_design: {
          is_enable: true
        },
        bg_color: "151e2e",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        footer_section: {
          about_section: {
            is_enable: true,
            text: "Ready to take laundry off your to-do list? Bestatlaundry services is a local laundry pickup and delivery service that you can order directly from your smart phone."
          },
          service_section: {
            is_enable: true
          },
          quicklink_section: {
            is_enable: true
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "+442086388690",
              value: "tel:+442086388690"
            },
            email: {
              label: "Email Us",
              value: "sales@bestatlaundry.com"
            },
            address: {
              text: "81 Randall Drive, Hornchurch - RM126TA"
            }
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com"
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com"
            },
            whatsapp: {
              is_enable: true,
              whatsapp_link: "whatsapp.com"
            },
            linkedin: {
              is_enable: true,
              linkedin_link: "linkedin.com"
            },
            youtube: {
              is_enable: true,
              youtube_link: "youtube.com"
            },
            twitter: {
              is_enable: true,
              twitter_link: "twitter.com"
            }
          }
        }
      }
    }
  },
  theme_4: {
    home: {
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_1.png"
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_2.png"
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_3.png"
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_4.png"
        },
        {
          _id: 5,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_5.png"
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_6.png"
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_7.png"
        },
        {
          _id: 8,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_8.png"
        },
        {
          _id: 9,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_9.png"
        },
        {
          _id: 10,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_10.png"
        },
        {
          _id: 11,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_11.png"
        },
        {
          _id: 12,
          is_section_enable: true,
          icon: "/assets/sections-four/Section_12.png"
        }
      ],
      header: {
        is_section_enable: true,
        primary_color: "046A38",
        secondary_color: "01190D",
        contact: {
          email_id: "sales@bestatlaundry.com",
          contact_number: "+442086388690"
        },
        nav: {
          bg_color: "ffffff",
          menu_font_color: "2e3639",
          menu_font_color_active: "1b9db3",
          menu_font_color_hover: "f07f1b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "#6b7385"
            },
            cart_value_color: "000000"
          }
        },
        btn_group: {
          btn1_text: "Login / Register",
          btn1_bg_color: "046A38",
          btn1_color: "ffffff",
          btn1_border_color: "046A38",
          btn1_is_enable: true,
          btn2_text: "Quick Order",
          btn2_color: "ffffff",
          btn2_bg_color: "000",
          btn2_border_color: "#192335",
          btn2_is_enable: true
        }
      },
      section_1: {
        is_section_enable: true,
        banner_type: "image",
        banner: "/assets/home_page_single_search.jpg",
        banner_two: "/assets/img/home/heroimg.png",
        sub_heading_top: "24/7 Dry Cleaning & Laundry Service",
        title: "One Solution for all your Washing Needs",
        paragraph: "Select your time & location for the service",
        btn_group: {
          btn1: {
            text: "Order now",
            bg_color: "#046A38",
            color: "ffffff",
            placeholder: "Enter Postcode"
          }
        }
      },
      section_2: {
        is_section_enable: true,
        title: "Trust the Experts",
        paragraph: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        first_card_image: "/assets/img/home/trustimg1.jpg",
        image_title: "Why 1000+ customers trust Us?",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/icons/priorityicon1.png",
              collection_title: "Top Quality",
              collection_paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing"
            },
            {
              _id: 2,
              icon: "/assets/icons/priorityicon4.png",
              collection_title: "Professionalism",
              collection_paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing"
            },
            {
              _id: 3,
              icon: "/assets/icons/priorityicon5.png",
              collection_title: "Convenience",
              collection_paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing"
            },
            {
              _id: 4,
              icon: "/assets/icons/priorityicon6.png",
              collection_title: "Cleanliness",
              collection_paragraph: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing"
            }
          ]
        }
      },
      section_3: {
        is_section_enable: true,
        title: "5 star Rated by customers in the UK in categories of dry cleaning",
        banner: "/assets/img/home/review-bg.jpg"
      },
      section_4: {
        is_section_enable: true,
        banner: "/assets/home/trustimg2.jpg",
        title: "About We",
        paragraph_one: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock,",
        paragraph_two: "A Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
        option_one: "Free same day collection",
        option_two: "Dedicated 24/7 support",
        option_three: "Standard wash for just Â£14",
        btn_group: {
          btn1: {
            text: "Know More",
            bg_color: "#046A38",
            color: "ffffff",
            is_enable: true
          }
        }
      },
      section_5: {
        is_section_enable: true,
        title: "How We Works",
        paragraph: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        card_section: {
          collection: [
            {
              _id: 1,
              icon: "/assets/home/howwe1.jpg",
              card_title: "Online Order",
              card_paragraph: "Use our friendly and easy access website or app to place your laundry order. choose a suitable pickup location and time."
            },
            {
              _id: 2,
              icon: "/assets/home/howwe2.jpg",
              card_title: "Transport",
              card_paragraph: "Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities."
            },
            {
              _id: 3,
              icon: "/assets/home/howwe3.jpg",
              card_title: "Dry Cleaning",
              card_paragraph: "Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities."
            },
            {
              _id: 4,
              icon: "/assets/home/howwe4.jpg",
              card_title: "Door Delivery",
              card_paragraph: "Dry and clean clothes were carefully bagged and delivered to your doorstep."
            }
          ]
        }
      },
      section_6: {
        is_section_enable: true,
        title: "service for Business & Personal",
        paragraph: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        first_card_image: "/assets/home/trustimg3.png",
        sub_title: "For Business",
        sub_title_two: "For Personal",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_list: "Restaurants"
            },
            {
              _id: 2,
              collection_list: "Spa"
            },
            {
              _id: 3,
              collection_list: "Hotels"
            },
            {
              _id: 4,
              collection_list: "Gym"
            },
            {
              _id: 5,
              collection_list: "Factory"
            },
            {
              _id: 6,
              collection_list: "Malls"
            },
            {
              _id: 7,
              collection_list: "Public sectors"
            },
            {
              _id: 8,
              collection_list: "Hospitals"
            }
          ]
        },
        card_section_two: {
          collection: [
            {
              _id: 1,
              collection_list: "Home Stay"
            },
            {
              _id: 2,
              collection_list: "Local Living"
            },
            {
              _id: 3,
              collection_list: "Hostel"
            },
            {
              _id: 4,
              collection_list: "Cottage"
            },
            {
              _id: 5,
              collection_list: "PG Stay"
            }
          ]
        }
      },
      section_7: {
        is_section_enable: true,
        title: "Our Product Category",
        paragraph: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC"
      },
      section_8: {
        is_section_enable: true,
        part_one: {
          title: "How we Prioritize",
          sub_title: "Step we follow for Health & Safety Precaution",
          section_image: "/assets/home/trustimg2.jpg",
          paragraph: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old",
          card_section: {
            collection: [
              {
                _id: 1,
                collection_title: "Contactless deliveries",
                collection_paragraph: "For our customers safety, we enabled the feature to allow customers to conduct contactless pick up & deliveries."
              },
              {
                _id: 2,
                collection_title: "Instant updates",
                collection_paragraph: "Weâ€™ll keep you posted throughout the process with texts/emails when driver is on their way and order has been cleaned."
              },
              {
                _id: 3,
                collection_title: "Monitoring the situation",
                collection_paragraph: "Weâ€™re keeping up to date with all developments from government to ensure the safety of our customers and staff."
              }
            ]
          }
        },
        part_two: {
          bg_color: "046A38",
          text_color: "ffffff",
          title: "We use Eco-friendly Laundry Detergent Sheets.",
          paragraph: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin",
          card_section: {
            collection: [
              {
                _id: 1,
                collection_title: "Vegan",
                collection_paragraph: "Our vegan-friendly laundry detergent is free from any animal product."
              },
              {
                _id: 2,
                collection_title: "Cruelty Free",
                collection_paragraph: "We never test on animals â€“ so our Laundry Leaves will always be cruelty-free."
              },
              {
                _id: 3,
                collection_title: "Eco-Friendly",
                collection_paragraph: "Free from any harmful chemicals or pollutants and are devoid of harmful toxins."
              }
            ]
          }
        }
      },
      section_9: {
        is_section_enable: true,
        title: "Frequently Asked Question",
        description: " Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        card_image: "/assets/home/trustimg4.jpg",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "Do you have a re-cleaning Policy?",
              isEnabled: true,
              collection_paragraph: "Although we have quality control where we check each garment before we pack, if we are unable to remove stains we usually inform customers before. If something slips through and you are not completely satisfied with the quality of our dry cleaning, we will re-clean your item free of charge, as long as itâ€™s within 24 hours."
            },
            {
              _id: 2,
              collection_title: "Do you have policy grantees?",
              isEnabled: true,
              collection_paragraph: "Yes"
            }
          ]
        }
      },
      section_10: {
        is_section_enable: true,
        banner: "howwe1",
        title: "Client Testimonials",
        paragraph: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        review_card: [
          {
            _id: 1,
            reviewer_pic: "/assets/home/testimonialuser1.jpg",
            reviewer_name: "Ariyan Rusov",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me."
          },
          {
            _id: 2,
            reviewer_pic: "/assets/home/testimonialuser2.jpg",
            reviewer_name: "Darren Valdez",
            rating: "4",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me."
          },
          {
            _id: 3,
            reviewer_pic: "/assets/home/testimonialuser3.jpg",
            reviewer_name: "Elinor Dunn",
            rating: "5",
            review_highlight: "Finibus mauris. Aliquam consectetur",
            review: "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me."
          }
        ]
      },
      section_11: {
        is_section_enable: true,
        title: "Just a Click Away",
        paragraph: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
        check_list: [
          {
            _id: 1,
            icons: "/assets/icons/priorityicon14.png",
            title: "Cashless payments"
          },
          {
            _id: 2,
            icons: "/assets/icons/priorityicon15.png",
            title: "Delivery in less then 24 hours"
          },
          {
            _id: 3,
            icons: "/assets/icons/priorityicon16.png",
            title: "Standard wash for just Â£14"
          },
          {
            _id: 4,
            icons: "/assets/icons/priorityicon17.png",
            title: "In-app live chat support"
          }
        ],
        banner: "/assets/home/just_clickaway.png"
      },
      section_12: {
        is_section_enable: true,
        title: "Blogs & Events",
        description: " Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC"
      },
      footer: {
        bg_color: "046A38",
        section_paragraph_color: "c2c2c2",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        social_media: {
          facebook: {
            is_enable: true,
            facebook_link: "facebook.com"
          },
          instagram: {
            is_enable: true,
            instagram_link: "instagram.com"
          },
          whatsapp: {
            is_enable: true,
            whatsapp_link: "whatsapp.com"
          },
          linkedin: {
            is_enable: true,
            linkedin_link: "linkedin.com"
          },
          youtube: {
            is_enable: true,
            youtube_link: "youtube.com"
          },
          twitter: {
            is_enable: true,
            twitter_link: "twitter.com"
          }
        }
      }
    }
  },
  theme_5: {
    home: {
      header: {
        is_section_enable: "on",
        primary_color: "2fa0ea",
        secondary_color: "efc10b",
        nav: {
          bg_color: "ffffff",
          menu_font_color: "000000",
          menu_font_color_active: "efc10b",
          menu_font_color_hover: "efc10b",
          cart_section: {
            is_enable: true,
            bg_color: "ffffff",
            cart_icon_color: "ffffff",
            cart_icon_bg_color: "#f07f1b",
            cart_label_section: {
              label: "items : ",
              color: "000000",
              bg_color: "ffffff",
              menu_font_color_hover: "efc10b",
              menu_font_color_active: "efc10b",
              btn1_bg_color: "efc10b",
              btn2_bg_color: "efc10b",
              btn1_border_color: "efc10b",
              btn2_border_color: "efc10b",
              btn3_bg_color: "efc10b",
              btn3_border_color: "efc10b",
              menu_font_color: "000000",
              btn1_color: "000000",
              btn2_color: "000000",
              btn3_color: "000000",
              cart_value_color: "ffffff"
            },
            cart_value_color: "ffffff",
            menu_font_color_hover: "efc10b",
            menu_font_color_active: "efc10b",
            btn1_bg_color: "efc10b",
            btn2_bg_color: "efc10b",
            btn1_border_color: "efc10b",
            btn2_border_color: "efc10b",
            btn3_bg_color: "efc10b",
            btn3_border_color: "efc10b",
            menu_font_color: "000000",
            btn1_color: "000000",
            btn2_color: "000000",
            btn3_color: "000000",
            color: "000000"
          },
          btn1_bg_color: "efc10b",
          btn2_bg_color: "efc10b",
          btn1_border_color: "efc10b",
          btn2_border_color: "efc10b",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          btn1_color: "000000",
          btn2_color: "000000",
          btn3_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        btn_group: {
          btn1_text: "Log in",
          btn1_bg_color: "efc10b",
          btn1_color: "000000",
          btn1_border_color: "efc10b",
          btn1_is_enable: true,
          btn2_text: "Sign up",
          btn2_bg_color: "efc10b",
          btn2_color: "000000",
          btn2_border_color: "efc10b",
          btn2_is_enable: true,
          btn3_text: "Quick Order",
          btn3_color: "000000",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          btn3_is_enable: true,
          bg_color: "ffffff",
          menu_font_color_hover: "efc10b",
          menu_font_color_active: "efc10b",
          menu_font_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        bg_color: "ffffff",
        contact: {
          bg_color: "ffffff",
          menu_font_color_hover: "efc10b",
          menu_font_color_active: "efc10b",
          btn1_bg_color: "efc10b",
          btn2_bg_color: "efc10b",
          btn1_border_color: "efc10b",
          btn2_border_color: "efc10b",
          btn3_bg_color: "efc10b",
          btn3_border_color: "efc10b",
          menu_font_color: "000000",
          btn1_color: "000000",
          btn2_color: "000000",
          btn3_color: "000000",
          cart_value_color: "ffffff",
          color: "000000"
        },
        menu_font_color_hover: "efc10b",
        menu_font_color_active: "efc10b",
        btn1_bg_color: "efc10b",
        btn2_bg_color: "efc10b",
        btn1_border_color: "efc10b",
        btn2_border_color: "efc10b",
        btn3_bg_color: "efc10b",
        btn3_border_color: "efc10b",
        menu_font_color: "000000",
        btn1_color: "000000",
        btn2_color: "000000",
        btn3_color: "000000",
        cart_value_color: "ffffff",
        color: "000000"
      },
      sections: [
        {
          _id: 1,
          is_section_enable: true,
          icon: "/assets/section-five/section_1.png"
        },
        {
          _id: 2,
          is_section_enable: true,
          icon: "/assets/section-five/section_2.png"
        },
        {
          _id: 3,
          is_section_enable: true,
          icon: "/assets/section-five/section_3.png"
        },
        {
          _id: 4,
          is_section_enable: true,
          icon: "/assets/section-five/section_4.png"
        },
        {
          _id: 5,
          is_section_enable: false,
          icon: "/assets/section-five/section_5.png"
        },
        {
          _id: 6,
          is_section_enable: true,
          icon: "/assets/section-five/section_6.png"
        },
        {
          _id: 7,
          is_section_enable: true,
          icon: "/assets/section-five/section_7.png"
        },
        {
          _id: 8,
          is_section_enable: false,
          icon: "/assets/section-five/section_8.png"
        }
      ],
      section_1: {
        is_section_enable: true,
        banner_type: "image",
        banner: "/assets/home_page_single_search.jpg",
        banner_two: "https://api.bestatservices.com/storage/compress_images/65c64bab94fb1.jpeg",
        title: "Welcome to BestatServices",
        paragraph: "Unveil an array of innovative white label software solutions crafted to cater to a diverse range of services, spanning across laundry, cleaning, restaurants, salons, nail care, and barber shops.",
        btn_group: {
          btn1: {
            text: "Search",
            bg_color: "efc10b",
            color: "ffffff",
            placeholder: "Enter Postcode",
            paragraph: "Unveil an array of innovative white label software solutions crafted to cater to a diverse range of services, spanning across laundry, cleaning, restaurants, salons, nail care, and barber shops.",
            title: "Welcome to BestatServices"
          }
        },
        bg_color: "efc10b"
      },
      section_4: {
        is_section_enable: true,
        banner: "/assets/img/offer/offer-vector-bg.png",
        offer_tag: "/assets/img/offer/offer-tag.png",
        picture: "/assets/img/offer/offer-wash-img.png",
        paragraph_one: "* Offer applied only to Registered Users (On Selected Shops)"
      },
      section_5: {
        is_section_enable: true,
        banner: "/assets/img/wash-fold-bg.jpg",
        banner_two: "/assets/img/home/heroimg.png",
        title: "WASH & FOLD",
        wash_title: [
          {
            _id: 1,
            wash_list: "Washed under 30 degrees"
          },
          {
            _id: 2,
            wash_list: "Dried at medium heat"
          },
          {
            _id: 3,
            wash_list: "Customerâ€™s responsibility to make sure all items are washable"
          },
          {
            _id: 4,
            wash_list: "Towels and beddings are excluded and will be charged separately."
          }
        ]
      },
      section_6: {
        is_section_enable: true,
        title: "ABOUT BESTATSERVICES",
        sub_heading: "We believe in innovation, fueling success at every step",
        section_image: "https://api.bestatservices.com/storage/compress_images/65c64ad629988.png",
        background_image_color: "2fa0ea",
        paragraph: "It is one of zest and competence, which drives us to create extraordinary solutions adapted to your specific requirements. We want to be your valued partner on the road to success by committing to excellence.",
        card_section: {
          collection: [
            {
              _id: 1,
              collection_title: "Cost Effective",
              collection_image: "/assets/img/about-section/about-icon-01.png",
              isEnabled: true,
              collection_paragraph: "BestatServices provides quality service with reasonable prices to all."
            },
            {
              _id: 2,
              collection_title: "Ease to Use",
              collection_image: "/assets/img/about-section/about-icon-02.png",
              isEnabled: true,
              collection_paragraph: "User friendly website, Book orders with 1 step."
            },
            {
              _id: 3,
              collection_title: "Faster Service",
              collection_image: "/assets/img/about-section/about-icon-03.png",
              isEnabled: true,
              collection_paragraph: "Our team will complete the Service within 48 hours."
            },
            {
              _id: 4,
              collection_title: "Quality of Service",
              collection_image: "/assets/img/about-section/about-icon-04.png",
              isEnabled: true,
              collection_paragraph: "Our promise is to provide an exceptional service to you."
            }
          ],
          _id: 4,
          collection_title: "Quality of Service",
          collection_image: "/assets/img/about-section/about-icon-04.png",
          isEnabled: true,
          collection_paragraph: "Our promise is to provide a exceptional service to you."
        },
        collection_paragraph: "Our promise is to provide an exceptional service to you.",
        collection_title: "Faster Service"
      },
      section_7: {
        is_section_enable: true,
        title: "Ease to Made Services",
        paragraph1: "BestatServices is a trusted white-label SaaS Software provider for businesses and agencies in this digital age.",
        banner: "https://api.bestatservices.com/storage/compress_images/65c64e69e0a75.png",
        paragraph2: "Our product is compatible with desktops, laptops, Macs, iOS, and Android devices",
        paragraph3: "Keep clients updated on their request status via email and SMS.",
        paragraph4: "Generate invoices for customers and business accounts by sending them printable PDFs or via email.",
        card_section: [
          {
            _id: 1,
            text: "Search for your postal code"
          },
          {
            _id: 2,
            text: "Select a convenient shop"
          },
          {
            _id: 3,
            text: "Add required services in your cart"
          },
          {
            _id: 4,
            text: "Choose a convenient date and time slot"
          },
          {
            text: "Place Order"
          }
        ],
        text: "Place Order"
      },
      section_8: {
        is_section_enable: true,
        banner: "/assets/home/iPhone13.png",
        bg_color: "#FBF2FF",
        placeholder: "Eg:-  +4407816545718",
        banner_two: "/assets/img/home/heroimg.png",
        title: "Download the app",
        paragraph: "With our award-winning app, your laundry and dry cleaning needs are just a tap away",
        mobile_link_section: {
          is_enable: true,
          android_link: "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
          ios_link: "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8"
        }
      },
      footer: {
        custome_banner_design: {
          is_enable: true
        },
        bg_color: "2fa0ea",
        section_heading_color: "ffffff",
        section_paragraph_color: "c2c2c2",
        site_title_en: "Â© Best at Services 2024. All rights reserved. ",
        footer_section: {
          needhelp_section: {
            is_enable: true,
            text: "NEED HELP?"
          },
          policy_section: {
            is_enable: true,
            text: "OUR POLICY"
          },
          quickconnect_section: {
            is_enable: true,
            text: "QUICK CONNECT"
          },
          callnow_for_Services_section: {
            is_enable: true,
            mbl_no: {
              label: "Phone",
              value: "+447438823475"
            },
            email: {
              label: "Email",
              value: "sales@bestatservices.com"
            }
          },
          social_media: {
            facebook: {
              is_enable: true,
              facebook_link: "facebook.com"
            },
            instagram: {
              is_enable: true,
              instagram_link: "instagram.com"
            },
            whatsapp: {
              is_enable: false,
              whatsapp_link: "whatsapp.com"
            },
            linkedin: {
              is_enable: false,
              linkedin_link: "linkedin.com"
            },
            youtube: {
              is_enable: false,
              youtube_link: "youtube.com"
            },
            twitter: {
              is_enable: false,
              twitter_link: "twitter.com"
            }
          },
          mobile_link_section: {
            is_enable: true,
            android_link: "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
            ios_link: "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
            bg_color: "2fa0ea",
            "email.value": "sales@bestatservices.com",
            "mbl_no.value": "+447438823475",
            site_link: "Â© Best at Services 2024. All rights reserved. "
          }
        },
        ios_link: "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
        android_link: "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
        "email.value": "sales@bestatservices.com",
        "mbl_no.value": "+447438823475",
        site_link: "Â© Best at Services 2024. All rights reserved. "
      }
    }
  },
};
