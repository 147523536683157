import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {threading} from "../../assets/img";

function Threading () {

return (
    <>
        <section className="rbpsec">
            <div className="container">                
                <div className="row justify-content-center">                    
                    <div className="col-md-5">
                        <div className="rbptable">
                            <h3>Threading</h3>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>Eyebrow</td>
                                        <td></td>
                                        <td className="text-end w-cus1">£3.50</td>
                                    </tr>
                                    <tr>
                                        <td>Upperlip</td>
                                        <td></td>
                                        <td className="text-end w-cus1">£2.50</td>
                                    </tr>
                                    <tr>
                                        <td>Chin</td>
                                        <td className="rbpstext">From</td>
                                        <td className="text-end w-cus1">£3.00</td>
                                    </tr>
                                    <tr>
                                        <td>Forehead</td>
                                        <td className="rbpstext">From</td>
                                        <td className="text-end w-cus1">£2.00</td>
                                    </tr>
                                    <tr>
                                        <td>Sides</td>
                                        <td className="rbpstext">From</td>
                                        <td className="text-end w-cus1">£4.00</td>
                                    </tr>
                                    <tr>
                                        <td>Full face (inc Eyebrows)</td>
                                        <td className="rbpstext">From</td>
                                        <td className="text-end w-cus1">£15.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="rbpimg">
                            <img src={threading} alt="threading" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
)
}
export default Threading;