import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkShopListFlow } from "../../../utility";

export const SET_SETTINGS = "SET_SETTINGS";

export const setSettingsAll = (payload: any) => ({
  type: SET_SETTINGS,
  payload,
});

function FooterTwoShop() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;
  const [areaCovered, setAreaCovered] = useState<any | null>({});
  const [settings, setSettings] = useState<any>({});
  const jsonData: any = useSelector<any>((state) => state?.shopHomeJsonList);

  const base_url = process.env.REACT_APP_BACKEND_URL;

  const settingsData: any = useSelector<any>((state) => state?.settings);

  const dateObj = new Date();
  const year = dateObj.getUTCFullYear();

  // useEffect(() => {
  //   if (Object.keys(settings).length === 0 && settingsData == undefined) {
  //     axios({
  //       method: "get",
  //       url: `${base_url}/api/settings`,
  //       headers: {
  //         Accept: "application/json",
  //         "Access-Control-Allow-Methods": "GET, POST",
  //       },
  //     })
  //       .then((response) => {
  //         if (response.status != 401) {
  //           setSettings(response.data.data);
  //           dispatch(setSettingsAll(response.data.data));
  //         }
  //       })
  //       .catch(function (error) {});
  //   }
  // }, []);

  const navigateToAreacovered = () => {
    if (checkShopListFlow()) {
      const shopName: any = localStorage.getItem("currentShopName");
      if (shopName) {
        const finalResult = shopName
          .trim()
          .replace(/\s(.)/g, (match: any) => match.toUpperCase())
          .replace(/\s/g, "")
          .replace(/^(.)/, (match: any) => match.toLowerCase());

        navigate(`/shop/${finalResult}/areaCovered`);
      } else {
        navigate("/areaCovered");
      }
    } else {
      navigate("/areaCovered");
    }
  };

  const navigateToAboutUs = () => {
    if (checkShopListFlow()) {
      const shopName: any = localStorage.getItem("currentShopName");
      if (shopName) {
        const finalResult = shopName
          .trim()
          .replace(/\s(.)/g, (match: any) => match.toUpperCase())
          .replace(/\s/g, "")
          .replace(/^(.)/, (match: any) => match.toLowerCase());

        navigate(`/shop/${finalResult}/aboutUs`);
      } else {
        navigate("/aboutUs");
      }
    } else {
      navigate("/aboutUs");
    }
  };


  return (
    <div>
      {jsonData && (
        <style type="text/css">
          {`
            :root {
              --footer-background: #${jsonData?.theme_2?.home?.footer?.bg_color};
          }
          .footer {
            background: var(--footer-background) url(../img/home/footer-bg-temp-2.png) no-repeat;
            padding-top: 40px;
            color: #fff;
        }
          `}
        </style>
      )}
      <footer className="sh2-footer">
        <div className="container">
          <div className="row sh2-footer-sectiontwo">
            <div className="col-md-12">
              <div className="gr-border">
                <div className="sh2-social-links gr-border-grid">
                  <div className="col-md sh2-footer-social text-center">
                    <ul className="list-inline">
                      {jsonData?.theme_2?.home?.footer?.footer_section
                        ?.social_media?.facebook?.is_enable && (
                        <li>
                          <a
                            href={
                              jsonData?.theme_2?.home?.footer?.footer_section
                                ?.social_media?.facebook?.facebook_link
                            }
                            target="_blank" rel="noreferrer"
                            style={{
                              color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                            }}
                          >
                            <i className="fab fa-facebook"></i>
                            Facebook
                          </a>
                        </li>
                      )}
                      {jsonData?.theme_2?.home?.footer?.footer_section
                        ?.social_media?.instagram?.is_enable && (
                        <li>
                          <a
                            href={
                              jsonData?.theme_2?.home?.footer?.footer_section
                                ?.social_media?.instagram?.instagram_link
                            }
                            target="_blank" rel="noreferrer"
                            style={{
                              color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                            }}
                          >
                            <i className="fab fa-instagram"></i>
                            Instagram
                          </a>
                        </li>
                      )}
                      {jsonData?.theme_2?.home?.footer?.footer_section
                        ?.social_media?.twitter?.is_enable && (
                        <li>
                          <a
                            href={
                              jsonData?.theme_2?.home?.footer?.footer_section
                                ?.social_media?.twitter?.twitter_link
                            }
                            target="_blank" rel="noreferrer"
                            style={{
                              color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                            }}
                          >
                            <i className="fab fa-twitter"></i>
                            Twitter
                          </a>
                        </li>
                      )}
                      {jsonData?.theme_2?.home?.footer?.footer_section
                        ?.social_media?.whatsapp?.is_enable && (
                        <li>
                          <a
                            href={
                              jsonData?.theme_2?.home?.footer?.footer_section
                                ?.social_media?.whatsapp?.whatsapp_link
                            }
                            target="_blank" rel="noreferrer"
                            style={{
                              color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                            }}
                          >
                            <i className="fab fa-whatsapp"></i>
                            Whatsapp
                          </a>
                        </li>
                      )}
                      {jsonData?.theme_2?.home?.footer?.footer_section
                        ?.social_media?.linkedin?.is_enable && (
                        <li>
                          <a
                            href={
                              jsonData?.theme_2?.home?.footer?.footer_section
                                ?.social_media?.linkedin?.linkedin_link
                            }
                            target="_blank" rel="noreferrer"
                            style={{
                              color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                            }}
                          >
                            <i className="fab fa-linkedin"></i>
                            Linkedin
                          </a>
                        </li>
                      )}
                      {jsonData?.theme_2?.home?.footer?.footer_section
                        ?.social_media?.youtube?.is_enable && (
                        <li>
                          <a
                            href={
                              jsonData?.theme_2?.home?.footer?.footer_section
                                ?.social_media?.youtube?.youtube_link
                            }
                            target="_blank" rel="noreferrer"
                            style={{
                              color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                            }}
                          >
                            <i className="fab fa-youtube"></i>
                            Youtube
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row sh2-footer-sectionthree">
            <div className="col-md col-sm-12">
              <h4 style={{
          color: `#${jsonData?.theme_2?.home?.footer?.section_heading_color}`,
        }}>Explore</h4>
              <ul>
                <li>
                  <a
                    href="javascript:void(0)"
                    data-bs-toggle="modal"
                    data-bs-target="#videomodal"
                    style={{
                      color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                    }}
                  >
                    How it works
                  </a>
                </li>
                <li>
                  <a href="/#services-section" style={{
          color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
        }}>Services</a>
                </li>
              </ul>
            </div>
            <div className="col-md col-sm-12">
              <h4 style={{
          color: `#${jsonData?.theme_2?.home?.footer?.section_heading_color}`,
        }}>Our company</h4>
              <ul>
                <li>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      navigateToAboutUs();
                    }}
                    style={{
                      color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                    }}
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a target="_blank" href={blogUrl} rel="noreferrer" style={{
          color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
        }}>
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      navigateToAreacovered();
                    }}
                    style={{
                      color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                    }}
                  >
                    Locations
                  </a>
                </li>
                <li>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/reviews");
                    }}
                    style={{
                      color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                    }}
                  >
                    Reviews
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row  sh2-footer-sectionfour">
            <div className="col-md-6 col-xs-12">
              <p className="sh2-footer-last" style={{
          color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
        }}>
                {Object.keys(settings).length > 0 &&
                  settings?.WebsiteSettings?.site_title_en}{" "}
                © {year}. All rights reserved. | Powered by &nbsp;
                <a href="http://www.bestatservices.com" target="blank">
                  www.bestatservices.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default FooterTwoShop;
