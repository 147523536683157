import React, { useEffect, useState } from "react";
import {
  fetchCroppedImage,
  setShopHomeCms,
  saveThemeJsonData,
} from "../../../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { ColorPicker } from "primereact/colorpicker";

const SectionEightThemeTwoEdit = () => {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  const [activeTab, setActiveTab] = useState(0);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [imageType, setImageType] = useState("");

  const handleHeaderChange = (e: any) => {
    const { value, name } = e.target;   
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_2: {
        home: {
          ...prevJsonData.theme_2.home,
          section_8: {
            ...prevJsonData.theme_2.home.section_8,
            [name]: value,
          },
        },
      },
    }));
  };
  const saveJsonData = () => {
    dispatch(setShopHomeCms(editData));
    dispatch(saveThemeJsonData(editData));
  };

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);
  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);
  useEffect(() => {
    setActiveTab(0);
  }, []);
  // HTML
  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section Eight Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Section Title</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="title_1"
                        value={editData?.theme_2?.home?.section_8?.title_1}
                        maxLength={250}
                        onChange={handleHeaderChange}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Section Sub Title</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="title_2"
                        value={editData?.theme_2?.home?.section_8?.title_2}
                        maxLength={250}
                        onChange={handleHeaderChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 1 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h2color"
                        value={
                          editData?.theme_2?.home?.section_8?.h2color
                        }
                      />
                      <ColorPicker
                        name="h2color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.theme_2?.home?.section_8?.h2color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 2 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h3color"
                        value={
                          editData?.theme_2?.home?.section_8?.h3color
                        }
                      />
                      <ColorPicker
                        name="h3color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.theme_2?.home?.section_8?.h3color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">paragraph Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="paracolor"
                        value={
                          editData?.theme_2?.home?.section_8?.paracolor
                        }
                      />
                      <ColorPicker
                        name="paracolor"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.theme_2?.home?.section_8?.paracolor
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={saveJsonData}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionEightThemeTwoEdit;
