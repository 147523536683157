import React from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import SectionTwoBannerThemeTwo from "./section-two";
import SectionOneThemeTwo from "./section-one";
import SectionThreeThemeTwo from "./section-three";
import SectionFourThemeTwo from "./section-four";
import SectionFiveThemeTwo from "./section-five";
import SectionSixThemeTwo from "./section-six";
import SectionSevenThemeTwo from "./section-seven";
import SectionEightThemeTwo from "./section-eight";
import SectionNineThemeTwo from "./section-nine";
import SectionOffer from "../theme-four/section-offer";
import GoogleReviews from "../../googleReview";
import "./sh2.css";
import FooterTwoShop from "./footerTwo";
import HeaderTwoShop from "./headerTwo";

function ThemeTwo() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);

  const checkIsSectionEnabled = (index: any) => {
    // if (settingsData?.template == 2) {
    return jsonData?.theme_2?.home?.sections[index]?.is_section_enable;
    // }
  };

  return (
    <>
      {/* <HeaderTwoShop /> */}
      {checkIsSectionEnabled(0) && <SectionOneThemeTwo />}
      {checkIsSectionEnabled(1) && <SectionTwoBannerThemeTwo />}
      {/* <SectionOffer /> */}
      <GoogleReviews />
      {checkIsSectionEnabled(2) && <SectionThreeThemeTwo />}
      {/* <SectionFourThemeTwo /> */}
      {checkIsSectionEnabled(4) && <SectionFiveThemeTwo />}
      {checkIsSectionEnabled(5) && <SectionSixThemeTwo />}
      {checkIsSectionEnabled(6) && <SectionSevenThemeTwo />}
      {checkIsSectionEnabled(7) && <SectionEightThemeTwo />}
      {checkIsSectionEnabled(8) && <SectionNineThemeTwo />}
      {/* <FooterTwoShop /> */}
    </>
  );
}

export default ThemeTwo;
