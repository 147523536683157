import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeJsonList, saveThemeJsonData } from "../../../redux/Actions";

function SectionSevenBannerThemeFour() {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const data: any = useSelector<any>((state) => state.homeJsonList);

  useEffect(() => {
    setEditData(data);
  }, [data]);

  const saveJsonDataToFile = () => {
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        ...prevJsonData.theme_4,
        home: {
          ...prevJsonData.theme_4.home,
          section_7: {
            ...prevJsonData.theme_4.home.section_7,
          },
        },
      },
    }));
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };
  const handleChange = (e: any) => {
    const { value, name, type, checked } = e.target;
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        ...prevJsonData.theme_4,
        home: {
          ...prevJsonData.theme_4.home,
          section_7: {
            ...prevJsonData.theme_4.home.section_7,
            [name]: value,
          },
        },
      },
    }));
  };
  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);
  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section7 Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 250 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={editData?.theme_4?.home?.section_7?.title}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">paragraph</label>
                    <input
                      type="text"
                      className="form-control"
                      name="paragraph"
                      value={editData?.theme_4?.home.section_7?.paragraph}
                      onChange={handleChange}
                      maxLength={1200}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionSevenBannerThemeFour;
