import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../../redux/Actions";
import { setValue } from "../../../utility";
import { fetchGetShopByPinCode } from "../../../redux/Actions/checkoutPageActions";
import DownloadApp from "../../downloadApp";
import {
  partner1,
  partner2,
  partner3,
  partner4,
  partner5,
  eco,
  priorityicon14,
  priorityicon15,
  priorityicon16,
  priorityicon17,
  appdownload,
  appstore,
  googleplay,
  testimonialuser1,
  testimonialuser2,
  testimonialuser3,
  testimonialimg1,
  testimonialimg2,
  testimonialimg3,
  trustimg,
  trustimg2,
  trustimg3,
  trustimg4,
  howwe1,
  howwe2,
  howwe3,
  howwe4,
} from "../../../assets/img";
import { Accordion } from "react-bootstrap";
import { fetchFaqData } from "../../../redux/Actions/policyActions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import axios from "axios";
// import BookNow from './index';

// const postalCode = [
//     { content: "GU111BH" },
//     { content: "GU111KD" },
//     { content: "GU111MH" },
// ];

function SectionTwelveThemeFour() {
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const navigate = useNavigate();
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);

  const base_url = process.env.REACT_APP_BACKEND_URL;

  // const base_url = "https://revamp.dreamguystech.com"

  // const fetchData = async () => {
  //     const base_url = "https://revamp.dreamguystech.com";
  //     try {
  //         const bodyFormData = new FormData();
  //         bodyFormData.append('keyword', 'Gu11');
  //         // res.header('Access-Control-Allow-Methods', 'GET, POST');
  //         const response = await axios({
  //             method: "post",
  //             url: `${base_url}/api/get_postal_codes`,
  //             data: bodyFormData,
  //             headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST' },
  //         })
  //         // const response = await axios.post(`${base_url}/api/get_postal_codes`,{keyword:"Gu11"});
  //         // const result = await response.then(response => response);

  //         if (response) {
  //             setPostalCodeList(response.data.data[0])
  //
  //         }
  //     } catch (error) {
  //
  //     }
  // }

  const settingsData: any = useSelector<any>((state) => state?.settings);

  const [productList, setProductList] = useState<any | null>({});
  const [blogList, setBlogList] = useState<any | null>({});
  useEffect(() => {
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .get(`${base_url}/api/get_categories?group_id=1`, {
        headers: headers,
      })
      .then((e) => {
        setProductList({ data: e.data });
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });

    axios
      .get(`${base_url}/api/blog_list?`, {
        headers: headers,
      })
      .then((e) => {
        setBlogList(e.data.data);
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });
  }, []);

  const faqData: any = useSelector<any>((state) => state?.faq);
  useEffect(() => {
    dispatch(fetchFaqData());
  }, []);

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
      // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }
  }, [state]);

  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;

  // useEffect(() => {
  //     // fetchData();
  //     dispatch(fetchPostalCodes())
  //     // dispatch(fetchPostalCodes());
  // }, [])

  const handleChange = (e: any) => {
    const result = e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
    // setPostalCodeList(e.target.value);
    const { value } = e.target;
    const updateValue = value.replace(/\s/g, "");
    if (value.length > 0) {
      dispatch(fetchPostalCodes(updateValue));
    }
    setPostalCodeValue(updateValue);
  };
  const onSearch = (searchTerm: any) => {
    // setPostalCodeList(searchTerm);
    setPostalCodeValue(searchTerm);
    setValue("postalCode", searchTerm);
    // dispatch(fetchPostalCodes(searchTerm));
  };
  useEffect(() => {
  }, [jsonData]);
  const handleSectionThreeThemeFour = async () => {
    const isValid = state.postalCodeList.some(
      (item: any) => item.postcode === postalCodeValue.toUpperCase()
    );
    setValue("postalCode", postalCodeValue);
    const results = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(postalCodeValue))
    );

    if (!results) {
      navigate("/areaNotCovered");
      return;
    }
    if (isValid) {
      navigate("/productLists");
    } else {
      navigate("/areaNotCovered");
    }
  };
  return (
    <>
      <section className="sh4-latestnews-section" id="news-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sh4-section-title">
                <h2> {jsonData?.theme_4?.home?.section_12?.title}</h2>
                <p>
                {jsonData?.theme_4?.home?.section_12?.description}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Swiper
                spaceBetween={15}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
                className="home-slider"
              >
                {blogList?.length > 0 && (
                  <SwiperSlide>
                    <div className="sh4-latestnews-card">
                      <div className="sh4-latestnews-img">
                        <img src={testimonialimg1} alt="" />
                        <div className="sh4-latestnews-info">
                          <div className="sh4-latestnews-top">
                            {/* <span className='latestnews-tag'>{blogList[0].category_name}</span> */}
                            {/* <span className='latestnews-addfav rounded-circle'><i className='uil uil-favorite'></i></span> */}
                          </div>
                          <div className="sh4-latestnews-bottom">
                            <div className="sh4-profile-user">
                              {/* <a className='profile-user-img'>
                                                            <img src={blogList[0].grid_sm_img}  className="rounded-circle" />
                                                        </a> */}
                              <div className="sh4-profile-user-info sh4-latestnews-user-info">
                                {/* <h6><a target='_blank' href="">Orlando Waters</a></h6> */}
                                <span className="sh4-latestnews-date">
                                  <i className="uil uil-calendar-alt me-2"></i>
                                  {blogList[0].formatted_date}{" "}
                                  {blogList[0].formatted_month}{" "}
                                  {blogList[0].formatted_year}
                                </span>
                              </div>
                            </div>
                            <h3>
                              <a
                                target="_blank"
                                className="text-white"
                                href={`${blogUrl}/blogg/${blogList[0].id}/${blogList[0].url_slug}`} rel="noreferrer"
                              >
                                {blogList[0].page_title.substring(0, 80)}
                                {blogList[0].page_title.length > 80
                                  ? "..."
                                  : ""}
                              </a>
                            </h3>
                            <p className="text-white">
                              {blogList[0].preview_text.substring(0, 95)}
                              {blogList[0].preview_text.length > 95
                                ? "..."
                                : ""}
                            </p>
                            {/* <div className='latestnews-arlk-count'>
                                                        <span className='arlk-count-tag'><i className='uil uil-heart-sign me-2'></i>45</span>
                                                        <span className='arlk-count-tag'><i className='uil uil-comment-alt me-2'></i>40</span>
                                                        <span className='ms-auto'><i className='uil uil-stopwatch me-2'></i>10 Min To Read</span>
                                                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )}
                {blogList?.length > 1 && (
                  <SwiperSlide>
                    <div className="sh4-latestnews-card">
                      <div className="sh4-latestnews-img">
                        <img src={testimonialimg2} alt="" />
                        <div className="sh4-latestnews-info">
                          <div className="sh4-latestnews-top">
                            {/* <span className='latestnews-tag'>{blogList[1].category_name}</span> */}
                            {/* <span className='latestnews-addfav rounded-circle'><i className='uil uil-favorite'></i></span> */}
                          </div>
                          <div className="sh4-latestnews-bottom">
                            <div className="sh4-profile-user">
                              {/* <a className='profile-user-img'>
                                                            <img src={blogList[1].grid_sm_img}  className="rounded-circle" />
                                                        </a> */}
                              <div className="sh4-profile-user-info sh4-latestnews-user-info">
                                {/* <h6><a target='_blank' href="">Richard Carg</a></h6> */}
                                <span className="sh4-latestnews-date">
                                  <i className="uil uil-calendar-alt me-2"></i>
                                  {blogList[1].formatted_date}{" "}
                                  {blogList[1].formatted_month}{" "}
                                  {blogList[1].formatted_year}
                                </span>
                              </div>
                            </div>
                            <h3>
                              <a
                                target="_blank"
                                className="text-white"
                                href={`${blogUrl}/blogg/${blogList[1].id}/${blogList[1].url_slug}`} rel="noreferrer"
                              >
                                {blogList[1].page_title.substring(0, 80)}
                                {blogList[1].page_title.length > 80
                                  ? "..."
                                  : ""}
                              </a>
                            </h3>
                            <p className="text-white">
                              {blogList[1].preview_text.substring(0, 95)}
                              {blogList[1].preview_text.length > 95
                                ? "..."
                                : ""}
                            </p>
                            {/* <div className='latestnews-arlk-count'>
                                                        <span className='arlk-count-tag'><i className='uil uil-heart-sign me-2'></i>45</span>
                                                        <span className='arlk-count-tag'><i className='uil uil-comment-alt me-2'></i>40</span>
                                                        <span className='ms-auto'><i className='uil uil-stopwatch me-2'></i>10 Min To Read</span>
                                                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )}
                {blogList?.length > 2 && (
                  <SwiperSlide>
                    <div className="sh4-latestnews-card">
                      <div className="sh4-latestnews-img">
                        <img src={testimonialimg3} alt="" />
                        <div className="sh4-latestnews-info">
                          <div className="sh4-latestnews-top">
                            {/* <span className='latestnews-tag'>{blogList[2].category_name}</span> */}
                            {/* <span className='latestnews-addfav rounded-circle'><i className='uil uil-favorite'></i></span> */}
                          </div>
                          <div className="sh4-latestnews-bottom">
                            <div className="sh4-profile-user">
                              {/* <a className='profile-user-img'>
                                                            <img src={blogList[2].grid_sm_img}  className="rounded-circle" />
                                                        </a> */}
                              <div className="sh4-profile-user-info sh4-latestnews-user-info">
                                {/* <h6><a target='_blank' href="">Andrea Masadoni</a></h6> */}
                                <span className="sh4-latestnews-date">
                                  <i className="uil uil-calendar-alt me-2"></i>
                                  {blogList[2].formatted_date}{" "}
                                  {blogList[2].formatted_month}{" "}
                                  {blogList[2].formatted_year}
                                </span>
                              </div>
                            </div>
                            <h3>
                              <a
                                target="_blank"
                                className="text-white"
                                href={`${blogUrl}/blogg/${blogList[2].id}/${blogList[2].url_slug}`} rel="noreferrer"
                              >
                                {blogList[2].page_title.substring(0, 80)}
                                {blogList[2].page_title.length > 80
                                  ? "..."
                                  : ""}
                              </a>
                            </h3>
                            <p className="text-white">
                              {blogList[2].preview_text.substring(0, 95)}
                              {blogList[2].preview_text.length > 95
                                ? "..."
                                : ""}
                            </p>
                            {/* <div className='latestnews-arlk-count'>
                                                        <span className='arlk-count-tag'><i className='uil uil-heart-sign me-2'></i>45</span>
                                                        <span className='arlk-count-tag'><i className='uil uil-comment-alt me-2'></i>40</span>
                                                        <span className='ms-auto'><i className='uil uil-stopwatch me-2'></i>10 Min To Read</span>
                                                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionTwelveThemeFour;
