import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchPostalCodes } from '../../../redux/Actions';
import DeleteAddressPopup from './deleteAddressPopup';
import { ToastContainer, toast } from 'react-toastify';
import DeleteProfilePopup from '../myProfile/deleteProfilePopup';
import { useNavigate } from 'react-router';
import { myprofile2,myorders,myaddress,logout2 } from '../../../assets/img'
import { checkShopListFlow } from '../../../utility';

function NewAddress() {
    const notify = (message: string) => toast(message);

    const [profile, setProfile] = useState({})
    const [deleteChange, setDeleteChange] = useState(false)
    const state: any = useSelector(state => state);
    const dispatch = useDispatch<any>();

    const [postalCodeList, setPostalCodeList] = useState([])
    const [postalCodeValue, setPostalCodeValue] = useState("")
    const [addressDetails, setAddressDetails] = useState("");
    const [addressType, setAddressType] = useState("");
    const [contactName, setContactName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [line1, setAddressLine1] = useState("");
    const [line2, setAddressLine2] = useState("");
    const [line3, setAddressLine3] = useState("");
    const [line4, setAddressLine4] = useState("");
    const [locality, setLocality] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [label, setLabel] = useState("");

    const [mobileError, setMobileError] = useState("");
    const US_PHONE_REGEX = new RegExp(/^(?:(?:(?:00\s?|\+)1\s?|0)7(?:[1345789]\d{2}|624)\s?\d{3}\s?\d{3})$/);

    const rand = Math.random();

    const base_url = process.env.REACT_APP_BACKEND_URL;
    // const base_url = "http://www.bestatlaundry.test";

    const token = localStorage.getItem("token");

    const navigate = useNavigate();
    const handleLogout = (e: any) => {
        e.preventDefault();
        localStorage.clear();
        navigate("/login")
    }

    useEffect(() => {
        axios({
            method: "get",
            url: `${base_url}/api/my_profile`,
            headers: {
                "Accept": "application/json",
                'Access-Control-Allow-Methods': 'GET, POST',
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status != 401) {
                setProfile(response.data.data);
            }
        }).catch(function (error) {
            
        })

        if (state) {
            setPostalCodeList(state.postalCodeList);
        }

    }, [state])

    async function handleAddressSubmit(event: any) {
        event.preventDefault();

        const headers = {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token,
        }

        axios.post(`${base_url}/api/save_address`, { address_type: addressType, address_details: `{"contact_name":"${contactName}","mobile_number":"${mobileNumber}","line_1":"${line1}","line_2":"${line2}","line_3":"${line3}","line_4":"${line4}","locality":"${locality}","city":"${city}","county":"${country}","label":"${label}","pincode":"${postalCodeValue}"}` }, {
            headers: headers
        }).then(e => {
            if (e.data.Response.response_code == "1" && e.data.Response.response_message == "address saved successfully") {
                notify(e.data.Response.response_message)
                navigateToUrl("/myAddress")
                return true
            }
            else {
                notify(e.data.Response.response_message)
                // setGenError(e.data.Response.response_message);
                return false
            }
        })
    }

    function updateAddressDetails() {
        setAddressDetails(`{"contact_name":"${contactName}","mobile_number":"${mobileNumber}","line_1":"${line1}","line_2":"${line2}","line_3":"${line3}","line_4":"${line4}","locality":"${locality}","city":"${city}","county":"${country}","label":"${label}","pincode":"${postalCodeValue}"}`);
    }

    function handleAddressTypeChange(e: any) {
        setAddressType(e.target.value);
        updateAddressDetails();
    }

    const handleChange = (e: any) => {
        // setPostalCodeList(e.target.value);
        const { value } = e.target;
        const updateValue = value.replace(/\s/g,'')
        if (value.length > 0) {
            dispatch(fetchPostalCodes(updateValue));
        }
        setPostalCodeValue(updateValue);
        updateAddressDetails();

    }

    const onSearch = (searchTerm: any) => {
        // setPostalCodeList(searchTerm);
        setPostalCodeValue(searchTerm);
        // dispatch(fetchPostalCodes(searchTerm));
        updateAddressDetails();
    };

    function handleNameChange(e: any) {
        setContactName(e.target.value);
        updateAddressDetails();
    }

    function handleMobileChange(e: any) {
        setMobileNumber(e.target.value);
        updateAddressDetails();
    }

    function handleLine1Change(e: any) {
        setAddressLine1(e.target.value);
        updateAddressDetails();
    }

    function handleLine2Change(e: any) {
        setAddressLine2(e.target.value);
        updateAddressDetails();
    }

    function handleLine3Change(e: any) {
        setAddressLine3(e.target.value);
        updateAddressDetails();
    }

    function handleLine4Change(e: any) {
        setAddressLine4(e.target.value);
        updateAddressDetails();
    }

    function handleLocalityChange(e: any) {
        setLocality(e.target.value);
        updateAddressDetails();
    }

    function handleCityChange(e: any) {
        setCity(e.target.value);
        updateAddressDetails();
    }

    function handleCountryChange(e: any) {
        setCountry(e.target.value);
        updateAddressDetails();
    }

    function handleLabelChange(e: any) {
        setLabel(e.target.value);
        updateAddressDetails();
    }

    const profileData: { [unit: string]: number } = profile;
    const navigateToUrl = (url:string) => {
        if (checkShopListFlow()) {
        const shopName: any = localStorage.getItem("currentShopName");
        if (shopName) {
        const finalResult = shopName
          .trim()
          .replace(/\s(.)/g, (match: any) => match.toLowerCase())
          .replace(/\s/g, "")
          .replace(/^(.)/, (match: any) => match.toLowerCase());
    
        const URL = window.location.href.split("/");
        if (URL.length > 4) {
          navigate(`/shop/${finalResult}${url}`);
        } else {
          navigate(url);
        }
    } else {
        navigate(url);
    }
} else {
    navigate(url);
}
      };
    return (
        <div>
            <section className="breadcrumpset">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumpview">
                                <h2>Manage Address</h2>
                                <ul>
                                    <li>
                                        <a href="/"><i className="fa fa-home" aria-hidden="true"></i> </a>
                                    </li>
                                    <li>
                                        <span> Manage Address</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section-myprofile">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 custom-myprofile">
                            <h1>Manage Address</h1>
                            <span className="float-end"><a href='#' onClick={(e) => {e.preventDefault(); navigateToUrl('/')}}><i className="fa fa-angle-double-left" aria-hidden="true"></i>Back to home</a></span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="user-profile">
                                <div className="user-profile-details">
                                    <span className="db-img">
                                        <a href='#' onClick={(e) => {e.preventDefault(); navigateToUrl('/editProfile')}}><i className="fas fa-pencil-alt" aria-hidden="true"></i></a>
                                    </span>
                                    <img className='user-profile-img' src={`${profileData.web_photo}?bust=${rand}`} />
                                    <h4><small>Hello,</small> {profileData.name}</h4>
                                </div>
                                <div className="orders-account">
                                    <div className="myorders" onClick={() => navigateToUrl('/myProfile')}>
                                        <a href='#' onClick={(e) => {e.preventDefault(); navigateToUrl('/myProfile')}}>
                                            <i className="fas fa-layer-group"></i>
                                            <h4>My Profile</h4>
                                        </a>
                                    </div>
                                    <div className="myorders" onClick={() => navigateToUrl('/myOrders')} >
                                        <a href='#' onClick={(e) => {e.preventDefault(); navigateToUrl('/myOrders')}}>
                                            <i className="fas fa-tag"></i>
                                            <h4>My Orders</h4>
                                        </a>
                                    </div>
                                    <div className="myorders" onClick={() => navigateToUrl('/myAddress')}  >
                                        <a href='#' onClick={(e) => {e.preventDefault(); navigateToUrl('/myAddress')}}>
                                            <i className="fas fa-map-marker-alt"></i>
                                            <h4>My Address</h4>
                                        </a>
                                    </div>
                                    {/* <div className="myorders">
                                        <button className="btn btn-danger remove_option m-sm-1" type="button" data-bs-toggle="modal" data-bs-target="#deleteprofile" data-id="1" onClick={() => setDeleteChange(!deleteChange)}><i className="fa fa-trash"></i></button>
                                        <h4>Delete Profile</h4>
                                    </div> */}
                                    <div className="myorders">
                                        <a href='#' onClick={(e) => { handleLogout(e) }}>
                                            <i className="fas fa-power-off"></i>
                                            <h4>Logout</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                        <div className="col-md-8">
                            <div className="profile-details-right">
                                <div className="row manageaddress">
                                    <div className="col-md-6">
                                        <h5>Add New Address</h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="edit-personal-info">
                                            <form method="POST" acceptCharset="UTF-8" onSubmit={handleAddressSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6 form-group profile-form ">
                                                        <label className="form-label" htmlFor="contact_name">Contact Name<span className="text-danger">*</span></label>
                                                        <input placeholder="Name" className="form-control" id="name" required maxLength={20} name="contact_name" type="text" value={contactName} onChange={handleNameChange} />
                                                    </div>
                                                    <div className="col-md-6 form-group profile-form">
                                                        <label className="form-label" htmlFor="label">Label<span className="text-danger">*</span></label>
                                                        <input placeholder="Home / Office / Others" className="form-control" id="label" required maxLength={30} name="label" type="text" value={label} onChange={handleLabelChange} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 form-group profile-form ">
                                                        <label className="form-label" htmlFor="address_line_1">Address Line1<span className="text-danger">*</span></label>
                                                        <input placeholder="Address Line1" className="form-control" id="address_line_1" required maxLength={30} name="address_line_1" type="text" value={line1} onChange={handleLine1Change} />
                                                    </div>
                                                    <div className="col-md-6 form-group profile-form">
                                                        <label className="form-label" htmlFor="address_line_2">Address Line2<span className="text-danger">*</span></label>
                                                        <input placeholder="Address Line2" className="form-control" id="address_line_2" required maxLength={30} name="address_line_2" type="text" value={line2} onChange={handleLine2Change} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 form-group profile-form">
                                                        <label className="form-label" htmlFor="address_line_3">Address Line3</label>
                                                        <input placeholder="Address Line3" className="form-control" id="address_line_3" name="address_line_3" type="text" value={line3} onChange={handleLine3Change} maxLength={30} />
                                                    </div>
                                                    <div className="col-md-6 form-group profile-form">
                                                        <label className="form-label" htmlFor="address_line_4">Address Line4</label>
                                                        <input placeholder="Address Line4" className="form-control" id="address_line_4" name="address_line_4" type="text" value={line4} onChange={handleLine4Change} maxLength={30} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 form-group profile-form">
                                                        <label className="form-label" htmlFor="location">Location</label>
                                                        <input placeholder="Location" className="form-control" id="location" name="location" type="text" value={locality} onChange={handleLocalityChange} maxLength={30} />
                                                    </div>
                                                    <div className="col-md-6 form-group profile-form">
                                                        <label className="form-label" htmlFor="city">Town/City<span className="text-danger">*</span></label>
                                                        <input placeholder="Town/City" className="form-control" id="city" required maxLength={30} name="city" type="text" value={city} onChange={handleCityChange} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 form-group profile-form ">
                                                        <label className="form-label" htmlFor="county">Country<span className="text-danger">*</span></label>
                                                        <input placeholder="Country" className="form-control" id="county" required maxLength={15} name="county" type="text" value={country} onChange={handleCountryChange} />
                                                    </div>
                                                    <div className="col-md-6 form-group profile-form ">
                                                        <label className="form-label" htmlFor="pincode">Pincode<span className="text-danger">*</span></label>
                                                        <input placeholder="Pincode" className="form-control ui-autocomplete-input" id="pincode" required maxLength={10} name="pincode" type="text" autoComplete="off" value={postalCodeValue} onChange={handleChange} />
                                                        <div className="dropdown">
                                                            {postalCodeList
                                                                .filter((item: any) => {
                                                                    const searchTerm = postalCodeValue.toLowerCase();
                                                                    const mainValue = item.postcode.toLowerCase();

                                                                    return (
                                                                        searchTerm &&
                                                                        mainValue.startsWith(searchTerm) &&
                                                                        mainValue !== searchTerm
                                                                    );
                                                                })
                                                                .slice(0, 10)
                                                                .map((item: any) => (
                                                                    <div
                                                                        onClick={() => onSearch(item.postcode)}
                                                                        className="dropdown-row"
                                                                        key={item.postcode}
                                                                    >
                                                                        {item.postcode}
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 form-group profile-form">
                                                        <label className="form-label" htmlFor="mobile_number">Mobile Number<span className="text-danger">*</span></label>
                                                        <div className="input-mob-no">
                                                            <input placeholder="Ex:- 07123456789" className="form-control" id="mobile_number" required maxLength={15} name="mobile_number" type="text" value={mobileNumber} onChange={handleMobileChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 form-group profile-form ">
                                                        <label className="form-label" htmlFor="address_type">Address Type<span className="text-danger">*</span></label>
                                                        <select className='form-select' name="address_type" id="address_type" value={addressType} onChange={handleAddressTypeChange} required>
                                                            <option value="">---Select---</option>
                                                            <option value="pickup">Pickup</option>
                                                            <option value="delivery">Delivery</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 profile-edit-btn">
                                                        <button type="submit" className="backto-home hover-btn">Save</button>
                                                        <a href='#' onClick={(e) => {e.preventDefault(); navigateToUrl('/myAddress')}} className="btn cancel-btn hover-btn">Cancel</a>
                                                    </div>
                                                </div>
                                                <input required name="postal_code_id" type="hidden" />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {deleteChange && <DeleteProfilePopup close={() => setDeleteChange(false)} />}
            <ToastContainer />
        </div>
    )
}

export default NewAddress