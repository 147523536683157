import React from "react";
import Home from "./sOne";
import SectionBanner from "./banner";

function Rogers() {
    return (
        <>
            <SectionBanner/>
            <Home/>
        </>
    )
}

export default Rogers;