import React from "react";
import {
  testimonialimg1,
  testimonialimg2,
  testimonialimg3,
  testimonialuser1,
  testimonialuser2,
  testimonialuser3,
} from "../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
const SectionEightThemeOne = () => {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state?.homeJsonList);
  
  // HTML
  return (
    <> 
    
      <section className="testimonial-section">
        <div className="testimonial-subtract-top"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="section-title text-start">
                What Our <span>Client</span> Say
              </h2>
            </div>
            {/* <div className='col-md-6 text-end'>
                            <a className='btn primary-btn hover-btn'>View more</a>
                        </div> */}
          </div>
          <div className="row pb-md-5">
            <div className="row justify-content-center">
              {settingsData?.template == 1 && !settingsData?.WebsiteSettings?.site_title_en.includes('Sasian') && (
                <>
                <div className="testi-grid">
                {jsonData?.theme_1?.home?.section_8?.cards.map((item:any)=>(
                    <>
                  <div className="sh1-testimonial-card">
                    <div className="sh1-profile-user">
                      <a className="sh1-profile-user-img">
                        <img src={item.profile} />
                      </a>
                      <div className="sh1-profile-user-info">
                        <h6>
                          <a>{item?.name}</a>
                        </h6>
                        <p>{item?.position}</p>
                      </div>
                    </div>
                    <p>
                    {item?.message}
                    </p>
                  </div>
                  <div className="sh1-testimonial-img">
                    <img src={item.backgroundImage} alt="" />
                    <div className="sh1-testimonial-overlay">
                      <p>
                      {item?.message}
                      </p>
                    </div>
                  </div>   
                  </>
                  ))}
                </div>                
                </>
              )}
              {settingsData?.template == 1 && settingsData?.WebsiteSettings?.site_title_en.includes('Sasian') && (
                <>
                <div className="testi-grid">
                <div className="testimonial-card">
                  <div className="profile-user">
                    <a className="profile-user-letter">
                      J
                    </a>
                    <div className="profile-user-info">
                      <h6>
                        <a>Jenny Race</a>
                      </h6>
                    </div>
                  </div>
                  <p>
                  Excellent service.
Wash, dry & ironed - highest quality.
reliable, trustworthy & friendly.
I would recommend this company without a doubt
                  </p>
                </div>
                <div className="testimonial-img">
                  <img src={testimonialimg1} alt="" />
                  <div className="testimonial-overlay">
                    <h6>CYNTHIA MACFARLANE</h6>
                    <p>
                      I  was so impressed with Sasian!  I found them at short notice and needed an alteration for a dress to go to an event.  They collected extremely promptly, finished the dress and returned the same day.  I would definitely use them again, they were polite and very professional.  Cannot recommend them highly enough!
                    </p>
                    
                  </div>
                </div>                  
                <div className="testimonial-card">
                  <div className="profile-user">
                    <a className="profile-user-letter">
                      S
                    </a>
                    <div className="profile-user-info">
                      <h6>
                        <a>Shelo Hoque</a>
                      </h6>
                    </div>
                  </div>
                  <p>
                  Tim provided a great service and a very quick turnaround. had an outfit dry cleaned and 2 pairs of jeans altered. Would happily use again. Thank you.
                  </p>
                </div>
                <div className="testimonial-img">
                  <img src={testimonialimg2} alt="" />
                  <div className="testimonial-overlay">
                    <h6>Shilpi Nagpal</h6>
                    <p>
                    Crisp and efficient service. The delivered all the shirts well packed and looking as good as new . Highly recommended. Thanku .
                    </p>
                    
                  </div>
                </div>
                <div className="testimonial-card">
                  <div className="profile-user">
                    <a className="profile-user-letter">
                      D
                    </a>
                    <div className="profile-user-info">
                      <h6>
                        <a>dominique jacobs</a>
                      </h6>
                    </div>
                  </div>
                  <p>
                  Excellent service!!! Accommodating and convenient. My experience from start to finish was pleasant as everything was managed with professionalism. My laundry came back clean and beautifully ironed. I highly recommend them!!
                  </p>
                </div>
                <div className="testimonial-img">
                  <img src={testimonialimg3} alt="" />
                  <div className="testimonial-overlay">
                    <h6>Gina Gorham</h6>
                    <p>
                      Amazing service. Collected items and returned back with 24 hours.  Would definitely use again.   Highly recommend
                    </p>
                    
                  </div>
                </div>
              </div>              
                <div className="sascallbtn py-5 text-center">
                    <a href="tel:+(44)7749300244" className="btn secondary-btn">Call Us</a>
                  </div>
              </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionEightThemeOne;
