import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function Whatsapp() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const location = useLocation();
  const link = settingsData?.WebsiteSettings?.social_link10;
  const url = location.pathname;
  const splitURL: any = url.toString().split("/");
  return (
    <>
      {splitURL[1] !== "maintenance" && (
        <div className="link-whatsapp">
          {link !== "" ? (
            <a target="blank" href={link}>
              <i className="fab fa-whatsapp"></i>
            </a>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </>
  );
}

export default Whatsapp;
