import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/plugins/fontawesome/css/fontawesome.min.css";
import "./assets/plugins/fontawesome/css/all.min.css";
import "./assets/css/style.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/@iconscout/unicons/css/line.css";
import reportWebVitals from "./reportWebVitals";
import App from "./components/App";
import { BrowserRouter } from "react-router-dom";
import "primereact/resources/primereact.min.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import Whatsapp from "./components/whatsapp";
import Loader from "./components/loader";
import Cookie from "./components/cookie/cookie";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      {/* <Elements stripe={stripePromise}> */}

      <Loader />
      <App />
      <Cookie />
      {/* </Elements> */}
      <Whatsapp />
      {/* <Livechat/> */}
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
