import React from "react";
import { useSelector } from "react-redux";

function SectionSixThemeFive() {
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  return (
    <div>
      <section
        className="aboutsec"
        style={{
          background: `#${jsonData?.theme_5?.home?.section_6?.background_image_color} url("assets/img/about-section/about-bg.png") no-repeat`,
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 d-none d-md-block">
              <div className="about-img-left aos-init" data-aos="zoom-in-up">
                <img
                  src={jsonData?.theme_5?.home?.section_6?.section_image}
                  className="img-fluid aos-init"
                  alt="About"
                  data-aos="zoom-in-up"
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div
                className="about-content-right aos-init"
                data-aos="zoom-in-up"
              >
                <div className="section-title">
                  <h2 className="text-start section-title-h2">
                    {jsonData?.theme_5?.home?.section_6?.title}
                  </h2>
                  <h2 className="section-title-head">
                    {jsonData?.theme_5?.home?.section_6?.sub_heading}
                  </h2>
                  <div className="section-line">
                    <span className="first-line"></span>
                  </div>
                  <div className="section-line small-line">
                    <span className="second-line"></span>
                  </div>
                </div>

                <p>{jsonData?.theme_5?.home?.section_6?.paragraph}</p>
                <div className="about-icon">
                  <div className="row">
                    {jsonData?.theme_5?.home?.section_6?.card_section.collection.map(
                      (item: any,index: number) => {
                        return (
                          <>
                            <div key={index} className="col-lg-6 col-md-6">
                              <div className="media d-flex">
                                <img
                                  src={item.collection_image}
                                  alt="About Icon"
                                />
                                <div className="media-body flex-grow-1">
                                  <h5>{item.collection_title}</h5>
                                  <p>{item.collection_paragraph}</p>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SectionSixThemeFive;
