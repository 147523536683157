import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {slider1,slider2,slider3,slider4,slider5,slider6,slider7,slider8,upicon1,upicon2,upicon3,upicon4,upicon5,upicon6,upicon7,upicon8} from "../../assets/img";

function Home () {

return (
    <>
        <div className="container">
            <div className="row">
                <div className="col-md-12">                    
                    <div className="slider-up">
                        <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        className="slider-up-slider"
                        >
                            <SwiperSlide >                            
                                <img src={slider1} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider2} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider3} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider4} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider5} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider6} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider7} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider8} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                      </Swiper>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">   
                    <div className="up-content">
                        <p>
                        Welcome to Uplands Laundrette, an independent family business with approximately 5 staff, a commercial laundry and a coin-op laundrette.
                        </p>
                        <p>
                        The laundry & laundrette are fitted with modern and environmentally friendly machines, all working with the necessary temperature of water and quality detergents and chemicals.The laundry does a good honest job and doesn’t cut corners with quality and finish, offering value for money.
                        </p>
                        <p>
                        Our operatives soon understand our clients' exact requirements and provide them with the flexible service they need.
                        </p>
                        <p>
                        Contact us regarding your laundry and dry cleaning requirements as we'd be happy to help.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md">  
                    <div className="up-card">
                        <div className="up-card-icon">
                            <img src={upicon1} alt="" />
                        </div>
                        <p>Self Service Wash</p>
                    </div>
                </div>
                <div className="col-md">  
                    <div className="up-card">
                        <div className="up-card-icon">
                            <img src={upicon1} alt="" />
                        </div>
                        <p>Self Service Wash</p>
                    </div>
                </div>
                <div className="col-md">  
                    <div className="up-card">
                        <div className="up-card-icon">
                            <img src={upicon4} alt="" />
                        </div>
                        <p>Self Service Wash</p>
                    </div>
                </div>
                <div className="col-md">  
                    <div className="up-card">
                        <div className="up-card-icon">
                            <img src={upicon2} alt="" />
                        </div>
                        <p>Clothing Alterations</p>
                    </div>
                </div>
                <div className="col-md">  
                    <div className="up-card">
                        <div className="up-card-icon">
                            <img src={upicon3} alt="" />
                        </div>
                        <p>Dry Cleaning</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md">  
                    <div className="up-card">
                        <div className="up-card-icon">
                            <img src={upicon5} alt="" />
                        </div>
                        <p>Self Service Wash</p>
                    </div>
                </div>
                <div className="col-md">  
                    <div className="up-card">
                        <div className="up-card-icon">
                            <img src={upicon1} alt="" />
                        </div>
                        <p>Self Service Wash</p>
                    </div>
                </div>
                <div className="col-md">  
                    <div className="up-card">
                        <div className="up-card-icon">
                            <img src={upicon8} alt="" />
                        </div>
                        <p>Self Service Wash</p>
                    </div>
                </div>
                <div className="col-md">  
                    <div className="up-card">
                        <div className="up-card-icon">
                            <img src={upicon6} alt="" />
                        </div>
                        <p>Clothing Alterations</p>
                    </div>
                </div>
                <div className="col-md">  
                    <div className="up-card">
                        <div className="up-card-icon">
                            <img src={upicon7} alt="" />
                        </div>
                        <p>Dry Cleaning</p>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}
export default Home;