import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {slider1,slider2,slider3,slider4,slider5,slider6,slider7,slider8,upimg3} from "../../assets/img";

function DryCleaning () {

return (
    <>
        <div className="container">
            <div className="row">
                <div className="col-md-12">                    
                    <div className="slider-up">
                    <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        className="slider-up-slider"
                        >                                                                                  
                            
                            <SwiperSlide >                            
                                <img src={slider4} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider5} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider6} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider7} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>                            
                            <SwiperSlide >                            
                                <img src={slider8} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider1} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider2} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide> 
                            <SwiperSlide >                            
                                <img src={slider3} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                      </Swiper>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">   
                    <div className="up-content">
                        <h2 className="up-h2">DRY CLEANING</h2>
                        <p>At Uplands Laundrette skilled staff combined with the latest cleaning & finishing technology offers a service that is fast and professional dealing with every garment individually.</p>
                        <p>We are experts in dealing with all types of fabrics, from business wear to designer outfits and wedding dresses.</p>
                        <p>Garments are pressed and hand finished with special finishing equipment.</p>
                        <p>We pride ourselves on the quality of our cleaning and have many happy customers who return time after time for a fast, efficient & friendly service.</p>
                        <p>As well as offering Dry Cleaning, we offer a more cost effective alternative, Steam Pressure Cleaning (SPC) for all your traditional dry cleaning requirements.</p>
                        <h4>Price List</h4>
                        <b className="up-sub-head">Suit Dry Cleaning</b>
                        <p>Two piece suit from £12.50</p>
                        <p>Three piece suit from £13.95</p>
                        <b className="up-sub-head">&nbsp</b>
                        <p>Trousers from £7.95</p>
                        <p>Skirt from £7.90</p>
                        <p>Jackets from £9.00</p>
                        <p>Jackets from £9.00</p>
                        <p>Coats from £11.00</p>
                        <p>Shirts from £2.95</p>
                        <p>Jumper Lightweight from £7.00</p>
                        <p>Evening Top from £5.00</p>
                        <p>Silk Evening Top from £5.95</p>
                        <p>Bustier Top from £5.95</p>
                        <p>Silk Bustier Top from £6.95</p>
                        <p>Silk Shirt from £4.95</p>
                        <b className="up-sub-head">Throwovers</b>
                        <p>Single £12.50</p>
                        <p>Double £14.50</p>
                        <b className="up-sub-head">Dresses</b>
                        <p>Day Dresses £12.90</p>
                        <p>Silk Day Dresses £13.90</p>
                        <p>Plain Evening Dresses £12.90</p>
                        <p>Silk Evening Dresses £13.95</p>
                        <p>Elaborate Evening Dresses £15.95</p>
                        <p>Long Evening Dress £14.95</p>
                        <p>Prom Dress £19.95</p>
                        <p>Ball Gown £19.95</p>
                        <b className="up-sub-head">Wedding Items</b>
                        <p>Wedding Dress from £90.00</p>
                        <p>Boxed £99.00</p>
                        <p>Bridemaids from £19.95</p>
                        <p>Flower Girl £15.00</p>
                        <p>Silk Wedding Dress from £100.00</p>
                        <b className="up-sub-head">Accessories</b>
                        <p>Tie £2.95</p>
                        <p>Scarf £3.95</p>
                        <p>Pashmina £6.95</p>
                        <p>Wrap £5.95</p>
                        <p>Hats £5.00</p>
                        <p>Dressing Gown £6.95</p>
                        <p>Waistcoats £5.00</p>
                        <p>Silk Waistcoats £5.95</p>
                        <b className="up-sub-head">Duvet Cleaning</b>
                        <p>Single £13.50*</p>
                        <p>Double £16.50*</p>
                        <p>King Size £18.00*</p>
                        <p>Single Blanket £12.00</p>
                        <p>Hats £5.00</p>
                        <p>Dressing Gown £6.95</p>
                        <p>Waistcoats £5.00</p>
                        <p>Double Blanket £14.50</p>
                        <p>* Additional £6.00 charge for each feather.</p>
                        <b className="up-sub-head">Sleeping Bags</b>
                        <p>Single £11.50</p>
                        <p>Double £13.50</p>
                        <p>Child's Size £10.00</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="up-img">
                        <img src={upimg3} alt="" />
                    </div>
                    <div className="up-card-bg">
                        <p>Book a Pickup - Call 01792 644238 or<br/> text 07999 999294</p>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}
export default DryCleaning;