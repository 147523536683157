import axios from "axios";
import { setShopHomeCms } from "./index";
import {
  shop_data_barber,
  shop_data_cleaning,
  shop_data_garden,
  shop_data_laundry,
  shop_data_nail,
  shop_data_salon,
} from "../../components/shopHome/edit/data";

export const SET_SHOP_HOME = "SET_SHOP_HOME";
export const SET_SHOPS_AREA = "SET_SHOPS_AREA";

export const setShopHome = (payload: any) => ({
  type: SET_SHOP_HOME,
  payload,
});
export const setShopsBasedonArea = (payload: any) => ({
  type: SET_SHOPS_AREA,
  payload,
});

export const fetchShopHomeData = () => async (dispatch: any) => {
  const base_url = process.env.REACT_APP_BACKEND_URL;
  let currentShopId = localStorage.getItem("currentShopHome") || null;
  const url = new URL(window.location.href);

  const url_array = url.pathname.split("/").filter(Boolean);
  let shop_name = url_array[1];
  const queryParamsShopId = url.searchParams.get("shop_id");

  if (queryParamsShopId) {
    shop_name = queryParamsShopId;
    currentShopId = queryParamsShopId;
  }

  localStorage.setItem("currentShopName", shop_name);

  try {
    const response = await axios({
      method: "get",
      url: `${base_url}/api/shop_home_page?shop_name=${shop_name}&shop_id=${currentShopId}`,
      headers: {
        Accept: "application/json",
      },
    });
    if (response) {
      dispatch(setShopHome(response.data.data));
      if (response.data.data.cms_json == "") {
        switch (response.data.data.profile.service_type) {
          case 0:
            dispatch(setShopHomeCms(shop_data_laundry));
            break;
          case 1:
            dispatch(setShopHomeCms(shop_data_cleaning));
            break;
          case 2:
            dispatch(setShopHomeCms(shop_data_salon));
            break;
          case 3:
            dispatch(setShopHomeCms(shop_data_nail));
            break;
          case 4:
            dispatch(setShopHomeCms(shop_data_barber));
            break;
          case 5:
            dispatch(setShopHomeCms(shop_data_garden));
            break;
          default:
            dispatch(setShopHomeCms(shop_data_laundry));
            break;
        }
      } else {
        dispatch(setShopHomeCms(response.data.data.cms_json));
      }
      localStorage.setItem("tenant_id", response.data.data.tenant_id);
      localStorage.setItem(
        "currentShopHome",
        response.data.data.home_page_settings.shop_id
      );
      const faviconLink = document.getElementById('favicon') as HTMLLinkElement;
      if (faviconLink) {
        faviconLink.href = response.data.data.home_page_settings.shop_home_page_image.favicon[0].file_name;
      } else {
        console.error('Favicon link element not found');
      }
    }
    return response;
  } catch (error) {}
};
export const fetchShopsBasedPincode =
  (area: any, currentCodeValue: any) => async (dispatch: any) => {
    const base_url = process.env.REACT_APP_BACKEND_URL;
    try {
      const response = await axios({
        method: "get",
        url: `${base_url}/api/shops_by_district?area_and_code=${area}-${currentCodeValue}`,
        headers: {
          Accept: "application/json",
        },
      });
      if (response) {
        dispatch(setShopsBasedonArea(response.data.data.covered_postcodes));
      }
      return response;
    } catch (error) {}
  };
