import React, { useEffect, useState } from "react";
import { ColorPicker } from "primereact/colorpicker";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeJsonList, saveThemeJsonData } from "../../../redux/Actions";

const HeaderTwoEdit = () => {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  const handleHeaderChange = (e: any) => {
    const { value, name, type, checked } = e.target;
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_2: {
        home: {
          ...prevJsonData.theme_2.home,
          header: {
            ...prevJsonData.theme_2.home.header,
            [name]: value,
            nav: {
              ...prevJsonData.theme_2.home.header.nav,
              [name]: value,
            },
          },
        },
      },
    }));
  };

  const saveJsonData = () => {
    
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);

  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);

  // HTML
  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Header Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <h4 className="edit-section-hdr">Navigation Section </h4>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Background 1</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="nav_bg_color"
                        value={
                          editData?.theme_2?.home?.header?.nav?.nav_bg_color
                        }
                        maxLength={250}
                      />
                      <ColorPicker
                        name="nav_bg_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.theme_2?.home?.header?.nav?.nav_bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Background 2</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="header_bg_color"
                        value={
                          editData?.theme_2?.home?.header?.nav?.header_bg_color
                        }
                        maxLength={250}
                      />
                      <ColorPicker
                        name="header_bg_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.theme_2?.home?.header?.nav?.header_bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Menu Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="menu_font_color"
                        value={
                          editData?.theme_2?.home?.header?.nav?.menu_font_color
                        }
                        maxLength={250}
                      />
                      <ColorPicker
                        name="menu_font_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.theme_2?.home?.header?.nav?.menu_font_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Menu hover</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="menu_font_color_hover"
                        value={
                          editData?.theme_2?.home?.header?.nav
                            ?.menu_font_color_hover
                        }
                        maxLength={250}
                      />
                      <ColorPicker
                        name="menu_font_color_hover"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.theme_2?.home?.header?.nav
                            ?.menu_font_color_hover
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Menu Active</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="menu_font_color_active"
                        value={
                          editData?.theme_2?.home?.header?.nav
                            ?.menu_font_color_active
                        }
                        maxLength={250}
                      />
                      <ColorPicker
                        name="menu_font_color_active"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.theme_2?.home?.header?.nav
                            ?.menu_font_color_active
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="edit-section-hdr">Quick Order Section </h4>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Button</label>
                    <input
                      type="text"
                      className="form-control"
                      name="text"
                      value={
                        editData?.theme_2?.home?.header?.btn_group.btn1?.text
                      }
                      onChange={handleHeaderChange}
                      maxLength={250}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Background</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="btn_bg_color"
                        value={
                          editData?.theme_2?.home?.header?.btn_group?.btn1
                            ?.btn_bg_color
                        }
                        maxLength={250}
                      />
                      <ColorPicker
                        name="btn_bg_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.theme_2?.home?.header?.btn_group?.btn1
                            ?.btn_bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="btn_color"
                        value={
                          editData?.theme_2?.home?.header?.btn_group.btn1
                            ?.btn_color
                        }
                        maxLength={250}
                      />
                      <ColorPicker
                        name="btn_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.theme_2?.home?.header?.btn_group?.btn_group
                            ?.btn1?.btn_color
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="edit-section-hdr">Order Now Section </h4>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Button</label>
                    <input
                      type="text"
                      className="form-control"
                      name="btn2_text"
                      value={
                        editData?.theme_2?.home?.header?.btn_group.btn2
                          ?.btn2_text
                      }
                      onChange={handleHeaderChange}
                      maxLength={250}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Background</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="btn2_bg_color"
                        value={
                          editData?.theme_2?.home?.header?.btn_group?.btn2
                            ?.btn2_bg_color
                        }
                        maxLength={250}
                      />
                      <ColorPicker
                        name="btn2_bg_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.theme_2?.home?.header?.btn_group?.btn2
                            ?.btn2_bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="btn2_color"
                        value={
                          editData?.theme_2?.home?.header?.btn_group.btn2
                            ?.btn2_color
                        }
                        maxLength={250}
                      />
                      <ColorPicker
                        name="btn2_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.theme_2?.home?.header?.btn_group?.btn_group
                            ?.btn2?.btn2_color
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={saveJsonData}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderTwoEdit;
