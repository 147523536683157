import { ColorPicker } from "primereact/colorpicker";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeJsonList, saveThemeJsonData } from "../../../redux/Actions";
import { data } from "../data";

function SectionSevenBannerThemeThree() {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const [selectedImage, setSelectedImage] = useState<string>("trustimg");
  const state: any = useSelector((state) => state);
  const data: any = useSelector<any>((state) => state.homeJsonList);

  useEffect(() => {
    setEditData(data);
  }, [data]);
 
  const saveJsonDataToFile = () => {
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_3: {
        ...prevJsonData.theme_3,
      home: {
        ...prevJsonData.theme_3.home,
        section_7: {
          ...prevJsonData.theme_3.home.section_7,
          btn_group: {
            ...prevJsonData.theme_3.home.section_7.btn_group,
            btn1: {
              ...prevJsonData.theme_3.home.section_7.btn_group.btn1,
            },
          },
        },
      },
    }
    }));
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };
  const handleChange = (e: any) => {
    const { value, name, type, checked } = e.target;
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_3: {
        ...prevJsonData.theme_3,
      home: {
        ...prevJsonData.theme_3.home,
        section_7: {
          ...prevJsonData.theme_3.home.section_7,
          [name]: value,
          btn_group: {
            ...prevJsonData.theme_3.home.section_7.btn_group,
            [name]: value,
            btn1: {
              ...prevJsonData.theme_3.home.section_7.btn_group.btn1,
              [name]: value,
              bg_color:
                name === "bg_color"
                  ? value
                  : prevJsonData.theme_3.home.section_7.btn_group.btn1.bg_color,
              color: name === "color"
              ? value
              : prevJsonData.theme_3.home.section_7.btn_group.btn1.color,
            },
          },
        },
      },
    }
    }));
  };
  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData)
    }
  }, [editData]);
  return (
    <>
        <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Section7 Theme Edit</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-content modal-body">
              <div className="edit-section">
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">
                        Title<small>(max 250 char)</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={editData?.theme_3?.home?.section_7?.title}
                        onChange={handleChange}
                        maxLength={250}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">paragraph</label>
                      <input
                        type="text"
                        className="form-control"
                        name="paragraph"
                        value={editData?.theme_3?.home.section_7?.paragraph}
                        onChange={handleChange}
                        maxLength={1200}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="edit-section">
                <div className="row">
                  <div className="col">
                    <div className=" mb-3">
                      <label className="form-label">Button 1</label>
                      <input
                        type="text"
                        className="form-control"
                        name="text"
                        value={editData?.theme_3?.home.section_7?.btn_group?.btn1?.text}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className=" mb-3">
                      <label className="form-label">Bg Color</label>
                      <div className="input-group color-change">
                        <input
                          type="text"
                          className="form-control"
                          name="bg_color"
                          value={editData?.theme_3?.home?.section_7?.btn_group?.btn1?.bg_color}
                          onChange={handleChange}
                        />
                        <ColorPicker
                          name="bg_color"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          format="hex"
                          value={editData?.theme_3?.home?.section_7?.btn_group?.btn1?.bg_color}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className=" mb-3">
                      <label className="form-label">Color</label>
                      <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="color"
                        value={editData?.theme_3?.home?.section_7?.btn_group?.btn1?.color}
                        onChange={handleChange}
                      />
                      <ColorPicker
                          name="color"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          format="hex"
                          value={editData?.theme_3?.home?.section_7?.btn_group?.btn1?.color}
                        />
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <button
                  type="submit"
                  data-bs-dismiss="modal"
                  className="btn primary-btn"
                  onClick={() => {
                    saveJsonDataToFile();
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default SectionSevenBannerThemeThree;
