import React, { useEffect, useState } from "react";
import axios from "axios";
// import './myFlat.css';
import OfferView from "../offerView";
import { Link } from "react-router-dom";
import {
  offertag,
  offerwashimg,
  offerprod1,
  offerprod2,
  offerprod3,
  offerprod4,
  offerprod5,
  offerprod6,
} from "../../assets/img";
import { useSelector } from "react-redux";

const base_url = process.env.REACT_APP_BACKEND_URL;
const offerListData = {
  result: {
    Response: {
      response_code: "1",
      response_message: "success",
    },
    data: {
      offer_details: [],
    },
  },
};

function SectionFourThemeFive() {
  // alert("called")
  const [changePopup, setChangePopup] = useState(false);
  const [titleValue, setTitleValue] = useState("");
  const [codeValue, setCodeValue] = useState("");
  const [descriptionValue, setDescriptionValueValue] = useState("");
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  const [offerList, setOfferList] = useState<any>([]);
  //const params=useParams()

  const handleClick = (code: any, title: any, description: any, e: any) => {
    //setMobileValue(e.target.value);
    setCodeValue(code);
    setTitleValue(title);
    setDescriptionValueValue(description);
    setChangePopup(!changePopup);
  };

  // useEffect(() => {
  //   const headers = {
  //     Accept: "application/json",
  //   };

  //   axios
  //     .get(`${base_url}/api/list_offer_details`, {
  //       headers: headers,
  //     })
  //     .then((e) => {
  //       setOfferList(e.data);
  //     });
  // }, []);

  return (

    <>

      {offerList?.Response?.response_code != "-1" ? <>
        <style>
          {`                     
            .offer-vector-wrap::after {
            content: url(${jsonData?.theme_5?.home?.section_4?.picture});
          }
          `}
        </style>
        <div
          className="offer-section aos-init"
          style={{
            background: `#${jsonData?.theme_5?.home?.header?.secondary_color} url(${jsonData?.theme_5?.home?.section_4?.banner}) no-repeat`,
          }}
          data-aos="zoom-in-up"
        >
          <div className="offer-vector-wrap"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="offer-tag aos-init" data-aos="zoom-in-up">
                  <img src={jsonData?.theme_5?.home?.section_4?.offer_tag} />
                </div>
                <ul className="offer-list aos-init" data-aos="zoom-in-up">
                  {offerList?.data?.offer_details?.map(
                    (offer_detail: any, offerindex: any) => (
                      <>
                        <li key={offer_detail.id}>
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#myOffer-1"
                            className="offer-btn hover-btn"
                            onClick={(e) =>
                              handleClick(
                                offer_detail.offer_code,
                                offer_detail.title,
                                offer_detail.description,
                                e
                              )
                            }
                          >
                            <div className="offer-wrap-left">
                              <span>{offer_detail.title}</span>
                              <span className="offer-wrap-price">
                                {offer_detail.offer_code}
                              </span>
                            </div>
                            <div className="offer-wrap-right">
                              <img
                                src={offerprod1}
                                className="img-fluid"
                                alt="offer"
                              />
                            </div>
                          </a>
                        </li>
                      </>
                    )
                  )}
                </ul>
                <div className="offer-desc">
                  <p>{jsonData?.theme_5?.home?.section_4?.paragraph_one}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="myOffer-1"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-md modal-dialog-centered">
            <div className="modal-content offermodal-body">
              {changePopup && (
                <OfferView
                  code={codeValue}
                  title={titleValue}
                  description={descriptionValue}
                  close={() => setChangePopup(false)}
                />
              )}
            </div>
          </div>
        </div>
      </> : null}
    </>


  );
}

export default SectionFourThemeFive;
