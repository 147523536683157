import React from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import SectionTwoBannerThemeTwo from "./section-two";
import SectionOneThemeTwo from "./section-one";
import SectionThreeThemeTwo from "./section-three";
import SectionFourThemeTwo from "./section-four";
import SectionFiveThemeTwo from "./section-five";
import SectionSixThemeTwo from "./section-six";
import SectionSevenThemeTwo from "./section-seven";
import SectionEightThemeTwo from "./section-eight";
import SectionNineThemeTwo from "./section-nine";
import SectionOffer from "../theme-four/section-offer";
import GoogleReviews from "../googleReview";
import { checkShopListFlow } from "../../utility";
import HomeShopList from "../homeShopList";
import DailyDeals from "../dailyDeals";

function ThemeTwo() {

  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  const checkIsSectionEnabled = (index: any) => {
    if (settingsData?.template == 2) {
      return jsonData?.theme_2?.home?.sections[index]?.is_section_enable;
    }
  };

  return (
    <>
      {checkIsSectionEnabled(0) && <SectionOneThemeTwo />}
      {checkIsSectionEnabled(1) && <SectionTwoBannerThemeTwo />}
      {checkShopListFlow() && <HomeShopList/>}
      {checkShopListFlow() && <DailyDeals/>}
      {/* <SectionOffer /> */}
      <GoogleReviews />
      {checkIsSectionEnabled(2) && <SectionThreeThemeTwo />}
      {/* <SectionFourThemeTwo /> */}
      {checkIsSectionEnabled(4) && <SectionFiveThemeTwo />}
      {checkIsSectionEnabled(5) && <SectionSixThemeTwo />}
      {checkIsSectionEnabled(6) && <SectionSevenThemeTwo />}
      {checkIsSectionEnabled(7) && <SectionEightThemeTwo />}
      {checkIsSectionEnabled(8) && <SectionNineThemeTwo />}
    </>
  );
}

export default ThemeTwo;
