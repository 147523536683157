export const UKPostalCode =
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
export const USPostalCode =
  /^\d{5}(?:[-\s]\d{4})?$/;
export const email =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
export const alphaNumericPattern = /^[a-zA-Z0-9_ .-]*$/;
export const alphabet = /^[A-Za-z\s]+$/;
export const phoneNumber = new RegExp(
  /^(?:(?:(?:00\s?|\+)44\s?|0\s?|)7(?:[1345789]\d{2}|624)\s?\d{3}\s?\d{3})$/
);
export const addressPattern = /^[a-zA-Z0-9_ ./,-]*$/;
