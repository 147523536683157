import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const SectionFourThemeOne = () => {
  const jsonData: any = useSelector<any>((state) => state?.homeJsonList);
  const settingsData: any = useSelector<any>((state) => state?.settings);
  // HTML
  return (
    <>
      <section className="priority-section">
        <h2 className="section-title text-center">
          {" "}
          {jsonData?.theme_1?.home?.section_4?.title}{" "}
        </h2>
        <div className="container">
          <div className="row justify-content-center">
            {jsonData?.theme_1?.home?.section_4?.cards?.map((items: any, index: any) => {
              return (
                <>
                  {index % 2 === 1 ? (
                    <>
                      <div className="col-md-3">
                        <div className="priority-card text-center mb-3">
                          <img src={items.icon} />
                          <h3>{items.heading}</h3>
                          <p>{items.paragraph}</p>
                        </div>
                        <div className="priority-img">
                          <img src={items.image} alt="" />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-3">
                        <div className="priority-img">
                          <img src={items.image} alt="" />
                        </div>
                        <div className="priority-card text-center mt-3">
                          <img src={items.icon} />
                          <h3>{items.heading}</h3>
                          <p>{items.paragraph}</p>
                        </div>
                      </div>
                    </>
                  )}
                </>
              );
            })}
          </div>
          {settingsData?.WebsiteSettings?.site_title_en.includes("Sasian") && (
              <div className="sascallbtn py-5 text-center">
                <a href="tel:+(44)7749300244" className="btn primary-btn">Call Us</a>
              </div>
            )}
        </div>
      </section>
    </>
  );
};

export default SectionFourThemeOne;
