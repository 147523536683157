import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { shop_data_laundry, shop_data_salon } from "../edit/data";

function SectionSixThemeThree() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const homedata: any = useSelector<any>((state) => state?.shopHomeJsonList);
  //const homedata: any = shop_data_laundry;
  return (
    <>
      <section className="sh3-howwe-section" id="how-work-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sh3-section-title">
                <span className="sh3-yellow-btn">
                  {homedata?.theme_3?.home?.section_6?.sub_heading_top}
                </span>
                <h2 style={{
                      color: `#${homedata?.theme_3?.home?.section_6?.h2color}`
                    }}>
                  {homedata?.theme_3?.home?.section_6?.title}
                </h2>
                <p style={{
                      color: `#${homedata?.theme_3?.home?.section_6?.h2subcolor}`
                    }}>
                  {homedata?.theme_3?.home?.section_6?.paragraph}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Swiper
                spaceBetween={15}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 4,
                  },
                  1024: {
                    slidesPerView: 4,
                  },
                }}
                className="home-slider"
              >
                {homedata?.theme_3?.home?.section_6?.card_section?.collection.map(
                  (items: any, index: any) => {
                    return (
                      <SwiperSlide>
                        <div className="sh3-card-howwe">
                          <div className="sh3-card-howwe-img">
                            <img src={items.icon} alt="" />
                            <span>{index + 1}</span>
                          </div>
                          <h3 style={{
                      color: `#${homedata?.theme_3?.home?.section_6?.h3color}`
                    }}>{items.card_title}</h3>
                          <p style={{
                      color: `#${homedata?.theme_3?.home?.section_6?.paracolor}`
                    }}>{items.card_paragraph}</p>
                          <div className="chips-container">
                            {items.chips &&
                              items.chips.map((chip:any, chipIndex:any) => (
                                <span key={chipIndex} className="chip" style={{
                                  color: `#${homedata?.theme_3?.home?.section_6?.paracolor}`
                                }}>
                                  {chip}
                                </span>
                              ))}
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  }
                )}
              </Swiper>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="sh3-load-more d-none">
                <p>
                  {
                    homedata?.theme_3?.home?.section_6?.card_section?.btn_group
                      ?.paragraph
                  }
                </p>
                <a
                  href="#book-now"
                  className="btn title-btn ms-2 hover-btn"
                  style={{
                    background: `#${homedata?.theme_3?.home?.section_6?.card_section?.btn_group?.btn1?.bg_color}`,
                    color: `#${homedata?.theme_3?.home?.section_6?.card_section?.btn_group?.btn1?.color}`,
                  }}
                >
                  {
                    homedata?.theme_3?.home?.section_6?.card_section?.btn_group
                      ?.btn1?.text
                  }
                  <i className="uil uil-arrow-circle-right ms-2"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionSixThemeThree;
