import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";


function SectionNineThemeThree() {

  const homedata: any = useSelector<any>((state) => state?.homeJsonList);

  return (
    <>
      <section className="serv-section" id="service-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <span className="yellow-btn">{homedata?.theme_3?.home?.section_9?.sub_heading_top}</span>
                <h2 className="text-white">
                {homedata?.theme_3?.home?.section_9?.title}
                </h2>
                <p className="text-white">
                {homedata?.theme_3?.home?.section_9?.paragraph}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="serv-slider-grid">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="home-slider"
                >
                  {homedata?.theme_3?.home?.section_9?.card_section?.collection?.map(
              (items: any,index: number) => {
                return (
                  <>
                  <SwiperSlide key={index}>
                    <div className="card-serv">
                      <div className="card-serv-img">
                        <img src={items.icon} alt="" />
                      </div>
                      <div className="card-serv-body">
                        <h3>{items.collection_title}</h3>
                        <p>
                        {items.collection_paragraph}
                        </p>
                        {/* <a className='btn primary-btn rounded-circle hover-btn'><i className='uil uil-arrow-right'></i></a> */}
                      </div>
                    </div>
                  </SwiperSlide>
                  </>
                );
              }
            )}
                </Swiper>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <ul className="appdata">
              {homedata?.theme_3?.home?.section_9?.card_section?.count_section?.map(
              (item: any, index: number) => {
                return (
                  <>
                <li key={index}>
                  <div className="media d-flex">
                    <span className="flex-shrink-0">
                      <img src={item.icon} alt="" />
                    </span>
                    <div className="flex-grow-1">
                      <h4>{item.count}</h4>
                      <p>{item.text}</p>
                    </div>
                  </div>
                </li>
                </>
                );
              }
            )}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default SectionNineThemeThree;
